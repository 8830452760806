import { useState } from 'react';
import { AxiosResponse } from 'axios';

import { ApiError } from 'src/models';
import { useToast } from 'src/hooks/useToast';
import QuickNotes from '../../../UI/QuickNotes';
import InfoDisplay from '../../../UI/InfoDisplay';
import InfoHistory from '../../../UI/InfoHistory';
import { useRequest } from 'src/hooks/useRequest';
import { IComment } from 'src/models/CommentModel';
import { BlackbookService } from 'src/services/blackbook';
import { ContactType, IContact } from 'src/models/ContactModel';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import { IContactContactTypeEx } from 'src/util/ContactsFunctions';
import AddComentsModal from 'src/components/Modals/AddComentsModal';

const Notes: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {
  const toast = useToast();

  const { data: comments, mutate } = useRequest<IComment[]>({ url: `comments/${contact._id}` })

  const [modal, setModal] = useState<'editing' | 'creating' | false>(false);
  const [comment, setComment] = useState<string>('');
  const [editingId, setEditingId] = useState<string>('');

  const toggleEditComment = (id: string) => {
    setModal('editing');
    setEditingId(id);
    setComment(comments!.find((c) => c._id === id)?.content || '');
  }

  const sendComment = () => {
    if (modal === 'editing') {
      BlackbookService.EditComment(editingId, { content: comment })
        .then(() => {
          setModal(false);
          setEditingId('');
          mutate(undefined, true);
          toast.success('Comment updated.');
        })
        .catch((err: AxiosResponse<ApiError>) => {
          return toast.error(err.data.message);
        });
    } else if (modal === 'creating') {
      BlackbookService.AddComment({ content: comment, assigned_to: contact._id })
        .then(() => {
          setModal(false);
          setComment('');
          mutate(undefined, true);
          toast.success('Comment created.');
        })
        .catch((err: AxiosResponse<ApiError>) => {
          return toast.error(err.data.message);
        })
    }
  }

  const deleteComment = (id: string) => {
    BlackbookService.DeleteComment(id)
      .then((res) => {
        mutate(undefined, true);
        toast.success(res);
      })
      .catch((err: AxiosResponse<ApiError>) => {
        return toast.error(err.data.message);
      })
  }
  
  return (
    <div className="container-fluid px-3">
      <AddComentsModal
        title={modal === 'creating' ? 'Create Comment' : 'Edit Comment'}
        view={modal === 'creating' || modal === 'editing'}
        setView={() => setModal(false)}
        toggle={() => setModal(false)}
        data={comment}
        setData={setComment}
        sendData={sendComment}
      />

      <div className="row border-bottom p-0 py-3">
        <QuickNotes
          isEditable
          title="Comments"
          texts={(comments || []).map((comment) => ({
            id: comment._id,
            text: comment.content,
            date: comment.created_at,
          }))}
          ActionUpdate={toggleEditComment}
          ActionDelete={deleteComment}
        />
        <div style={{ maxWidth: '160px' }}>
          <SimpleButton onClick={() => {
            setComment('');
            setModal('creating')
          }}>
            ADD COMMENT
          </SimpleButton>
        </div>
      </div>

      {(contact.type === ContactType.CLIENT || contact.type === ContactType.FREELANCE) && (
        <div className="row border-bottom p-0 py-3">
          <InfoDisplay
            title="dietary and health notes"
            texts={[{ text: contact.entity.health_note || 'Not configured.' }]}
          />
        </div>
      )}

      <div className="row border-bottom p-0 py-3">
        <InfoHistory
          title="communication history"
          texts={[
            {
              text: 'Aaron Finn sent Isaac Kumer a PFP message',
              date: '27/5/2022 13:47:24',
            },
            {
              text: 'Isaac Kumer called Aaron Finn via VolP',
              date: '27/5/2022 13:47:24',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Notes;
