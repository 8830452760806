import { url } from 'inspector';
import { object, string, number, array ,date, InferType } from 'yup';
import { RatesModel } from '../../../../../models/ItemModel';

const RatesSchema = object({
    hour: number().typeError('must be a number'),
    daily: number().typeError('must be a number'),
    week: number().typeError('must be a number'),
    sale: number().typeError('must be a number'),
    holiday_hour: number().typeError('must be a number'),
    holiday_day: number().typeError('must be a number'),
    overtime_hour: number().typeError('must be a number'),
    holiday_overtime_hour: number().typeError('must be a number')
})

export default RatesSchema