import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Create from "../../../../Rates/Create/Create";
import CreateVersion from "../../../../Rates/Create/Version";
import useQuery from "../../../../../hooks/useQuery";
import RateListQuote from "./RateListQuote";

type RateParams = {
  rateType: string;
  rateCardVersion: string;
  setGetRates: any;
  setView: any;
  setViewRates: any;
  typeRateCards: string;
  setTypeRateCards: any;
};

const AddRates = (props: any) => {
  const query = useQuery();
  const history = useHistory();
  const params = useParams<RateParams>();

  const [isContactViewOpen, setIsContactViewOpen] = useState<boolean>(false);

  const [isComparing, setIsComparing] = useState<boolean>(false);
  const [globalDiscount, setGlobalDiscount] = useState<boolean>(false);
  const [showRateSelect, setShowRateSelect] = useState<boolean>(false);

  const [typeRate, setTypeRate] = useState<string>("master");

  const [isAddingRateCard, setIsAddingRateCard] = useState<boolean>(false);
  const [isAddingRateCardVersion, setisAddingRateCardVersion] =
    useState<boolean>(false);

  const toggleAddingRateCard = (type?: string) => {
    if (isAddingRateCard) {
      query.delete("create");
      history.replace({ search: query.toString() });
    } else {
      query.set("create", type || "");
      history.push({ search: query.toString(), pathname: "/rates" });
    }
    setIsAddingRateCard((prev) => !prev);
  };

  useEffect(() => {
    const { rateType } = params;
    const shouldOpenCreateView = query.get("create");

    if (
      shouldOpenCreateView !== undefined &&
      shouldOpenCreateView !== null &&
      !isAddingRateCard
    ) {
      setIsAddingRateCard(true);
    }

    if (shouldOpenCreateView === undefined || shouldOpenCreateView === null) {
      setIsAddingRateCard(false);
      if (!rateType) {
        // history.push("/rates/system");
      }
    }

    if (rateType !== typeRate) {
      setTypeRate(rateType);
    }
  }, [params, isAddingRateCard]);

  return (
    <div>
      {isAddingRateCard && <Create />}

      {(isAddingRateCardVersion || params.rateCardVersion) && (
        <CreateVersion
          toggleOnAddRateCardVersion={() =>
            setisAddingRateCardVersion((prev) => !prev)
          }
        />
      )}

      {/* {globalDiscount && (
        <GlobalDiscount
          onGlobalChange={() => setGlobalDiscount((prev) => !prev)}
        />
      )} */}

      {!globalDiscount &&
        !isComparing &&
        !isAddingRateCard &&
        !isAddingRateCardVersion &&
        !params.rateCardVersion && (
          <div className={`container-fluid h-100 `}>
            <div className="row" style={{ height: "98%" }}>
              {!params.rateCardVersion && (
                <RateListQuote
                  onAddRateCard={toggleAddingRateCard}
                  typeRate={typeRate}
                  onChangeTypeRate={setTypeRate}
                  setGetRates={props.setGetRates}
                  setView={props.setView}
                  setViewRates={props.setViewRates}
                  typeRateCards={props.typeRateCards}
                  setTypeRateCards={props.setTypeRateCards}
                />
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default AddRates;
