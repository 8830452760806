import de from "@mobiscroll/react/dist/src/i18n/de";
import axios from "axios";
import { R } from "src/models";


const GetPublicData = async (id:any) => {
    const response = await axios
      .get("factory/quotes/" + id +  "/last-version")
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    return response?.data.data;
  };

const ChangeStatusQuote = async (id:any , data:any) => {
  const response = await axios
  .patch("factory/quotes/" + id + "/public-status", data)
  .catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });
return response?.data.data;
}

export const factoryPublicService = {
    GetPublicData,
    ChangeStatusQuote
};

export default factoryPublicService;
