import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import AccesoriesSchema from './validation';
import { useFormik, Form, FormikProvider, Formik } from 'formik';
import { AccesoriesModel } from '../../../../../models/ItemModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { updateAccesoriesMandatory, setAccesoriesMandatory, setAccesoriesOptional, updateAccesoriesOptional } from '../../../../../store/stockpile/createFormSlice';
import { useEffect, useState } from 'react';
import { Button, Input, Table } from 'reactstrap';
import IconButton from '../../../../UI/buttons/IconButton';
import SimpleButton from '../../../../UI/buttons/SimpleButton';
import PackageModal from '../../../../Modals/PackageModal/PackageModal';
import SpecificAsset from '../../../../Modals/SpecificAssetModal/SpecificAsset';
import { cleanAssets, deleteItemPackage, setItemPackage, setNewInfoRates, updateQtyRateInfo } from '../../../../../store/stockpile/packageItems';
import EmptyCircle from 'src/components/UI/EmptyCircle';
import ItemsSchema from './validation';
import Trash from '../../../../../assets/icons/trash.svg'
import { GetAllRateForItem } from 'src/store/stockpile/stockpileSlice';


interface Props {
  toggleAddingPackage: any
  maxValue: any,
  setMaxValue: any
}

interface rowProps {
  index: any;
  item: any;
  setViewModalAsset: any
  setID: any
  setMaxValue: any,
  maxValue: any,
  setIndex: any,
  selectID:any
}

const RowItemTable = (props: rowProps) => {
  const [insideValue, setInsideValue] = useState(1)
  const dispatch = useDispatch()
  const Items = useSelector((state: RootState) => state.packageItems.RatesDataInfo)

  const [viewModal, setViewModalAsset] = useState<boolean>(false)

  const item = props.item
  const setID = props.setID
  const setMaxValue = props.setMaxValue

  // const item = props.item;
  // const setViewModalAsset = props.setViewModalAsset;
  // const setID = props.setID;
  // const setMaxValue = props.setMaxValue;

  const formik = useFormik({
    initialValues: {
      qty: 1
    },
    validationSchema: ItemsSchema,
    onSubmit: () => { }
  })

  const { errors, setErrors, values, setFieldValue } = formik

  const getItemsRates = async () => {

    const res: any = await dispatch(GetAllRateForItem(item?._id))
    return res?.payload?.data && res?.payload?.data[0]?.rate_card_item?.rates

  }

  const Rates: Object = getItemsRates()

  useEffect(() => {
  setFieldValue('qty' , props.maxValue[props.index])  
  }, [props.maxValue[props.index]])

  return (
    <>
    <tr>
      <td>
        <div className={`col d-flex p-0 text-truncate`} >

          {props.item.picture_url && <img
            style={{ width: '40px', height: '40px', marginLeft: '20px' }}
            loading="lazy" src={process.env.REACT_APP_IMAGES_AWS + props.item.picture_url} alt="" className="avatar2" />
          }
          {
            !props.item.picture_url &&
            <div style={{ width: '40px', height: '40px', marginLeft: '20px' }} >
              <EmptyCircle title={props.item.name} small />
            </div>
          }
          <div className="ms-2">
            <p className="ItemRowTableForm">{item?.name}</p>
          </div>
        </div>
      </td>
      <td className='backred'>
        <input
          type="number"
          style={{ width: '52px', height: '30px', marginLeft: '-5%', marginTop: '5px' }}
          value={formik.values.qty}
          onChange={async (e: any) => {
            setInsideValue(e.target.value)
            let cpt = props.maxValue
            cpt[props.index] = e.target.value
            setMaxValue(cpt)
            const res: any = await dispatch(GetAllRateForItem(item?._id))
            if (res.payload) {
              res?.payload?.data && res?.payload?.data.length > 0 && dispatch(updateQtyRateInfo({
                index: props.index,
                qty: e.target.value,
                rates: res?.payload?.data[0]?.rate_card_item?.rates
              }))
            }
            setFieldValue('qty', e.target.value)
          }}
        />
      </td>
      {errors.qty && 
      <p className='validation'>the value should be more than 0</p>
      }
      <td>
        <p style={{ marginTop: '11px' }}>{props.maxValue[props.index]}</p>
      </td>
      <td>
        <p style={{ marginTop: "11px" }}>Equipment</p>
      </td>
      <td>
        <button
          onClick={() => {
            setViewModalAsset(true)
            setID(item._id)
           // props.setIndex(props.index)
            
            // setMaxValue(formik.values.qty)
          }}
          className="btn btn-primary mx-1 typo-body text-white"
          style={{ marginTop: "5px" }}
        >
          SELECT SPECIFIC ASSET
        </button>
      </td>
      <td>
        <div style={{ marginLeft: '-120%', marginTop: '5px' }} >
          <div>
            <IconButton
              color="danger"
              custom={Trash}
              customStyles={{ width: '12px', height: '12px' }}
              onClick={() => {
                dispatch(deleteItemPackage(props.index))
                let cpt = props.maxValue
                cpt[props.index] = 1
                setMaxValue(cpt)
              }}
              outline
            />
          </div>
        </div>
        <SpecificAsset
            view={viewModal}
            setView={setViewModalAsset}
            IDITEM={props.selectID}
            maxValue={props.maxValue}
            setMaxValue={setMaxValue}
            index={props.index}
          />
      </td>
    </tr>


    </>
  )
}

const Items: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const Items = useSelector((state: RootState) => state.packageItems.Items);
  const AssetsData: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AssetData);
  const Assets = useSelector((state: RootState) => state.packageItems.PackageAssets);


  const [viewModal, setViewModal] = useState<boolean>(false)
  const [viewModalAsset, setViewModalAsset] = useState<boolean>(false)
  const [selectID, setID] = useState<any>('')
  const [indexItem, setIndexItem] = useState<any>(0)

  const maxValue = props.maxValue
  const setMaxValue = props.setMaxValue

  const [itemsPreselect, setPreselectItems] = useState<Array<any>>([])

  const formik = useFormik({
    initialValues: {} as AccesoriesModel,
    validationSchema: AccesoriesSchema,
    onSubmit: () => {},
  });

  const { errors, touched, handleSubmit, values, setFieldValue } = formik;

  return (
    <div className="container mt-3 ">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate style={{ background: '' }} >
          <div className="">
            <div style={{ marginLeft: '-4.35%' }}>
              <Table className='table table-striped table-borderless table-hover w-100'
                responsive="lg"
                style={{ minWidth: '100%' }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sortable HeadTableFonts"
                      style={{ marginLeft: "20%" }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; Item name
                    </th>
                    <th className="HeadTableFonts" scope="col">
                      QTY
                    </th>
                    <th className="HeadTableFonts" scope="col">
                      Stock
                    </th>
                    <th className="HeadTableFonts" scope="col">
                      category
                    </th>
                    <th className="HeadTableFonts" scope="col">
                      specific asset
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Items.map((item: any, index: any) => {
                      return (
                        <RowItemTable
                          index={index}
                          item={item}
                          setViewModalAsset={setViewModalAsset}
                          setID={setID}
                          selectID={selectID}
                          setMaxValue={setMaxValue}
                          maxValue={maxValue}
                          setIndex={setIndexItem}
                        />
                      )
                    })
                  }
                </tbody>
              </Table>
              <div className="table-msg">
                {Items.length == 0 ? <p>There is no selected item</p> : <></>}
              </div>
            </div>
          </div>

          <PackageModal
            View={viewModal}
            setView={setViewModal}
            toggleAddingPackage={() => { }}
            itemsPreselect={itemsPreselect}
            setPreselectItems={setPreselectItems}
            Qtys={maxValue}
          />
        </Form>
      </FormikProvider>
      <div style={{ maxWidth: '130px', maxHeight: '200px', marginLeft: '-4%', marginTop: '-1%' }} >
        <SimpleButton onClick={() => { setViewModal(true) }}>
          ADD ITEMS
        </SimpleButton>
      </div>
    </div>
  );
};

export default Items;
