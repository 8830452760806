import { Input } from 'reactstrap';
import React, { useCallback, useRef } from "react";
import { parseInputError } from 'src/util';

interface ButtonFileProps {
    label: string;
    size?: boolean;
    error?: string;
    accept?: string;
    multiple?: boolean;
    selectedName?: string | string[];
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ButtonFiles = ({
    size,
    error,
    label,
    accept,
    onChange,
    multiple,
    selectedName,
}: ButtonFileProps) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const valueCalc = () => {
        if (!(selectedName instanceof Array)) {
            if (selectedName) {
                return selectedName
            } else {
                return label
            }
        } else {
            if (selectedName) {
                return selectedName.toString()
            } else {
                return label
            }
        }
    }

    const openFileSelector = useCallback(() => {
        if (inputRef && inputRef.current)
            inputRef.current.click()
    }, [inputRef])
    
    return (
        <div
            className={`input-file row ${size?'':'col-12'}`}
        >
            <div className={`col-9`} style={{ justifyContent: 'flex-end', display: 'flex', paddingRight: '0px' }} onClick={openFileSelector}>
                <input
                    hidden
                    type="file"
                    ref={inputRef}
                    accept={accept}
                    multiple={multiple}
                    onChange={onChange}
                    className="custom-file-input"
                />

                <Input
                    disabled
                    type="text"
                    value={valueCalc()}
                    className={"form-control" + (error ? " is-invalid" : "")}
                    aria-label="File input with segmented button"
                    style={{ cursor: 'pointer', height: '42px', borderRadius: '5px 0px 0px 5px' }}
                />
            </div>
            <div className="col-3" style={{ background: '', alignItems: 'flex-start', display: 'flex', paddingLeft: '0px' }} onClick={openFileSelector}>
                <button className="input-group-text" onClick={() => inputRef?.current?.focus()} style={{ height: 42 }}>
                    Select File
                </button>
            </div>
            {error && <div className="validation">{parseInputError(error)}</div>}
        </div>
    )
}

export default ButtonFiles

