import React, { useState } from "react";
import { Modal } from "reactstrap";
import AssingAsset from "./AssingAsset";
import CreateNewQuote from "./CreateNewQuote";
import CreateNewVersionQuote from "./CreateNewVersionQuote";

// import EditColumns from "./EditColumns";
import EditNewVersionQuote from "./EditNewVersionQuote ";
import MergeQuotes from "./MergeQuotes";

interface Props {
  viewModal: boolean;
  setViewModal: (value: boolean) => void;
  toggle: any;
  modalQuotes: boolean;
  setModalQuotes: (value: boolean) => void;
  editColumns: boolean;
  setEditColumns: (value: boolean) => void;
  modalAssignAsset: boolean;
  setModalAssignAsset: (value: boolean) => void;
  modalNewQuotes: boolean;
  setModalNewQuotes: (value: boolean) => void;
  ApproveQuote: any;
  loadingQuote: boolean;
  modalBrief: boolean;
  setModalBrief: (value: boolean) => void;
  jobDataById: any;
  idquote: any;
  projectDataId: any;
  modalEditVersion: boolean;
  setModalEditVersion: (value: boolean) => void;
  filterDataRate: any;
  handleSave: any;
  setIs_new_version: (value: boolean) => void;
  compareArray: boolean;
  savingRate: boolean;
  setSavingRate: (value: boolean) => void;
  viewCreateRate: boolean;
  setViewCreateRate: (value: boolean) => void;
  modalConfirm?: boolean;
  setModalConfirm: (value: boolean) => void;
  secondMessage: boolean;
  setSecoundMessage: (value: boolean) => void;
  modalcreateQuote: boolean;
  setModalcreateQuote: (value: boolean) => void;
  closeMessage: boolean;
  setCloseMessage: (value: boolean) => void;
  typeRateCards: string;
  getRates: any;
}
const ModalsQuote: React.FC<Props> = (props) => {
  const closeModal = () => {
    props.setModalAssignAsset(false);
    props.setViewModal(false);
    props.setModalQuotes(false);
    props.setEditColumns(false);
    props.setModalNewQuotes(false);
    props.setModalEditVersion(false);
    props.setModalBrief(false);
    props.setIs_new_version(false);
    props.setModalcreateQuote(false);
    props.setSecoundMessage(false);
    props.setModalConfirm(false);
  };

  return (
    <div className="containerModalTwo">
      <Modal
        isOpen={props.viewModal}
        toggle={() => props.toggle}
        centered
        className="modalQuoteTwo"
        style={
          props.editColumns
            ? {
                width: "350px",
                maxWidth: "400px",
                position: "absolute",
                right: 0,
              }
            : props.modalQuotes
            ? {
                width: "1250px",
                maxWidth: "1250px",
              }
            : props.modalAssignAsset
            ? {
                width: "900px",
                maxWidth: "900px",
              }
            : props.modalNewQuotes || props.modalEditVersion
            ? {
                width: "550px",
                maxWidth: "650px",
              }
            : {
                width: "550px",
                maxWidth: "650px",
              }
        }
      >
        <div
          className={
            "text-center d-flex flex-column custom-scrollbar overflow-hidden"
          }
          style={
            props.editColumns
              ? { height: "650px" }
              : props.modalQuotes
              ? { height: "650px" }
              : props.modalAssignAsset
              ? { height: "550px" }
              : props.modalNewQuotes || props.modalEditVersion
              ? { height: "230px" }
              : { height: "230px" }
          }
        >
          <div
            onClick={closeModal}
            className="cursor-pointer p-2"
            style={{ width: "30px" }}
          >
            <i
              className="bi bi-x-lg  "
              style={{
                fontSize: "18px",
              }}
            ></i>
          </div>
          {
            // props.editColumns ? (
            //   <EditColumns />
            // ) :
            props.modalQuotes ? (
              <MergeQuotes />
            ) : props.modalAssignAsset ? (
              <AssingAsset />
            ) : props.filterDataRate() === true &&
              props.compareArray === true ? (
              // props.modalcreateQuote
              <CreateNewQuote
                setViewModal={props.setViewModal}
                ApproveQuote={props.ApproveQuote}
                loadingQuote={props.loadingQuote}
                filterDataRate={props.filterDataRate}
              />
            ) : (props.idquote === undefined &&
                props.filterDataRate() === false) ||
              (props.idquote === undefined && props.compareArray === false) ? (
              <CreateNewVersionQuote
                setViewModal={props.setViewModal}
                ApproveQuote={props.ApproveQuote}
                loadingQuote={props.loadingQuote}
                projectDataId={props.projectDataId}
                closeModal={closeModal}
                handleSave={props.handleSave}
                filterDataRate={props.filterDataRate}
                setIs_new_version={props.setIs_new_version}
                savingRate={props.savingRate}
                setSavingRate={props.setSavingRate}
                viewCreateRate={props.viewCreateRate}
                setViewCreateRate={props.setViewCreateRate}
                modalConfirm={props.modalConfirm}
                setModalConfirm={props.setModalConfirm}
                secondMessage={props.secondMessage}
                setSecoundMessage={props.setSecoundMessage}
                idquote={props.idquote}
                closeMessage={props.closeMessage}
                setCloseMessage={props.setCloseMessage}
                typeRateCards={props.typeRateCards}
                getRates={props.getRates}
              />
            ) : (props.idquote !== undefined &&
                props.filterDataRate() === false) ||
              (props.idquote !== undefined && props.compareArray === false) ? (
              <EditNewVersionQuote
                setViewModal={props.setViewModal}
                ApproveQuote={props.ApproveQuote}
                loadingQuote={props.loadingQuote}
                projectDataId={props.projectDataId}
                closeModal={closeModal}
                handleSave={props.handleSave}
                filterDataRate={props.filterDataRate}
                setIs_new_version={props.setIs_new_version}
                savingRate={props.savingRate}
                setSavingRate={props.setSavingRate}
                viewCreateRate={props.viewCreateRate}
                setViewCreateRate={props.setViewCreateRate}
                modalConfirm={props.modalConfirm}
                setModalConfirm={props.setModalConfirm}
                secondMessage={props.secondMessage}
                setSecoundMessage={props.setSecoundMessage}
                idquote={props.idquote}
                closeMessage={props.closeMessage}
                setCloseMessage={props.setCloseMessage}
              />
            ) : (
              []
            )
          }
        </div>
      </Modal>
    </div>
  );
};

export default ModalsQuote;
