import React from "react";
import RateModel from "../../../../../models/RateModel";

interface Props {
  onSelect?: (id: string, rate_card_version: string, name: string) => void;
  contact: RateModel;
  active?: boolean;
  block?: boolean | false;
}

const RateItemQuote: React.FC<Props> = (props) => {
  return (
    <div
      className={
        (props.active ? "active " : "") +
        (!props.block ? "border-top " : "") +
        " container-fluid p-3 py-2 contact-item"
      }
      onClick={props.onSelect?.bind(null, "0")}
    >
      <div className="row align-items-center">
        <div
          className={`col d-flex p-0 text-truncate ${
            // props.onSelect ? 'ms-3' : 'ms-2'
            "ms-2"
          }`}
        >
          <div className="ms-2">
            <p className="m-0 text-black typo-body contact-title">
              {props.contact.name}{" "}
              {props.block ? (
                <i className="ms-3 bi bi-lock-fill text-primary"></i>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateItemQuote;
