import { createRef, useCallback, useRef } from "react";
import { Input, Label } from "reactstrap";

interface Props {
  title: string;
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  checked?: boolean
}


const Checkbox: React.FC<Props> = (props) => {


  return (
    <div className={`form-check p-0 m-0 ${props.className}`}>
      <Label className="typo-body text-primary p-0 m-0" style={{ whiteSpace: 'nowrap' }} check>
        <Input
          className="form-check-input"
          type="checkbox"
          defaultChecked={props.value}
          checked={props.checked}
          onChange={props.onChange.bind(null, !props.value)}
        />{' '}
        <p className="ms-1 p-0 mb-0" style={{marginTop: '1px'}}>
          {props.title}
        </p>
      </Label>

    </div>
  );
};

export default Checkbox;
