export const ConvertSize: any = (size: any) => {
    switch (size) {
        case 'meters':
            return 'mts'
            break;
        case 'grams':
            return 'g'
            break;
        case 'centimeters':
            return 'cm'
        break;
        default:
            break;
    }
}