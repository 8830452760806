import isJSON from "../util/isJSON";

export interface TokenData {
  access_token: string;
  refresh_token: string;
}

export class TokenService {
  public static readonly key = 'user';

  public static removeUserSession() {
    localStorage.removeItem(this.key)
  }

  public static createUserSession(data: TokenData) {
    localStorage.setItem(this.key, JSON.stringify(data))
  }

  public static getSessionUser(): TokenData | null {
    const data = localStorage.getItem(this.key);
    if (!data || !isJSON(data)) return null;
    
    const parsed: TokenData = JSON.parse(data);

    if (
      !parsed.access_token ||
      !parsed.refresh_token
    ) return null;

    return parsed;
  }
}

export default TokenService;