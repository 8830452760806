import React from "react";
import {
  TreeListTextEditor,
  TreeListNumericEditor,
  TreeListDateEditor,
  TreeListBooleanEditor,
} from "@progress/kendo-react-treelist";
import { useEffect, useState } from "react";
import update from "immutability-helper";
import SelectUnitQuote from "../../../UI/forms/SelectUnitQuote";
import { setEditQuote } from "../../../../store/factory/factorySetTableQuote";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface TdWrapper {
  className: any;
  record: any;
}

const InputWrapper = ({
  onEdit,
  className,
  inputValue,
  type,
  style,
  min,
  max,
}: any) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(inputValue);
  }, []);

  return (
    <input
      value={value}
      className={className}
      onBlur={() => onEdit(value)}
      type={type}
      onChange={(e: any) => setValue(e.target.value)}
      style={style}
      min={min}
      max={max}
    />
  );
};

export const TdWrapper: React.FC<TdWrapper> = (props) => {
  return (
    <td className={"TdWrapper"} style={{ zIndex: "1" }}>
      {props.children}
    </td>
  );
};

export default (
  setView: any,
  handle_Select_sub_Header: any,
  setGetHeader: any,
  quantity: number,
  setQuantity: any,
  rate: number,
  setRate: any,
  units_number: number,
  setUnits_number: any,
  units_type: any,
  setUnits_type: any,
  quoteForm: any,
  setQuoteForm: any,
  formControl: boolean,
  setformControl: any,
  addItems: any,
  setAddItems: any,
  setCountHeader: any,
  specificRatesbyItemId: any,
  getIdItem: any,
  setGetIdItem: any,
  getIdItemRate: any,
  setModalAssignAsset: any,
  setViewModal: any,
  indexSelected: any,
  setIndexSelected: any,
  deleteItemsColumn: any,
  setGetIdItemForAssets: any,
  formatNumber: any
) => {
  const addItemsTree = (e: any, dataItem: any) => {
    e.stopPropagation();
    setView(true);
    setAddItems(true);

    if (dataItem.name === "header") {
      setGetHeader(dataItem);
    } else {
      setGetHeader(dataItem);
    }
  };

  const totalItems = (dataItem?: any) => {
    const indexItems = quoteForm.headers.findIndex(
      (i: any) => i.id === dataItem.id
    );
    const qf = quoteForm?.headers[indexItems] ?? {};
    let QTY = qf.quantity;
    let RATE = qf.rate;
    let UNITS = qf.units_number;

    const result = Number(QTY) * Number(RATE) * Number(UNITS);

    return formatNumber(result);
  };

  const optionSelect = [
    { label: "day", value: "daily" },
    { label: "holiday_day", value: "holiday_day" },
    { label: "holiday_hour", value: "holiday_hour" },
    { label: "holiday_overtime_hour", value: "holiday_overtime_hour" },
    { label: "hour", value: "hour" },
    { label: "overtime_hour", value: "overtime_hour" },
    { label: "sale", value: "sale" },
    { label: "week", value: "week" },
  ];

  let icon = (
    <div className="required ">
      <i
        className={`${
          formControl
            ? "bi bi-arrows-angle-expand me-3 fs-5 cursor-pointer"
            : " bi bi-arrows-angle-contract me-3 fs-5 cursor-pointer"
        }  `}
        onClick={() => setformControl(!formControl)}
      />
      Item
    </div>
  );

  const getIndexSelect = (val: any, indexItems: any, dataItem: any) => {
    const data = dataItem.items?._id;

    setIndexSelected(indexItems);

    setGetIdItem(data);

    setQuoteForm((prev: any) =>
      update(prev, {
        headers: {
          [indexItems]: {
            units_type: { $set: val },
          },
        },
      })
    );
  };

  const assignAsset = (dataItem: any) => {
    console.log("dataItemmmmmmmmmmmmmmmm para Asset", dataItem.items?._id);
    setViewModal(true);
    setModalAssignAsset(true);
    setGetIdItemForAssets(dataItem.items?._id);
  };

  const dispatch = useDispatch();
  const QuoteValue: any = useSelector(
    (state: RootState) => state.factorySetTableQuote
  );

  // console.log("dataquote", QuoteValue);

  // const InputWrapper = ({
  //   item,
  //   indexItems,
  //   quoteForm,
  //   setQuoteForm,
  //   dataItem,
  //   onEdit,
  //   className,
  //   inputValue,
  //   type,
  //   style,
  //   min,
  //   max,
  // }: any) => {
  //   const [value, setValue] = useState("");

  //   useEffect(() => {
  //     setValue(inputValue);
  //   }, []);

  //   const getValue = (e: any) => {
  //     setValue(e.target.value);

  //     // if (dataItem[indexItems].id !== QuoteValue[indexItems].id) {
  //     //   dispatch(setEditQuote(dataItem));
  //     // }
  //   };

  //   useEffect(() => {
  //     dispatch(setEditQuote(QuoteValue));
  //   }, [QuoteValue]);

  //   return (
  //     <input
  //       value={value}
  //       className={className}
  //       onBlur={() => onEdit(value)}
  //       type={type}
  //       onChange={(e: any) => getValue(e)}
  //       style={style}
  //       min={min}
  //       max={max}
  //     />
  //   );
  // };

  return [
    {
      field: "name",
      title: <span>{icon}</span>,
      width: "380px",
      editCell: TreeListTextEditor,
      expandable: true,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        const indexItems = quoteForm.headers.findIndex(
          (i: any) => i.id === dataItem.id
        );

        return (
          <>
            <TdWrapper className="" record={""}>
              {dataItem.assigned_to === null &&
              dataItem.type !== "sub header" ? (
                <div
                  className="container-column "
                  style={{
                    marginBottom: "-12px",
                    marginTop: "-12px",
                    zIndex: "1",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                      style={{ height: "65px" }}
                    >
                      <i className="bi bi-list fs-3 me-1" />

                      <InputWrapper
                        quoteForm={quoteForm}
                        setQuoteForm={setQuoteForm}
                        dataItem={dataItem}
                        onEdit={(value: any) =>
                          setQuoteForm(
                            update(quoteForm, {
                              headers: {
                                [indexItems]: { name: { $set: value } },
                              },
                            })
                          )
                        }
                        inputValue={quoteForm.headers[indexItems].name}
                        type="text"
                        className=" p-1 ms-1 "
                        style={{
                          width: "150px",
                          height: "30px",
                          border: "none",
                          borderRadius: "5px",
                        }}
                      />

                      {dataItem.employees && (
                        <div
                          onClick={(e) => onExpandChange(e, dataItem, level)}
                          role="button"
                          tabIndex={-1}
                        >
                          {expanded ? (
                            <i className="bi bi-caret-up-fill ms-1" />
                          ) : (
                            <i className="bi bi-caret-down-fill ms-1" />
                          )}
                        </div>
                      )}

                      <div
                        className="cursor-pointer ms-3"
                        onClick={(e) => addItemsTree(e, dataItem)}
                      >
                        <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6 me-2" />
                        <small className=" text-primary text-decoration-underline">
                          ADD ITEMS
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              ) : dataItem.type === "sub header" ? (
                <div
                  className="container-column "
                  style={{
                    marginTop: "-12px",
                    zIndex: "1",
                    marginBottom: "-12px",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                      style={{ height: "65px" }}
                    >
                      <i className="bi bi-list fs-3 ms-3 me-1" />
                      <InputWrapper
                        quoteForm={quoteForm}
                        setQuoteForm={setQuoteForm}
                        dataItem={dataItem}
                        onEdit={(value: any) =>
                          setQuoteForm(
                            update(quoteForm, {
                              headers: {
                                [indexItems]: { name: { $set: value } },
                              },
                            })
                          )
                        }
                        inputValue={quoteForm.headers[indexItems].name}
                        type="text"
                        className=" p-1 ms-1 "
                        style={{
                          width: "150px",
                          height: "30px",
                          border: "none",
                          borderRadius: "5px",
                        }}
                      />
                      {dataItem.employees && (
                        <div
                          onClick={(e) => onExpandChange(e, dataItem, level)}
                          role="button"
                          tabIndex={-1}
                        >
                          {expanded ? (
                            <i className="bi bi-caret-up-fill ms-1" />
                          ) : (
                            <i className="bi bi-caret-down-fill ms-1" />
                          )}
                        </div>
                      )}

                      <div
                        className="cursor-pointer ms-3"
                        onClick={(e) => addItemsTree(e, dataItem)}
                      >
                        <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6 me-2" />
                        <small className=" text-primary text-decoration-underline">
                          ADD ITEMS
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex ms-3" style={{ zIndex: "1" }}>
                  <div className="d-flex">
                    <div className="">
                      <i className="bi bi-list fs-3 me-2 mb-2" />
                    </div>

                    <div className="d-flex flex-column mt-2">
                      <span className="pb-2" style={{ marginBottom: "-10px" }}>
                        {dataItem?.items?.name}
                      </span>
                      <small className="text-gray mb-2">
                        {dataItem.items?.category?.sub_category?.name}
                      </small>
                      <button
                        className="btn btn-primary text-white "
                        onClick={() => assignAsset(dataItem)}
                        style={{ width: "150px" }}
                      >
                        ASSIGN ASSET
                      </button>
                    </div>
                  </div>

                  {dataItem.employees && (
                    <div
                      onClick={(e) => onExpandChange(e, dataItem, level)}
                      role="button"
                      className=""
                    >
                      {expanded ? (
                        <i className="bi bi-caret-up-fill ms-1" />
                      ) : (
                        <i className="bi bi-caret-down-fill ms-1" />
                      )}
                    </div>
                  )}
                </div>
              )}
            </TdWrapper>
          </>
        );
      },
    },
    {
      field: "quantity",
      title: <span className="required">QTY</span>,
      width: "120px",
      editCell: TreeListTextEditor,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        const indexItems = quoteForm.headers.findIndex(
          (i: any) => i.id === dataItem.id
        );

        return (
          <TdWrapper className="" record={""}>
            {dataItem.assigned_to === null && dataItem.type !== "sub header" ? (
              <div
                className="container-column "
                style={{
                  marginBottom: "-12px",
                  marginTop: "-12px",
                  zIndex: "1",
                }}
              >
                <div className="row">
                  <div
                    className="col-md-12  bg-light-blue  p-2  d-flex justify-content-start align-items-center "
                    style={{ height: "65px" }}
                  ></div>
                </div>
              </div>
            ) : dataItem.type === "sub header" ? (
              <div
                className="container-column "
                style={{
                  marginTop: "-12px",
                  zIndex: "1",
                  marginBottom: "-12px",
                }}
              >
                <div className="row">
                  <div
                    className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                    style={{ height: "65px" }}
                  ></div>
                </div>
              </div>
            ) : (
              <div className="container-input-treelist" style={{ zIndex: "1" }}>
                <InputWrapper
                  item={quantity}
                  indexItems={indexItems}
                  quoteForm={quoteForm}
                  setQuoteForm={setQuoteForm}
                  dataItem={dataItem}
                  onEdit={(value: any) =>
                    setQuoteForm(
                      update(quoteForm, {
                        headers: {
                          [indexItems]: { quantity: { $set: value } },
                        },
                      })
                    )
                  }
                  inputValue={quoteForm.headers[indexItems]?.quantity}
                  type="number"
                  min="0"
                  // max="10"
                  className=" p-1 ms-1 "
                  style={{
                    height: "35px",
                    width: "45px",
                    border: "1px solid #DEE2E6",
                    borderRadius: "5px",
                  }}
                />
              </div>
            )}
          </TdWrapper>
        );
      },
    },
    {
      field: "rate",
      title: <span className="required">RATE</span>,
      format: "{0}",
      width: "120px",
      editCell: TreeListDateEditor,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        const indexItems = quoteForm.headers.findIndex(
          (i: any) => i.id === dataItem.id
        );

        return (
          <TdWrapper className="" record={""}>
            {dataItem.assigned_to === null && dataItem.type !== "sub header" ? (
              <div
                className="container-column"
                style={{
                  marginBottom: "-12px",
                  marginTop: "-12px",
                  zIndex: "1",
                }}
              >
                <div className="row">
                  <div
                    className="col-md-12  bg-light-blue  p-2  d-flex justify-content-start align-items-center "
                    style={{ height: "65px" }}
                  ></div>
                </div>
              </div>
            ) : dataItem.type === "sub header" ? (
              <div
                className="container-column "
                style={{
                  marginTop: "-12px",
                  zIndex: "1",
                  marginBottom: "-12px",
                }}
              >
                <div className="row">
                  <div
                    className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                    style={{ height: "65px" }}
                  ></div>
                </div>
              </div>
            ) : (
              <div
                className="container-input-treelist mt-1"
                style={{ zIndex: "1" }}
              >
                <InputWrapper
                  item={rate}
                  quoteForm={quoteForm}
                  setQuoteForm={setQuoteForm}
                  dataItem={dataItem}
                  onEdit={(value: any) =>
                    setQuoteForm(
                      update(quoteForm, {
                        headers: {
                          [indexItems]: { rate: { $set: value } },
                        },
                      })
                    )
                  }
                  className=" p-1 ms-1 "
                  inputValue={quoteForm.headers[indexItems]?.rate}
                  type="number"
                  min="0"
                  // max="10"
                  style={{
                    height: "35px",
                    width: "85px",
                    border: "1px solid #DEE2E6",
                    borderRadius: "5px",
                  }}
                />
              </div>
            )}
          </TdWrapper>
        );
      },
    },
    {
      field: "units_number",
      title: <span className="required">UNITS</span>,
      width: "120px",
      editCell: TreeListNumericEditor,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        const indexItems = quoteForm.headers.findIndex(
          (i: any) => i.id === dataItem.id
        );
        // setIndexSelected(indexItems);

        return (
          <TdWrapper className="" record={""}>
            {dataItem.assigned_to === null && dataItem.type !== "sub header" ? (
              <div
                className="container-column bg-white"
                style={{
                  marginBottom: "-12px",
                  marginTop: "-12px",
                  zIndex: "1",
                }}
              >
                <div className="row ">
                  <div
                    className="col-md-12  bg-light-blue  p-2  d-flex justify-content-start align-items-center "
                    style={{ height: "65px" }}
                  ></div>
                </div>
              </div>
            ) : dataItem.type === "sub header" ? (
              <div
                className="container-column "
                style={{
                  marginTop: "-12px",
                  zIndex: "1",
                  marginBottom: "-12px",
                }}
              >
                <div className="row">
                  <div
                    className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                    style={{ height: "65px" }}
                  ></div>
                </div>
              </div>
            ) : (
              <div
                className="d-flex  flex-column container-input-treelist "
                style={{
                  marginTop: "35px",
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <InputWrapper
                  item={units_number}
                  quoteForm={quoteForm}
                  setQuoteForm={setQuoteForm}
                  dataItem={dataItem}
                  onEdit={(value: any) =>
                    setQuoteForm(
                      update(quoteForm, {
                        headers: {
                          [indexItems]: { units_number: { $set: value } },
                        },
                      })
                    )
                  }
                  className=" p-1 ms-1 "
                  inputValue={quoteForm.headers[indexItems]?.units_number}
                  type="number"
                  min="0"
                  // max="10"
                  style={{
                    height: "35px",
                    width: "45px",
                    border: "1px solid #DEE2E6",
                    borderRadius: "5px",
                  }}
                />
                <div>
                  <SelectUnitQuote
                    className=""
                    placeholder={quoteForm.headers[indexItems]?.units_type}
                    value={quoteForm.headers[indexItems]?.units_type}
                    options={optionSelect}
                    onChange={(val: any) =>
                      getIndexSelect(val, indexItems, dataItem)
                    }
                  />
                </div>
              </div>
            )}
          </TdWrapper>
        );
      },
    },
    {
      field: "fullTime",
      title: "TOTAL",
      width: "100px",
      editCell: TreeListBooleanEditor,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;
        return (
          <>
            <TdWrapper className="" record={""}>
              {dataItem.assigned_to === null &&
              dataItem.type !== "sub header" ? (
                <div
                  className="container-column"
                  style={{
                    marginBottom: "-12px",
                    marginTop: "-12px",
                    zIndex: "1",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12  bg-light-blue  p-2  d-flex justify-content-start align-items-center "
                      style={{ height: "65px" }}
                    >
                      <div
                        className="cursor-pointer ms-3"
                        onClick={() => handle_Select_sub_Header(dataItem)}
                      >
                        <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6 me-2" />
                        <small className=" text-primary text-decoration-underline">
                          ADD SUB-HEADER
                        </small>
                      </div>
                      {dataItem.type === "Header 2" ? (
                        <div
                          className="d-flex ms-auto "
                          style={{ paddingRight: "18%" }}
                        >
                          <i
                            className="bi bi-dash-circle text-danger   cursor-pointer"
                            onClick={() => deleteItemsColumn(dataItem)}
                          />
                        </div>
                      ) : (
                        <div
                          className="d-flex ms-auto "
                          style={{ paddingRight: "18%" }}
                        >
                          <i
                            className="bi bi-dash-circle text-danger   cursor-pointer"
                            onClick={() => deleteItemsColumn(dataItem)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : dataItem.type === "sub header" ? (
                <div
                  className="container-column "
                  style={{
                    marginTop: "-12px",
                    zIndex: "1",
                    marginBottom: "-12px",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                      style={{ height: "65px" }}
                    >
                      {dataItem.type === "sub header" ? (
                        []
                      ) : (
                        <div
                          className="cursor-pointer"
                          style={{ paddingRight: "18%" }}
                        >
                          <i
                            className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6 cursor-pointer"
                            onClick={() => deleteItemsColumn(dataItem)}
                          />

                          <small className=" text-primary text-decoration-underline ">
                            ADD SUB-HEADER
                          </small>
                        </div>
                      )}
                      <div
                        className="d-flex ms-auto "
                        style={{ paddingRight: "18%" }}
                      >
                        <i
                          className="bi bi-dash-circle text-danger   cursor-pointer"
                          onClick={() => deleteItemsColumn(dataItem)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex " style={{ paddingRight: "17%" }}>
                  {totalItems(dataItem)}
                  <i
                    className="bi bi-dash-circle text-danger ms-auto cursor-pointer "
                    onClick={() => deleteItemsColumn(dataItem)}
                  />
                </div>
              )}
            </TdWrapper>
          </>
        );
      },
    },
  ];
};
