import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';
import { getSections } from '../../../util/ItemsFunctions';
import { RootState } from '../../../store/store'
import { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import ContactSection from './ContactSection';
import { setItemPackage, SelectItems } from '../../../store/stockpile/kitItems';
import SimpleButton from '../../../components/UI/buttons/SimpleButton'
import QuickAddItem from '../QuickAddItem';
import { updateAccesoriesMandatory } from 'src/store/stockpile/createFormSlice';
import AdvanceSearch from 'src/components/Stock/StockList/AdvanceSearch';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import InputSearch from 'src/components/UI/forms/InputSearch';



interface Props {
  setView: any,
  View: any,
  toggleAddingPackage: any
  setViewInfo?: any
  viewInfo?: any
}

const AccesoryItemModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const ItemData = useSelector((state: RootState) => state.stockpileSlice.ItemData);
  const [shotby, setShortBy] = useState<string>('name')
  const [modalAddquick, setModalAddquick] = useState<boolean>(false)

  const [indexItemSelected, setSelectedItem] = useState<any>([])

  const [assetList, setAssetList] = useState<boolean>(false)
  const [itemID, setItemID] = useState<string>('')
  const [itemSearch, setItemSearch] = useState<string>('')

  const [itemSaveIt, setItemSave] = useState<any>({})

  const [advcCategory, setAdvanceCategory] = useState<any>(false)
  const [words, setWords] = useState<any>('')
  const [type, setType] = useState<any>('')


  const handleSearch = (e: any) => {
    setItemSearch(e.target.value)
  }
  const FWords = (item: any, words: any) => {

    if (words) {
      return item.name.toLowerCase().includes(words.toLowerCase());
    } else return item;

  }


  const bySearch = (item: any, search: any) => {
    if (search) {
      return item.name.toLowerCase().includes(search.toLowerCase());
    } else return item;
  };

  const byType = (item: any, type: any) => {
    if (type) {
      if (type == 0) {
        return item.internal_assets > 0
      }
      if (type == 1) {
        return item.external_assets > 0
      }
    } else return item;
  }

  const byCategoryForID = (item: any, category: any) => {
    if (category) {
      if (category == 'any') {
        return true
      } else {
        const result = item.category?._id == category ? true : false;

        return result
      }
    } else return true;
  };

  const filteredList = (items: any, search: any, AdvanceCategory: any) => {

    return items
      .filter((item: any) => bySearch(item, search))
      // .filter((item: any) => item?.category?._id == '67807598690173953' ? true : false)
      .filter((item: any) => byCategoryForID(item, AdvanceCategory))
      .filter((item: any) => byType(item, type))
      .filter((item: any) => FWords(item, words))

  }


  const [test, settest] = useState<boolean>(false)




  const sections = getSections(filteredList(ItemData, itemSearch, advcCategory), shotby)

  const toggle = () => {
    settest(prevState => (!prevState));
  }

  return (
    <Modal isOpen={props.View.modal} toggle={() => props.setView({ index: '', modal: false })} centered
      style={{ width: '367px', height: '553px', overflowX: 'hidden' }}

    >
      <p className='poppinsText' style={{
        fontSize: '22px',
        position: 'absolute',
        marginBottom: '-25%',
        marginTop: '-2%',
        marginLeft: '2%',
        fontWeight: 'lighter',
        cursor: 'pointer',
        fontFamily: 'revert'
      }}
        onClick={() => props.setView({ index: '', modal: false })}
      >
        x
      </p>
      <div className="row container" style={{ padding: '.8em', marginTop: '-35%' }}
      >

        <div className="col-12" style={{
          display: 'flex', alignSelf: 'center',
          justifyContent: 'center',
          marginTop: '40%', marginBottom: '-14%'
        }}>
          <p className="ModalTitle"
            style={{
              fontSize: '24px', fontWeight: 'bold',
              justifyContent: 'center', alignSelf: 'center',
              display: 'flex', marginLeft: '4%', color: '#344469'
            }}
          >
            Select Item
          </p>
        </div>
        <div className="col-12 mt-3">
          <div className="input-icon mt-3" style={{
            margin: '.8em'
            , width: '108%', marginLeft: '-1%',
            marginTop: '1.5em'
          }} >
            <InputSearch value={itemSearch} onChange={handleSearch} placeholder="Search" />

          </div>
          <UncontrolledDropdown isOpen={test} toggle={toggle} className={`col-12 mt-2 mb-1 ms-4 me-0`}  >
            <DropdownToggle
              className="btn btn-link typo-body p-0 float-end me-2 "
              style={{ boxShadow: 'none', background: 'white', border: 0 }}
            >
              ADVANCED SEARCH
            </DropdownToggle>

            {<AdvanceSearch
              setAdvanceCategory={setAdvanceCategory}
              setWords={setWords}
              setType={setType}
              close={toggle}
            />}
          </UncontrolledDropdown>
          <div className="row mt-2 contact-container" style={
            {
              paddingRight: '.8em',
              // paddingBottom: '.3em',
              width: '123%',
              marginLeft: '-6%',
              height: '400px',
              maxWidth: '400px'
            }
          }   >

            <div className="col-12 p-0 pe-3 custom-scrollbar contact-list"
              style={{
                height: '100%', paddingLeft: '.1em',
                paddingRight: '5em'
              }}>

              <FlatList
                list={Object.keys(sections)}
                renderItem={(item: any, index: any) => {
                  return (
                    <ContactSection
                      ref={sections[item].ref}
                      letter={item}
                      items={sections[item].items}
                      setID={setItemID}
                      showAssets={setAssetList}
                      index={props.View.index}
                      indexItem={index}
                      setSelected={setSelectedItem}
                      indexItemSelected={indexItemSelected}
                      preSave={setItemSave}
                    />
                  );
                }}
                renderWhenEmpty={() => <p className="result-message">Without results</p>}
              />
            </div>
          </div>
        </div>
        <div className='col-12' style={{ marginTop: '0%' }} >
          <SimpleButton onClick={() => { setModalAddquick(true) }}>
            Quick add new item
          </SimpleButton>
        </div>
        <div className="col-12 " style={{ marginTop: '2%' }}  >
          <div className="row justify-content-evenly">
            <button
              className="btn btn-success  text-white col-auto poppinsText"
              onClick={() => {
                dispatch(updateAccesoriesMandatory({ index: props.View?.index, data: itemSaveIt }))
                props.setView({ index: '', modal: false })
              }}
              style={{
                width: '124px', height: '37px',
                fontSize: '12px', textTransform: 'uppercase'
              }}
            >
              Select Item
            </button>
          </div>
        </div>
      </div>
      <QuickAddItem
        setView={setModalAddquick}
        view={modalAddquick}
        setModalSelect={props.setView}
        title={'Quick Add Item'}
        indexInput={props.View?.index}
        setViewInfo={props.setViewInfo}
        viewInfo={props.viewInfo}
      />

    </Modal>
  );
};

export default AccesoryItemModal;
