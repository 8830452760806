import axios from "axios";
import set from "lodash.set";
import { R } from "src/models";
import { BCreateSkillset } from "src/models/Bodies"
import { ISkillset } from "src/models/SkillsetModel";
import { deepEntries } from "src/util";

type SkillsetsModules = 'blackbook' /* | '' */

const CreateSkillSet = async (params: BCreateSkillset, module: SkillsetsModules) => {
  const { data } = await axios.post<R<ISkillset>>(`skillsets/${module}`, params);

  return data.data;
}

const UpdateSkillset = async (id: string, params: Partial<BCreateSkillset>, module: SkillsetsModules) => {
  const { data } = await axios.put<R<ISkillset>>(`skillsets/${module}/${id}`, params);

  return data.data;
}

const CreateManySkillsets = async (params: { skillsets: BCreateSkillset[] }, module: SkillsetsModules) => {
  const skillsets: any[] = [];

  Object.entries(params.skillsets).forEach(([key, value]) => {
    if (!value) return

    if (typeof value === 'object' && value.constructor.name === Object.name) {
      const cleanedObj = {}
      for (const [_key, val] of deepEntries(value)) {
        if (typeof val === 'string' && val === '') continue
        set(cleanedObj, _key, val)
      }

      skillsets.push(cleanedObj);
    }
  })

  const { data } = await axios.post<R<{ skillsets: ISkillset[] }>>(`skillsets/${module}/many`, {
    skillsets: JSON.stringify(skillsets)
  });

  return data.data;
}

const UpdateManySkillsets = async (params: { skillsets: Partial<BCreateSkillset & { id: string }>[] }, module: SkillsetsModules) => {
  const skillsets: any[] = [];

  Object.entries(params.skillsets).forEach(([key, value]) => {
    if (!value) return

    if (typeof value === 'object' && value.constructor.name === Object.name) {
      const cleanedObj = {}
      for (const [_key, val] of deepEntries(value)) {
        if (typeof val === 'string' && val === '') continue
        set(cleanedObj, _key, val)
      }

      skillsets.push(cleanedObj);
    }
  })

  const { data } = await axios.put<R<{ skillsets: ISkillset[] }>>(`skillsets/${module}/many`, {
    skillsets: JSON.stringify(skillsets)
  });

  return data.data;
}

export const SkillsetsService = {
  CreateSkillSet,
  CreateManySkillsets,

  UpdateSkillset,
  UpdateManySkillsets
}