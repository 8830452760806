import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

import authService from 'src/services/auth';
import { useRequest } from 'src/hooks/useRequest';
import { ApiError, InternalStatus } from 'src/models';
import Checkbox from '../components/UI/forms/Checkbox';
import { Link } from 'react-router-dom';
import logo from '../assets/images/PFP_logo_white.png';


const Login: React.FC = () => {
  const history = useHistory();

  const { mutate } = useRequest({ url: 'users/me' })

  const [password, setPassword] = useState<string>('123')
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [user, setUser] = useState<string>('andry.martinez@lumation.co')

  const [errors, setErrors] = useState<{ email: boolean; password: boolean }>({ email: false, password: false });

  const login = () => {
    authService.Login({
      email: user,
      password: password
    }).then(() => {
      /* Success logged in */
      mutate(null, true)
      return history.push("/")
    }).catch((err: AxiosResponse<ApiError>) => {
      const code = err.data.statusCode.internal;

      switch (code) {
        case InternalStatus.INVALID_EMAIL:
          setErrors({ ...errors, email: true })
          break;
        case InternalStatus.INVALID_CREDENTIALS:
          setErrors({ ...errors, password: true })
          break;
        default:
          break;
      }
    })
  };

  const handleChangeUser = (event: any) => {
    setUser(event.target.value)
    errors.email && setErrors({ ...errors, email: false })
  }

  const handleChangePassword = (event: any) => {
    setPassword(event.target.value)
    errors.password && setErrors({ ...errors, password: false })
  }

  return (
    <div className='row col-lg-12 vh-100 pb-lg-0 pb-sm-5'>
      <div className="d-flex flex-column align-items-center justify-content-center col-lg-6 ">
        <p className="text-primary typo-display mb-4">Welcome!</p>

        <div className="container-login p-lg-0 p-sm-3 ">
          <div className="container-fluid bg-contrast d-flex justify-content-center">
            <div className="row w-100 pt-5 pb-5 px-3">
              <div className="col-12">
                <p className="typo-body text-primary text-center">Sign in</p>
              </div>

              <div className="col-12">
                <div className="input-icon mt-3 text-primary">
                  <i className="bi bi-envelope-fill mt-1 ms-2"></i>
                  <input
                    value={user}
                    type="email"
                    placeholder="Email"
                    id="input-login-email"
                    onChange={handleChangeUser}
                    className={"form-control py-2 typo-body-important text-primary" + (errors.email ? " is-invalid" : "")}
                  />
                  {errors.email && <div className="invalid-feedback">Invalid email.</div>}
                </div>
              </div>

              <div className="col-12">
                <div className="input-icon mt-3 text-primary">
                  <i className="bi bi-lock-fill mt-1 ms-2"></i>
                  <input
                    type="password"
                    value={password}
                    placeholder="Password"
                    id="input-login-password"
                    onChange={handleChangePassword}
                    className={"form-control py-2 typo-body-important text-primary" + (errors.password ? " is-invalid" : "")}
                  />
                  {errors.password && <div className="invalid-feedback">Invalid password, please try again.</div>}
                </div>
              </div>

              <div className="col-12 mt-1">
                <Checkbox
                  title="Remember me"
                  value={rememberMe}
                  onChange={(value) => setRememberMe(value)}
                />
              </div>

              <div className="col-12 d-flex justify-content-center mt-4">
                <button
                  className="btn btn-primary typo-body text-white"
                  onClick={login}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-between container-login px-2">
          <div className="col-auto p-0">
            <Link to="/recover-password" className="typo-body text-primary">
              Forgot password?
            </Link>
          </div>
          <div className="col-auto p-0">
            <p className="typo-body text-primary">Create new account</p>
          </div>
          <Link to={'/quotes/public/245492983032448778'}>public quote test</Link>
        </div>

        <div>
        </div>
        <div className='mt-lg-5 me-3 mt-sm-0'>
          <img src={logo} className="sidebar-logo"  />
          <p className='text-muted'>@2022  All rights reserved</p>
        </div>
      </div>
      <div className='col-lg-6 bg-primary d-none d-sm-block'>

      </div>
    </div>

  );
};

export default Login;
