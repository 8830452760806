import InputFloatingLabel from './InputFloatingLabel';

interface Props {
  options: {
    title: string;
    value: string;
  }[];
  label?: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  autoHeight?: boolean;
}

const ComboBox: React.FC<Props> = (props) => {
  return (
    <div className={`h-100 ${props.className}`}>
      {props.label && (
        <label className="text-primary typo-body">{props.label}</label>
      )}

      <select
        className="form-select text-primary typo-body-important"
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        style={{height: props.autoHeight ? "auto" : '45px'}}
      >
        {props.options.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.title}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default ComboBox;
