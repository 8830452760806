import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import RatesSchema from './validations';
import { useFormik, Form, FormikProvider } from 'formik';
import { RatesModel } from '../../../../../models/ItemModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { setRates , changeRatesErrors } from '../../../../../store/stockpile/createFormSlice';
import { useEffect } from 'react';


const Rates: React.FC = () => {

  const dispatch = useDispatch()
  const data = useSelector((state: RootState) => state.createFormSlice.RatesData);


  const formik = useFormik({
    validationSchema: RatesSchema,
    initialValues: {
      ...data
    } ,
    onSubmit: () => {

    }
  })

  const { errors, touched, handleSubmit, values, setFieldValue } = formik

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeRatesErrors(true))
    }else{
      dispatch(changeRatesErrors(false))
    }
  } , [errors])

  return (
    <div className="container-fluid px-3 mt-3">
      <p className="m-0 text-primary typo-body">STANDARD RATE CARD</p>
      <FormikProvider value={formik} >
        <Form autoComplete="off" noValidate onSubmit={(e) => { }}  >
          <div className="row mt-2">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Hour rate"
                value={values.hour.toString()}
                onChange={(value) => {
                  setFieldValue('hour', value)
                  dispatch(setRates({ hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Day rate"
                value={values.daily.toString()}
                onChange={(value) => {
                  setFieldValue('daily', value)
                  dispatch(setRates({ daily: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.daily}
              />
             
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Week rate"
                value={values.week.toString()}
                onChange={(value) => {
                  setFieldValue('week', value)
                  dispatch(setRates({ week: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.week}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Sell rate"
                value={values.sale.toString()}
                onChange={(value) => {
                  setFieldValue('sale', value)
                  dispatch(setRates({ sale: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.sale}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Overtime hour rate"
                value={values.overtime_hour.toString()}
                onChange={(value) => {
                  setFieldValue('overtime_hour', value)
                  dispatch(setRates({ overtime_hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.overtime_hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday hour rate"
                value={values.holiday_hour.toString()}
                onChange={(value) => {
                  setFieldValue('holiday_hour', value)
                  dispatch(setRates({ holiday_hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday day rate"
                value={values.holiday_day.toString()}
                onChange={(value) => {
                  setFieldValue('holiday_day', value)
                  dispatch(setRates({ holiday_day: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_day}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday overtime rate"
                value={values.holiday_overtime_hour.toString()}
                onChange={(value) => {
                  setFieldValue('holiday_overtime_hour', value)
                  dispatch(setRates({ holiday_overtime_hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_overtime_hour}
              />
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default Rates;
