import React from "react";
import IconButton from "../../../UI/buttons/IconButton";
import InputDate from "../../../UI/forms/InputDate";
import InputFloatingLabel from "../../../UI/forms/InputFloatingLabel";
import SelectFloatingLabel from "../../../UI/forms/SelectFloatingLabel";
import TextArea from "../../../UI/forms/TextArea";

interface Props {}

const FormularyTaskModal: React.FC<Props> = (props) => {
  const checkBox = [
    { title: "Deliverable 1", value: false },
    { title: "Deliverable 2", value: false },
    { title: "Deliverable 3", value: false },
    { title: "Deliverable 4", value: false },
    { title: "Deliverable 5", value: false },
    { title: "Deliverable 6", value: false },
    { title: "Deliverable 7", value: false },
    { title: "Deliverable 8", value: false },
  ];
  return (
    <div className="container mt-2  custom-scrollbar overflow-auto ">
      <div className=" row  g-2 ">
        <div className="col-md-12 " style={{ marginBottom: "-20px" }}>
          <InputFloatingLabel
            placeHolder="Title"
            value=""
            onChange={() => {}}
            isRequired
          />
        </div>
        <div
          className="col-sm-6 col-md-6 col-lg-6 "
          style={{ marginTop: "10px" }}
        >
          <InputDate
            // onChange={(e: any) =>
            //   handleChange("end_date", moment(e.value).format("DD/MM/YYYY"))
            // }
            // placeHolder="Project start date"
            onChange={(e: any) => {}}
            value={1234}
          />
        </div>
        <div
          className="col-sm-6 col-md-6 col-lg-6"
          style={{ marginTop: "10px" }}
        >
          <InputDate
            // onChange={(e: any) =>
            //   handleChange("end_date", moment(e.value).format("DD/MM/YYYY"))
            // }
            // placeHolder="Project start date"
            onChange={(e: any) => {}}
            value={""}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 ">
          <SelectFloatingLabel
            placeholder="Assigned to"
            onChange={() => {}}
            value=""
            options={[]}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 ">
          <SelectFloatingLabel
            placeholder="Category"
            onChange={() => {}}
            value=""
            options={[checkBox]}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 ">
          <SelectFloatingLabel
            placeholder="Low"
            onChange={() => {}}
            value=""
            options={[]}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 ">
          <SelectFloatingLabel
            placeholder="Not started"
            onChange={() => {}}
            value=""
            options={[]}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 ">
          <SelectFloatingLabel
            placeholder="Never"
            onChange={() => {}}
            value=""
            options={[]}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6">
          <SelectFloatingLabel
            placeholder="End repeat"
            onChange={() => {}}
            value=""
            options={[]}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6">
          <SelectFloatingLabel
            placeholder="General"
            onChange={() => {}}
            value=""
            options={[]}
          />
        </div>
        <div className=" row g-2 m-0 p-0">
          <div className="col-sm-3 col-md-3 col-lg-3">
            <InputFloatingLabel
              placeHolder="Location name"
              value=""
              onChange={() => {}}
            />
            <div className="cursor-pointer add-location">
              <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
              <small className=" text-primary "> ADD LOCATION</small>
            </div>
          </div>
          <div className="col-sm-9 col-md-9 col-lg-9 ">
            <InputFloatingLabel
              placeHolder="Address"
              value=""
              onChange={() => {}}
            />
          </div>
        </div>
        <div
          className="d-flex col-sm-6 col-md-6 col-lg-6 container-input-file-create mt-3  m-1"
          style={{ width: "98.5%" }}
        >
          <div className="custom-input-file-create col-md-6 col-sm-6 col-xs-6 container-task-input">
            <input type="file" id="" className="input-file" value="" />
            <span className="m-0">Attach files </span>
            or drag and drop here.
          </div>
        </div>
        <div className="col-md-12">
          <TextArea
            className=""
            placeHolder="Description"
            title=""
            value=""
            onChange={() => {
              ("");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormularyTaskModal;
