import React, { useEffect, useState } from "react";
import { useBarcode } from "react-barcodes";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import AddStock from "../components/Stock/AddItem/AddStock";
import AddStockService from "../components/StockService/AddStock/AddStock";
import AddStockCSV from "src/components/StockCSV/AddStock/AddStockCSV";

import StockCategories from "../components/Stock/StockCategories/StockCategories";
import StockList from "../components/Stock/StockList/StockList";
import StockView from "../components/Stock/StockView/StockView";
import { RootState } from "../store/store";
import AddStockPackage from "../components/StockPackage/AddStock/AddStock";
import AddStockKit from "../components/StockKit/AddStock/AddStock";

import ChangeCondition from "../components/Modals/ChangeCondition";
import {
  cleanAssetData,
  UpdateAssetForId,
} from "src/store/stockpile/stockpileSlice";
import {
  setEquipmentItem,
  setselectedState,
  setShowAddAsset,
  setShowAddItem,
  setShowAddItemService,
  setShowAddUploadCSV,
  setShowAssetLis,
  setShowEditAsset,
  setShowEditItem,
  setShowEditItemService,
} from "src/store/stockpile/RecursosVisualeSlice";
import useQuery from "src/hooks/useQuery";
import { useHistory, useParams } from "react-router-dom";
import { EmptyForm } from "src/store/stockpile/createFormSlice";
import { EmptyFormService } from "src/store/stockpile/createServiceForm";
import AssetData from "src/components/Stock/AddItem/tabs/AssetData/AssetData";
//import { setDataAsset, setIdItem } from 'src/store/stockpile/selectAssetsSlice';
import SimpleStockView from "src/components/Stock/StockView/SimpleStockView";
import EditStock from "src/components/Stock/EditItems/EditStock";
import EditStockService from "src/components/StockService/EditService/EditStockService";
import { useRequest } from "src/hooks/useRequest";
import AddAsset from "src/components/Stock/AddAsset/AddAsset";
import EditAsset from "src/components/Stock/EditAsset/EditAsset";
import BarcodePreview from "src/components/Stock/StockList/BarcodePreview";
import PackageStockView from "src/components/Stock/StockView/PackageStockView";
import StockViewEmpty from "src/components/Molecules/StockViewEmptySelect";

const Stockpile: React.FC = () => {
  const query = useQuery();
  const history = useHistory();
  const params = useParams<{ itemId: string; assetId: string }>();

  const dispatch = useDispatch();
  const ASSET_SELECTED = useSelector(
    (state: RootState) => state.selectAssetsSlice.data
  );
  const DataConffirm = useSelector(
    (state: RootState) => state.selectAssetsSlice.dataConfirm
  );
  const { data: ITEM_DATA } = useRequest<any>({
    url: "stockpile/items/" + params.itemId,
  });
  const AssetAll: Array<any> = useSelector(
    (state: RootState) => state.stockpileSlice.AssetData
  );
  const FullScreen = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.FullWidth
  );
  const ShowAddItem = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowAddItem
  );
  const ShowAddItemService = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowAddItemService
  );
  const ShowAddUploadCSV = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowAddUploadCSV
  );
  const ShowEditItem = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowEditItem
  );
  const ShowEditItemService = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowEditItemService
  );
  const ShowAssets = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowAssetList
  );
  const ShowAddAsset = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowAddAsset
  );
  const ShowEditAsset = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowEditAsset
  );
  const categories: Array<any> = useSelector(
    (state: RootState) => state.categorieSlice.categories
  );
  const AssetData: Array<any> = useSelector(
    (state: RootState) => state.stockpileSlice.AssetData
  );

  const EquipmentItem = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.EquipmentItem
  );

  const [isAddingPackage, setIsAddingPackage] = useState<boolean>(false);

  const [isAddingKit, setIsAddingKit] = useState<boolean>(false);

  const [isContactViewOpen, setIsContactViewOpen] = useState<boolean>(false);

  const [typeForm, setTypeForm] = useState<any>("392839829382");

  const [modalCondition, setModalCondition] = useState<boolean>(false);

  const [valueCondition, setValueCondition] = useState<any>("");

  const [layoutState, setLayoutState] = useState<boolean>(false);

  useEffect(() => {
    setValueCondition(ASSET_SELECTED?.condition);
  }, [ASSET_SELECTED]);

  const { innerHeight } = window;

  useEffect(() => {}, [ASSET_SELECTED]);

  useEffect(() => {
    dispatch(EmptyForm([]));
    dispatch(EmptyFormService([]));
  }, [ShowAddItem, ShowAddItemService]);

  useEffect(() => {
    const shouldOpenCreateModal = query.get("create");

    if (
      shouldOpenCreateModal !== undefined &&
      shouldOpenCreateModal !== null &&
      !ShowAddItem
    ) {
      dispatch(setShowAddItem(true));
    }

    if (shouldOpenCreateModal === undefined || shouldOpenCreateModal === null) {
      dispatch(setShowAddItem(false));
    }
  }, [query, ShowAddItem]);

  useEffect(() => {
    const shouldOpenCreateModal = query.get("createService");

    if (
      shouldOpenCreateModal !== undefined &&
      shouldOpenCreateModal !== null &&
      !ShowAddItemService
    ) {
      dispatch(setShowAddItemService(true));
    }

    if (shouldOpenCreateModal === undefined || shouldOpenCreateModal === null) {
      dispatch(setShowAddItemService(false));
    }
  }, [query, ShowAddItemService]);

  useEffect(() => {
    const shouldOpenCreateModal = query.get("UploadCSV");

    if (
      shouldOpenCreateModal !== undefined &&
      shouldOpenCreateModal !== null &&
      !ShowAddItemService
    ) {
      dispatch(setShowAddUploadCSV(true));
    }

    if (shouldOpenCreateModal === undefined || shouldOpenCreateModal === null) {
      dispatch(setShowAddUploadCSV(false));
    }
  }, [query, ShowAddUploadCSV]);

  useEffect(() => {
    const shouldOpenCreateModal = query.get("editItem");

    if (
      shouldOpenCreateModal !== undefined &&
      shouldOpenCreateModal !== null &&
      !ShowEditItem
    ) {
      dispatch(setShowEditItem(true));
    }

    if (shouldOpenCreateModal === undefined || shouldOpenCreateModal === null) {
      dispatch(setShowEditItem(false));
    }
  }, [query, ShowEditItem]);

  useEffect(() => {
    const shouldOpenCreateModal = query.get("editItemService");

    if (
      shouldOpenCreateModal !== undefined &&
      shouldOpenCreateModal !== null &&
      !ShowEditItemService
    ) {
      dispatch(setShowEditItemService(true));
    }

    if (shouldOpenCreateModal === undefined || shouldOpenCreateModal === null) {
      dispatch(setShowEditItemService(false));
    }
  }, [query, ShowEditItem]);

  useEffect(() => {
    const shouldOpenCreateModal = query.get("AddAsset");

    if (
      shouldOpenCreateModal !== undefined &&
      shouldOpenCreateModal !== null &&
      !ShowAddAsset
    ) {
      dispatch(setShowAddAsset(true));
    }

    if (shouldOpenCreateModal === undefined || shouldOpenCreateModal === null) {
      dispatch(setShowAddAsset(false));
    }
  }, [query, ShowAddAsset]);

  useEffect(() => {
    const shouldOpenCreateModal = params.assetId;

    if (
      shouldOpenCreateModal !== undefined &&
      shouldOpenCreateModal !== null &&
      !ShowEditAsset
    ) {
      dispatch(setShowEditAsset(true));
    }

    if (shouldOpenCreateModal === undefined || shouldOpenCreateModal === null) {
      dispatch(setShowEditAsset(false));
    }
  }, [query, ShowEditAsset]);

  const toggleAddingStock: any = (category: any) => {
    if (ShowAddItem) {
      query.delete("create");
      history.replace({ search: query.toString() });
    } else {
      query.set("create", category || "");
      history.push({ search: query.toString() });
    }
    dispatch(setShowAddItem((prev: any) => !prev));
  };

  const toggleAddingService: any = (category: any) => {
    if (ShowAddItemService) {
      query.delete("createService");
      history.replace({ search: query.toString() });
    } else {
      query.set("createService", category || "");
      history.push({ search: query.toString() });
    }
    dispatch(setShowAddItemService((prev: any) => !prev));
  };

  const toggleAddingPackage: any = () => {
    setIsAddingPackage((prev) => !prev);
  };

  const toggleAddingKit: any = () => {
    setIsAddingKit((prev) => !prev);
  };

  const toggleModalCondition: any = () => {
    setModalCondition((prev) => !prev);
  };

  const i = () => {
    setModalCondition(true);
  };

  const switchsCategoryAndSubCategory = (category: any, subCategory: any) => {
    //  ITEM_DATA?.category?._id != '67807598690173953'
    if (category == "67807598690173953") {
      return false;
    }

    categories.map((cat: any) => {
      if (cat._id == "67807598690173953") {
        cat.sub_categories?.map((sub: any) => {
          if (sub._id == subCategory) {
            return false;
          }
        });
      }
    });
  };

  useEffect(() => {
    if (ASSET_SELECTED?.serial && ITEM_DATA?.category?._id && params.itemId) {
      if (ITEM_DATA?.category?._id) {
        setLayoutState(true);
      }
    } else {
      setLayoutState(false);
    }
  }, [ITEM_DATA?.category?._id, ASSET_SELECTED?.serial, params.itemId]);

  return (
    <>
      {ShowEditAsset && (
        <div className="row h-25">
          <div className="col-12" style={{ height: "46em" }}>
            <EditAsset
              onFinish={() => {
                history.push("/stockpile/" + params.itemId + "/");
                dispatch(setShowEditAsset((prev: any) => !prev));
              }}
            />
          </div>
        </div>
      )}

      {ShowAddAsset && (
        <div className="row h-25">
          <div className="col-12" style={{ height: "46em" }}>
            <AddAsset
              onFinish={() => {
                query.delete("AddAsset");
                history.replace({ search: query.toString() });
                dispatch(setShowAddAsset((prev: any) => !prev));
              }}
            />
          </div>
        </div>
      )}

      {ShowEditItemService && (
        <div className="row h-25">
          <div className="col-12" style={{ height: "46em" }}>
            <EditStockService
              onFinish={() => {
                query.delete("editItemService");
                history.replace({ search: query.toString() });
                dispatch(setShowEditItemService((prev: any) => !prev));
              }}
            />
          </div>
        </div>
      )}

      {ShowEditItem && (
        <div className="row h-25">
          <div className="col-12" style={{ height: "46em" }}>
            <EditStock
              onFinish={() => {
                query.delete("editItem");
                history.replace({ search: query.toString() });
                dispatch(setShowEditItem((prev: any) => !prev));
              }}
              TypeForm={typeForm}
            />
          </div>
        </div>
      )}

      {ShowAddUploadCSV && (
        <div className="row h-25">
          <div className="col-12" style={{ height: "46em" }}>
            <AddStockCSV
              onFinish={() => {
                query.delete("UploadCSV");
                history.replace({ search: query.toString() });
                dispatch(setShowAddUploadCSV((prev: any) => !prev));
              }}
            />
          </div>
        </div>
      )}

      {ShowAddItem && (
        <div className="row h-25">
          <div className="col-12" style={{ height: "46em" }}>
            <AddStock onFinish={toggleAddingStock} TypeForm={typeForm} />
          </div>
        </div>
      )}

      {ShowAddItemService && (
        <div className="row">
          <div className="col-12">
            <AddStockService onFinish={toggleAddingService} />
          </div>
        </div>
      )}

      {isAddingPackage && (
        <div className="row">
          <div className="col-12" style={{ height: "42em" }}>
            <AddStockPackage onFinish={toggleAddingPackage} />
          </div>
        </div>
      )}

      {isAddingKit && (
        <div className="row">
          <div className="col-12" style={{ height: "42em" }}>
            <AddStockKit onFinish={toggleAddingKit} />
          </div>
        </div>
      )}

      <Row
        style={
          ShowAddItem ||
          ShowEditAsset ||
          ShowAddAsset ||
          ShowAddItemService ||
          isAddingPackage ||
          isAddingKit ||
          ShowAddUploadCSV ||
          ShowEditItem ||
          ShowEditItemService
            ? { display: "none" }
            : {}
        }
        className="vh-100 p-sm-2 justify-content-between"
      >
        {FullScreen ? (
          ""
        ) : (
          <Col lg="2" className="p-0 m-0 vh-100">
            <div className="card h-100">
              <StockCategories toggleAddingPackage={toggleAddingPackage} />
            </div>
          </Col>
        )}
        <Col lg="3" md="12" sm="12" className="p-0 m-0 vh-100  ">
          <div
            className={`card ms-0 ${
              layoutState && AssetData.length > 0 ? "h-80" : "h-100"
            }  p-0`}
          >
            <StockList
              onAddContact={toggleAddingStock}
              onAddService={toggleAddingService}
              setTypeForm={setTypeForm}
              toggleAddingkit={toggleAddingKit}
            />
          </div>

          {AssetData.length > 0 && (
            <div
              className="card p-2 m-0 me-1"
              style={{
                display: layoutState ? "" : "none",
              }}
            >
              <BarcodePreview
                changeCondition={i}
                valueCondition={valueCondition}
                serial={ASSET_SELECTED?.serial}
              />
            </div>
          )}
        </Col>
        <Col
          lg={FullScreen ? "9" : "7"}
          className="p-0 m-0 vh-100 mt-sm-5 mt-lg-0 mt-md-5"
        >
          <div
            className="card overflow-y overflow-x-none custom-scrollbar w-100 h-100 trans-all m-0 mt-1 "
            style={{ flexGrow: isContactViewOpen ? 1 : 2 }}
          >
            {ITEM_DATA?.category?._id != "67807598690173956" &&
            ITEM_DATA?.category?._id != "67807598690173950" &&
            params.itemId ? (
              <StockView
                isOpen={true}
                toggleIsOpen={() => setIsContactViewOpen((prev) => !prev)}
              />
            ) : (
              []
            )}
            {ITEM_DATA?.category?._id == "67807598690173956" &&
              params.itemId && (
                <SimpleStockView
                  isOpen={true}
                  toggleIsOpen={() => setIsContactViewOpen((prev) => !prev)}
                />
              )}

            {ITEM_DATA?.category?._id == "67807598690173950" &&
              params.itemId && (
                <PackageStockView
                  isOpen={true}
                  toggleIsOpen={() => setIsContactViewOpen((prev) => !prev)}
                />
              )}

            {!params.itemId && <StockViewEmpty />}
          </div>
        </Col>
      </Row>
      <ChangeCondition
        view={modalCondition}
        setView={setModalCondition}
        toggle={toggleModalCondition}
        setValue={setValueCondition}
        setUpdate={() => {
          dispatch(
            UpdateAssetForId({
              update: { condition: valueCondition },
              id: ASSET_SELECTED?._id,
            })
          );
          setModalCondition(false);
        }}
        value={valueCondition}
      />
    </>
  );
};
///col card h-100 overflow-y overflow-x-none custom-scrollbar trans-all

//${isContactViewOpen ? 'd-none' : 'd-block'

export default Stockpile;
