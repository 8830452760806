import { forwardRef, Ref } from 'react';

interface Props {
  letter: string;
  renderItems: () => JSX.Element;
}

const LetterSection = forwardRef<HTMLDivElement, Props>(
  (props, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <p
              className="m-0 typo-body ps-3 py-1 text-primary"
              style={{ background: '#f3f3f3' }}
            >
              {props.letter}
            </p>
            <div>
              {props.renderItems()}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default LetterSection;
