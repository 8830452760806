import React from "react";

interface props {
    onDoubleClick?:any
    onClick?:any
}

class DoubleClick extends React.Component<props> {
    
    timer:any = null

    onClickHandler = (event:any) => {
        clearTimeout(this.timer);

        if (event.detail === 1) {
            this.timer = setTimeout(this.props.onClick, 200)
        } else if (event.detail === 2) {
            this.props.onDoubleClick()
        }
    }

    render() {
        return (
            <div onClick={this.onClickHandler}>
                {this.props.children}
            </div>
        )
    }
}

export default DoubleClick