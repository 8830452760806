import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import authService from '../services/auth';
import AuthModel from '../models/AuthModel';

async function rejectMessage(message: string) {
  throw new Error(message);
}

export const checkAuth = createAsyncThunk('auth/check', async () => {
  try {
    const log = await authService.CheckSession()
    console.log({ log })
    if (!log) return rejectMessage('No user found')
    return { ...log }
  } catch (error) {
    console.error(error);
    return rejectMessage('No user found')
  }
});


const initialState = {
  isAuth: 1,
  remember: false,
  userData: {} as AuthModel,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.isAuth = 0
      authService.Logout()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.isAuth = 2
        state.userData.accessToken = action.payload?.token || ''
      })
      .addCase(checkAuth.pending, (state, action) => {
        state.isAuth = 1
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.isAuth = 0
      })
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
