import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import AccesoriesSchema from './validation';
import { useFormik, Form, FormikProvider } from 'formik';
import { AccesoriesModel } from '../../../../../models/ItemModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { updateAccesoriesMandatory, setAccesoriesMandatory, setAccesoriesOptional, updateAccesoriesOptional, DeleteMandatory, DeleteOptional } from '../../../../../store/stockpile/createFormSlice';
import { useEffect, useState } from 'react';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import SelectInput from 'src/components/UI/forms/Select';
import { getAllAssets } from 'src/store/stockpile/stockpileSlice';
import AccesoryItemModal from 'src/components/Modals/AccesoryItemModal/AccesoryItemModal';
import { Input } from 'reactstrap';
import OptionalItemModal from 'src/components/Modals/OptionalItemModal/OptionalItemModal';
import ModalItemCreate from 'src/components/Modals/QuickAddItem/ModalItemCreated';


const Accessories: React.FC = () => {
  const dispatch = useDispatch()
  const ItemData = useSelector((state: RootState) => state.stockpileSlice.ItemData);
  const AllAssets: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AllAssets);

  const Mandatory = useSelector((state: RootState) => state.createFormSlice.AccesoriesMandatoryData);
  const Optional = useSelector((state: RootState) => state.createFormSlice.AccesoriesOptionalData);
  const [modalItem, setModalItem] = useState({ index: '', modal: false })
  const [modalItemOptional, setModalItemOptional] = useState({ index: '', modal: false })

  const [indexItemSelectedMandatory, setSelectedItemMandatory] = useState([])
  const [indexItemSelectedOptional, setSelectedItemOptional] = useState([])

  const [inputSelected, selectInput] = useState<any>('')
  const [createdView, setCreatedView] = useState(false)


  const formik = useFormik({
    initialValues: {
    } as AccesoriesModel,
    validationSchema: AccesoriesSchema,
    onSubmit: () => { }
  })

  const { errors, touched, handleSubmit, values, setFieldValue } = formik


  useEffect(() => {
    dispatch(getAllAssets())

  }, [])

  useEffect(() => {


  }, [AllAssets])

  const optionItems = ItemData?.map((item: any) => {
    return {
      label: item?.name,
      value: item?._id
    }
  }) || []

  useEffect(() => {

  }, [Mandatory])

  return (
    <div className="container-fluid px-3 mt-3">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate >
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <p className="m-0 text-primary typo-body">MANDATORY ACCESSORIES</p>
              {
                Mandatory.map((input, index: any) => {
                  return (
                    <div className="mt-2 row" >
                      <div className='col-10' onClick={() => {
                        setModalItem({ index: index, modal: true })
                      }}>
                        <div className='p-0 m-0' style={{ pointerEvents: 'none' }} >
                          <SelectInput
                            onChange={(value: any) => {
                              //dispatch(updateAccesoriesOptional({ index: index, data: value }))
                            }}
                            value={input}
                            options={optionItems}
                            placeholder='Item name'
                          />
                        </div>
                      </div>
                      <div className='col-1' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        {
                          index == 0 && input ?
                            <SimpleButton
                              onClick={() => {
                                !input && index > 0 && dispatch(DeleteMandatory(index))
                                input && dispatch(updateAccesoriesMandatory({ index: index, data: '' }))
                              }}
                              type="danger"
                              icon="bi-dash-circle-fill"
                            >
                              {/* <i className="fas fa-times" /> */}
                            </SimpleButton>

                            : index > 0 &&
                            <SimpleButton
                              onClick={() => {
                                !input && index > 0 && dispatch(DeleteMandatory(index))
                                input && dispatch(updateAccesoriesMandatory({ index: index, data: '' }))
                              }}
                              type="danger"
                              icon="bi-dash-circle-fill"
                            >
                              {/* <i className="fas fa-times" /> */}
                            </SimpleButton>

                        }
                      </div>
                    </div>
                  )
                })
              }

              <div style={{ maxWidth: '200px' }}>
                <SimpleButton onClick={() => {
                  dispatch(setAccesoriesMandatory({}))
                }}>
                  ADD MANDATORY ACCESSORY

                </SimpleButton>
              </div>

            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <p className="m-0 text-primary typo-body">OPTIONAL ACCESSORIES</p>

              {
                Optional.map((input, index: any) => {
                  return (
                    <div className='mt-2 row'>
                      <div className="col-10" onClick={() => {
                        setModalItemOptional({ index: index, modal: true })
                      }}>
                        <div style={{ pointerEvents: 'none' }}>
                          <SelectInput
                            onChange={(value: any) => {
                              // dispatch(updateAccesoriesOptional({ index: index, data: value }))
                            }}
                            value={input}
                            options={optionItems}
                            placeholder='Item name'
                            searchable
                          />

                        </div>
                      </div>
                      <div className='col-1' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        {
                          index == 0 && input ?
                          <SimpleButton
                            onClick={() => {
                              !input && index > 0 && dispatch(DeleteOptional(index))
                              input && dispatch(updateAccesoriesOptional({ index: index, data: '' }))
                            }}
                            type="danger"
                            icon="bi-dash-circle-fill"
                          >
                            {/* <i className="fas fa-times" /> */}
                          </SimpleButton>

                          : index > 0 &&
                          <SimpleButton
                            onClick={() => {
                              !input && index > 0 && dispatch(DeleteOptional(index))
                              input && dispatch(updateAccesoriesOptional({ index: index, data: '' }))
                            }}
                            type="danger"
                            icon="bi-dash-circle-fill"
                          >
                            {/* <i className="fas fa-times" /> */}
                          </SimpleButton>
                          
                        }
                      </div>
                    </div>
                  )
                })
              }
              <div style={{ maxWidth: '200px' }}>
                <SimpleButton onClick={() => {
                  dispatch(setAccesoriesOptional({}))
                }}>
                  ADD OPTIONAL ACCESSORY
                </SimpleButton>
              </div>
            </div>
          </div>
        </Form>
        <AccesoryItemModal
          View={modalItem}
          setView={setModalItem}
          toggleAddingPackage={() => { }}
          setViewInfo={setCreatedView}
          viewInfo={createdView}
        />
        <OptionalItemModal
          View={modalItemOptional}
          setView={setModalItemOptional}
          toggleAddingPackage={() => { }}
          setViewInfo={setCreatedView}
          viewInfo={createdView}
        />
        <ModalItemCreate
          setView={setCreatedView}
          view={createdView}
        />
      </FormikProvider>
    </div>
  );
};

export default Accessories;
