export interface IEntity {
  _id: string;
  created_at: string; // In Date format
  updated_at: string; // In Date format
}

export type R<T> = {
  data: T;
}

export interface ApiError<T = string> {
  statusCode: {
    http: number;
    internal: InternalStatus;
  }
  message: T;
}

export enum InternalStatus {
  NOT_DEFINED = 1001,
  INVALID_CREDENTIALS = 1002,
  EXPIRED_TOKEN = 1003,
  INVALID_TOKEN = 1004,
  USED_REFRESH_TOKEN = 1005,
  RECOVER_PASSWORD_REQUEST_ALREADY_SENT = 1006,
  USER_NOT_FOUND = 1007,
  INTERNAL_ERROR = 1008,
  TWO_FACTOR_ENABLED = 1009,
  EMAIL_NOT_VERIFIED = 1010,
  TWO_FACTOR_DISABLED = 1011,
  ITEM_TYPE_NOT_FOUND = 1012,
  ASSET_ALREADY_EXISTS = 1013,
  FORBIDDEN = 1014,
  INVALID_2FA_CODE = 1015,
  CATEGORY_NOT_FOUND = 1016,
  INVALID_FILE_TYPE = 1017,
  ASSET_WITHOUT_RECEIPT = 1018,
  INVALID_EMAIL = 1019,
}

/* eslint-disable no-unused-vars */
export enum SoftDeleteStatus {
  /**
   * Completely functional status
   */
  ACTIVE = 1,
  /**
   * It means that the item/asset/etc is under an archived status
   */
  DELETED = 0,
}

export interface SoftDelete {
  date: string; // Always parsable as Date
  state: SoftDeleteStatus;
}