import React, { useEffect } from "react";


type colorType = "purple" | "lightGreen" | "greenActive" | "lightBlue" | "primary" | "danger";

type borderType = "border-start" | "border-end" | 'false'

interface Props {
    count: number,
    color?: colorType,
    title: string,
    border?: borderType 
}

const CounterElement: React.FC<Props> = (props) => {
    let count = props.count

    let color
    let border

    switch (props.color) {
        case 'danger':
            color = 'text-danger'
            break;

        case 'primary':
            color = 'text-primary'
            break;

        case 'lightBlue':
            color = 'text-lightblue'
            break;

        case 'lightGreen':
            color = 'text-lightgreen'
            break;

        case 'greenActive':
            color = 'text-green'
            break;

        case 'purple':
            color = 'text-purple'
            break;

        default:
           
            break;
    }







    return (
        <div className={`col-sm-6 col-md-6 col-lg-2 mt-md-3 mt-sm-3 mt-3 ${props.border != 'false' ? props.border == "border-start"?'border-start-bar-factory':'border-end-bar-factory': 'border-none'}  ps-3 `}>
            <h6 style={{color: '#53607F'}} className="mx-1 fs-4 p-0 m-0 poppins-count-factory">
                {count}
            </h6>
            <span className={`mx-1 font-counter-factory text-uppercase ${color}`}>
                {props.title}
            </span>
        </div>
    )

}


export default CounterElement