import { useRequest } from "src/hooks/useRequest";
import { ISkillset } from "src/models/SkillsetModel";

interface BookableSkillsTableProps {
  skills: string[];
}

function BookableSkillsTable({ skills }: BookableSkillsTableProps) {
  const { data, error } = useRequest<ISkillset[]>({ url: 'skillsets/blackbook/many', params: { ids: skills.join(',') } });

  if (!skills || !skills.length) return (
    <h6 className="mt-2 text-primary">This contact does not have any bookable skills configured.</h6>
  );

  if (!data && !error)
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
      </div>
    )

  if (!data && error)
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted">
        <i className="fs-3 bi bi-exclamation-triangle d-block"></i>
        <p className="h6">Error while loading the contact skillsets.</p>
      </div>
    )

  /* Just for typescript validation, but this case should never happen */
  if(!data)
    return null;

  return (
    <table className="table table-borderless">
      <thead>
        <tr className="text-primary typo-body">
          <th scope="col">BOOKABLE SKILLSET</th>
          <th scope="col">HOUR</th>
          <th scope="col">DAY</th>
          <th scope="col">WEEK</th>
          <th scope="col">OVERTIME HOUR</th>
          <th scope="col">HOLIDAY HOUR</th>
          <th scope="col">HOLIDAY DAY</th>
          <th scope="col">HOLIDAY OVERTIME HOUR</th>
        </tr>
      </thead>

      <tbody>
        {data.map((skillset, index) => {
          return (
            <tr
              className={`${
                (index / 2) % 1 > 0 ? 'table-primary' : ''
              } text-primary typo-body`}
              key={index}
            >
              <td className="align-middle">{skillset.skillset}</td>

              <td className="align-middle">{skillset.rate_card.rates?.hour || 'Not configured.'}</td>
              <td className="align-middle">{skillset.rate_card.rates?.daily || 'Not configured.'}</td>
              <td className="align-middle">{skillset.rate_card.rates?.week || 'Not configured.'}</td>
              <td className="align-middle">{skillset.rate_card.rates?.overtime_hour || 'Not configured.'}</td>
              <td className="align-middle">{skillset.rate_card.rates?.holiday_hour || 'Not configured.'}</td>

              <td className="align-middle">{skillset.rate_card.rates?.holiday_day || 'Not configured.'}</td>
              <td className="align-middle">{skillset.rate_card.rates?.holiday_overtime_hour || 'Not configured.'}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  )
}

export default BookableSkillsTable