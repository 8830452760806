import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CreateProjectForm from "../components/Factory/FactoryView/createProyectForm/CreateProjectForm";
import CreateQuoteForm from "../components/Factory/FactoryView/createQuoteForm/CreateQuoteForm";
import FactoryView from "../components/Factory/FactoryView/FactoryView";
import FatoryViewDetailProject from "../components/Factory/FactoryView/FactoryViewDetailProject";

const Factory: React.FC = () => {
  const [activeControlView, setActiveControlView] =
    useState<string | null>(null);
  const [selectJob, setSelectJob] = useState<any>(null);
  const [activeTask, setActiveTask] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectProject, setSelectProject] = useState<any>(null);
  const [closeModalJob, setCloseModalJob] = useState(false);

  const { idproject, idjob }: any = useParams();

  return (
    <div className={"h-100"}>
      <div className="row h-100">
        {activeControlView === null ? (
          <FactoryView
            activeControlView={activeControlView}
            setActiveControlView={setActiveControlView}
            selectJob={selectJob}
            setSelectJob={setSelectJob}
            activeTask={activeTask}
            setActiveTask={setActiveTask}
            openModal={openModal}
            setOpenModal={setOpenModal}
            selectProject={selectProject}
            setSelectProject={setSelectProject}
            closeModalJob={closeModalJob}
            setCloseModalJob={setCloseModalJob}
          />
        ) : activeControlView === "project" || activeControlView === "job" ? (
          <CreateProjectForm
            setActiveControlView={setActiveControlView}
            setOpenModal={setOpenModal}
            activeControlView={activeControlView}
            selectJob={selectJob}
            setSelectJob={setSelectJob}
            setSelectProject={setSelectProject}
          />
        ) : activeControlView === "editProject" ? (
          //  && selectJob !== null
          <CreateProjectForm
            setActiveControlView={setActiveControlView}
            setOpenModal={setOpenModal}
            activeControlView={activeControlView}
            selectJob={selectJob}
            setSelectJob={setSelectJob}
            setSelectProject={setSelectProject}
          />
        ) : idproject && idjob ? (
          <CreateQuoteForm
            setActiveControlView={setActiveControlView}
            selectProject={selectProject}
            selectJob={selectJob}
            setSelectProject={setSelectProject}
          />
        ) : activeControlView === "viewProject" && selectJob !== null ? (
          <FatoryViewDetailProject
            setActiveControlView={setActiveControlView}
            setActiveTask={setActiveTask}
            openModal={openModal}
            setOpenModal={setOpenModal}
            setSelectJob={setSelectJob}
            selectJob={selectJob}
            activeTask={activeTask}
            activeControlView={activeControlView}
            selectProject={selectProject}
            setSelectProject={setSelectProject}
            closeModalJob={closeModalJob}
            setCloseModalJob={setCloseModalJob}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Factory;
