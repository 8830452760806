import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RatesModel } from '../../models/ItemModel';
import { PackageModel } from '../../models/ItemModel';
import stockpileService from '../../services/stockpile';


export const CreatePackage = createAsyncThunk('package/create', async (data: PackageModel) => {
    try {
        const res: any = await stockpileService.CreatePackage(data)
        console.log(res);
        return res._id.toString();
    } catch (error) {
        console.log(error);
        return false
    }
})

interface RateInfo {
    hour: number,
    daily: number,
    week: number,
    sale: number,
    holiday_day: number,
    holiday_hour: number,
    overtime_hour: number,
    holiday_overtime_hour: number,
    Qty: number,
    _id: string
}


const initialState = {
    PackageItems: [],
    PackageAssets: [] as Array<Array<any>>,
    Items: [],
    Assets: [] as Array<any>,
    maxValue: [],
    Description: {
        name: '',
        description: '',
        picture: ''
    },
    namePicture: '',
    RatesData: {
        hour: '',
        daily: '',
        week: '',
        sale: '',
        holiday_day: '',
        holiday_hour: '',
        overtime_hour: '',
        holiday_overtime_hour: '',
    },
    RatesDataInfo: [] as Array<RateInfo>,
    RatesErrors: false,
    DescriptionErrors: false,
    ItemsErrors: false,
    checkItemFormAlert: false,
    packageResponse: '',
    loading: false
};

const selectAssetsSlice = createSlice({
    name: 'PackageItems',
    initialState,
    reducers: {
        setItemPackage: (state, action) => {
            state.PackageItems = state.PackageItems.concat(action.payload)
        },
        setMaxvalue: (state, action) => {
            state.maxValue = action.payload
        },
        deleteItemPackage: (state, action) => {
            const ar = state.Items
            ar.splice(action.payload, 1);
            state.PackageItems = ar
        },
        AddSpecificAssetsPackage: (state , action) =>{
            state.PackageAssets.length = action.payload
        },
        AddAsset: (state, action) => {
        state.PackageAssets[action.payload.index] = state.PackageAssets[action.payload.index].concat([action.payload.asset])

        },
        AddAssetEmpty: (state, action) => {
        state.PackageAssets[action.payload] = []

        },
        deleteAssetPackage: (state, action) => {
            const ar = state.PackageAssets[action.payload.index]
            ar.splice(action.payload.deleteId, 1);
            state.PackageAssets[action.payload.index] = ar
        },
        setNamePicture: (state, action) => {
            state.namePicture = action.payload
        },
        SelectItems: (state, action) => {
            state.Items = state.PackageItems
        },
        SelectAssets: (state, action) => {
            state.Assets = state.Assets.concat(state.PackageAssets[action.payload])
        },
        cleanAssets:(state) => {
            state.PackageAssets = []
        }
        ,
        setNewInfoRates: (state, action) => {
            let review = state.RatesDataInfo.filter((rti) => rti._id == action.payload._id)
            let kp = action.payload.qty || 1
            if (review.length < 1) {
                state.RatesDataInfo = state.RatesDataInfo.concat([{
                    hour: parseInt(action.payload.hour) * kp,
                    daily: action.payload.daily * kp,
                    week: action.payload.week * kp,
                    sale: action.payload.sale * kp,
                    holiday_day: action.payload.holiday_day * kp,
                    holiday_hour: action.payload.holiday_hour * kp,
                    overtime_hour: action.payload.overtime_hour * kp,
                    holiday_overtime_hour: action.payload.holiday_overtime_hour * kp,
                    Qty: kp,
                    _id: action.payload?._id
                } as RateInfo])
            }
        },
        updateQtyRateInfo: (state, action) => {
            state.RatesDataInfo[action.payload.index] = {
                hour: action.payload.rates?.hour * action.payload.qty,
                daily: action.payload.rates?.daily * action.payload.qty,
                week: action.payload.rates?.week * action.payload.qty,
                sale: action.payload.rates?.sale * action.payload.qty,
                holiday_day: action.payload.rates?.holiday_day * action.payload.qty,
                holiday_hour: action.payload.rates?.holiday_hour * action.payload.qty,
                overtime_hour: action.payload.rates?.overtime_hour * action.payload.qty,
                holiday_overtime_hour: action.payload.rates?.holiday_overtime_hour * action.payload.qty,
                Qty: action.payload.qty,
                _id: state.RatesDataInfo[action.payload.index]?._id
            }
        },
        setDescriptionData: (state, action) => {
            state.Description = action.payload
        },
        setRates: (state, action) => {
            state.RatesData = { ...state.RatesData, ...action.payload }
        },
        changeRatesErrors: (state, action) => {
            state.RatesErrors = action.payload
        },
        changeDescriptionErrors: (state, action) => {
            state.DescriptionErrors = action.payload
        },
        changeItemsErrors: (state, action) => {
            state.ItemsErrors = action.payload
        },
        EmptyPackageForm: (state) => {
            state.PackageItems = []
            state.PackageAssets = []
            state.Items = []
            state.Assets = []
            state.Description = {
                name: '',
                description: '',
                picture: ''
            }
            state.namePicture = ''
            state.RatesData = {
                hour: '',
                daily: '',
                week: '',
                sale: '',
                holiday_day: '',
                holiday_hour: '',
                overtime_hour: '',
                holiday_overtime_hour: '',
            }
            state.RatesErrors = false
            state.packageResponse = ''
            state.loading = false
        },
        setCheckItemFormAlert: (state, action) => {
            state.checkItemFormAlert = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(CreatePackage.fulfilled, (state, action) => {
            state.loading = false
            state.packageResponse = action.payload
        })
            .addCase(CreatePackage.pending, (state, action) => {
                state.loading = true
            })
            .addCase(CreatePackage.rejected, (state, action) => {
                state.loading = false
            })
    }
});

export const { changeRatesErrors, setMaxvalue,setRates,AddAssetEmpty,AddSpecificAssetsPackage ,cleanAssets,setNamePicture, setCheckItemFormAlert, changeDescriptionErrors, changeItemsErrors, updateQtyRateInfo, setNewInfoRates, EmptyPackageForm, setDescriptionData, setItemPackage, deleteItemPackage, AddAsset, deleteAssetPackage, SelectItems, SelectAssets } = selectAssetsSlice.actions;
export default selectAssetsSlice.reducer;
