import set from 'lodash.set';
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface CreateRateCardState {
  data: {
    name:         string;
    description:  string;
    client_id:    string;
  }
}

const initialState: CreateRateCardState = {
  data: {
    name:        "",
    description: "",
    client_id:   ""
  }
};

const _createRateCardForm = createSlice<CreateRateCardState, SliceCaseReducers<CreateRateCardState>>({
  name: 'rates-add-ratecard-form',
  initialState,
  reducers: {
    clearData: (state) => {
      state = initialState;
    },
    setData: {
      prepare: (data: CreateRateCardState) => {
        return { payload: data };
      },
      reducer: (state, action) => {
        state.data = {...action.payload};
      }
    },
  },
});

export const createRateCardForm = _createRateCardForm.actions;
export default _createRateCardForm.reducer;
