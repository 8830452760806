import { url } from 'inspector';
import { object, string, number, array, date, InferType } from 'yup';
import ItemModel from '../../../../../models/ItemModel';



let sizesSchemaValidationm = object({
  weight: number().typeError('weight must be a number'),
  length: number().typeError('length must be a number'),
  width: number().typeError('length must be a number'),
  height: number().typeError('length must be a number'),
  weight_unit: string().typeError('length must be a text'),
  sizes_unit: string().typeError('length must be a text'),
});

let ItemSchemaValidation = object().shape({
  name: string().required().typeError('the name is required'),
  category: string().typeError('must be a text'),
 // sub_category: object().typeError('must be a text'),
  brand: string().typeError('this must be a text'),
  web_links: array().of(
    string().url().label('this field'),
  ).typeError('this must be a url'),
  manual_url: string().url().label('this field'),
  sizes: sizesSchemaValidationm,
  description: string(),
  consumption: number().typeError('consumption must be a number')
});

export default ItemSchemaValidation




