interface Props {
    icon?: string,
    children: React.ReactNode;
    type?: 'danger' | 'default';
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
}

const SimpleButton = ({
    icon = 'bi-plus-circle-fill',
    type = 'default',
    onClick,
    children,
}: Props) => {
    return (
        <button
            onClick={onClick}
            style={{
                ...(type === 'danger' ? { color: '#e63d4f' } : {}),
            }}
            className="btn btn-link typo-body btn-icon-text shadow-none d-flex align-items-center gap-2 p-0"
        >
            <i
                style={{
                    marginTop: 0,
                    height: 'auto',
                    display: 'block',
                    fontSize: '20px',
                }}
                className={`bi ${icon}`}
            ></i>
            <small
                style={{
                    display: 'block',
                }}
                className="px-0"
            >{children}</small>
        </button>
    )
}

export default SimpleButton

