import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AxiosRequestConfig } from "axios";

import { useRequest } from "./useRequest";
import { RootState } from "src/store/store";
import { IRateCard } from "src/models/RateModel";
import { IContact } from "src/models/ContactModel";

export default function useRates() {
    //const filters = useSelector((state: RootState) => state.blackbookFiltersSlice);

    const config: AxiosRequestConfig = useMemo(() => {
        const conf: AxiosRequestConfig = {
            url: 'rate-cards/all',
        };

        return conf;

    }, []);

    const { ...res } = useRequest<IRateCard[]>(config)

    return res
}