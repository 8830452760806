import { useFormik } from "formik";
import { AxiosResponse } from "axios";
import { FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";

import { ApiError } from "src/models";
import useQuery from "src/hooks/useQuery";
import { useToast } from "src/hooks/useToast";
import { layoutConfig } from "src/store/layout";
import { ICategory } from "src/models/Category";
import useContacts from "src/hooks/useContacts";
import { useRequest } from "src/hooks/useRequest";
import SelectInput from "src/components/UI/forms/Select";
import { BlackbookService } from "src/services/blackbook";
import ConfirmModal from "src/components/Modals/ConfirmModal";
import StepButton from "src/components/UI/buttons/stepButton";
import ButtonFiles from "src/components/UI/buttons/ButtonFile";
import ProgressbarTitle from "src/components/UI/ProgressbarTitle";
import { groupUploadErrors, parseInputError, UploadError } from "src/util";

const UploadCSV: React.FC<{ onFinish: (category?: string) => void }> = ({
  onFinish,
}) => {
  const query = useQuery();
  const history = useHistory();
  const toast = useToast();
  const dispatch = useDispatch();

  const { mutate } = useContacts();
  const { data: categories } = useRequest<ICategory[]>({
    url: "categories/contacts/all",
  });

  const [errors, setErrors] = useState<UploadError[]>([]);
  const [readingFullErrors, setReadingFullErrors] = useState<boolean>(false);

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<ICategory>();
  const totalCategories = useMemo(
    () =>
      categories
        ? categories.reduce((acc, category) => {
            return [
              ...acc,
              category,
              ...category.sub_categories.map((cat) => ({
                ...cat,
                name: `${category.name} > ${cat.name}`,
              })),
            ];
          }, [] as ICategory[])
        : [],
    [categories]
  );

  useEffect(() => {
    dispatch(layoutConfig.setModuleTitle("BLACKBOOK - UPLOAD CONTACTS"));
  }, []);

  const formik = useFormik<{ file: File | null }>({
    initialValues: {
      file: null,
    },
    onSubmit: async (values) => {
      if (!selectedCategory) {
        return toast.error("Please select a valid category.");
      }

      if (!values.file) {
        formik.setFieldError("file", "Please select a file.");
        return toast.error("Please select a valid file.");
      }

      BlackbookService.CreateContactsByCSV(
        values.file,
        selectedCategory._id,
        (progressEvent) => {
          setUploadProgress(progressEvent.loaded / progressEvent.total);
        }
      )
        .then((res) => {
          toast.success(res || "Successfully uploaded.");
          formik.resetForm();
          onFinish();
          setUploadProgress(0);
          mutate(undefined, true);

          return;
        })
        .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
          if (!err?.data?.message)
            return toast.error(
              "An error occurred please try reloading the page."
            );

          setErrors(err.data.message);
          setUploadProgress(0);
        });
    },
  });

  useEffect(() => {
    const category = query.get("category");
    if (!categories) return;
    const exitsQuery = category !== undefined && category !== null;
    if (!exitsQuery) return;

    const selectedCat = totalCategories.find((c) => c._id === category);

    selectedCat && setSelectedCategory(selectedCat);
  }, [query, categories, totalCategories]);

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;

    const file = e.target.files[0];
    formik.setFieldValue("file", file);
  };

  const handleSelectCategory = (category: string) => {
    if (!category) return;

    query.set("category", category);
    history.push({ search: query.toString() });
  };

  const onCancelModalClose = (confirm?: boolean) => {
    setIsCancelling(false);
    if (confirm !== undefined && !confirm) {
      formik.resetForm();
      onFinish();
    }
  };

  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="The changes you made will be lost if you continue."
          confirmText="KEEP EDITING"
          cancelText="DISCARD UPLOAD"
          onClose={onCancelModalClose}
        />
      )}

      <div className="container-fluid h-100">
        <div className="row" style={{ height: "98%" }}>
          <div className="col card h-100 custom-scrollbar overflow-y">
            {/* Top actions */}
            <div className="row justify-content-between align-items-center p-2">
              <button
                className="btn btn-outline-danger typo-body col-auto border-0"
                onClick={setIsCancelling.bind(null, true)}
              >
                Cancel
              </button>
            </div>

            <section className="container">
              <div className="row mb-2 pt-5">
                <div className="col-5">
                  <ButtonFiles
                    label="CSV file"
                    multiple={false}
                    onChange={onChangeFile}
                    error={formik.errors.file}
                    selectedName={
                      formik.values.file ? formik.values.file.name : ""
                    }
                  />
                </div>
                <div className="col-4">
                  <p className="poppinsText text-primary mb-1">
                    {uploadProgress * 100}% UPLOAD COMPLETE
                  </p>
                  <div className="d-flex w-100">
                    <ProgressbarTitle
                      className="w-100"
                      value={uploadProgress}
                      file
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-5">
                  <FormGroup className="row col-12">
                    <SelectInput
                      value={selectedCategory?._id}
                      placeholder="Select a category for the new contact *"
                      onChange={(val: string) => handleSelectCategory(val)}
                      options={totalCategories?.map((category) => ({
                        label: category.name,
                        value: category._id,
                      }))}
                    />
                  </FormGroup>
                </div>
              </div>
            </section>

            <footer className="container">
              <div className="w-100 row justify-content-end mb-2">
                <StepButton
                  onClick={() => formik.submitForm()}
                  customText="Upload"
                  save
                />
              </div>

              {errors.length > 0 && (
                <div className="backprimary w-100 p-2">
                  <h6 className="ps-2">
                    There are some invalid contacts (
                    {groupUploadErrors(errors).length}) in the csv:
                  </h6>

                  <ul>
                    {groupUploadErrors(errors)
                      .slice(
                        0,
                        readingFullErrors
                          ? groupUploadErrors(errors).length
                          : 15
                      )
                      .map((error, index) => (
                        <li key={`${error.in}-${index}`}>
                          <span>Row #{error.in + 1}:</span>
                          <ol>
                            {error.errors.map((err, i) => (
                              <li key={`${err.field}-${error.in}-${i}`}>
                                <span>
                                  <b>{parseInputError(err.field)}:</b>{" "}
                                  {err.error}.
                                </span>
                              </li>
                            ))}
                          </ol>
                        </li>
                      ))}
                  </ul>

                  {groupUploadErrors(errors).length > 15 &&
                    (readingFullErrors ? (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setReadingFullErrors(false)}
                      >
                        Show less
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setReadingFullErrors(true)}
                      >
                        Show all
                      </button>
                    ))}
                </div>
              )}
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadCSV;
