import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  QuoteValue: [] as any,
};

console.log("value", initialState);

const factorySetTableQuote = createSlice({
  name: "setQuote",
  initialState,
  reducers: {
    // setEditQuote: (state, action) => {
    //   state.QuoteValue = [...state.QuoteValue, action.payload];
    // },
    setEditQuote: (state, action) => {
      state.QuoteValue = [...state.QuoteValue, action.payload];
    },
  },
});

export const { setEditQuote } = factorySetTableQuote.actions;
export default factorySetTableQuote.reducer;
