import { Html5QrcodeScanner, Html5Qrcode, Html5QrcodeScanType } from 'html5-qrcode'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import ButtonIconText from '../UI/buttons/ButtonIconText';
import SimpleButton from '../UI/buttons/SimpleButton';

interface Props {
    isOpen: boolean,
    setCode: any,
    setStateModal: any
    showScanner?: any
    showScannerValue?: any
    html5scanner?: any
}

const RenderElemnt: React.FC<Props> = (props) => {

    const [cameraId, setCameraId] = useState<any>('')

    const [refHtml, setRefHtml] = useState<any>('')

    const input: any = useRef()


    const startScan = (cameraId: any) => {
        const html5QrCode: any = new Html5Qrcode("reader");
        setRefHtml(html5QrCode)
        const config = { fps: 10, qrbox: { width: 250, height: 250 } };
        props.showScanner(true)
        const qrCodeSuccessCallback: any = (decodedText: any, decodedResult: any) => {
            props.setCode(decodedText)
            props.setStateModal(false)
        };
        html5QrCode.start({ facingMode: "user" }, config, qrCodeSuccessCallback);
    }

    const stopScan = (html5QrCode: any) => {
        props.showScanner(false)
        html5QrCode.stop().then((ignore: any) => {
            // QR Code scanning is stopped.
            console.log('stopped');

        }).catch((err: any) => {
            // Stop failed, handle it.
            console.log(err);

        });

    }

    const StartScanFile = (imageFile: any) => {
        const html5QrCode: any = new Html5Qrcode("reader");
        html5QrCode.scanFile(imageFile, true)
            .then((decodedText: any, decodedResult: any) => {
                // success, use decodedText
                props.setCode(decodedText)
                props.setStateModal(false)

            })
            .catch((err: any) => {
                // failure, handle it.
                console.log(`Error scanning file. Reason: ${err}`)
            });
    }


    useEffect(() => {

        Html5Qrcode.getCameras().then(devices => {
            if (devices && devices.length) {
                setCameraId(devices[0].id)
                // .. use this to start scanning.
            }
        }).catch(err => {
            // handle err
        });

    }, [props.isOpen]);


    const handlePicture = async (event: any) => {
        const file = event.target.files[0]
        StartScanFile(file)
    }





    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <div data-testid="scanner" ></div>
            {props.showScannerValue && (
                <Button
                    style={{ marginTop: '3%', height: '3em', padding: '1.5%', background: 'red', color: 'white', fontWeight: 'bold' }}
                    onClick={() => stopScan(refHtml)}
                >
                    STOP SCANNING
                </Button>
            )}
            {!props.showScannerValue && (
                <>
                    <div style={{ fontSize: '100px', display: 'flex' }}>
                        <i className='bi bi-upc-scan'></i>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <ButtonIconText
                                title='SCAN AN IMAGE'
                                onClick={() => input?.current?.click()}
                            />
                            <input
                                type="file"
                                ref={input}
                                onChange={handlePicture}
                                hidden
                            />
                        </div>

                        <div style={{ marginLeft: '5px' }}>
                            <button onClick={() => startScan(cameraId)}
                                className='btn btn-success' style={{ height: '2.5em', fontWeight: 'bold', color: 'white' }} >
                                START SCANNING
                            </button>
                        </div>
                    </div>
                </>
            )
            }
        </div>
    )
}

export default RenderElemnt;