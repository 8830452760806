import { FormGroup, FormFeedback, FormText , Input } from "reactstrap";
import { parseInputError } from "src/util";

export type InputType =
  | 'text'
  | 'email'
  | 'select'
  | 'file'
  | 'radio'
  | 'checkbox'
  | 'switch'
  | 'textarea'
  | 'button'
  | 'reset'
  | 'submit'
  | 'date'
  | 'datetime-local'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'url'
  | 'week'
  | 'password'
  | 'datetime'
  | 'time'
  | 'color';

interface Props {
  value?: string;
  onChange: (value: string) => void;
  placeHolder?: string;
  className?: string;
  isRequired?: boolean;
  disabled?: boolean;
  errorMessage?: any;
  inputType?: InputType;
}


const InputFloatingLabel: React.FC<Props> = (props) => {
  return (
    <FormGroup className={props.className}>

    <div className="d-flex align-items-center">
      <div className="form-floating text-primary typo-body-important w-100">
        <Input
            {...props}
            type={props.inputType}
            className={(props.className ? props.className : "") + ` form-control text-secondary typo-body-important ${props.disabled && 'disabledInput' } `}
            placeholder={props.placeHolder}
            value={props.value}
            onChange={(event) => props?.onChange(event.target.value)}
            valid={props.errorMessage?false:props.value?true:false}
            invalid={props.errorMessage?true:false}
            style={{height: 42}}
            disabled={props.disabled}
          />

          <label className={`${props.isRequired ? "required" : ""}`}>
            {props.placeHolder}
          </label>
        </div>
      </div>
      <p className="validation">{props.errorMessage}</p>
    </FormGroup>
  );
};

export default InputFloatingLabel;
