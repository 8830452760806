import FlatList from 'flatlist-react';
import { Modal } from 'reactstrap';
import { getSections } from '../../util/RateFunctions';
import RateSection from '../Rates/RateList/RateSection';
import DUMMY_CONTACTS from '../../dummy_data/contacts.json';
import { useEffect, useState } from 'react';
import useRates from 'src/hooks/useRates';
import RateModel from 'src/models/RateModel';

interface Props {
  onClose: (value?: boolean) => void;
  onSelectRate: (rate: RateModel) => void;
}

const SelectRateCompare: React.FC<Props> = (props) => {
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState<RateModel[]>([]);
  const { data: rates, error } = useRates();
  const [typeRateCards, setTypeRateCards] = useState<string>("system");
  const [list, setlist] = useState<RateModel[]>([]);
  const [standartRateCardList, setStandartRateCardList] = useState<RateModel[]>(
    []
  );

  useEffect(() => {

    if (loading) {
      if (Array.isArray(rates)) {
        if (typeRateCards === "system") {
          let systemRateCardList = rates.filter(
            (item) => !item.assigned_to && !item.blocked
          );
          let standartRateCardList = rates.filter(
            (item) => !item.assigned_to && item.blocked
          );

          setStandartRateCardList(standartRateCardList);
          setdata(systemRateCardList);
          setlist(systemRateCardList);

          setloading(false);
        } else if (typeRateCards === "clients") {
          let clientsRateCardList = rates.filter((item) => item.assigned_to);
          setdata(clientsRateCardList);
          setlist(clientsRateCardList);

          setloading(false);
        }
      }
    }
  }
  )

    //set sections
  let sections = getSections(list);
  const goTo = (letter: string) => {
    if (sections[letter]) {
      sections[letter].ref.current?.scrollIntoView();
    }
  };

  const changeRate = (id: string) => {
    // console.log("Change rate: " + id);
    
  };
    
  return (
    <Modal isOpen={true} toggle={props.onClose.bind(null, undefined)} centered>
      <div className="container">
        <div className="row p-3">
          <div className="col-12">
            <h2 className="font-weight-bold text-secondary typo-display">
              Select rate card to compare
            </h2>
          </div>

          <div className="col-12 mt-1 p-0">
            <button className="btn btn-primary mx-1"  onClick={() => {setTypeRateCards("system");setloading(true);}}>SYSTEM RATE CARDS</button>
            <button className="btn btn-primary mx-1" onClick={() => {setTypeRateCards("clients");setloading(true)}}>CLIENT RATE CARDS</button>
          </div>
        </div>

        <div className="input-icon mt-1 mx-2">
          <i className="bi bi-search text-primary"></i>
          <input
            className="form-control py-2 typo-body text-primary"
            type="search"
            id="example-search-input"
            placeholder="Search"
          />
        </div>

        <div
          className="overflow-y overflow-x-none px-2 custom-scrollbar mt-3"
          style={{ height: 400 }}
        >
          <FlatList
            list={Object.keys(sections)}
            renderItem={(item: any, index: any) => {
              return (
                <RateSection
                  ref={sections[item].ref}
                  key={index}
                  letter={item}
                  items={sections[item].items}
                  onRateSelected={(rate:RateModel) => props.onSelectRate(rate)}
                />
              );
            }}
            renderWhenEmpty={() => <div>List is empty!</div>}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectRateCompare;
