import React, { useState } from "react";
import { Modal } from "reactstrap";
import Status from "./Status";

interface Props {
  view: boolean;
  setView: any;
  toggle: boolean;
  activeControl: string;
  setActiveControl: any;
  getNewStatus: any;
  sendProject: any;
  setSendProject: any;
}
const ModalStatus: React.FC<Props> = (props) => {
  const closeModal = () => {
    props.setView(false);
    // props.setPreView(false);
  };

  return (
    <Modal
      isOpen={props.view}
      toggle={() => props.toggle}
      centered
      className=""
      style={{ width: "550px", maxWidth: "550px" }}
    >
      <div className={""} style={{ height: "570px" }}>
        <div
          onClick={closeModal}
          className="cursor-pointer pt-2"
          style={{ width: "30px" }}
        >
          <i
            className="bi bi-x-lg  "
            style={{
              fontSize: "18px",
              marginRight: "300px",
              height: "150px",
              padding: "20px",
            }}
          ></i>
        </div>
        <div className="row ">
          <Status
            setView={props.setView}
            activeControl={props.activeControl}
            setActiveControl={props.setActiveControl}
            getNewStatus={props.getNewStatus}
            sendProject={props.sendProject}
            setSendProject={props.setSendProject}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalStatus;
