function Meeting() {
  return (
    <div className="card flex-grow-1">
      <div className="card-body px-5">

        <section className="card-title d-flex gap-4 text-center text-body">
          <h5 className="flex-grow-1">Meeting Today</h5>
          <span className="text-muted cursor-pointer">
            <i className="bi bi-three-dots"></i>
          </span>
        </section>

        <section className="h-100 d-flex flex-column justify-content-center align-items-center">
          <p className="fs-6 text-body m-0">Consultation Reports</p>
          <span className="text-muted"><i className="bi bi-clock-fill"></i> 14:30 PM - 16 PM</span>
          <span className="d-inline-flex gap-1 mt-1">
            {Array.from({ length: 3 }).map((_, i) => (
              <span className="align-items-center d-flex fs-6 justify-content-center avatar2 text-primary bg-contrast" style={{ width: 25, height: 25 }}>
                {['A', 'B', 'C'][i]}
              </span>
            ))}
          </span>
          <div className="mt-3 mb-4 d-flex gap-4 text-body">
            <i className="cursor-pointer bi bi-arrow-left"></i>
            <i className="cursor-pointer bi bi-arrow-right"></i>
          </div>
        </section>

      </div>

      <div className="card-footer d-flex gap-3 justify-content-center">
        <button className="btn btn-primary">
          All Meetings
        </button>
        <button className="btn btn-default">
          Create Meeting
        </button>
      </div>
    </div>
  )
}

export default Meeting;