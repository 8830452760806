import axios from 'axios';
// import get from 'lodash.get';

import { R } from 'src/models';
// import { deepEntries } from 'src/util';
import { BCreateRateCard, IRateCard, BAddRateCardItem, BCreateRateCardVersion } from 'src/models/RateModel';
// import { IContact } from 'src/models/ContactModel';
// import { decodeBase64 } from 'src/util/converToBase64';

const CreateRateCard = async (params: BCreateRateCard): Promise<IRateCard> => {

    const { data } = await axios.post<R<IRateCard>>('rate-cards/', params, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    });

    return data.data;
}

const CreateRateCardVersion = async (id: string, params: BCreateRateCardVersion): Promise<IRateCard> => {

    const { data } = await axios.post<R<IRateCard>>(`rate-cards/${id}/rate-card-versions`, params, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    });

    return data.data;
}


const DeleteRateCard = async (id: string): Promise<IRateCard> => {
    // const params = { permanently: permanentlyDelete };
    const { data } = await axios.delete<R<IRateCard>>(`rate-cards/${id}`);
  
    return data.data;
}


export const RateCardService = {
    CreateRateCard,
    DeleteRateCard,
    CreateRateCardVersion
}