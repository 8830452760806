import React from "react";
import FlatList from "flatlist-react";
import { useHistory, useParams } from "react-router-dom";
import { useState, Fragment, useEffect } from "react";
import { getSections } from "../../../../../util/RateFunctions";
import RateModel from "../../../../../models/RateModel";
import RateListEmpty from "../../../../Rates/RateList/RateListEmpty";
import AddRateCardModal from "../../../../../components/Modals/AddRateCardModal";
import useQuery from "./../../../../../hooks/useQuery";
import useRates from "./../../../../../hooks/useRates";
import RateSectionQuote from "./RateSectionQuote";
import RateItemQuote from "./RateItemQuote";
import { Col, Row } from "reactstrap";
import InputSearch from "../../../../UI/forms/InputSearch";

interface Props {
  onAddRateCard: (type?: string) => void;
  typeRate: string;
  onChangeTypeRate?: (type: string) => void;
  setGetRates: any;
  setView: any;
  setViewRates: any;
  typeRateCards: string;
  setTypeRateCards: any;
}

const RateListQuote: React.FC<Props> = (props) => {
  const query = useQuery();
  const history = useHistory();
  const params = useParams<{ rateCardId: string }>();

  const create = query.get("create");

  const [filter, setFilter] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [loading, setloading] = useState(true);

  const [data, setdata] = useState<RateModel[]>([]);
  const [list, setlist] = useState<RateModel[]>([]);
  const [standartRateCardList, setStandartRateCardList] = useState<RateModel[]>(
    []
  );

  const [addRateCardModalOpen, setaddRateCardModalOpen] =
    useState<boolean>(false);
  const [addRateCardModalType, setaddRateCardModalType] =
    useState<string>("system");

  const { data: rates, error } = useRates();

  const changeRate = (id: string, rateVersion: string, name: string) => {
    props.setGetRates({ rate_id: id, rate_version: rateVersion, name: name });
    props.setView(false);
    props.setViewRates(false);
  };

  useEffect(() => {
    if (loading) {
      if (Array.isArray(rates)) {
        if (props.typeRateCards === "system") {
          let systemRateCardList = rates.filter(
            (item) => !item.assigned_to && !item.blocked
          );
          let standartRateCardList = rates.filter(
            (item) => !item.assigned_to && item.blocked
          );

          setStandartRateCardList(standartRateCardList);
          setdata(systemRateCardList);
          setlist(systemRateCardList);

          setloading(false);
        } else if (props.typeRateCards === "clients") {
          let clientsRateCardList = rates.filter((item) => item.assigned_to);
          setdata(clientsRateCardList);
          setlist(clientsRateCardList);

          setloading(false);
        }
      }
    }
  });

  //set sections
  let sections = getSections(list);
  const goTo = (letter: string) => {
    if (sections[letter]) {
      sections[letter].ref.current?.scrollIntoView();
    }
  };

  //search
  const searchData = (text: string) => {
    let newData = [...data];
    let searchItems = newData.filter((item) =>
      item.name.toLocaleLowerCase().includes(text.trim().toLocaleLowerCase())
    );

    setlist(searchItems);
    setSearch(text);
  };

  const systemSelect = () => {
    props.setTypeRateCards("system");
    if (Array.isArray(rates)) {
      let systemRateCardList = rates.filter(
        (item) => !item.assigned_to && !item.blocked
      );
      let standartRateCardList = rates.filter(
        (item) => !item.assigned_to && item.blocked
      );

      setStandartRateCardList(standartRateCardList);
      setdata(systemRateCardList);
      setlist(systemRateCardList);
    }
  };

  const clientsSelect = () => {
    props.setTypeRateCards("clients");
    if (Array.isArray(rates)) {
      let clientsRateCardList = rates.filter((item) => item.assigned_to);
      setdata(clientsRateCardList);
      setlist(clientsRateCardList);
    }
  };

  return (
    <div className="d-flex flex-column h-100 px-0 mx-0">
      {/* Modal add rate card */}
      {addRateCardModalOpen && (
        <AddRateCardModal
          onClose={() => setaddRateCardModalOpen(false)}
          // type={addRateCardModalType}
          onSave={props.onAddRateCard}
          systemRateCardId={
            rates
              ? rates
                .filter((item) => !item.assigned_to && item.blocked)[0]
                ._id.toString()
              : ""
          }
        />
      )}
      {/* Modal add rate card */}

      <div className="contact-header d-flex flex-column px-0">
        {/* rate list - top buttons */}
        <div className="px-0">
          <div className="mt-0 p-0 pe-1 me-5">
              <Row className="col-md-auto col-lg-12 me-5 ">
                <Col lg="6" className="pe-0 me-0 ">
                  <button
                    onClick={systemSelect}
                    className={
                      (props.typeRateCards === "system" ||
                        props.typeRateCards === "master"
                        ? "btn-primary text-white"
                        : "btn-default text-primary") + " btn btn-block px-3 px-xl-3 m-0"
                    }
                  >
                    SYSTEM RATE CARDS
                  </button>
                </Col>
                <Col lg="6" className="ps-0 ms-0 ">
                  <button
                    onClick={clientsSelect}
                    className={
                      (props.typeRateCards === "clients"
                        ? "btn-primary text-white"
                        : "btn-default text-primary") + " btn btn-block me-3   "
                    }
                  >
                    CLIENT RATE CARDS
                  </button>
                </Col>
              </Row>
          </div>
        </div>
        {/* rate list - top buttons */}

        {/* rate list - top search */}
        <div className="px-2">
          <div className="mt-2" >
            <InputSearch
              className="w-100"
              placeholder="Search rate card"
            />
          </div>
        </div>
        {/* rate list - top search */}
      </div>

      <div className="row contact-container w-100 mx-auto nt-1 " >
        <div className="col p-0 h-100 custom-scrollbar contact-list vw-100 ">
          {/* render standart rate card */}
          {standartRateCardList.map((item: RateModel, index: number) => {
            return (
              <RateItemQuote
                key={index}
                contact={item}
                onSelect={() =>
                  changeRate(
                    item._id.toString(),
                    item.rate_card_version[0]._id.toString(),
                    item.name
                  )
                }
                active={params.rateCardId === item._id.toString()}
                block
              />
            );
          })}

          {!loading && (
            <Fragment>
              {list.length > 0 ? (
                <FlatList
                  list={Object.keys(sections)}
                  renderItem={(item: any, index: any) => {
                    return (
                      <RateSectionQuote
                        ref={sections[item].ref}
                        key={index}
                        letter={item}
                        items={sections[item].items}
                        onRateSelected={changeRate}
                        active={!!params.rateCardId ? params.rateCardId : ""}
                      />
                    );
                  }}
                  renderWhenEmpty={() => <RateListEmpty />}
                />
              ) : (
                <RateListEmpty />
              )}
            </Fragment>
          )}
        </div>

        <div className="list-linked p-2 overflow-y hide-scrollbar h-100">
          {Object.keys(sections).map((item, index) => {
            return (
              <p
                // onClick={goTo.bind(null, item)}
                key={index}
                className="m-0 p-0 typo-body text-primary"
              >
                {item}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RateListQuote;
