import React from "react";
import { useToast } from "src/hooks/useToast";
import InputSelect from "src/components/UI/forms/InputSelect";
import SimpleButton from "src/components/UI/buttons/SimpleButton";

type SocialKeys = "facebook" | "twitter" | "instagram" | "linkedin";
type CommunicationKeys = "discord" | "website" | "skype";

interface SocialAndCommFormProps {
  socials?: {
    type: SocialKeys;
  }[];
  communication?: {
    type: CommunicationKeys;
  }[];
  formik: any;

  handleChange: (fieldName: string, value: any, path?: string) => void;
  setSocials?: (
    value: React.SetStateAction<Required<SocialAndCommFormProps>["socials"]>
  ) => void;
  setCommunication?: (
    value: React.SetStateAction<
      Required<SocialAndCommFormProps>["communication"]
    >
  ) => void;

  fillWith?: React.ReactNode;
}

export default function SocialAndCommForm({
  formik,
  socials,
  communication,
  fillWith,

  setSocials,
  handleChange,
  setCommunication,
}: SocialAndCommFormProps) {
  const toast = useToast();

  return (
    <div className="row mt-2">
      <div className="col-6">
        {!socials &&
          !setSocials &&
          communication &&
          setCommunication &&
          fillWith &&
          fillWith}

        {socials &&
          setSocials &&
          socials.map((item, index) => {
            return (
              <div className="row g-0">
                <div className="col-11">
                  <InputSelect
                    key={index}
                    select={{
                      value: item.type,
                      onChange: (value) => {
                        if (socials.some((social) => social.type === value)) {
                          toast.warn("Social media already added.");
                          return;
                        }
                        const val = formik.values?.social?.[item.type];

                        handleChange(`social.${item.type}`, undefined);
                        handleChange(`social.${value}`, val);

                        socials.splice(index, 1, { type: value as SocialKeys });
                      },
                      options: [
                        { label: "Instagram", value: "instagram" },
                        { label: "Facebook", value: "facebook" },
                        { label: "Twitter", value: "twitter" },
                        { label: "Linkedin", value: "linkedin" },
                      ],
                    }}
                    input={{
                      placeHolder: "Social media profile",
                      value: `${
                        formik.values?.social?.[item.type] === undefined
                          ? ""
                          : formik.values?.social?.[item.type]
                      }`,
                      onChange: (val) => {
                        handleChange(`social.${item.type}`, val);
                      },
                    }}
                  />
                </div>

                <div className="col-1 px-2 pt-1">
                  <SimpleButton
                    onClick={() => {
                      setSocials((prev) =>
                        prev.filter((i) => i.type !== item.type)
                      );
                      handleChange(`social.${item.type}`, undefined);
                    }}
                    type="danger"
                    icon="bi-dash-circle-fill"
                  >
                    {/* <i className="fas fa-times" /> */}
                  </SimpleButton>
                </div>
              </div>
            );
          })}

        {socials && setSocials && (
          <SimpleButton
            onClick={() => {
              let toAdd: SocialKeys = "facebook";
              ["facebook", "twitter", "instagram", "linkedin"].forEach(
                (item) => {
                  if (!socials.find((social) => social.type === item)) {
                    toAdd = item as SocialKeys;
                  }
                }
              );

              if (socials.length === 4) {
                toast.warn("You can only add 4 social media profiles");
                return;
              }

              setSocials((prev) => [...(prev || []), { type: toAdd }]);
            }}
          >
            ADD SOCIAL MEDIA PROFILE
          </SimpleButton>
        )}
      </div>

      <div className="col-6">
        {socials &&
          setSocials &&
          !communication &&
          !setCommunication &&
          fillWith &&
          fillWith}

        {communication &&
          setCommunication &&
          communication.map((item, index) => {
            return (
              <div className="row g-0">
                <div className="col-11">
                  <InputSelect
                    key={index}
                    select={{
                      value: item.type,
                      onChange: (value) => {
                        if (communication.some((com) => com.type === value)) {
                          toast.warn("Communication media already added.");
                          return;
                        }
                        const val = formik.values?.communication?.[item.type];

                        handleChange(`communication.${item.type}`, undefined);
                        handleChange(`communication.${value}`, val);

                        communication.splice(index, 1, {
                          type: value as CommunicationKeys,
                        });
                      },
                      options: [
                        { label: "Website", value: "website" },
                        { label: "Skype", value: "skype" },
                        { label: "Discord", value: "discord" },
                      ],
                    }}
                    input={{
                      placeHolder: "Communication profile",
                      /* @ts-ignore */
                      errorMessage: formik.errors.communication?.[item.type],
                      value: `${
                        formik.values?.communication?.[item.type] === undefined
                          ? ""
                          : formik.values?.communication?.[item.type]
                      }`,
                      onChange: (val) => {
                        handleChange(`communication.${item.type}`, val);
                      },
                    }}
                  />
                </div>

                <div className="col-1 px-2 pt-1">
                  <SimpleButton
                    onClick={() => {
                      setCommunication((prev) =>
                        prev.filter((i) => i.type !== item.type)
                      );
                      handleChange(`communication.${item.type}`, undefined);
                    }}
                    type="danger"
                    icon="bi-dash-circle-fill"
                  >
                    {/* <i className="fas fa-times" /> */}
                  </SimpleButton>
                </div>
              </div>
            );
          })}

        {communication && setCommunication && (
          <SimpleButton
            onClick={() => {
              let toAdd: CommunicationKeys = "discord";
              (["discord", "skype", "website"] as CommunicationKeys[]).forEach(
                (item) => {
                  if (!communication.find((com) => com.type === item)) {
                    toAdd = item as CommunicationKeys;
                  }
                }
              );

              if (communication.length === 3) {
                toast.warn("You can only add 3 communication profiles");
                return;
              }

              setCommunication((prev) => [...(prev || []), { type: toAdd }]);
            }}
          >
            ADD COMMUNICATION PROFILE
          </SimpleButton>
        )}
      </div>
    </div>
  );
}
