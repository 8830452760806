import axios from 'axios';
import set from 'lodash.set';
import get from 'lodash.get';

import { R } from 'src/models';
import { deepEntries } from 'src/util';
import { IContact } from 'src/models/ContactModel';
import { IComment } from 'src/models/CommentModel';
import { decodeBase64 } from 'src/util/converToBase64';
import { BCreateComment, BCreateContact, BEditComment } from 'src/models/Bodies';

const CreateContact = async (
  params: BCreateContact,
  files: Record<string, string> = {}
): Promise<IContact> => {
  const formData = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    if (!value || value === "") return;

    if (typeof value === "object" && value.constructor.name === Object.name) {
      for (const [_key, val] of deepEntries(value)) {
        decodeBase64(val, get(files, _key))
          .then((data) => {
            formData.append(_key.split(".")[1] || _key.split(".")[0], data);
          })
          .catch((err) => {});
      }

      const cleanedObj = {}
      for (const [_key, val] of deepEntries(value)) {
        if (typeof val === 'string' && val === '') continue
        set(cleanedObj, _key, val)
      }

      value = JSON.stringify(cleanedObj);
    }

    formData.append(key, value);
  });

  const { data } = await axios.post<R<IContact>>(
    "blackbook/contacts",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data.data;
};

const CreateContactsByCSV = async (file: File, category: string, onProgressChange?: ((progressEvent: any) => void) | undefined): Promise<string> => {
  const formData = new FormData();

  formData.append('contacts', file);
  formData.append('category', category);
  
  const { data } = await axios.post<R<string>>('blackbook/contacts/csv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: progressEvent => {
      if (onProgressChange) onProgressChange(progressEvent);
    }
  });

  return data.data;
}

const EditContact = async (id: string, params: Partial<BCreateContact>, files: Record<string, string> = {}): Promise<IContact> => {
  const formData = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    if (!value || value === '') return

    if (typeof value === 'object' && (value as any).constructor.name === Object.name) {
      for (const [_key, val] of deepEntries(value)) {
        decodeBase64(val, get(files, _key)).then(data => {
          formData.append(_key.split('.')[1] || _key.split('.')[0], data);
        }).catch(err => {})
      }

      value = JSON.stringify(value);
    }

    formData.append(key, value);
  })

  const { data } = await axios.put<R<IContact>>(`blackbook/contacts/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return data.data;
}

const ArchiveContact = async (id: string, permanentlyDelete = false): Promise<IContact> => {
  const { data } = await axios.delete<R<IContact>>(`blackbook/contacts/${id}`, {
    params: { permanently: permanentlyDelete },
  });

  return data.data;
};

const GetAllContacts = async () => {
  const response = await axios
    .get("blackbook/contacts/all")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  return response?.data.data;
};

const RestoreFromArchive = async (id: string): Promise<IContact> => {
  const { data } = await axios.put<R<IContact>>(`blackbook/contacts/${id}/restore`);

  return data.data;
}

const AddComment = async (body: BCreateComment): Promise<IComment> => {
  const { data } = await axios.post<R<IComment>>(`comments/contact/create`, {
    ...body,
  });

  return data.data;
}

const EditComment = async (id: string, body: BEditComment): Promise<IComment> => {
  const { data } = await axios.put<R<IComment>>(`comments/${id}/edit`, {
    ...body,
  });

  return data.data;
}

const DeleteComment = async (id: string): Promise<string> => {
  const { data } = await axios.delete<R<string>>(`comments/${id}/delete`);

  return data.data;
}

export const BlackbookService = {
  CreateContact,
  CreateContactsByCSV,
  EditContact,
  ArchiveContact,
  RestoreFromArchive,
  AddComment,
  EditComment,
  DeleteComment,
  GetAllContacts
}
