import { AnySchema, array, boolean, object, string } from 'yup';

import { ProspectData as TProspectData } from 'src/models/ContactModel';
import FreelancePersonalValidation from '../Freelance/PersonalInformation/validation';

export type ProspectData = TProspectData & {
  contact_since: string;
}

const ProspectPersonalValidation = FreelancePersonalValidation.omit(['email', 'address', 'union_affiliation', 'nationality', 'gender']).concat(object<Partial<Record<keyof ProspectData, AnySchema>>>({
  emails: array().of(
    object<Record<keyof Required<ProspectData>['emails'][number], AnySchema>>({
      address: string().email().max(100).required(),
      type: string().oneOf(['work', 'personal', 'other']).required(),
      primary: boolean().optional(),
    })
  ),

  source: string().max(100).optional(),
}));

export default ProspectPersonalValidation;