import isJSON from "./isJSON";

interface DefaultData {
  exp: number;
  iat: number;
}

export default function parseJwt<T extends Record<string, any>>(token: string): (T & DefaultData) | null {
  var base64Url = token.split('.')[1];
  var base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return isJSON(jsonPayload) ? JSON.parse(jsonPayload) : {};
};