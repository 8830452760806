interface Props {
  title: string;
  stars: 0 | 1 | 2 | 3 | 4 | 5,
}

const Rating: React.FC<Props> = (props) => {
  return (
    <div>
      <p className="m-0 typo-body text-muted text-uppercase pb-1">
        {props.title}
      </p>
      <div className="d-flex align-items-center">
        <i className={`bi bi-star-fill h5 px-1 ${props.stars >= 1 ? 'star-enabled' : 'star-disabled'}`}></i>
        <i className={`bi bi-star-fill h5 px-1 ${props.stars >= 2 ? 'star-enabled' : 'star-disabled'}`}></i>
        <i className={`bi bi-star-fill h5 px-1 ${props.stars >= 3 ? 'star-enabled' : 'star-disabled'}`}></i>
        <i className={`bi bi-star-fill h5 px-1 ${props.stars >= 4 ? 'star-enabled' : 'star-disabled'}`}></i>
        <i className={`bi bi-star-fill h5 px-1 ${props.stars >= 5 ? 'star-enabled' : 'star-disabled'}`}></i>
       
      </div>
    </div>
  );
};

export default Rating;
