import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownMenu, Input } from "reactstrap";
import { setCategorieActive, setCategoryFilter } from "src/store/stockpile/categorieSlice";
import { RootState } from "src/store/store";
import useSWR from "swr";


interface Props {
    setAdvanceCategory: any
    setWords: any
    setType: any
    className?: any
    close?:any
    AditionalFunction?:any
}

const AdvanceSearch: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const categories = useSelector((state: RootState) => state.categorieSlice.categories);
    const Items = useSelector((state: RootState) => state.stockpileSlice.ItemData);
    const Assets = useSelector((state: RootState) => state.stockpileSlice.AssetData);
    const categorieActive: any = useSelector((state: RootState) => state.categorieSlice.categorieActive);
    const categorySelected: any = useSelector((state: RootState) => state.categorieSlice.categorySelectedFilter);

    const [categoryValue, setCategoryValue] = useState<any>('')
    const [keywords, setKeywords] = useState<any>('')
    const [type, setType] = useState<any>('')

    const [additional , setAditional] = useState(false)


    const setAdvanceSearch = () => {
        props.setAdvanceCategory(categoryValue)
        props.setWords(keywords)
        props.setType(type)
        if (props.close) {
            props.close()
        }
    }
 

    const playSearch = () => {

        if (categorieActive == 888876373900000 && categorySelected == 'any') {
            setAdvanceSearch()
        }else{
            dispatch(setCategoryFilter('any'))
            dispatch(setCategorieActive(888876373900000))
            setAdvanceSearch()
        }



        
    }

    const EmptyFilters = () => {
        props.setAdvanceCategory('')
        props.setWords('')
        props.setType('')

        setCategoryValue('')
        setKeywords([])
        setType('')
    }

    const ResetCategory = () => {
        // dispatch(setCategoryFilter('any'))
        // dispatch(setCategorieActive(888876373900000))
    }

    useEffect(() => {
    }, [])

    return (
        <DropdownMenu className={` p-2 `+ props.className} style={{ width: 300 }} >
            <p className="text-primary typo-body">Advanced search</p>

            <select className="form-select text-primary typo-body-important"
                onChange={(e) => {
                    setCategoryValue(e.target.value)
                    ResetCategory()
                }}
                value={categoryValue}
            >
                <option value="data" selected>
                    Select Category
                </option>
                {categories.map((categorie: any, index: any) => {
                    return (
                        <option key={index} value={categorie?._id}>
                            {categorie?.name}
                        </option>
                    )
                })}
            </select>

            <select className="form-select text-primary typo-body-important  mt-2"
                value={type}
                onChange={(e) => {
                    setType(e.target.value)
                    ResetCategory()

                }}
            >
                <option value="" selected className=" ">
                    Select Item type
                </option>
                <option value={0}>Direct Owner</option>
                <option value={1}>External Owner</option>
            </select>

            <Input
                className="form-control searchWords text-primary mt-2 typo-body-import"
                placeholder="Includes the words"
                onChange={(e) => {
                    setKeywords(e.target.value)
                    ResetCategory()
                }}
                value={keywords}
            />

            <button onClick={() => playSearch()} className="btn btn-primary mt-3 text-white typo-body"
            >
                Search
            </button>
            <button onClick={() => EmptyFilters()} className="btn btn-outline-primary mt-3 ms-1  typo-body"
            >
                Clear filters
            </button>
        </DropdownMenu>
    );
};

export default AdvanceSearch;
