import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle } from "react";

import { RootState } from "src/store/store";
import { TabsContent } from "../../AddContact";
import OrganizationValidation from "./validation";
import { toBase64 } from "src/util/converToBase64";
import { OrganizationData } from "src/models/ContactModel";
import ButtonFiles from "src/components/UI/buttons/ButtonFile";
import { RODocumentMimeTypesArray, ROImageMimeTypesArray } from "src/util";
import { createContactForm } from "src/store/blackbook/createContactSlice";

const OrganizationDocuments = forwardRef((_props, ref: TabsContent['ref']) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);

  const formik = useFormik<OrganizationData>({
    initialValues: {
      ...(data.data || {}),
    },
    validationSchema: OrganizationValidation.pick(['documents']),
    onSubmit: () => {},
  });

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      try {
        const errors = await formik.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }

        return true;
      } catch {
        return false;
      }
    }
  }));

  useEffect(() => {
    if (data.initialValues !== undefined && data.appliedInitialValues === false) {
      formik.setValues(data.initialValues || {});
      /* @ts-ignore */
      dispatch(createContactForm.setField('appliedInitialValues', true, ''));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChange = (fieldName: string, value: any, addData = true, dispatchValue = true) => {
    formik.setFieldValue(fieldName, value);

    /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
    if (value instanceof File) {
      toBase64(value as File).then(base64 => {
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, base64 as string, 'data'));
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, value.name, 'files'));
      }).catch(err => {
        console.log(err);
      })

      return;
    }

    if (dispatchValue) {
      /* @ts-ignore */
      dispatch(createContactForm.setField(fieldName, value, addData ? 'data' : ''));
    }
  }

  return (
    <div className="container-fluid px-3 mt-3">

      <div className="row mt-4">
        <div className="col-6">
          <ButtonFiles
            label="Logo for document branding"
            accept={ROImageMimeTypesArray.join(',')}
            error={formik.errors.documents?.docs_logo}
            selectedName={data.files?.documents?.docs_logo}
            onChange={(val) => handleChange('documents.docs_logo', val.currentTarget.files?.item(0))}
          />
        </div>
        <div className="col-6">
          <ButtonFiles
            label="Contract"
            error={formik.errors.documents?.contract}
            accept={RODocumentMimeTypesArray.join(',')}
            selectedName={data.files?.documents?.contract}
            onChange={(val) => handleChange('documents.contract', val.currentTarget.files?.item(0))}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <ButtonFiles
            label="Trade license"
            accept={RODocumentMimeTypesArray.join(',')}
            error={formik.errors.documents?.trade_license}
            selectedName={data.files?.documents?.trade_license}
            onChange={(val) => handleChange('documents.trade_license', val.currentTarget.files?.item(0))}
          />
        </div>
        <div className="col-6">
          <ButtonFiles
            label="Insurance"
            error={formik.errors.documents?.insurance}
            accept={RODocumentMimeTypesArray.join(',')}
            selectedName={data.files?.documents?.insurance}
            onChange={(val) => handleChange('documents.insurance', val.currentTarget.files?.item(0))}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <ButtonFiles
            label="Style guide"
            error={formik.errors.documents?.style_guide}
            selectedName={data.files?.documents?.style_guide}
            onChange={(val) => handleChange('documents.style_guide', val.currentTarget.files?.item(0))}
          />
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  );
});

export default OrganizationDocuments;
