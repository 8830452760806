import FlatList from "flatlist-react";
import React, { createRef, RefObject, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import update from "immutability-helper";

import AddItemsSection from "./AddItemsSection";

interface Props {
  setView: any;
  ItemData: any;
  getItemSelect: any;
  setgetItemSelect: any;
  quoteForm: any;
  setQuoteForm: any;
  getHeader: any;
  getSubHeader: any;
  quantity: any;
  setQuantity: any;
  rate: number;
  setRate: any;
  setViewRates: any;
  rateQuoteVersion: any;
  getRates: any;
  setGetIdItem: any;
  sendItemSelect: any;
  setSendItemSelect: any;
}
type contactSectionType = {
  [key: string]: { items: any; ref: RefObject<HTMLDivElement> };
};
export const getSections = (ItemName: any) => {
  const ordered = ItemName.sort((a: any, b: any) => {
    return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
  });

  const data: contactSectionType = {};
  const abc: string[] = [
    "A",
    "B",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "Ñ",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  abc.forEach((letter) => {
    data[letter] = { items: [], ref: createRef() };
  });

  for (let item of ordered) {
    let letter = item.name[0].toUpperCase();

    if (data[letter] === undefined) {
      data[letter] = {
        items: [],
        ref: createRef<HTMLDivElement>(),
      };
    }

    data[letter].items.push(item);
  }

  return data;
};
const AddItems: React.FC<Props> = (props) => {
  const [addItems, setAddItems] = useState<any>(props.quoteForm.headers);

  const handleSelect = (item?: any) => {
    props.setGetIdItem(item._id);
    const ItemsSelect = {
      items: item === null ? [] : item,
      quantity: 0,
      rate: 0,
      id: uuidv4(),
      units_number: 0,
      units_type: "sale",
      level: "2",
      elements: null,
      assigned_to: props.getHeader.id,
    };

    const findItemsAdd = addItems.filter(
      (fil: any) => fil.items?._id !== item._id
    );

    const findItems = addItems.filter((fil: any) => {
      if (fil.items?._id === item._id) {
        setAddItems(findItemsAdd);
      } else {
        setAddItems([...addItems, ItemsSelect]);
      }
    });

    return findItems;
  };

  const sendItems = () => {
    props.setView(false);
    props.setViewRates(false);
    props.setQuoteForm({ ...props.quoteForm, headers: addItems });
  };

  useEffect(() => {
    if (props.sendItemSelect) {
      sendItems();
    }

    props.setSendItemSelect(false);
  }, [props.sendItemSelect]);

  const ItemsName = props.ItemData.map((e: any) => e);

  const [dataVersion, setDataVersion] = useState(ItemsName);

  const version =
    props.rateQuoteVersion !== undefined &&
    props.rateQuoteVersion.filter((e: any) => e.item !== null);

  let sections = getSections(dataVersion);
  const params = useParams<{ rateCardId: string }>();
  const history = useHistory();

  return (
    <div
      className="custom-scrollbar overflow-auto d-flex flex-column"
      style={{ height: "400px" }}
    >
      <>
        {ItemsName.length > 0 ? (
          <FlatList
            list={Object.keys(sections)}
            renderItem={(item: any, index: any) => {
              return (
                <AddItemsSection
                  ref={sections[item].ref}
                  key={index}
                  letter={item}
                  items={sections[item].items}
                  handleSelect={handleSelect}
                  active={!!params.rateCardId ? params.rateCardId : ""}
                  addItems={addItems}
                />
              );
            }}
          />
        ) : (
          []
        )}
      </>
    </div>
  );
};

export default AddItems;
