import React, { useState } from "react";
import { Modal } from "reactstrap";
import ClienBrief from "./ClientBrief";

interface Props {
  view: boolean;
  setView: any;
  toggle: boolean;
  brief: any;
}
const ModalBrief: React.FC<Props> = (props) => {
  const closeModal = () => {
    props.setView(false);
    // props.setPreView(false);
  };

  return (
    <Modal
      isOpen={props.view}
      toggle={() => props.toggle}
      centered
      className=""
      style={{ width: "950px", maxWidth: "950px" }}
    >
      <div className={""} style={{ height: "270px" }}>
        <div
          onClick={closeModal}
          className="cursor-pointer pt-2"
          style={{ width: "30px" }}
        >
          <i
            className="bi bi-x-lg  "
            style={{
              fontSize: "18px",
              marginRight: "300px",
              height: "150px",
              padding: "20px",
            }}
          ></i>
        </div>
        <div className="row">
          <ClienBrief brief={props.brief} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalBrief;
