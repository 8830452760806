import React, { useCallback, useEffect } from "react";
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";
import Clock from "../../components/clock";
import authService from "../../services/auth";
import { useRequest } from "../../hooks/useRequest";
import logoIcon from "../../assets/images/GAMEAE_logo_white.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useQuery from "../../hooks/useQuery";
import { getProjectId } from "../../store/factory/factorySlice";
import { getJobById } from "../../store/factory/factoryJobSlice";

interface Props {
  className?: String;
  toggleSideBar: () => void;
}

const routes: { [key: string]: string } = {
  "/blackbook": "BLACKBOOK",
  "/stockpile": "STOCKPILE",
  "/rates": "RATES",
  "/factory": "FACTORY",
};

const Topbar: React.FC<Props> = (props) => {
  const location = useLocation();
  const { mutate } = useRequest({ url: "users/me" });

  const dispatch = useDispatch();

  const query = useQuery();

  let match = useRouteMatch("/factory/:idproject/:idjob");

  const config = useSelector((state: RootState) => state.layoutSlice);

  const handleLogout = useCallback(() => {
    authService.Logout();
    /* Revalidate user login status */
    mutate(null, true);
  }, [mutate]);

  const ProjectData = useSelector((state: RootState) => state.factorySlice);

  const { projectDataId } = ProjectData;

  const { jobDataById } = useSelector(
    (state: RootState) => state.getJobsTypeSlice
  );

  const getDataId = () => {
    dispatch(getProjectId(match?.params.idproject));
    dispatch(getJobById(match?.params.idjob));
  };

  // useEffect(() => {
  //   getDataId();
  // }, []);

  return (
    <div
      // color="primary"
      className="topbar container-fluid bg-primary"
    >
      <div className="row align-items-center h-100">
        <div className="col col-sm-5 d-flex align-items-center">
          <i
            className="bi bi-list text-white ms-2 h3 m-0"
            onClick={props.toggleSideBar}
          ></i>

          <Link to="/">
            <i className="bi bi-chevron-left text-white h6 m-0 ms-4 "></i>
          </Link>

          {match?.params.idproject && match?.params.idjob ? (
            <nav aria-label="breadcrumb" className="mt-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    to="/factory"
                    className="text-white m-0 ms-3 text-decoration-none"
                  >
                    FACTORY
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={`/factory/project/detail/${match?.params.idproject}`}
                    className="text-white text-decoration-none"
                    href="#"
                  >
                    {projectDataId?.name?.toUpperCase()}
                  </Link>
                </li>
                <li className="breadcrumb-item " aria-current="page">
                  <Link
                    to="/"
                    className="text-white text-decoration-none"
                    href="#"
                  >
                    {jobDataById?.name?.toUpperCase()}
                  </Link>
                </li>
                {/* <li className="breadcrumb-item " aria-current="page">
                  <Link to="/" className="text-white " href="#">
                    QUOTE
                  </Link>
                </li> */}
              </ol>
            </nav>
          ) : (
            []
          )}

          {routes[location.pathname] === "FACTORY" ? (
            <p className="text-white m-0 ms-3">
              {(!!routes[location.pathname] && config.moduleTitle) ||
              !!config.moduleTitle
                ? config.moduleTitle
                : !routes[location.pathname] && !config.moduleTitle
                ? ""
                : routes[location.pathname]}
            </p>
          ) : (
            []
          )}

          {/* <p className="text-white m-0 ms-3">
            {(!!routes[location.pathname] && config.moduleTitle) ||
            !!config.moduleTitle
              ? config.moduleTitle
              : !routes[location.pathname] && !config.moduleTitle
              ? ""
              : routes[location.pathname]}
          </p> */}
        </div>

        <div className="col-2 d-flex justify-content-center d-none d-sm-block">
          <Link to="/">
            <img
              src={logoIcon}
              alt=""
              style={{ width: "133px", height: "31px" }}
            />
          </Link>
        </div>

        <div className="col  d-flex align-items-center justify-content-end">
          <p className="text-white m-0 ms-3 d-none d-lg-block">
            Translate Script
          </p>

          <div className="d-none d-md-block">
            <Clock className="mx-3" />
          </div>

          <i className="bi bi-search text-white h6 m-0 mx-3"></i>
          <i className="bi bi-bell-fill text-white h6 m-0 mx-3"></i>

          <div className="dropdown">
            <button
              className="btn btn-primary"
              type="button"
              id="avatar_dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="avatar mx-2 dropdown-toggle"
                src="https://www.edarabia.com/wp-content/uploads/2015/11/7-ways-to-become-the-person-everyone-respects.jpg"
                alt=""
              />
            </button>

            <ul className="dropdown-menu" aria-labelledby="avatar_dropdown">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleLogout}>
                  Logout
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
