import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { FormGroup, Label, Input, ListGroup, ListGroupItem } from "reactstrap";
import { RootState } from '../../store/store'

interface Props {
    view: any,
    setView: any
    toggle: any
    setValue?: any
    setUpdate?:any
    value:any
}

const ChangeCondition: React.FC<Props> = (props) => {

    return (
        <Modal className="ModalOptions" isOpen={props.view} toggle={props.toggle} centered>
            <div className="container ">
                <div className="row ">
                    <p className='poppinsText' style={{
                        fontSize: '22px',
                        fontWeight: 'lighter',
                        cursor: 'pointer',
                        fontFamily: 'revert',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginBottom: '-1%'
                    }}
                        onClick={() => props.setView(false)}
                    >
                        x
                    </p>
                    <div className="col-12" style={{
                        display: 'flex', alignSelf: 'center',
                        justifyContent: 'center'

                    }}>
                        <p className="ModalTitle"
                            style={{
                                fontSize: '24px', fontWeight: 'bold',
                                justifyContent: 'center', alignSelf: 'center',
                                display: 'flex', color: '#344469'
                            }}
                        >
                            Change condition
                        </p>
                    </div>
                    <div className="col-12 mt-3">
                        <ListGroup>
                            <ListGroupItem>
                                <FormGroup check>
                                    <Label className="textOptionsModal" check>
                                        <Input type="radio" name="radio1"
                                            value={'excellent'}
                                            onChange={(e) => {
                                                props.setValue(e.target.value)
                                            }}
                                            checked={
                                                props.value == 'excellent'? 
                                                true:false
                                            }
                                        /> Excellent
                                    </Label>
                                </FormGroup>
                            </ListGroupItem>
                            <ListGroupItem>
                                <FormGroup check >
                                    <Input type="radio" name="radio1" value={'good'}
                                        onChange={(e) => {
                                            props.setValue(e.target.value)
                                        }}
                                        checked={
                                            props.value == 'good'? 
                                            true:false
                                        }
                                    />
                                    <Label className="textOptionsModal" check>
                                        Good
                                    </Label>
                                </FormGroup>
                            </ListGroupItem>
                            <ListGroupItem>
                                <FormGroup check>
                                    <Label className="textOptionsModal" check>
                                        <Input type="radio" name="radio1" value={'fair'}
                                            onChange={(e) => {
                                                props.setValue(e.target.value)
                                            }}
                                            checked={
                                                props.value == 'fair'? 
                                                true:false
                                            }
                                        /> Fair
                                    </Label>
                                </FormGroup>
                            </ListGroupItem>
                            <ListGroupItem>
                                <FormGroup check>
                                    <Label className="textOptionsModal" check>
                                        <Input type="radio" name="radio1" value={'poor'}
                                            onChange={(e) => {
                                                props.setValue(e.target.value)
                                            }}
                                            checked={
                                                props.value == 'poor'? 
                                                true:false
                                            }
                                        /> Poor
                                    </Label>
                                </FormGroup>
                            </ListGroupItem>
                        </ListGroup>
                    </div>

                    <div className="col-12 mt-3" style={{ marginBottom: '5%' }}>
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-success  text-white col-auto"
                                onClick={props.setUpdate}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ChangeCondition