import { Input } from "reactstrap";

interface Props {
  title?: string;
  value: string;
  placeHolder: string;
  onChange: (value: string) => void;
  className?: string;
  rows?: number;
  errorMessage?: string;
  disabled?: boolean;
  style?:any
}

const TextArea: React.FC<Props> = (props) => {
  return (
    <div className={`pb-3 ${props.className}`}>
      {props.title && (
        <label className="m-0 typo-body text-muted text-uppercase pb-1">
          {props.title}
        </label>
      )}
      <Input
        className="form-control typo-body-primary-important searchWords"
        placeholder={props.placeHolder}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        rows={props.rows || 3}
        type="textarea"
        valid={props.errorMessage ? false : props.value ? true : false}
        invalid={props.errorMessage ? true : false}
        disabled={props.disabled}
        style={props.style}
      />
    </div>
  );
};

export default TextArea;
