import Rating from '../../../UI/Rating';

interface Props {}

const KPIRating: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <Rating title="assurance" stars={1} />
        </div>
        <div className="col-6">
          <Rating title="responsiveness" stars={5} />
        </div>
        <div className="col-6">
          <Rating title="empathy" stars={5} />
        </div>
        <div className="col-6">
          <Rating title="tangibles" stars={2} />
        </div>
        <div className="col-6">
          <Rating title="reliability" stars={5} />
        </div>
      </div>
    </div>
  );
};

export default KPIRating;
