import { Col, Row } from "reactstrap";


import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/effect-coverflow/effect-coverflow.scss';
import '../styles/swiper-extra.scss';

import Meeting from "src/components/DashboardLayout/Content/Meeting";
import Calendar from "src/components/DashboardLayout/Content/Calendar";
import SalesCard from "src/components/DashboardLayout/Content/SalesCard";
import Proposals from "src/components/DashboardLayout/Content/Proposals";
import TasksTracking from "src/components/DashboardLayout/Content/TasksTracking";
import EmployeeStatus from "src/components/DashboardLayout/Content/EmployeeStatus";
import ProjectsSwiper from "src/components/DashboardLayout/Content/ProjectsSwiper";
import ProjectsProgress from "src/components/DashboardLayout/Content/ProjectsProgress";
import QuotesVsConfirmed from "src/components/DashboardLayout/Content/QuotesVsConfirmed";

const Dashboard: React.FC = () => {
  return (
    <div className="container-fluid h-100 d-block pt-3 pb-2 overflow-y hide-scrollbar">
      <Row className="gx-3">

        {/* Left Cards */}
        <Col xs="12" lg="3">
          <div className="d-flex flex-column">
            <SalesCard />
            <QuotesVsConfirmed />
            <Proposals />
          </div>
        </Col>

        {/* Right Cards */}
        <Col xs="12" lg="9">
          <Row>
            <Col xs="12" lg="8">
              <ProjectsSwiper />
              <ProjectsProgress />
            </Col>

            <Col xs="12" lg="4">
              <TasksTracking />
            </Col>
          </Row>

          {/* Bottom cards */}
          <section className="mt-3 d-flex gap-3 flex-wrap">
            <Meeting />
            <Calendar />
            <EmployeeStatus />
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
