import { ThunkAction } from "redux-thunk";
import { configureStore, Action } from "@reduxjs/toolkit";

import auth from "./authSlice";
import layoutSlice from "./layout";
import toastsSlice from "./toasts";
import recoverSlice from "./recoverSlice";
import kitItems from "./stockpile/kitItems";
import packageItems from "./stockpile/packageItems";
import categorieSlice from "./stockpile/categorieSlice";
import stockpileSlice from "./stockpile/stockpileSlice";
import createFormSlice from "./stockpile/createFormSlice";
import blackbookFiltersSlice from "./blackbook/filtersSlice";
import selectAssetsSlice from "./stockpile/selectAssetsSlice";
import createServiceForm from "./stockpile/createServiceForm";
import createContactFormSlice from "./blackbook/createContactSlice";
import RecursosVisualeSlice from "./stockpile/RecursosVisualeSlice";
import CreateItemsCsvForm from "./stockpile/CreateItemsCsvForm";
import EditFormSlice from "./stockpile/EditFormSlice";
import EditAssetForm from "./stockpile/EditAssetForm";
import createRateCardSlice from "./rates/createRateCardSlice";
import factorySlice from "./factory/factorySlice";
import getContactAll from "./blackbook/getContactSlice";
import getJobsTypeSlice from "./factory/factoryJobSlice";
import CreateAssetCsvForm from "./stockpile/CreateAssetsCSVForm";
import factoryPublicSlice from "./factory/factoryPublicSlice";
import factorySetTableQuote from "./factory/factorySetTableQuote";

const store = configureStore({
  reducer: {
    auth,
    layoutSlice,
    recoverSlice,
    stockpileSlice,
    createFormSlice,
    selectAssetsSlice,
    blackbookFiltersSlice,
    createContactFormSlice,
    packageItems,
    categorieSlice,
    RecursosVisualeSlice,
    kitItems,
    createServiceForm,
    CreateItemsCsvForm,
    EditFormSlice,
    toastsSlice,
    EditAssetForm,
    createRateCardSlice,
    factorySlice,
    getContactAll,
    getJobsTypeSlice,
    CreateAssetCsvForm,
    factoryPublicSlice,
    factorySetTableQuote,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
