import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import EmptyCircle from "src/components/UI/EmptyCircle";
import { setEquipmentItem } from "src/store/stockpile/RecursosVisualeSlice";
import { RootState } from "src/store/store";
import {
  cleanAsset,
  getCommentsForAsset,
  setIdItem,
} from "../../../store/stockpile/selectAssetsSlice";
import DoubleClick from "../../Behaviors/DoubleClicks";

interface Props {
  index?: any;
  onSelect?: (id: string) => void;
  item: any;
  setID: any;
  showAssets: any;
}

const ContactItem: React.FC<Props> = (props) => {
  let count1 = 10;
  let count2 =
    props.item.category?._id == "67807598690173953" ||
    props.item.category?._id == "67807598690173950"
      ? true
      : false;

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ itemId: string }>();

  const ASSET_SELECTED = useSelector(
    (state: RootState) => state.selectAssetsSlice.data
  );

  const [click, setClick] = useState<number>(0);

  const handleAsset = () => {
    dispatch(cleanAsset());
    props.showAssets(true);
    dispatch(setEquipmentItem(true));
    history.push(`/stockpile/${props.item._id}`);
    dispatch(setIdItem(props.item._id));
  };

  return (
    // <DoubleClick onDoubleClick={} >
    <div
      className={`border-top p-3 py-2 contact-item ${
        props.item._id == params.itemId && "contact-item-selected"
      }`}
    >
      <div className="row align-items-center">
        {props.onSelect && (
          <div className="col-auto p-0 ps-2">
            <input className="form-check-input" type="checkbox" />
          </div>
        )}

        <div
          onClick={() => {
            if (props.item.category == "67807598690173953") {
              handleAsset();
            } else {
              dispatch(setEquipmentItem(false));
              dispatch(setIdItem(props.item._id));
              dispatch(cleanAsset());
              history.push(`/stockpile/${props.item._id}`);
            }
          }}
          className={`col d-flex p-0 text-truncate ${
            props.onSelect ? "ms-3" : "ms-2"
          }`}
        >
          {props.item.picture_url && (
            <img
              loading="lazy"
              src={process.env.REACT_APP_IMAGES_AWS + props.item.picture_url}
              alt=""
              className="avatar2"
            />
          )}
          {!props.item.picture_url && (
            <EmptyCircle title={props.item.name} small />
          )}
          <div className="ms-2">
            <p className="m-0 text-black typo-body contact-title text-truncate-title">
              {props.item.name}
            </p>
            <p className="m-0 text-black typo-body contact-subtitle">
              {props.item.name}
            </p>
          </div>
        </div>

        <div className="col-auto p-0 pe-1">
          {count1 ? (
            <p className="m-0 contact-trailing typo-body bg-primary my-1">
              {props.item.internal_assets ? props.item.internal_assets : 0}
            </p>
          ) : (
            <p className="m-0 contact-trailing typo-body bg-primary my-1">0</p>
          )}
          {count2 && (
            <p
              className="m-0 contact-trailing typo-body my-1"
              style={{ background: "#C6D763" }}
            >
              {props.item.external_assets ? props.item.external_assets : 0}
            </p>
          )}
        </div>
      </div>
    </div>
    //  </DoubleClick>
  );
};

export default ContactItem;
