import ComboBox from '../../../../UI/forms/ComboBox';
import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import InputSelect from '../../../../UI/forms/InputSelect';
import AssetScheme from './validation';
import { useFormik, Form, FormikProvider } from 'formik';
import { AssetModel, warehouse, purchase, External, PickupInfo } from '../../../../../models/ItemModel';
import IconButton from '../../../../UI/buttons/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { CreateAsset } from '../../../../../store/stockpile/stockpileSlice';
import { setAsset, updateValuesAssets, setFormNameFile, setAssetExternal, setFormDate, setFormsCount, setFormsExternalCount } from '../../../../../store/stockpile/createFormSlice';
import FormAsset from './Form';


import AddMore from '../../../../UI/AddMore';
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import FormAssetExternal from './FormExternal';
import { FormGroup, Input, InputGroup, Label  } from 'reactstrap';
import { useRequest } from 'src/hooks/useRequest';
import { useParams } from 'react-router-dom';

interface Props {
  images: any,
  objectPick?: any,
  setObjectPick?: any,
  dateCount?: any,
  setDateCount?: any
  scrollAction?:any,
  currentTab?:any,
  setCurrentTab?:any
}

const AssetData = (props: Props) => {
  const params = useParams<{ itemId: string; }>();
  const dispatch = useDispatch()
  const data = useSelector((state: RootState) => state.createFormSlice.AssetsData);
  const formsCountDirect = useSelector((state: RootState) => state.createFormSlice.formsCount);
  const formsCountExternal = useSelector((state: RootState) => state.createFormSlice.formsExternalCount);

  const ArrayArrays = useSelector((state: RootState) => state.createFormSlice.DatesData);

  const DirectAssets = useSelector((state: RootState) => state.createFormSlice.AssetsData);

  const images: Array<string> = props.images
  const currentTab = props.currentTab
  const setCurrentTab = props.setCurrentTab

  const formsCount = currentTab == 0 ? formsCountDirect : formsCountExternal

  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId });

  const [submitState, setSubmit] = useState<any>(() => { });
  const dateCount = props.dateCount
  const setDateCount = props.setDateCount

  const setImages = (element: any) => {
    images.length == DirectAssets.length ? images[images.length - 1] = element : images.push(element);

  }

  const setItem = () => {
    dispatch(CreateAsset([
      data
      , images]))
  }

  const base = {
    serial: '',
    is_external: 'false',
    has_digital_receipt: false 
  }

  const baseExternal = {
    serial: '',
    purchase: {
      date: '',
      origin: '',
      price: ''
    },
    warehouse: {
      warehouse: '',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '',
    is_external: true,
    condition: '',
    external: {
      prices: {
        hour: '',
        daily: '',
        week: '',
        sale: '',
        holiday_hour: '',
        holiday_day: '',
        overtime_hour: '',
        holiday_overtime_hour: ''
      },
      company_owner: '',
      pickup_location: '',
    } as External,
    sizes: {
      name: 'dw',
      width: '',
      height: '',
      weight: '',
      length: '',
      weight_unit: '',
      sizes_unit: ''
    }
  } as AssetModel

  const AddOtherForm = () => {
    dispatch(setAsset(base))
  }

  const AddOtherFormExternal = () => {
    dispatch(setAssetExternal(baseExternal))
  }

  const AddForm = () => {
    if (currentTab == 0) {
      AddOtherForm()
      dispatch(setFormsCount([]))
      dispatch(setFormDate([]))
      dispatch(setFormNameFile([]))

    }
    if (currentTab == 1) {
      AddOtherFormExternal()
      dispatch(setFormsExternalCount([]))
    }
  }


  const tabsContent: (index: number) => JSX.Element[] = index => [
    <FormAsset
      setImages={setImages}
      dateCount={dateCount}
      setDateCount={setDateCount}
      index={index}
    />,
    <FormAssetExternal
      index={index}
      setImages={setImages}
    />,
  ];

  return (
    <div className="container-fluid px-1 mt-3 m-0 ">
      <div className='row mb-4'>
      <div className="col-sm-12 col-md-4">
      <FormGroup>
          <Label for="exampleCustomSelectDisabled">Custom Select Disabled</Label>
          <Input type="text" value={ITEM_DATA?.name} id="exampleCustomSelectDisabled" name="customSelect" readOnly
          style={{color: 'gray'}}
          />
        </FormGroup>
      </div>
      <div className="col-sm-12 col-md-4">
      <FormGroup>
          <Label for="exampleCustomSelectDisabled">Custom Select Disabled</Label>
          <Input type="text" value={ITEM_DATA?.brand} id="exampleCustomSelectDisabled" name="customSelect" readOnly
                    style={{color: 'gray'}}
                    />
        </FormGroup>
      </div>
      <div className="col-sm-12 col-md-4">
      <FormGroup>
          <Label for="exampleCustomSelectDisabled">Custom Select Disabled</Label>
          <Input type="text" value={ITEM_DATA?.model} id="exampleCustomSelectDisabled" name="customSelect" readOnly 
                    style={{color: 'gray'}}
                    />
        </FormGroup>      </div>
      </div>
      <div className='pe-5' style={{ marginBottom: 10, marginLeft: '-.6%' }} >
        <button onClick={setCurrentTab.bind(null, 0)} className="btn btn-primary col-auto mx-2 btn-fitcontent">
          Direct Owner
        </button>
        <button onClick={setCurrentTab.bind(null, 1)} className="btn btn-default col-auto mx-2 btn-fitcontent">
          External Owner
        </button>
      </div>

      {
        formsCount.map((item, index) => {
          return (
            <>
              {tabsContent(index)[currentTab]}
              <hr />
            </>
          )
        })
      }

    </div>
  );
};

export default AssetData;
