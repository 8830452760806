import FlatList from 'flatlist-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal, Table } from 'reactstrap';
import { setTimeout } from 'timers';
import { AddAsset, AddAssetEmpty, deleteAssetPackage, SelectAssets } from '../../../store/stockpile/packageItems';
import { getAssetForItem } from '../../../store/stockpile/stockpileSlice';
import { RootState } from '../../../store/store'
import AssetSingleItem from './AssetSingleItem';


interface Props {
    view: any
    setView: any
    IDITEM?: any
    maxValue?: any,
    index?: any,
    setMaxValue?: any
}




const SpecificAsset: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const AssetsData = useSelector((state: RootState) => state.stockpileSlice.AssetData);

    const [search, setSearch] = useState<string>("")


    const [assets, setAssets] = useState<any>(AssetsData ? AssetsData : [])
    const [selectedState, setselectedState] = useState<any>(0)

    useEffect(() => {
        props.IDITEM ? dispatch(getAssetForItem(props.IDITEM)) : console.log('');
    }, [props.IDITEM])


    const handleSearch = (e: any) => {
        setSearch(e.target.value)
    }


    const bySearch = (asset: any, search: any) => {
        if (search) {
            return asset.serial.toLowerCase().includes(search.toLowerCase());
        } else return asset;
    };

    const filteredList = (assets: any, search: any) => {
        return AssetsData
            .filter((asset: any) => bySearch(asset, search))
    };

    return (
        <Modal isOpen={props.view} toggle={() => props.setView(false)} centered
            style={{ maxWidth: '598px', maxHeight: '408px' }}>

            <div className="container text-center">
                <div className='row'>
                    <p className='poppinsText' style={{
                        fontSize: '22px',
                        fontWeight: 'lighter',
                        cursor: 'pointer',
                        fontFamily: 'revert',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginBottom: '-1%'
                    }}
                        onClick={() => props.setView(false)}
                    >
                        x
                    </p>
                    <div className="col-12" style={{
                        display: 'flex', alignSelf: 'center',
                        justifyContent: 'center'

                    }}>
                        <p className="ModalTitle"
                            style={{
                                fontSize: '24px', fontWeight: 'bold',
                                justifyContent: 'center', alignSelf: 'center',
                                display: 'flex', color: '#344469'
                            }}
                        >
                            Select Assets
                        </p>
                    </div>
                    <div>
                        <div className="input-icon mt-2 col-9 w-100" style={{ marginBottom: '5px' }}>
                            <i className="bi bi-search text-primary"></i>
                            <Input
                                className="form-control py-2 typo-body text-primary"
                                type="search"
                                id="example-search-input"
                                placeholder="Search"
                                value={search}
                                onChange={handleSearch}
                                style={{ minWidth: '100%', height: '37px' }}
                            />
                        </div>
                    </div>
                    <div style={{ minWidth: '105%', marginLeft: '-2%' }}>
                        <Table className='table table-striped table-borderless table-hover w-100'>
                            <thead>
                                <tr>
                                    <th scope="col" className="sortable HeadTableFonts" >
                                    </th>
                                    <th scope="col" className="sortable HeadTableFonts" >
                                        Asset number
                                    </th>
                                    <th className="HeadTableFonts" scope="col">
                                        USAGE
                                    </th>
                                    <th className="HeadTableFonts" scope="col">
                                        Age
                                    </th>
                                    <th className="HeadTableFonts" scope="col">
                                        serial number
                                    </th>
                                    <th className="HeadTableFonts" scope="col">
                                        condition
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <FlatList
                                    list={filteredList(assets, search)}
                                    renderItem={(item: any, index: any) => {

                                        return (
                                            <AssetSingleItem
                                                item={item}
                                                key={index}
                                                selecteState={selectedState == index ? true : false}
                                                onClick={() => { setselectedState(index) }}
                                                maxValue={props.maxValue}
                                                indexMaxValue={props.index}
                                                setMaxValue={props.setMaxValue}
                                                view={props.view}
                                            />

                                        );
                                    }}
                                    renderWhenEmpty={() => {
                                        return (
                                            <>
                                                <p style={{ color: 'lightgray', marginLeft: 8 }} >No results</p>
                                            </>
                                        )
                                    }}
                                />
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-12 " >
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-success  text-white col-auto poppinsText"
                                onClick={() => {
                                    props.setView(false)
                                   dispatch(SelectAssets(props.index))
                                }}
                                style={{
                                    width: '184px', height: '37px',
                                    fontSize: '12px', textTransform: 'uppercase',
                                    marginBottom: '1%'
                                }}
                            >
                                Select Specific Asset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SpecificAsset;
