import { useState, useRef, useEffect } from 'react';
import ConfirmModal from '../../Modals/ConfirmModal';
import Checkbox from '../../UI/forms/Checkbox';
import ProgressbarTitle from '../../UI/ProgressbarTitle';
import Accessories from './tabs/Accesories/Accessories';
import AssetData from './tabs/AssetData/AssetData';
//import Description from './tabs/Description';
import GeneralData from './tabs/GeneralData/GeneralData';
import Rates from './tabs/Rates/Rates';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import checkFields from '../../../util/CheckFields'
import { CreateItem, CreateAsset, CreateAccesories, CreateRates, RefreshForNewItem } from '../../../store/stockpile/stockpileSlice';
import { AccesoriesModel, AssetModel, External, PickupInfo, RatesModel } from '../../../models/ItemModel';
import StepButton from 'src/components/UI/buttons/stepButton';
import { setShowAddItem, setCheckItemFormAlert } from 'src/store/stockpile/RecursosVisualeSlice';
import { setAsset, EmptyForm, setFormNameFile, setAssetExternal, setFormDate, setFormsCount, setFormsExternalCount } from 'src/store/stockpile/createFormSlice';

import { useToast } from 'src/hooks/useToast';
import moment from 'moment';
import { setCategoryFilter } from 'src/store/stockpile/categorieSlice';
import { setTimeout } from 'timers';
import { Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


interface Props {
  onFinish: () => void;
  TypeForm: any;
}

const tabs: string[] = [
  'General Data',
  'Asset data',
  'Rates',
  'Accessories',
  // 'Description',
];


const AddStock: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [images, setImagesForm] = useState<Array<any>>([]);
  const [resetAllInputs, setResetAllInputs] = useState<any>(false);

  const [checkItemForm, setCheckItemForm] = useState<any>(false);

  const checkItemFormAlert = useSelector((state: RootState) => state.RecursosVisualeSlice.checkItemFormGeneralDataAlert);



  const [dateCount, setDateCount] = useState<
    { type: string; date: string }[]
  >([{ type: 'date of purchase', date: '' }]);

  const [objectPick, setObjPick] = useState<PickupInfo>({
    country: '',
    state: '',
    city: '',
    zip: '',
    adress: ''
  })

  ///Loading
  const Loading = useSelector((state: RootState) => state.stockpileSlice.loading);


  /////CREATE ITEM CHECK
  const ItemID = useSelector((state: RootState) => state.stockpileSlice.ItemResponse);

  /////Errors 
  const Gdta = useSelector((state: RootState) => state.createFormSlice.GeneralErrors);
  const Asst = useSelector((state: RootState) => state.createFormSlice.AssetsErrors);
  const Rtes = useSelector((state: RootState) => state.createFormSlice.RatesErrors);

  /////FORMS
  const data = useSelector((state: RootState) => state.createFormSlice.data);
  const sizesG = useSelector((state: RootState) => state.createFormSlice.sizesG);
  const rates: any = useSelector((state: RootState) => state.createFormSlice.RatesData);
  const mandatory = useSelector((state: RootState) => state.createFormSlice.AccesoriesMandatoryData)
  const optional = useSelector((state: RootState) => state.createFormSlice.AccesoriesOptionalData)


  //const accesories = useSelector((state: RootState) => state.createFormSlice.AccesoriesData);
  const DirectAssets = useSelector((state: RootState) => state.createFormSlice.AssetsData);
  const ExternalAssets = useSelector((state: RootState) => state.createFormSlice.AssetsDataExternal);
  const [currentTabType, setCurrentTabType] = useState<number>(0);

  const progress = {
    ...data, ...rates, ...DirectAssets, ...ExternalAssets
  }

  useEffect(() => {
    dispatch(EmptyForm([]))
    setResetAllInputs(true)
  }, [])

  useEffect(() => {
    [data].map((item) => {
      if (item.category) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }

      if (item.name) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }
    })

  }, [checkItemForm, data])

  const red: any = useRef<HTMLAreaElement>()

  const scrollAction = () => {
    setTimeout(() => {
      red?.current?.scrollTo({
        behavior: 'smooth',
        top: 25000
      })
    }, 500)
  }

  // const percenCompletion = (1 - checkFields(progress).percent)
  const percenCompletion = 0.05

  const tabsContent: JSX.Element[] = [
    <GeneralData
      resetAll={resetAllInputs}
      alertComplete={checkItemFormAlert}
    />,
    <AssetData
      images={images}
      objectPick={objectPick}
      setObjectPick={setObjPick}
      dateCount={dateCount}
      setDateCount={setDateCount}
      scrollAction={scrollAction}
      currentTab={currentTabType}
      setCurrentTab={setCurrentTabType}
    />,
    <Rates />,
    <Accessories />
  ];

  const onCancelModalClose = (value?: any) => {
    setIsCancelling(false);

    if (value == '') {
      props.onFinish()
      return;
    }


  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    setCurrentTab((prev) => {
      if (prev < tabs.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  const deleteEmptyProps = (obj: any) => {
    const end = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != ""));
    return end
  }


  const setItem = async () => {
    if (!Gdta && !Asst && !Rtes) {
      const ItemCreated: any = { ...data, sizes: sizesG }
      delete ItemCreated.sub_category;
      const res: any = await dispatch(CreateItem(ItemCreated))

      if (res.payload._id) {
        dispatch(RefreshForNewItem(res.payload))
        dispatch(setCategoryFilter('any'))


        ////Create Assets
        let TotalAssets = [...DirectAssets, ...ExternalAssets]

        if (TotalAssets.length > 0) {
          let AssetsWithItem = TotalAssets.map((asset: any) => {
            const entries = Object.entries(asset).length
            asset = {
              ...asset, purchase: {
                ...asset.purchase,
                price: parseInt(asset?.purchase?.price),
                date: moment(asset.purchase?.date).format()
              },
              end_of_life: asset?.end_of_life ? moment(asset?.end_of_life).format() : null,
              warranty_expiration: asset?.warranty_expiration ? moment(asset?.warranty_expiration).format() : null,
              item: res.payload._id,
              external: {
                prices: {
                  hour: asset.external?.prices?.hour || null,
                  daily: asset.external?.prices?.daily || null,
                  week: asset.external?.prices?.week || null,
                  sale: asset.external?.prices?.sale || null,
                  holiday_hour: asset.external?.prices?.holiday_hour || null,
                  holiday_day: asset.external?.prices?.holiday_day || null,
                  overtime_hour: asset.external?.prices?.overtime_hour || null,
                  holiday_overtime_hour: asset.external?.prices?.holiday_overtime_hour || null
                },
                company_owner: asset.external?.company_owner || null,
                pickup_location: asset.external?.pickup_location || null,
              }
            }
            if (entries && asset.serial) { return asset }
          });
          const results = AssetsWithItem.filter((element: any) => {
            return element != null;
          });
          const resAssets = await dispatch(CreateAsset([
            results, images
          ]))
        }

        ///Create Accesories
        if (mandatory.length > 0 || optional.length > 0) {

          var filteredMandatory = mandatory.filter((el: any) => el != '');

          var filteredOptional = optional.filter((el: any) => el != '');

          const Accesories = {} as any

          if (filteredMandatory.length > 0) { Accesories.mandatory = [...filteredMandatory] } else { Accesories.mandatory = [] }
          if (filteredOptional.length > 0) { Accesories.optional = [...filteredOptional] } else { Accesories.optional = [] }

          const AllDataAccesories = {
            data: Accesories,
            item: res.payload._id
          }

          const resAccesories = await dispatch(CreateAccesories(AllDataAccesories))


        }

        let checkRatesField: Array<RatesModel> = [rates]

        checkRatesField.map(async (item) => {
          if (item.daily || item.holiday_day || item.holiday_hour || item.holiday_overtime_hour ||
            item.hour || item.overtime_hour || item.sale || item.week) {

            ////Create Rates
            const AllDataRates = {
              data: rates,
              item: res.payload._id
            }
            const resRates = await dispatch(CreateRates(AllDataRates))

          }
        })

        toast.success('Save item succesfull')
        props.onFinish()
        dispatch(EmptyForm([]))

      }

    } else {
      if (Gdta) {
        let cpy = [Gdta]
        cpy.map((error) => {
          toast.error('error ' + Object.keys(error)[0] + ' in General Data')
        })
      }
      if (Rtes) {
        let cpy = [Rtes]
        cpy.map((error) => {
          toast.error('you have errors in Rates Data')
        })
      }
      if (Asst) {
        let cpy = [Asst]
        cpy.map((error) => {
          toast.error('error ' + Object.keys(error)[0] + ' in Asset Data')
        })
      }
    }
  }
  const base = {
    serial: '',
    is_external: 'false'
  }

  const baseExternal = {
    serial: '',
    purchase: {
      date: '',
      origin: '',
      price: ''
    },
    warehouse: {
      warehouse: '',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '',
    is_external: true,
    condition: '',
    external: {
      prices: {
        hour: '',
        daily: '',
        week: '',
        sale: '',
        holiday_hour: '',
        holiday_day: '',
        overtime_hour: '',
        holiday_overtime_hour: ''
      },
      company_owner: '',
      pickup_location: '',
    } as External,
    sizes: {
      name: 'dw',
      width: '',
      height: '',
      weight: '',
      length: '',
      weight_unit: '',
      sizes_unit: ''
    }
  } as AssetModel

  const AddOtherForm = () => {
    let dat = dispatch(setAsset(base))

  }

  const AddOtherFormExternal = () => {
    let dat = dispatch(setAssetExternal(baseExternal))

  }

  const AddForm = () => {

    if (currentTabType == 0) {
      AddOtherForm()
      dispatch(setFormsCount([]))
      dispatch(setFormDate([]))
      dispatch(setFormNameFile([]))

    }
    if (currentTabType == 1) {
      AddOtherFormExternal()
      dispatch(setFormsExternalCount([]))
    }

    scrollAction()


  }
  const styleFab: any = {
    marginTop: '-10%',
    right: '4%',
    bottom: "4%",
    marginLeft: '100%',
    position: 'absolute',
    background: '#6b8ab8'
  };

  const [tooltipOpen, setTooltip] = useState(false)

  const toggle = () => {
    setTooltip(!tooltipOpen)
  }

  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new item will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD ITEM"
          onClose={onCancelModalClose}
        />
      )}

      <div className="container-fluid h-100 "  >
        <div className="row" style={{ height: '92vh' }}>
          {currentTab == 1 && <Tooltip title="Add Asset" placement="top-start">
            <Fab color="primary" aria-label="add" style={styleFab} onClick={
              () => {
                AddForm()
              }
            } onMouseDown={toggle}>
              <AddIcon />
            </Fab>
          </Tooltip>}

          <div className="col-12 card h-100" ref={red} style={{overflowY: 'auto'}}>

            {/* Top actions */}
            <div className="row justify-content-between align-items-center p-2
            col-lg-12 col-md-auto col-sm-12 col-12 align-self-center
            
            "
              style={{ position: 'absolute', background: 'white', zIndex: 1 }}>

              <div className='p-0 m-0 border-0 col-auto row'>

                <button
                  onClick={setIsCancelling.bind(null, true)}
                  className="btn btn-outline-danger typo-body col-auto border-0">
                  Cancel
                </button>
                <div className='p-0 m-0 border-0 col-auto ms-1'>
                  {currentTab > 0 ? (
                    <StepButton onClick={prevStep} previous />
                  ) : (<div style={{ width: '110px' }}> </div>)}
                </div>
              </div>

              <h4
                className='text-primary col-auto' >Create Item</h4>

              <div className='p-0 m-0 border-0 col-auto row justify-content-between'>

                {currentTab < tabs.length - 1 && (
                  <div className='p-0 m-0 border-0 col-auto me-1'>
                    <StepButton onClick={!Gdta ? nextStep : () => {
                      toast.error('you have errors, solve them to continue')
                    }} next />
                  </div>
                )}

                <button onClick={setItem} className={`btn btn-outline-success typo-body col-auto border-0
                ${!Gdta ? !checkItemForm ? 'disabled' : '' : 'disabled'}
                `}>
                  Save item
                </button>

              </div>

            </div>

            <div className='custom-scrollbar overflow-y h-100 col-12 mt-4'>
              {/* Tab View */}

              <div className={`container ${Loading && 'disabledElement'} mt-5`}  >
                <div className="row mt-4">
                  <div className="col">
                    <ul className="nav nav-tabs mt-3 justify-content-between">
                      <div className="row">
                        {tabs.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="nav-item col-auto p-0"
                              onClick={!Gdta ? !checkItemForm ? () => { dispatch(setCheckItemFormAlert(true)) } : setCurrentTab.bind(null, index) : () => { }}
                            >
                              <p
                                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : !Gdta ? !checkItemForm ? 'disabled' : '' : 'disabled'
                                  }`}
                                aria-current="page"
                              >
                                {item}
                              </p>
                            </li>
                          );
                        })}
                      </div>
                    </ul>

                    <div className="row justify-content-end mt-2">
                      <div className="col-auto">
                        <ProgressbarTitle value={percenCompletion} />
                        <div style={{ marginLeft: '14%' }}>
                          <Checkbox
                            title="Mark as completed"
                            value={false}
                            onChange={(value) => { }}
                          />
                        </div>
                      </div>
                    </div>

                    {tabsContent[currentTab]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStock;
