
interface Props {
  value: number;
  className?: string,
  file?: boolean,
  width?: any
}

const ProgressbarTitle: React.FC<Props> = (props) => {
  const percent:number = props.value * 100
  return (
    <div className={`d-flex flex-column align-items-center ${props.className}`}>
      <p className={`text-primary typo-body text-muted ${props.file && 'd-none'}`} style={{marginLeft: '-45%', marginBottom:'0px'}}>
        {parseFloat((percent).toFixed(1))}% COMPLETED
      </p>
      {/* <progress value={props.value}></progress> */}
      <div className="progress" style={{width: props.width?props.width:'168px', height: '8px'}}>
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={props.value}
          aria-valuemin={0}
          aria-valuemax={1}
          style={{width: `${props.value * 100}%`}}
        ></div>
      </div>
    </div>
  );
};

export default ProgressbarTitle;
