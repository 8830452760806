import BarChart from '../../../UI/charts/BarChart';
import DoughnutChart from '../../../UI/charts/DoughnutChart';
import LineChart from '../../../UI/charts/LineChart';
import InfoDisplay from '../../../UI/InfoDisplay';

interface Props {}

const FinancialData: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom py-3">
        <InfoDisplay
          title="CLIENT STATUS NOTE"
          texts={[
            {
              text: 'He always pays on time but frequently asks for discounts.',
            },
          ]}
        />
      </div>

      <div className="row p-0 py-3 border-bottom">
        <div className="col-6">
          <BarChart
            title="account receivables"
            labels={['250', '315', '175']}
            data={[250, 315, 175]}
          />
        </div>
        <div className="col-6">
          <BarChart
            title="account payables"
            labels={['250', '315', '175']}
            data={[250, 315, 175]}
          />
        </div>
        <div className="col-6">
          <LineChart
            title="total turnover"
            labels={['250', '315', '175']}
            data={[250, 315, 175]}
          />
        </div>
        <div className="col-6">
          <DoughnutChart title="profit margin" value={0.8}  color="#c6d763"/>
        </div>
        <div className="col-6"><DoughnutChart title="confirmation ratio" value={0.5}  color="#f2cb48"/></div>
        <div className="col-6"><DoughnutChart title="total income" value={0.3321}  color="#906c91"/></div>
      </div>

      <div className="row py-3 border-bottom">
        <div className="col-6">
          <InfoDisplay 
            title="client rate cards"
            texts={[
              {text: 'Aaron Alps', isUnderline: true},
              {text: 'Alps Electric RC for Aaron', isUnderline: true},
              {text: 'Alps Finn Aaron', isUnderline: true},
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialData;
