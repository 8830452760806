interface Props {
  icon?: string;
  image?: string;
  outline?: boolean;
  color?: string;
  tooltip?: string;

  onClick?: any;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement> &
    Record<string, any>;
  style?: {};
  colorIcon?: any;
  custom?: any;
  customStyles?: object;
  imageClass?: string;
}

const IconButton: React.FC<Props> = (props) => {
  return (
    <button
      className={`btn btn-icon p-1 pb-4 btn${props.outline ? "-outline" : ""}-${
        props.color
      } mx-1 ${props.outline ? "" : "text-white"} ${
        props.image ? "btn-icon" : ""
      }`}
      data-toggle="tooltip"
      title={props.tooltip}
      onClick={props.onClick}
      {...props.buttonProps}
      style={{
        height: "29px",
        width: "36px",
        ...(props.buttonProps?.style || {}),
      }}
    >
      {props.icon && (
        <i
          style={
            props.colorIcon ? { color: props.colorIcon, fontSize: "15px" } : {}
          }
          className={`${props.icon} iconIconButton`}
        ></i>
      )}
      {props.custom && (
        <img src={props.custom} alt="" style={{ ...props.customStyles }} />
      )}
      {props.image && (
        <img
          src={props.image}
          className={props.imageClass}
          alt=""
          style={{ ...props.customStyles }}
        />
      )}
    </button>
  );
};

export default IconButton;
