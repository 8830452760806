import ItemModel, {
  AssetModel,
  RatesModel,
  PackageModel,
  sizesModel,
} from "../../models/ItemModel";
import LocalStorageService from "../localStorage";
import { decoderBase64All } from "../../util/converToBase64";
import axios from "axios";
import { R } from "src/models";

const customHeader = () => ({
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
});

const CreateItem = async (Item: ItemModel) => {
  const sizesDefragment: any = {
    name: Item.sizes?.name,
    width: Item.sizes?.width,
    height: Item.sizes?.height,
    weight: Item.sizes?.weight,
    length: Item.sizes?.length,
    sizes_unit: Item.sizes?.sizes_unit,
    weight_unit: Item.sizes?.weight_unit,
  };

  const sizesString = JSON.stringify(sizesDefragment);

  const DecodePhotos: any = Item.photos
    ? await decoderBase64All(Item.photos)
    : [];

  const DecodePicture: any =
    Item.picture_url && (await decoderBase64All([Item.picture_url]));

  const formData: any = new FormData();
  formData.append("name", Item.name);
  formData.append("brand", Item.brand);
  formData.append("description", Item.description);
  formData.append("category", Item.category);
  formData.append("sub_category", Item.sub_category);
  Item.type
    ? formData.append("type", Item.type)
    : formData.append("type", "4824715373839368");
  formData.append("model", Item.model);
  formData.append("consumption", Item.consumption);
  formData.append("consumption_unit", "watts");
  Item.web_links
    ? Item.web_links?.map((link: any) => {
      formData.append("web_links[]", link);
    })
    : formData.append("web_links[]", ["https://stackoverflow.com/"]);
  Item.dryhire && formData.append("dryhire", Item.dryhire?.toString());
  Item.discountable &&
    formData.append("discountable", Item.discountable?.toString());
  Item.manual_url && formData.append("manual_url", Item.manual_url);
  formData.append("sizes", sizesString);
  formData.append("picture", DecodePicture[0]);

  for (let i = 0; i < DecodePhotos?.length; i++) {
    formData.append("photos", DecodePhotos[i]);
  }

  const response = await axios
    .post<R<ItemModel>>("stockpile/items", formData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const CreateAsset = async (Assets: Array<AssetModel>, ImagesArray: any) => {
  const formData = new FormData();

  formData.append("assets", JSON.stringify(Assets));

  console.log("Servicio Asset");
  console.log(Assets);

  for (let i = 0; i < ImagesArray.length; i++) {
    formData.append("digital_receipt", ImagesArray[i]);
  }

  const response = await axios
    .post<R<AssetModel>>("stockpile/assets/create-many", formData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

interface Accesories {
  mandatory: Array<string>;
  optional: Array<string>;
}

const CreateAccesories = async (Accesories: Accesories, Item: any) => {
  const formData = new FormData();

  const response = await axios
    .put("stockpile/items/" + Item + "/add-accessories", Accesories)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const CreateRates = async (Rates: RatesModel, idItem: any) => {
  const data = {
    item: idItem,
    rates: Rates,
  };

  const response = await axios
    .post("rate-card-versions/rate-card-items", data)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const UpdateItem = async (Item: any) => {
  const sizesDefragment: any = {
    name: Item.sizes?.name,
    width: Item.sizes?.width,
    height: Item.sizes?.height,
    weight: Item.sizes?.weight,
    length: Item.sizes?.length,
    sizes_unit: Item.sizes?.sizes_unit,
    weight_unit: Item.sizes?.weight_unit,
  };

  const sizesString = JSON.stringify(sizesDefragment);

  const DecodePhotos: any = [];

  const DecodePicture: any = Item.picture_url
    ? await decoderBase64All([Item.picture_url])
    : false;

  console.log("DECODEPIC");

  console.log(DecodePicture);
  console.log(Item.picture_url);

  const formData: any = new FormData();
  formData.append("name", Item.name || "");
  formData.append("brand", Item.brand || "");
  formData.append("description", Item.description || "");
  formData.append("category", Item.category || "");
  formData.append("sub_category", Item.sub_category || "");
  Item.type
    ? formData.append("type", Item.type)
    : formData.append("type", "4824715373839368");
  formData.append("model", Item.model || "");
  formData.append("consumption", Item.consumption || "");
  formData.append("consumption_unit", "watts");
  Item.web_links
    ? Item.web_links?.map((link: any) => {
      formData.append("web_links[]", link);
    })
    : formData.append("web_links[]", ["https://stackoverflow.com/"]);
  Item.dryhire && formData.append("dryhire", Item.dryhire?.toString() || "");
  Item.discountable &&
    formData.append("discountable", Item.discountable?.toString() || "");
  Item.manual_url && formData.append("manual_url", Item.manual_url || "");
  formData.append("sizes", sizesString);
  formData.append("picture", DecodePicture[0] ? DecodePicture[0] : "");

  const response = await axios
    .put<R<ItemModel>>("stockpile/items/" + Item._id, formData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetAllRateCards = async () => {
  const response = await axios.get("rate-cards/all").catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });

  return response?.data.data;
};

const GetClientRateCards = async () => {
  const response = await axios
    .get(
      "rate-cards/all?assigned_to=4824715373839356&to_date=2022-03-26T23:59&from_date=2022-03-26T00:0"
    )
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetAllItems = async () => {
  const response = await axios
    .get("stockpile/items/all")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetAllAssets = async () => {
  const response = await axios
    .get("stockpile/assets/all?")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetAssetsForItem = async (id: any) => {
  const response = await axios
    .get("stockpile/items/" + id + "/assets")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetAssetsForID = async (id: any) => {
  const response = await axios
    .get("stockpile/assets/" + id)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetCommentsForAsset = async (AssetId: any) => {
  const response = await axios
    .get("comments/" + AssetId)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetAllCategories = async () => {
  const response = await axios
    .get("categories/items/all")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
  return response?.data.data;
};

const CreatePackage = async (Package: PackageModel) => {
  const formData = new FormData();

  let pic = [];
  if (Package.picture) {
    pic = await decoderBase64All([Package.picture]);
  }

  formData.append("name", Package.name);
  formData.append("description", Package.description);
  pic[0] && formData.append("picture", pic[0]);
  Package.assets.map((a: any) => {
    formData.append("assets[]", a?._id);
  });
  Package.items.map((i: any) => {
    formData.append("items[]", i?._id);
  });

  const response = await axios
    .post<R<any>>("stockpile/item-groups/packages", formData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const CreateKit = async (Kit: PackageModel) => {
  const formData = new FormData();

  let pic = [];
  if (Kit.picture) {
    pic = await decoderBase64All([Kit.picture]);
  }

  formData.append("name", Kit.name);
  formData.append("description", Kit.description);
  pic[0] && formData.append("picture", pic[0]);
  Kit.assets.map((a: any) => {
    formData.append("assets[]", a?._id);
  });
  Kit.items.map((i: any) => {
    formData.append("items[]", i?._id);
  });

  const response = await axios
  .post<R<any>>("stockpile/item-groups/kits", formData)
  .catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });

return response?.data.data;
};

const getTotalPrice = async () => {
  const response = await axios
    .get<R<any>>("stockpile/assets/total-price")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const CreateCommentForAsset = async (CommentData: any) => {
  const response = await axios
    .post<R<any>>("comments/asset/create", CommentData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data?.data;
};

const UpdateCommentForAsset = async (CommentData: any, idComment: any) => {
  const response = await axios
    .put<R<any>>("comments/" + idComment + "/edit", CommentData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data?.data;
};

const DeleteCommentForAsset = async (idComment: any) => {
  const response = await axios
    .delete<R<any>>("comments/" + idComment + "/delete")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data?.data;
};

const UpdateAsset = async (update: any, id: any) => {
  const response = await axios
    .put<R<any>>("stockpile/assets/" + id, update)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data?.data;
};

const CreateService = async (service: any) => {
  const formData = new FormData();

  let pic = [];
  if (service.picture) {
    pic = await decoderBase64All([service.picture]);
  }

  formData.append("name", service.name);
  formData.append("description", service.description);
  pic[0] && formData.append("picture", pic[0]);

  let str = service?.skill_sets ;
  let arr = str.split(',');
  arr?.map((a: any) => {
    formData.append("skill_sets[]", a);
  });

  const response = await axios
    .post<R<any>>("stockpile/services", formData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const UpdateService = async (service: any) => {
  const DecodePicture: any =
    service.picture && (await decoderBase64All([service.picture]));

  const data = {
    name: service?.name,
    description: service?.description,
    // picture: DecodePicture[0],
    skill_sets: service?.skill_sets,
  };

  console.log("PAYLOAD");
  console.log(data);

  const response = await axios
    .put<R<any>>("stockpile/services/" + service?._id, data)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetMaintenance = async (AssetID: any) => {
  const response = await axios
    .get<R<any>>("stockpile/assets/" + AssetID + "/maintenances")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

    return response?.data.data;

};

const CreateMaintenance = async (data: any, id: any) => {
  const response = await axios
    .put<R<any>>("stockpile/assets/" + id + "/maintenance", data)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
};

const GetAccesories = async (AssetID: any) => {
  const response = await axios
    .get<R<any>>("stockpile/items/" + AssetID + "/accessories")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetWareHouses = async () => {
  const response = await axios
    .get<R<any>>("stockpile/ware-houses/all")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const DeleteItemForId = async (idItem: any) => {
  //var data = '{\r\n  "two_factor_code": "480704"\r\n}';

  var config: any = {
    method: "delete",
    url: "stockpile/items/" + idItem,
    headers: {},
    //  data: data
  };

  let status = 200;

  const response = await axios(config).catch(function (error) {
    status = error.status;
  });

  return status;
};

const DeleteAssetForId = async (idItem: any) => {
  //var data = '{\r\n  "two_factor_code": "480704"\r\n}';

  var config: any = {
    method: "delete",
    url: "stockpile/assets/" + idItem,
    headers: {},
    // data: data
  };

  let status = 200;

  const response = await axios(config).catch(function (error) {
    status = error.status;
  });

  return status;
};

const GetRateCardsForItem = async (idItem: any) => {
  const response = await axios
    .get("rate-cards/items/" + idItem + "/all-rates")
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};
const CreateItemsForCSV = async (FileCsv: any) => {
  const formData = new FormData();

  const DecodeCSV: any = FileCsv && (await decoderBase64All([FileCsv]));

  formData.append("csv_item", DecodeCSV[0]);

  const response = await axios
    .post<R<any>>("stockpile/items/create-by-csv", formData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const CreateAssetsForCSV = async (FileCsv: any) => {
  const formData = new FormData();

  const DecodeCSV: any = FileCsv && (await decoderBase64All([FileCsv]));

  formData.append("csv_item", DecodeCSV[0]);

  const response = await axios
    .post<R<any>>("stockpile/items/create-by-csv", formData)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const GetItemForID = async (idItem: any) => {
  const response = await axios
    .get("stockpile/items/" + idItem)
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });

  return response?.data.data;
};

const stockpileService = {
  CreateItem,
  CreateItemsForCSV,
  CreateAsset,
  CreateAccesories,
  CreateRates,
  GetAllItems,
  GetAssetsForItem,
  GetCommentsForAsset,
  GetAllCategories,
  GetAccesories,
  CreatePackage,
  CreateKit,
  getTotalPrice,
  GetAllRateCards,
  GetClientRateCards,
  GetAssetsForID,
  CreateCommentForAsset,
  UpdateCommentForAsset,
  DeleteCommentForAsset,
  UpdateAsset,
  CreateService,
  GetMaintenance,
  CreateMaintenance,
  GetWareHouses,
  GetAllAssets,
  DeleteItemForId,
  GetRateCardsForItem,
  UpdateItem,
  UpdateService,
  DeleteAssetForId,
  GetItemForID,
  CreateAssetsForCSV
};

export default stockpileService;
