export const CheckValue = (value:any, indicator = true, separator?:any) => {
    if (value == undefined) {
        if (indicator) {
            return '-'
        }else{
            return ''
        }
    }else{
        if (separator) {
            return value + separator
        }
        return value
    }
}