import ComboBox from '../../../../UI/forms/ComboBox';
import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import InputSelect from '../../../../UI/forms/InputSelect';
import AssetScheme from './validation';
import { useFormik, Form, FormikProvider } from 'formik';
import { AssetModel, warehouse, purchase, External, PickupInfo } from '../../../../../models/ItemModel';
import IconButton from '../../../../UI/buttons/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { CreateAsset } from '../../../../../store/stockpile/stockpileSlice';
import { setAsset, updateValuesAssets, setFormNameFile, setAssetExternal, setFormDate, setFormsCount, setFormsExternalCount } from '../../../../../store/stockpile/EditFormSlice';
import FormAsset from './Form';


import AddMore from '../../../../UI/AddMore';
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import FormAssetExternal from './FormExternal';
import { useRequest } from 'src/hooks/useRequest';
import { useParams } from 'react-router-dom';

interface Props {
  images: any,
  objectPick?: any,
  setObjectPick?: any,
  dateCount?: any,
  setDateCount?: any,
  ASSETS_ALL?: any
}

const AssetData = forwardRef((props: Props, ref) => {

  const dispatch = useDispatch()
  const params = useParams<{ itemId: string; assetId: string }>();
  const AssetR: any = useRef()
  const AssetL: any = useRef()

  const data = useSelector((state: RootState) => state.createFormSlice.AssetsData);

  const ITEM_ASSETS = props.ASSETS_ALL

  const ArrayArrays = useSelector((state: RootState) => state.createFormSlice.DatesData);

  const DirectAssets = useSelector((state: RootState) => state.createFormSlice.AssetsData);

  const ASSET_SELECTED = useSelector((state: RootState) => state.selectAssetsSlice.data);



  const images: Array<string> = props.images
  const [currentTab, setCurrentTab] = useState<number>(0);

  const base = {
    serial: '',
    purchase: {
      date: '',
      origin: '',
      price: ''
    },
    warehouse: {
      warehouse: 'warehouse',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '',
    is_external: 'false'
  }

  const baseExternal = {
    serial: '',
    purchase: {
      date: '',
      origin: '',
      price: ''
    },
    warehouse: {
      warehouse: '',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '',
    is_external: true,
    condition: '',
    external: {
      prices: {
        hour: '',
        daily: '',
        week: '',
        sale: '',
        holiday_hour: '',
        holiday_day: '',
        overtime_hour: '',
        holiday_overtime_hour: ''
      },
      company_owner: '',
      pickup_location: '',
    } as External,
    sizes: {
      name: 'dw',
      width: '',
      height: '',
      weight: '',
      length: '',
      weight_unit: '',
      sizes_unit: ''
    }
  } as AssetModel

  const AddOtherForm = () => {
    dispatch(setAsset(base))
  }

  const AddOtherFormExternal = () => {
    dispatch(setAssetExternal(baseExternal))
  }

  const AddForm = () => {
    if (currentTab == 0) {
      AddOtherForm()
      dispatch(setFormsCount([]))
      dispatch(setFormDate([]))
      dispatch(setFormNameFile([]))
    }
    if (currentTab == 1) {
      AddOtherFormExternal()
      dispatch(setFormsExternalCount([]))
    }
  }

  useEffect(() => { AddForm() }, [])

  let formsCountDirect: any
  let checkDirect = ITEM_ASSETS?.filter((asset: any) => asset.is_external == false && asset._id == params.assetId)

  formsCountDirect = checkDirect && { state: true, Asset: checkDirect[0] } || []


  const formsCountExternal = ITEM_ASSETS?.map((asset: any, index: any) => {
    if (asset.is_external == true) {
      //  AddForm()
      return { state: true, Asset: asset }
    }
  }) || []

  let formsCount: Array<any> = []
  formsCount[0] = currentTab == 0 ? formsCountDirect : formsCountExternal[0]

  const [submitState, setSubmit] = useState<any>(() => { });
  const dateCount = props.dateCount
  const setDateCount = props.setDateCount

  const setImages = (element: any) => {
    images.length == DirectAssets.length ? images[images.length - 1] = element : images.push(element);

  }

  const setItem = () => {
    dispatch(CreateAsset([
      data
      , images]))
  }

  useEffect(() => {
    
    if (formsCountExternal[0]?.state) {
      setCurrentTab(1)
    } else {
      setCurrentTab(0)
    }
  }, [formsCountExternal])

  const ChargeData = () => {
    AssetR?.current?.FullDataForm()
  }

  const ChargeDataL = () => {
    AssetL?.current?.FullDataForm()
  }





  useImperativeHandle(ref, () => ({

    ChargeAssetData() {
      ChargeData()
    }
  }));



  const tabsContent: (index: number, Asset: any) => JSX.Element[] = (index, Asset) => [
    <FormAsset
      setImages={setImages}
      dateCount={dateCount}
      setDateCount={setDateCount}
      Asset={Asset}
      index={index}
      ref={AssetR}
    />,
    <FormAssetExternal
      index={index}
      Asset={Asset}
      setImages={setImages}
      ref={AssetL}
    />,
  ];

  return (
    <div className="container-fluid px-1 mt-3 m-0 ">
      <div className='pe-5' style={{ marginBottom: 10, marginLeft: '-.6%' }} >
        <button onClick={setCurrentTab.bind(null, 0)} className="btn btn-primary col-auto mx-2 btn-fitcontent"
          style={currentTab == 1 ? { pointerEvents: 'none', opacity: 0.2 } : {}}>
          Direct Owner
        </button>
        <button onClick={setCurrentTab.bind(null, 1)} className="btn btn-default col-auto mx-2 btn-fitcontent"
          style={currentTab == 0 ? { pointerEvents: 'none', opacity: 0.2 } : {}}>
          External Owner
        </button>
      </div>

      {
        formsCount.map((item: any, index: any) => {
          if (index < 1) {
            
            return (
              <>
                {tabsContent(0, item?.Asset)[currentTab]}
                <hr />
              </>
            )
          }
        })
      }
      <div style={{ justifyContent: 'center', display: 'flex' }}>

      </div>

    </div>
  );
});

export default AssetData;
