import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { RootState } from 'src/store/store';
import { useToast } from 'src/hooks/useToast';
import { ROImageMimeTypesArray } from 'src/util';
import { TabsContent } from '../../../AddContact';
import { toBase64 } from 'src/util/converToBase64';
import InputDate from 'src/components/UI/forms/InputDate';
import InputSelect from 'src/components/UI/forms/InputSelect';
import ButtonFiles from 'src/components/UI/buttons/ButtonFile';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import ClientPersonalValidation, { ClientData } from './validation';
import { createContactForm } from 'src/store/blackbook/createContactSlice';
import InputFloatingLabel from 'src/components/UI/forms/InputFloatingLabel';
import SocialAndCommForm from '../../SocialAndComm';

type EmailKeys = 'primary' | 'personal';
type CommunicationKeys = "discord" | "website" | "skype";
type SocialKeys = "facebook" | "twitter" | "instagram" | "linkedin";

const ClientPersonalInformation = forwardRef((_props, ref: TabsContent['ref']) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const data = useSelector((state: RootState) => state.createContactFormSlice.data);

  const [socials, setSocials] = useState<{ type: SocialKeys }[]>([]);
  const [communication, setCommunication] = useState<{ type: CommunicationKeys }[]>([]);
  const [emails, setEmails] = useState<{ type: EmailKeys }[]>([{ type: 'primary' }, { type: 'personal' }]);

  const formik = useFormik<ClientData>({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      contact_since: data.contact_since || '',
      ...(data.data || {}),
    },
    validationSchema: ClientPersonalValidation,
    onSubmit: () => {},
  });

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      try {
        const errors = await formik.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }

        return true;
      } catch {
        return false;
      }
    }
  }));

  useEffect(() => {
    if (data.initialValues !== undefined && data.appliedInitialValues === false) {
      formik.setValues(data.initialValues || {});
      /* @ts-ignore */
      dispatch(createContactForm.setField('appliedInitialValues', true, ''));

      if (data.initialValues?.email) {
        setEmails(emails => emails.some((e) => e.type === 'primary') ? emails : [...emails, { type: 'primary' }])
      }
  
      if (data.initialValues?.personal_email) {
        setEmails(emails => emails.some((e) => e.type === 'personal') ? emails : [...emails, { type: 'personal' }]);
      }
    }

    for (const key in (data.data?.social || data?.initialValues?.social || {})) {
      if ((data?.data?.social?.[key] || data?.initialValues?.social?.[key]) && socials.length === 0) {
        setSocials(socials => [...socials, { type: key as SocialKeys }]);
      }
    }

    for (const key in (data.data?.communication || data.initialValues?.communication || {})) {
      if ((data.data?.communication?.[key] || data.initialValues?.communication?.[key]) && communication.length === 0) {
        setCommunication(communication => [...communication, { type: key as CommunicationKeys }]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication.length, data, socials.length])

  const handleChange = (fieldName: string, value: any, path = 'data') => {
    formik.setFieldValue(fieldName, value);

    /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
    if (value instanceof File) {
      toBase64(value as File).then(base64 => {
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, base64 as string, 'data' ));
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, value.name, 'files' ));
      }).catch(err => {
        console.log(err);
      })

      return;
    }

    /* @ts-ignore */
    dispatch(createContactForm.setField(fieldName, value, path));
  }

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="row">
        <div className="col">
          <ButtonFiles
            label="Profile picture"
            error={formik.errors.logo}
            selectedName={data.files?.logo}
            accept={ROImageMimeTypesArray.join(',')}
            onChange={(val) => handleChange('logo', val.currentTarget.files?.item(0))}
          />
        </div>
        <div className="col"></div>
      </div>
      
      <div className="row mt-4">
        <div className="col">
          <InputSelect
            select={{
              value: `${formik.values.title}`,
              onChange: (value: string) => { handleChange('title', value) },
              options: [
                { value: 'Mr', label: 'Mr' },
                { value: 'Ms', label: 'Ms' },
                { value: 'Mrs', label: 'Mrs' },
                { value: 'Miss', label: 'Miss' },
              ],
            }}
            input={{
              isRequired: true,
              placeHolder: 'First name',
              value: formik.values.first_name,
              errorMessage: formik.errors.first_name,
              onChange: (val) => { handleChange('first_name', val) },
            }}
          />
        </div>

        <div className="col">
          <InputFloatingLabel
            placeHolder="Middle name"
            value={formik.values.middle_name}
            errorMessage={formik.errors.middle_name}
            onChange={(val) => handleChange('middle_name', val)}
          />
        </div>

        <div className="col">
          <InputFloatingLabel
            placeHolder="Last name"
            value={formik.values.last_name}
            errorMessage={formik.errors.last_name}
            onChange={(val) => handleChange('last_name', val)}
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-6">
          {emails.map((email, index) => {
            const key = email.type === 'primary' ? 'email' : 'personal_email';
            return (
              <InputSelect
                key={email.type}
                select={{
                  value: `${email.type}`,
                  onChange: (value: string) => {
                    if (emails.some(email => email.type === value)) {
                      toast.warn('Email type already added.');
                      return;
                    }
                    
                    const val = formik.values?.[key];
                    handleChange(key, val);

                    emails.splice(index, 1, { type: value as EmailKeys });
                  },
                  options: [
                    { value: 'primary', label: 'Work' },
                    { value: 'personal', label: 'Personal' },
                  ],
                }}
                input={{
                  placeHolder: 'Email',
                  value: formik.values?.[key],
                  errorMessage: formik.errors?.[key],
                  isRequired: email.type === 'primary',
                  onChange: (val) => { handleChange(key, val) },
                }}
              />
          )})}
        </div>

        <div className="col-6">
          <InputFloatingLabel
            isRequired
            placeHolder="Phone"
            value={formik.values.phone}
            errorMessage={formik.errors.phone}
            onChange={(val) => handleChange('phone', val)}
          />
        </div>
      </div>

      {emails.length < 2 && (
        <div className="row">
          <div className="col-6">
            <SimpleButton
              onClick={() => {
                let toAdd: EmailKeys = 'primary';
                ['personal', 'primary'].forEach((item) => {
                  if (!emails.find((email) => email.type === item)) {
                    toAdd = item as EmailKeys;
                  }
                })

                if (emails.length === 2) {
                  toast.warn('You can only add 2 emails.');
                  return;
                }

                setEmails((prev) => [...prev, { type: toAdd }])
              }}
            >
              ADD EMAIL
            </SimpleButton>
          </div>
        </div>
      )}

      <SocialAndCommForm
        formik={formik}
        socials={socials}
        communication={communication}

        setSocials={setSocials}
        handleChange={handleChange}
        setCommunication={setCommunication}
      />

      <div className="row mt-2">
        <div className="col-6">
          <p className="typo-body-important m-1 opacity-75 p-0 text-muted text-opacity-50">NICKNAME:</p>
          <InputFloatingLabel
            placeHolder="Nickname"
            value={formik.values.nickname}
            errorMessage={formik.errors.nickname}
            onChange={(val) => handleChange('nickname', val)}
          />
        </div>

        <div className="col-6">
          <p className="typo-body-important m-1 opacity-75 p-0 text-muted text-opacity-50">CONTACT SINCE:</p>
          <InputDate
            max={new Date()}
            value={formik.values.contact_since}
            error={formik.errors.contact_since}
            onChange={(e: any) => handleChange('contact_since', e.valueText, '')}
          />
        </div>
      </div>
    </div>
  );
});

export default ClientPersonalInformation;
