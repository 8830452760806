import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useImperativeHandle } from 'react';

import { RootState } from 'src/store/store';
import { TabsContent } from '../../AddContact';
import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import { createContactForm } from 'src/store/blackbook/createContactSlice';

const ClientOrganization = forwardRef((_props, ref: TabsContent['ref']) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      return true;
    }
  }));

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="row">
        <div className="col-12 col-md-6 ">
          <InputFloatingLabel
            placeHolder="Organization name"
            value={`${data?.data?.organization || ''}`}
            /* @ts-ignore */
            onChange={(val) => dispatch(createContactForm.setField('organization', val))}
          />
        </div>
      </div>
    </div>
  );
});

export default ClientOrganization;
