import BarChart from "src/components/UI/charts/BarChart";

function Proposals() {
  return (
    <div className="card">
      <div className="card-body">
      
        <h4 className="card-title text-primary">Proposals</h4>

        <div className="mt-4">
          <BarChart
            fullWidth
            data={[ 250, 100, 1000 ]}
            labels={[ '', '', '' ]}
            chartOptions={{
              interaction: {
                intersect: false,
                mode: 'index'
              },
              scales: {
                y: {
                  grid: {
                    display: false
                  },
                  ticks: {
                    display: false
                  }
                },
                x: {
                  grid: {
                    display: false
                  }
                }
              },
            }}
            datasetProps={{
              borderWidth: 2,
            }}
          />
        </div>

      </div>
    </div>
  );
}

export default Proposals;