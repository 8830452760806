import { useState, useRef, useEffect } from 'react';
import ConfirmModal from '../../Modals/ConfirmModal';
import Checkbox from '../../UI/forms/Checkbox';
import ProgressbarTitle from '../../UI/ProgressbarTitle';
import Accessories from './tabs/Accesories/Accessories';
import AssetData from './tabs/AssetData/AssetData';
//import Description from './tabs/Description';
import GeneralData from './tabs/GeneralData/GeneralData';
import Rates from './tabs/Rates/Rates';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import checkFields from '../../../util/CheckFields'
import { CreateItem, CreateAsset, CreateAccesories, CreateRates, RefreshForNewItem, EditItem, getAssetForItem, getAllItems, GetAllRateForItem } from '../../../store/stockpile/stockpileSlice';
import { AccesoriesModel, AssetModel, PickupInfo, RatesModel } from '../../../models/ItemModel';
import StepButton from 'src/components/UI/buttons/stepButton';
import { setShowAddItem, setCheckItemFormAlert, setAccesoryEditView } from 'src/store/stockpile/RecursosVisualeSlice';
import { EmptyForm } from 'src/store/stockpile/createFormSlice';
import { useRequest } from 'src/hooks/useRequest';
import { useHistory, useParams } from 'react-router-dom';
import { useToast } from 'src/hooks/useToast';
import { setAllassets, setAsset } from 'src/store/stockpile/EditFormSlice';
import { useSWRConfig } from 'swr';
import { RefreshAccesories, setDataAsset } from 'src/store/stockpile/selectAssetsSlice';



interface Props {
  onFinish: () => void;
  TypeForm: any;
}

const tabs: string[] = [
  'General Data',
  'Rates',
  'Accessories'
];


const EditStock: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const GeneralRef: any = useRef();
  const AssetsRef: any = useRef();
  const history = useHistory()


  const params = useParams<{ itemId: string; }>();
  const toast = useToast();
  const { mutate } = useSWRConfig()
  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId });
  const ASSET_DATA: any = useSelector((state: RootState) => state.selectAssetsSlice.data);
  const ASSETACCESORIESSELECTED: any = useSelector((state: RootState) => state.selectAssetsSlice.AccesoriesSelected);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [images, setImagesForm] = useState<Array<any>>([]);
  const [resetAllInputs, setResetAllInputs] = useState<any>(false);

  const [checkItemForm, setCheckItemForm] = useState<any>(false);

  const checkItemFormAlert = useSelector((state: RootState) => state.RecursosVisualeSlice.checkItemFormGeneralDataAlert);

  const [dateCount, setDateCount] = useState<
    { type: string; date: string }[]
  >([{ type: 'date of purchase', date: '' }]);

  const [objectPick, setObjPick] = useState<PickupInfo>({
    country: '',
    state: '',
    city: '',
    zip: '',
    adress: ''
  })

  ///Loading
  const Loading = useSelector((state: RootState) => state.stockpileSlice.loading);


  /////CREATE ITEM CHECK
  const ItemID = useSelector((state: RootState) => state.stockpileSlice.ItemResponse);

  /////Errors 
  const Gdta = useSelector((state: RootState) => state.EditFormSlice.GeneralErrors);
  const Asst = useSelector((state: RootState) => state.EditFormSlice.AssetsErrors);
  const Rtes = useSelector((state: RootState) => state.EditFormSlice.RatesErrors);

  /////FORMS
  const data = useSelector((state: RootState) => state.EditFormSlice.data);
  const sizesG = useSelector((state: RootState) => state.EditFormSlice.sizesG);
  const rates: any = useSelector((state: RootState) => state.EditFormSlice.RatesData);
  const mandatory = useSelector((state: RootState) => state.createFormSlice.AccesoriesMandatoryData)
  const optional = useSelector((state: RootState) => state.createFormSlice.AccesoriesOptionalData)
  const Accesories = {
    mandatory: [...mandatory],
    optional: [...optional]
  } as AccesoriesModel

  //const accesories = useSelector((state: RootState) => state.createFormSlice.AccesoriesData);
  const DirectAssets = useSelector((state: RootState) => state.createFormSlice.AssetsData);
  const ExternalAssets = useSelector((state: RootState) => state.createFormSlice.AssetsDataExternal);

  const AssetsBack = useSelector((state: RootState) => state.EditFormSlice.AllAssets);
  const AccesoryEdit = useSelector((state: RootState) => state.RecursosVisualeSlice.AccesoryEdit);

  const progress = {
    ...data, ...rates, ...DirectAssets, ...ExternalAssets
  }

  useEffect(() => {
    dispatch(EmptyForm([]))
    setResetAllInputs(true)
    dispatch(setAllassets([]))
    dispatch(getAllItems())
    dispatch(GetAllRateForItem(params.itemId))
  }, [])

  useEffect(() => {
    [data].map((item) => {
      if (item.category) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }

      if (item.name) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }
    })

  }, [checkItemForm, data])

  useEffect(() => {

    if (AccesoryEdit) {
     setCurrentTab(2)
    }
    
  }, [AccesoryEdit])
  


  // const percenCompletion = (1 - checkFields(progress).percent)
  const percenCompletion = 0.05

  const tabsContent: JSX.Element[] = [
    <GeneralData
      resetAll={resetAllInputs}
      alertComplete={checkItemFormAlert}
      ItemData={ITEM_DATA}
      ref={GeneralRef}
    />,
    <Rates />,
    <Accessories />
  ];

  const onCancelModalClose = (value?: any) => {
    setIsCancelling(false);

    if (value == '') {
      props.onFinish()
      return;
    }


  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    setCurrentTab((prev) => {
      if (prev < tabs.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  const deleteEmptyProps = (obj: any) => {
    const end = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != ""));
    return end
  }

  const setItem = async () => {
    if (!Asst && !Rtes) {

      const beforeItem: any = { ...data, sizes: { ...deleteEmptyProps(sizesG) } }

      const ItemCreated: any = deleteEmptyProps(beforeItem)


      const res: any = await dispatch(EditItem(ItemCreated))

      dispatch(setDataAsset({ ...ASSET_DATA, item: { ...ItemCreated, picture_url: res.payload?.picture_url } }))
      ///Create Accesories
      if (mandatory.length > 0 || optional.length > 0) {

        var filteredMandatory = mandatory.filter((el: any) => el != '');

        var filteredOptional = optional.filter((el: any) => el != '');

        const Accesories = {} as any

        if (filteredMandatory.length > 0) { Accesories.mandatory = [...filteredMandatory] } else { Accesories.mandatory = [] }
        if (filteredOptional.length > 0) { Accesories.optional = [...filteredOptional] } else { Accesories.optional = [] }

        const AllDataAccesories = {
          data: Accesories,
          item: res.payload._id
        }

        const resAccesories: any = await dispatch(CreateAccesories(AllDataAccesories))

        console.log('Accesories');
        console.log(resAccesories);
        

        if (resAccesories.payload) {

          const Refresh:any = {
            mandatory: resAccesories.payload?.mandatory_accessories,
            optional: resAccesories.payload?.optional_accessories
          }
          dispatch(RefreshAccesories(Refresh))

        }


      }

      if (!res.payload) {
        toast.error('Edit Item Failed')
      }

      toast.success('Edit item succesfull')
      props.onFinish()
      dispatch(EmptyForm([]))



    } else {

      if (Gdta) {
        let cpy = [Gdta]
        cpy.map((error) => {
          toast.error('error ' + Object.keys(error)[0] + ' in General Data')
        })
      }

    }
  }


  useEffect(() => {
    GeneralRef?.current?.FullDataForm(ITEM_DATA)
  }, [GeneralRef, ITEM_DATA])

  const base = {
    serial: '',
    purchase: {
      date: '',
      origin: '',
      price: ''
    },
    warehouse: {
      warehouse: 'warehouse',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '',
    is_external: 'false'
  }

  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new item will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD ITEM"
          onClose={onCancelModalClose}
        />
      )}

      <div className="container-fluid h-100">
        <div className="row" style={{ height: '90vh' }}>
          <div className="col-12 card h-100" >
            {/* Top actions */}
            <div className="row justify-content-between align-items-center p-2
            col-lg-12 col-md-auto col-sm-12 col-12 align-self-center
            "
              style={{ position: 'absolute', background: 'white', zIndex: 1 }}>

              <div className='p-0 m-0 border-0 col-auto row'>

                <button
                  onClick={setIsCancelling.bind(null, true)}
                  className="btn btn-outline-danger typo-body col-auto border-0">
                  Cancel
                </button>
                <div className='p-0 m-0 border-0 col-auto ms-1'>
                  {currentTab > 0 ? (
                    <StepButton onClick={prevStep} previous />
                  ) : (<div style={{ width: '110px' }}> </div>)}
                </div>
              </div>

              <h4
                className='text-primary col-auto' >Edit Item</h4>

              <div className='p-0 m-0 border-0 col-auto row justify-content-between'>

                {currentTab < tabs.length - 1 && (
                  <div className='p-0 m-0 border-0 col-auto me-1'>
                    <StepButton onClick={nextStep} next />
                  </div>
                )}

                <button onClick={setItem} className="btn btn-outline-success typo-body col-auto border-0">
                  Save item
                </button>

              </div>

            </div>
            {/* Tab View */}
            <div className='custom-scrollbar overflow-y h-100 mt-4'>
              <div className={`container ${Loading && 'disabledElement'} mt-5`}  >
                <div className="row mt-3">
                  <div className="col">
                    <ul className="nav nav-tabs mt-3 justify-content-between">
                      <div className="row">
                        {tabs.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="nav-item col-auto p-0"
                              onClick={setCurrentTab.bind(null, index)}
                            >
                              <p
                                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : ''}`}
                                aria-current="page"
                              >
                                {item}
                              </p>
                            </li>
                          );
                        })}
                      </div>
                    </ul>

                    <div className="row justify-content-end mt-2">
                      <div className="col-auto">
                        <ProgressbarTitle value={percenCompletion} />
                        <div style={{ marginLeft: '14%' }}>
                          <Checkbox
                            title="Mark as completed"
                            value={false}
                            onChange={(value) => { }}
                          />
                        </div>
                      </div>
                    </div>

                    {tabsContent[currentTab]}

                    <div className="row justify-content-between mt-5 mb-5 d-none">
                      {currentTab < 1 && (
                        <div style={{ width: '100px' }} />
                      )}
                      {currentTab > 0 && (
                        <StepButton onClick={prevStep} previous />
                      )}

                      {currentTab < tabs.length - 1 && (
                        <StepButton onClick={nextStep} next />
                      )}

                      {currentTab === tabs.length - 1 && (
                        <StepButton onClick={setItem} save />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStock;
