import { useEffect, useState } from 'react';

// Tabs Components
import IconButton from '../../UI/buttons/IconButton';
import EquipmentData from './tabs/EquipmentData';
import History from './tabs/History';
import Kits from './tabs/Kits';
import Accessories from './tabs/Accessories';
import Maintenance from './tabs/Maintenance';
import Rates from './tabs/Rates';
import Projects from './tabs/Projects';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import { AssetModel } from '../../../models/ItemModel';
import { setFullWidth, setselectedState, setShowAddAsset, setShowAssetLis, setShowEditAsset, setShowEditItem } from '../../../store/stockpile/RecursosVisualeSlice';
import { AllWareHouses, cleanItemGetted, DeleteAsset, DeleteItem, getAllItems, GetAllRateForItem, getAssetForItem, GetItemForID, RefreshForDeleteAsset, RefreshForDeleteItem } from 'src/store/stockpile/stockpileSlice';
import { useToast } from 'src/hooks/useToast';

import TrashIcon from '../../../assets/icons/trash.svg'
import EditIcon from '../../../assets/icons/edit.svg'
import { useHistory, useParams } from 'react-router-dom';
import { getAssetForID, setDataAsset, setIdItem } from 'src/store/stockpile/selectAssetsSlice';
import { useRequest } from 'src/hooks/useRequest';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import useQuery from 'src/hooks/useQuery';
import { setCategoryFilter } from 'src/store/stockpile/categorieSlice';
import { EmptyForm, updateValuesAssets } from 'src/store/stockpile/EditFormSlice';
import EmptyCircle from 'src/components/UI/EmptyCircle';


interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const tabs = [
  'Equipment data',
  'Rates',
  'Maintenance',
  'Projects',
  'History',
  'Accessories',
  'Kits',
];

const StockView: React.FC<Props> = (props) => {
  const toast = useToast()

  const dispatch = useDispatch()
  const params = useParams<{ itemId: string; }>();
  const history = useHistory();
  const query = useQuery()

  const [currentTab, setCurrentTab] = useState(0);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false)
  const [isCancellingAsset, setIsCancellingAsset] = useState(false)

  const { innerHeight } = window
  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId });

  const ASSET_DATA: any = useSelector((state: RootState) => state.selectAssetsSlice.data);

  const AllAssetForItem: any = useSelector((state: RootState) => state.stockpileSlice.AssetData) || [];

  const FullWidth: any = useSelector((state: RootState) => state.RecursosVisualeSlice.FullWidth);
  const ShowEdit: any = useSelector((state: RootState) => state.RecursosVisualeSlice.ShowEditItem);
  const ShowAddAsset: any = useSelector((state: RootState) => state.RecursosVisualeSlice.ShowAddAsset);
  const ShowEditAsset: any = useSelector((state: RootState) => state.RecursosVisualeSlice.ShowEditAsset);


  const ItemGetted: any = useSelector((state: RootState) => state.stockpileSlice.ItemGetted) || [];


  const toggleOptionsState = () => setOptionsOpen((prev) => !prev);

  useEffect(() => {
    dispatch(setDataAsset({}))
    dispatch(cleanItemGetted())
    dispatch(GetAllRateForItem(params.itemId))
    dispatch(AllWareHouses())
  }, [])

  useEffect(() => {
    dispatch(GetItemForID(params.itemId))
    dispatch(getAssetForItem(params.itemId))
  }, [params.itemId])

  useEffect(() => {
      dispatch(setDataAsset({...ASSET_DATA ,item: ItemGetted }))
  }, [ItemGetted, params.itemId])

  useEffect(() => {
    if (AllAssetForItem.length > 0) {
      dispatch(getAssetForID(AllAssetForItem[0]._id))
    }
  }, [AllAssetForItem])



  const toggleEditItem: any = (category: any) => {
    if (ShowEdit) {
      query.delete('editItem');
      history.replace({ search: query.toString() })
    } else {
      query.set('editItem', category || '');
      history.push({ search: query.toString() })
    }
    dispatch(setShowEditItem((prev: any) => !prev));
  };

  const toggleAddingAsset: any = (category: any) => {
    if (ShowAddAsset) {
      query.delete('AddAsset');
      history.replace({ search: query.toString() })
    } else {
      query.set('AddAsset', category || '');
      history.push({ search: query.toString() })
    }
    dispatch(setShowAddAsset((prev: any) => !prev))
  };


  const toggleEditingAsset: any = (category: any) => {
    if (ShowEditAsset) {
      query.delete('EditAsset');
      history.replace({ search: query.toString() })
    } else {
      query.set('', category || '');
      history.push('/stockpile/' + params.itemId + '/' + query.toString().substring(1))
    }
    dispatch(setShowEditAsset((prev: any) => !prev));
    dispatch(EmptyForm([]))
  };


  const tabsContent = [
    <EquipmentData />,
    <Rates />,
    <Maintenance />,
    <Projects />,
    <History />,
    <Accessories />,
    <Kits />,
  ];
  const onCancelModalClose = async (value?: boolean) => {
    if (!value) {
      dispatch(setCategoryFilter(ITEM_DATA?.category?._id))

      const response: any = await dispatch(DeleteItem(ITEM_DATA?._id))

      if (response.payload == '200') {
        dispatch(setShowAssetLis(false))
        dispatch(RefreshForDeleteItem(ITEM_DATA?._id))
        toast.success('Item has been deleted')
        history.push('/stockpile')
      } else {
        if (!response.payload || response.payload == '403') {
          toast.error('You are not allowed to delete this Item.')

        } else {
          toast.error('error for delete item')

        }
      }
    }
    setIsCancelling(false);
  };

  const onCancelModalAssetClose = async (value?: boolean) => {

    if (!value) {
      dispatch(setCategoryFilter(ASSET_DATA?.item?.category?._id))

      const response: any = await dispatch(DeleteAsset(ASSET_DATA?._id))




      if (response.payload == '200') {
        dispatch(setDataAsset({ ...AllAssetForItem[0] }))
        dispatch(setselectedState(0))
        dispatch(RefreshForDeleteAsset(ASSET_DATA?._id))
        toast.success('Asset has been deleted')
        //  history.push('/stockpile')
      } else {
        if (response.payload == '403') {
          toast.error('You are not allowed to delete this asset.')

        } else {
          toast.error('error for delete Asset')

        }

      }
    }
    setIsCancellingAsset(false);

  };

  const messageComming = (message:any) => {
    toast.info(message + ' is coming soon!')
  }

  return (
    <div className="d-flex flex-column vh-100 px-2 my-sm-5 my-lg-0">
      {/* Header */}

      <header className="w-100 mt-3 d-flex justify-content-between align-items-center ps-1">
        <i
          onClick={() => FullWidth ? dispatch(setFullWidth(false)) : dispatch(setFullWidth(true))}
          className={`${FullWidth
            ? 'bi bi-arrows-angle-contract'
            : 'bi bi-arrows-angle-expand'
            } col-auto  h5 m-0`} style={{ fontWeight: 'bold', color: '#344469' }}
        ></i>
        <div className='d-flex' style={{ flexDirection: 'row' }} >

          <div className='d-flex me-4 mt-2' style={{ flexDirection: 'column', fontSize: '12px' }}>
            <p className='text-primary'>ITEM</p>
            {ASSET_DATA?.serial && <p className='text-primary'>ASSET ({ASSET_DATA?.serial})</p>}
          </div>
          <div className='d-flex ' style={{ flexDirection: 'column' }}>

            <div className="d-flex align-items-center">
              <IconButton
                color="primary"
                icon="bi bi-plus-lg"
                // tooltip="Add"
                onClick={() => toggleAddingAsset()}
                outline
              />

              <IconButton
                color="primary"
                icon="bi bi-share-fill"
                // tooltip="Share contact"
                outline
              />

              <IconButton
                color="primary"
                custom={EditIcon}
                customStyles={{ width: '12px', height: '12px' }}
                onClick={() => toggleEditItem()}
                outline
              />

              <IconButton
                color="danger"
                custom={TrashIcon}
                customStyles={{ width: '12px', height: '12px' }}
                onClick={() => {
                  setIsCancelling(true)
                }}
                outline
              />
            </div>
            {ASSET_DATA?.serial && <div className='d-flex align-items-end mt-1' style={{ marginLeft: '50%' }} >
              <IconButton
                color="primary"
                custom={EditIcon}
                customStyles={{ width: '12px', height: '12px' }}
                onClick={() => toggleEditingAsset(ASSET_DATA?._id)}
                outline
              />

              <IconButton
                color="danger"
                custom={TrashIcon}
                customStyles={{ width: '12px', height: '12px' }}
                onClick={() => {
                  setIsCancellingAsset(true)
                }}
                outline
              />
            </div>}

          </div>

        </div>

      </header>

      <div className="w-100 mt-3 d-flex justify-content-between ps-1 ">
        <div className="d-flex align-items-center">
          {ASSET_DATA?.item?.picture_url && <img
            className="avatar-profile"
            src={process.env.REACT_APP_IMAGES_AWS + ASSET_DATA?.item?.picture_url}
            alt=""
          />
          }

          {
           !ASSET_DATA?.item?.picture_url && ASSET_DATA?.item?.name && <EmptyCircle title={ASSET_DATA?.item?.name} />
          }

          <div className="ms-3">
            <p className="m-0 text-black typo-body contact-title">
              {AllAssetForItem && ASSET_DATA?.item?.name}
            </p>
            <p className="m-0 text-black typo-body contact-subtitle">
              {
                //ITEM_DATA?.description
              }
            </p>
          </div>
        </div>

        <div className="d-flex flex-column align-items-end">
          <div className="d-flex align-item-en">
            <p className="m-0 text-black typo-body text-end">Stock:</p>
            <p className="m-0 contact-trailing typo-body bg-primary ms-3">{AllAssetForItem.length}</p>
          </div>

          <div className="d-flex align-item-en me-3 my-1">
            <p className="m-0 text-black typo-body">Quarantine:</p>
            <p className="m-0  typo-body ms-3 text-black">0</p>
          </div>

          <div className="d-flex align-item-en me-3">
            <p className="m-0 text-black typo-body">Currently available:</p>
            <p className="m-0  typo-body ms-3 text-black">0</p>
          </div>
        </div>
      </div>

      <div className="row ms-4">
        <div className="col offset-1 d-flex align-items-cente">
          <button className="btn btn-default mx-1 typo-body " onClick={() => messageComming('Assign item')}  style={{opacity: 0.5}}>
            Assign item
          </button>

          <button className="btn btn-default mx-1 typo-body " onClick={() => messageComming('Quarantine')}  style={{opacity: 0.5}}>
            Quarantine
          </button>

          <button className="btn btn-default mx-1 typo-body " onClick={() => messageComming('Retire item')}  style={{opacity: 0.5}}>
            Retire item
          </button>

          <button className="btn btn-default mx-1 typo-body " onClick={() => messageComming('Generate report')}  style={{opacity: 0.5}}>
            Generate report
          </button>

          <button className="btn mx-1 typo-body " onClick={() => messageComming('Flag item')}  style={{ background: '#f6ce49' , color: 'black' }}>
            Flag item
          </button>
        </div>
      </div>

      {/* Body */}

      <ul className="nav nav-tabs mt-3">
        {tabs.map((item, index) => {
          return (
            <li
              key={index}
              className="nav-item"
              onClick={setCurrentTab.bind(null, index)}
            >
              <p
                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : ''
                  }`}
                aria-current="page"
              >
                {item}
              </p>
            </li>
          );
        })}
      </ul>

      {/* Tabs */}
      <div style={{ maxHeight: innerHeight - 320 + 'px' }}>
        {tabsContent[currentTab]}
      </div>

      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This item will be deleted"
          confirmText="CANCEL"
          cancelText="DELETE ITEM"
          onClose={onCancelModalClose}
        />
      )}

      {isCancellingAsset && (
        <ConfirmModal
          title="Are you sure?"
          description={"This asset will be deleted (" + ASSET_DATA?.serial + ")"}
          confirmText="CANCEL"
          cancelText="DELETE ASSET"
          onClose={onCancelModalAssetClose}
        />
      )}
    </div>
  );
};

export default StockView;
