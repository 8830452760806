interface Props {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?:string;
}

const InputSearch: React.FC<Props> = (props) => {
  return (
    <div className={`input-icon ${props.className} text-left`} >
      <i className="bi bi-search text-primary py-2 ps-3"></i>
      <input
        className="form-control py-2 typo-body text-primary"
        type="search"
        value={props.value}
        onChange={(event) =>
          props.onChange && props.onChange(event.target.value)
        }
        placeholder={props.placeholder}
        style={{
          border: "1.2px solid #DEE2E6"
        }}
      />
    </div>
  );
};

export default InputSearch;
