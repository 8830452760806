import React, { useState, useEffect } from "react";
import { ApiError } from "src/models";
import { AxiosResponse } from "axios";
import { useToast } from "src/hooks/useToast";
import { useHistory, useParams } from "react-router-dom";

import useQuery from "src/hooks/useQuery";
import { useRequest } from "src/hooks/useRequest";
import useRates from "src/hooks/useRates";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { createRateCardForm } from "src/store/rates/createRateCardSlice";

import AdvanceSearch from "../../Contacts/ContactList/AdvanceSearch";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import Checkbox from "../../UI/forms/Checkbox";
import TextArea from "../../UI/forms/TextArea";

import RateModel, {
  RateCardVersionsModel,
  RateCardItemModel,
  IRates,
  BAddRateCardItem,
  IRateCardItem,
} from "../../../models/RateModel";
import { RateCardService } from "src/services/rates";
import TableCell from "./table/TableCell";
import Filters, { applyFilters, RateCardFilters } from "../Filters/Filters";
import IconButton from "src/components/UI/buttons/IconButton";
import { IContact } from "src/models/ContactModel";
import { ContactTypeEx } from "src/util/ContactsFunctions";
import { string } from "yup";
import EmptyCircle from "src/components/UI/EmptyCircle";

interface RateCardVersion {
  created_at: string;
  created_by: string;
  description: string;
  rate_card: string;
  updated_at: string;
  version: number;
  _id: string;
}

interface Props {
  toggleOnAddRateCardVersion: () => void;
}

const CreateVersion: React.FC<Props> = (props) => {
  const params = useParams<{ rateCardId: string; rateCardVersion: string }>();
  const toast = useToast();
  const query = useQuery();
  const history = useHistory();

  const type = query.get("create");

  const dispatch = useDispatch();
  const createRateCardData = useSelector(
    (state: RootState) => state.createRateCardSlice
  );

  const [loading, setloading] = useState<boolean>(true);
  const [sending, setsending] = useState<boolean>(false);

  const {
    data: rate,
    error,
    mutate: mutateRate,
  } = useRequest<RateModel[]>(
    params?.rateCardId ? { url: `rate-cards/${params.rateCardId}` } : null
  );
  const {
    data: version,
    error: errVersion,
    mutate: mutateVersion,
  } = useRequest<RateCardVersion>(
    params.rateCardVersion
      ? { url: `rate-card-versions/${params.rateCardVersion}` }
      : null
  );
  const {
    data: items,
    error: errItems,
    mutate: mutateItems,
  } = useRequest<RateCardItemModel[]>(
    rate
      ? {
          url: params.rateCardVersion
            ? `rate-card-versions/${params.rateCardVersion}/rate-card-items`
            : `rate-card-versions/${
                rate[0].rate_card_versions[
                  rate[0].rate_card_versions.length - 1
                ]._id
              }/rate-card-items`,
        }
      : null
  );

  const [list, setlist] = useState<RateCardItemModel[]>([]);
  const [filters, setfilters] = useState<RateCardFilters>({
    search: "",
    category: "",
  });
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (items && loading) {
      mutateVersion(undefined, true);
      mutateItems(undefined, true);
      setlist([...items]);
      setloading(false);
    }
  }, [items]);

  const formatItemsToSendData = (
    items: IRateCardItem[]
  ): BAddRateCardItem[] => {
    let list = [...items];
    let newList: BAddRateCardItem[] = [];

    for (let i = 0; i < list.length; i++) {
      const dataItem = list[i];

      // conditional
      if (dataItem.item !== null) {
        let newItem: BAddRateCardItem = {
          item: dataItem.item !== null ? dataItem.item._id : "",
          rates: dataItem.rates,
        };

        newList.push(newItem);
      }
    }

    return newList;
  };

  const editRateValue = (id: string, value: IRates) => {
    // var allData     = [...list];
    let allData = list.slice();

    let item = allData.find((item) => item._id === id);
    if (item) {
      let index = allData.indexOf(item);
      allData[index].rates = value;

      setlist(allData);
    }
  };

  console.log("list", list);
  console.log("rate", rate);

  const handleSave = async () => {
    if (list && rate) {
      setsending(true);

      let newRateCardVersionData = {
        rate_card_items: formatItemsToSendData(list),
      };

      RateCardService.CreateRateCardVersion(rate[0]._id.toString(), {
        ...newRateCardVersionData,
      })
        .then((rateCard) => {
          setsending(false);
          setSaveModalOpen(false);

          toast.success("RateCard version created successfully.");

          mutateRate(undefined, true);
          mutateItems(undefined, true);

          if (params.rateCardVersion) {
            history.goBack();
          } else {
            props.toggleOnAddRateCardVersion();
          }
        })
        .catch((err: AxiosResponse<ApiError>) => {
          setsending(false);
          return toast.error(
            Array.isArray(err.data.message)
              ? err.data.message.join("\n")
              : err.data.message
          );
        });
    }
  };

  let tableItems = applyFilters(list, filters);

  return (
    <>
      <div className="container-fluid h-100">
        {!items && !errItems && (
          <div className="d-flex justify-content-center align-items-center py-5 h-100">
            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
          </div>
        )}

        {items && !errItems && rate && (
          <React.Fragment>
            {saveModalOpen && (
              <ConfirmModal
                title="Are u sure?"
                description={`A new version will be created.`}
                buttons={
                  <>
                    {sending ? (
                      <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
                    ) : (
                      <div>
                        <button
                          className="btn btn-danger text-white mx-1 px-3"
                          onClick={() => setSaveModalOpen(false)}
                          disabled={sending}
                        >
                          CANCEL
                        </button>
                        <button
                          className="btn btn-success text-white mx-1 px-3"
                          onClick={() => handleSave()}
                          disabled={sending}
                        >
                          CONFIRM
                        </button>
                      </div>
                    )}
                  </>
                }
                onClose={() => setSaveModalOpen(false)}
              />
            )}
            <div className="row" style={{ height: "98%" }}>
              <div className="col card h-100 custom-scrollbar overflow-y p-3">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="content-rate-description">
                      <h1 className="typo-body h5 mt-2 fw-bold">
                        {!params.rateCardVersion ? (
                          <i
                            onClick={() => props.toggleOnAddRateCardVersion()}
                            className={`bi bi-chevron-left text-primary h5 me-2`}
                          />
                        ) : (
                          <i
                            onClick={() => history.goBack()}
                            className={`bi bi-chevron-left text-primary h5 me-2`}
                          />
                        )}

                        {!params.rateCardVersion ? (
                          <span>{rate[0].name} - New version</span>
                        ) : (
                          <span>
                            {rate[0].name} - {version?.description}
                          </span>
                        )}
                      </h1>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Filters
                      col="4"
                      filterValue={filters}
                      onChangeFilters={(value: RateCardFilters) =>
                        setfilters(value)
                      }
                      advandedFilters
                    />
                  </div>
                </div>
                <div className="w-100 custom-scrollbar overflow-x mt-3 custom-table">
                  <table className="table table-borderless">
                    <thead>
                      <tr className="text-primary typo-body">
                        <th scope="col">ITEM</th>
                        <th scope="col">CATEGORY</th>
                        <th scope="col">HOUR</th>
                        <th scope="col">DAY</th>
                        <th scope="col">WEEK</th>
                        <th scope="col">SALE</th>
                        <th scope="col">OVERTIME HOUR</th>
                        <th scope="col">HOLIDAY HOUR</th>
                        <th scope="col">HOLIDAY DAY</th>
                        <th scope="col">HOLIDAY OVERTIME HOUR</th>
                      </tr>
                    </thead>
                    {tableItems.length > 0 ? (
                      <tbody>
                        {tableItems.map((item, index) => {
                          return (
                            <tr
                              className={`${
                                (index / 2) % 1 > 0 ? "" : "table-primary"
                              } text-primary typo-body`}
                              key={index}
                            >
                              <td className="d-flex align-items-center">
                                {item?.item?.picture_url && (
                                  <img
                                    loading="lazy"
                                    src={
                                      process.env.REACT_APP_IMAGES_AWS +
                                      item?.item?.picture_url
                                    }
                                    alt=""
                                    className="avatar2"
                                  />
                                )}
                                {!item?.item?.picture_url && (
                                  <EmptyCircle title={item?.item?.name} small />
                                )}
                                <p className="m-0 ms-2">
                                  {item.item && item.item.name
                                    ? `${item.item.name}`
                                    : ""}
                                  {item.item && item.item.brand
                                    ? `- ${item.item.brand}`
                                    : ""}
                                </p>
                              </td>

                              <TableCell
                                value={
                                  item.item?.category
                                    ? item.item.category.name
                                    : "-"
                                }
                              />

                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.hour}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    hour: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.daily}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    daily: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.week}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    week: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.sale}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    sale: Number(value),
                                  })
                                }
                              />

                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.overtime_hour}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    overtime_hour: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.holiday_hour}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    holiday_hour: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.holiday_day}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    holiday_day: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.holiday_overtime_hour}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    holiday_overtime_hour: Number(value),
                                  })
                                }
                              />
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="table-primary text-primary text-center align-middle typo-body">
                          <td colSpan={10} className="align-middle py-3">
                            No items were found.
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>

                {params.rateCardVersion ? (
                  <div className="content-restore-version-btn">
                    <div className="btn-container">
                      <button
                        className="btn btn-primary px-4 py-2"
                        onClick={() => setSaveModalOpen(true)}
                      >
                        Restore version
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row justify-content-center">
                    <button
                      className="btn btn-success mb-2 mt-4 px-3 col-auto text-white"
                      onClick={() => setSaveModalOpen(true)}
                    >
                      CREATE NEW VERSION
                    </button>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default CreateVersion;
