import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { useToast } from "src/hooks/useToast";
import { useRequest } from "src/hooks/useRequest";
import { ApiError } from "src/models";
import { ICategory } from "src/models/Category";
import RateModel, {
  BAddRateCardItem,
  IRateCardItem,
  IRates,
  RateCardItemModel,
} from "src/models/RateModel";
import { RateCardService } from "src/services/rates";
import AdvanceSearch from "../../Contacts/ContactList/AdvanceSearch";
import ConfirmModal from "../../Modals/ConfirmModal";
import Checkbox from "../../UI/forms/Checkbox";
import InputFloatingLabel from "../../UI/forms/InputFloatingLabel";
import Filters, { applyFilters, RateCardFilters } from "../Filters/Filters";
import EmptyCircle from "src/components/UI/EmptyCircle";

interface Props {
  onGlobalChange: () => void;
}

const GlobalChange: React.FC<Props> = (props) => {
  const params = useParams<{ rateCardId: string; rateCardVersion: string }>();
  const toast = useToast();
  const { data: categories, error: errorCategory } = useRequest<ICategory[]>({
    url: `categories/items/all`,
  });

  const [sending, setsending] = useState<boolean>(false);

  const [filters, setfilters] = useState<RateCardFilters>({
    search: "",
    category: "",
  });
  const [isAddChange, setIsAddChange] = useState(false);

  const {
    data: rate,
    error,
    mutate: mutateRate,
  } = useRequest<RateModel[]>(
    params?.rateCardId ? { url: `rate-cards/${params.rateCardId}` } : null
  );
  const {
    data: items,
    error: errItems,
    mutate: mutateItems,
  } = useRequest<RateCardItemModel[]>(
    rate
      ? {
          url: params.rateCardVersion
            ? `rate-card-versions/${params.rateCardVersion}/rate-card-items`
            : `rate-card-versions/${
                rate[0].rate_card_versions[
                  rate[0].rate_card_versions.length - 1
                ]._id
              }/rate-card-items`,
        }
      : null
  );

  const [applyChangeToSelectedCategories, setapplyChangeToSelectedCategories] =
    useState<string[]>([]);
  const [selectedItemsToApplyChange, setselectedItemsToApplyChange] = useState<
    string[]
  >([]);
  const [globalChange, setglobalChange] = useState<Number>(0);

  const toggleOnAddChange = () => setIsAddChange((prev) => !prev);

  const onAddChange = (value?: boolean) => {
    toggleOnAddChange();

    if (value) {
      props.onGlobalChange();
    }
  };

  const handleGlobalChange = (value: string) => {
    let newValue = Number(value);

    if (newValue > 100) newValue = 100;
    else if (newValue < -100) newValue = -100;

    setglobalChange(newValue);
  };

  const changeSelectedCategories = (id: string) => {
    if (items) {
      let selectedCategories = [...applyChangeToSelectedCategories];
      let itemsList = [...items];

      if (applyChangeToSelectedCategories.includes(id)) {
        selectedCategories = [...applyChangeToSelectedCategories].filter(
          (item) => item !== id
        );
        setapplyChangeToSelectedCategories(selectedCategories);
      } else {
        selectedCategories.push(id);
        setapplyChangeToSelectedCategories(selectedCategories);
      }

      let newSelectedItems = [];

      for (let i = 0; i < itemsList.length; i++) {
        const item = itemsList[i];
        if (
          item.item.category &&
          selectedCategories.includes(item.item.category._id)
        ) {
          newSelectedItems.push(item._id);
        }
      }

      setselectedItemsToApplyChange(newSelectedItems);
    }
  };

  const selectAll = () => {
    if (items) {
      let newArrayItemsSelected = [];

      if (selectedItemsToApplyChange.length === items.length) {
        setselectedItemsToApplyChange([]);
        setapplyChangeToSelectedCategories([]);
      } else {
        for (let i = 0; i < [...items].length; i++) {
          const item = items[i];
          newArrayItemsSelected.push(item._id);
        }

        setapplyChangeToSelectedCategories([]);
        setselectedItemsToApplyChange(newArrayItemsSelected);
      }
    }
  };

  const toggleItem = (id: string) => {
    if (items) {
      let newArrayItemsSelected = [...selectedItemsToApplyChange];

      if (selectedItemsToApplyChange.includes(id)) {
        setselectedItemsToApplyChange(
          selectedItemsToApplyChange.filter((item) => item !== id)
        );
      } else {
        newArrayItemsSelected.push(id);
        setselectedItemsToApplyChange(newArrayItemsSelected);
      }
    }
  };

  const calculateChange = (data: number) => {
    console.log(globalChange);
    console.log(1 + Number(globalChange) / 100);
    return data * (1 + Number(globalChange) / 100);
  };

  const formatItemsToSendData = (
    items: IRateCardItem[]
  ): BAddRateCardItem[] => {
    let list = [...items];
    let newList: BAddRateCardItem[] = [];

    const applyChange = (rates: IRates): IRates => {
      let newRates: IRates = rates;
      newRates.daily = calculateChange(newRates.daily);
      newRates.holiday_day = calculateChange(newRates.holiday_day);
      newRates.holiday_hour = calculateChange(newRates.holiday_hour);
      newRates.holiday_overtime_hour = calculateChange(
        newRates.holiday_overtime_hour
      );
      newRates.hour = calculateChange(newRates.hour);
      newRates.overtime_hour = calculateChange(newRates.overtime_hour);
      newRates.sale = calculateChange(newRates.sale);
      newRates.week = calculateChange(newRates.week);

      return newRates;
    };

    for (let i = 0; i < list.length; i++) {
      const dataItem = list[i];

      // conditional
      if (dataItem.item !== null) {
        let newItem: BAddRateCardItem = {
          item: dataItem.item !== null ? dataItem.item._id : "",
          rates:
            selectedItemsToApplyChange.includes(dataItem._id) &&
            Number(globalChange) > 0
              ? applyChange(dataItem.rates)
              : dataItem.rates,
        };

        newList.push(newItem);
      }
    }

    return newList;
  };

  const handleSave = async () => {
    if (items && rate) {
      setsending(true);

      let newRateCardVersionData = {
        rate_card_items: await formatItemsToSendData(items),
      };

      RateCardService.CreateRateCardVersion(rate[0]._id.toString(), {
        ...newRateCardVersionData,
      })
        .then((rateCard) => {
          setsending(false);
          setIsAddChange(false);

          toast.success("RateCard version created successfully.");

          mutateRate(undefined, true);
          mutateItems(undefined, true);

          props.onGlobalChange();
        })
        .catch((err: AxiosResponse<ApiError>) => {
          setsending(false);
          return toast.error(
            Array.isArray(err.data.message)
              ? err.data.message.join("\n")
              : err.data.message
          );
        });
    }
  };

  let tableItems = items ? applyFilters(items, filters) : [];

  return (
    <>
      {isAddChange && rate && (
        <ConfirmModal
          title="Are you sure?"
          description={`You will create a new version for System ${rate[0].name}'s rates.`}
          buttons={
            <>
              {sending ? (
                <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
              ) : (
                <div>
                  <button
                    className="btn btn-danger text-white mx-1 px-3"
                    onClick={() => setIsAddChange(false)}
                    disabled={sending}
                  >
                    GO BACK
                  </button>
                  <button
                    className="btn btn-success text-white mx-1 px-3"
                    onClick={() => handleSave()}
                    disabled={sending}
                  >
                    CONFIRM
                  </button>
                </div>
              )}
            </>
          }
          onClose={() => setIsAddChange(false)}
        />
      )}

      {items && (
        <div className="container-fluid h-100">
          <div className="row" style={{ height: "98%" }}>
            <div className="col card h-100 custom-scrollbar overflow-y p-3">
              <div className="row justify-content-between">
                <div className="col-md-auto col-6">
                  <h1 className="typo-body h5 mt-2 fw-bold">
                    <i
                      className="bi bi-chevron-left h6 me-2"
                      onClick={props.onGlobalChange}
                    />
                    <span className="h6">Apply global % change</span>
                  </h1>
                </div>

                <div className="col-md-4 col-6">
                  <Filters
                    col="12"
                    filterValue={filters}
                    onChangeFilters={(value: RateCardFilters) =>
                      setfilters(value)
                    }
                    advandedFilters
                  />
                </div>
              </div>

              <p className="m-0 text-secondary typo-body mt-3">
                Define the percentahe change you want to apply to the current
                version's rates. Remember that a negative percentage will
                represent a discount and a positive percentage will represent an
                increase.
              </p>

              <div className="d-flex mt-3 align-items-center flex-wrap">
                <InputFloatingLabel
                  inputType="number"
                  value={globalChange.toString()}
                  onChange={(value) => handleGlobalChange(value)}
                  placeHolder="Global rate change"
                  className="custom-input-number"
                />

                <p className="m-0 ms-2 text-primary typo-body col-auto">
                  APPLY TO:
                </p>

                {categories && categories.length > 0 && (
                  <>
                    {categories.map((category: ICategory, index: number) => {
                      return (
                        <div className={`form-check ms-2`}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={applyChangeToSelectedCategories.includes(
                              category._id
                            )}
                            onChange={() =>
                              changeSelectedCategories(category._id)
                            }
                          />
                          <label className="form-check-label typo-body text-primary">
                            {category.name}
                          </label>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>

              <div className="w-100 custom-scrollbar overflow-x mt-3">
                <table className="table table-borderless custom-table">
                  <thead>
                    <tr className="text-primary typo-body">
                      <th>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => selectAll()}
                          checked={
                            selectedItemsToApplyChange.length === items.length
                          }
                        />
                      </th>
                      <th scope="col">ITEM</th>
                      <th scope="col">CATEGORY</th>
                      <th scope="col">HOUR</th>
                      <th scope="col">DAY</th>
                      <th scope="col">WEEK</th>
                      <th scope="col">SALE</th>
                      <th scope="col">OVERTIME HOUR</th>
                      <th scope="col">HOLIDAY HOUR</th>
                      <th scope="col">HOLIDAY DAY</th>
                      <th scope="col">HOLIDAY OVERTIME HOUR</th>
                    </tr>
                  </thead>

                  <tbody>
                    {tableItems.map((item, index) => {
                      return (
                        <tr
                          className={`${
                            (index / 2) % 1 > 0 ? "table-primary" : ""
                          } text-primary typo-body`}
                          key={index}
                        >
                          <td className="align-middle">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={() => toggleItem(item._id)}
                              checked={selectedItemsToApplyChange.includes(
                                item._id
                              )}
                            />
                          </td>
                          <td className="d-flex align-items-center">
                            {item?.item?.picture_url && (
                              <img
                                loading="lazy"
                                src={
                                  process.env.REACT_APP_IMAGES_AWS +
                                  item?.item?.picture_url
                                }
                                alt=""
                                className="avatar2"
                              />
                            )}
                            {!item?.item?.picture_url && (
                              <EmptyCircle title={item?.item?.name} small />
                            )}
                            <p className="m-0 ms-2">
                              {item.item && item.item.name
                                ? `${item.item.name}`
                                : ""}
                              {item.item && item.item.brand
                                ? `- ${item.item.brand}`
                                : ""}
                            </p>
                          </td>
                          <td className="align-middle">
                            {item.item ? item.item.category?.name : "-"}
                          </td>
                          <>
                            <td className="align-middle">
                              $
                              {selectedItemsToApplyChange.includes(item._id) &&
                              Number(globalChange) !== 0
                                ? calculateChange(item.rates.hour)
                                : item.rates.hour}
                            </td>
                            <td className="align-middle">
                              $
                              {selectedItemsToApplyChange.includes(item._id) &&
                              Number(globalChange) !== 0
                                ? calculateChange(item.rates.daily)
                                : item.rates.daily}
                            </td>
                            <td className="align-middle">
                              $
                              {selectedItemsToApplyChange.includes(item._id) &&
                              Number(globalChange) !== 0
                                ? calculateChange(item.rates.week)
                                : item.rates.week}
                            </td>
                            <td className="align-middle">
                              $
                              {selectedItemsToApplyChange.includes(item._id) &&
                              Number(globalChange) !== 0
                                ? calculateChange(item.rates.sale)
                                : item.rates.sale}
                            </td>
                            <td className="align-middle">
                              $
                              {selectedItemsToApplyChange.includes(item._id) &&
                              Number(globalChange) !== 0
                                ? calculateChange(item.rates.overtime_hour)
                                : item.rates.overtime_hour}
                            </td>
                            <td className="align-middle">
                              $
                              {selectedItemsToApplyChange.includes(item._id) &&
                              Number(globalChange) !== 0
                                ? calculateChange(item.rates.holiday_hour)
                                : item.rates.holiday_hour}
                            </td>
                            <td className="align-middle">
                              $
                              {selectedItemsToApplyChange.includes(item._id) &&
                              Number(globalChange) !== 0
                                ? calculateChange(item.rates.holiday_day)
                                : item.rates.holiday_day}
                            </td>
                            <td className="align-middle">
                              $
                              {selectedItemsToApplyChange.includes(item._id) &&
                              Number(globalChange) !== 0
                                ? calculateChange(
                                    item.rates.holiday_overtime_hour
                                  )
                                : item.rates.holiday_overtime_hour}
                            </td>
                          </>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="row justify-content-center">
                <button
                  disabled={
                    Number(globalChange) === 0 ||
                    selectedItemsToApplyChange.length === 0
                  }
                  className="btn btn-success mb-2 mt-4 col-auto text-white"
                  onClick={toggleOnAddChange}
                >
                  CREATE NEW VERSION
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GlobalChange;
