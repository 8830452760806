import React , { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { useToast } from 'src/hooks/useToast';

interface Props {
  icon: string;
  title: string;
  pathTo: string;
  isOpen?: boolean;
  id?: any,
  disabled?:boolean
}

const SidebarItem: React.FC<Props> = (props) => {
  const toast = useToast()
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [test, setTest] = useState<any>(false)


  const toggle = () => {
    setTooltipOpen(!tooltipOpen)

  }

  useEffect(() => {
    setTimeout(() => setTest(true), 200)

  }, [tooltipOpen])


  const messageDisbled = (module:string) => {
    toast.info(module + ' module is coming soon')
  }

  return (
    <>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={props.id}
        toggle={toggle}
      >
        {props.title}
      </Tooltip>
      <div onClick={!props.disabled? () => {} : () => messageDisbled(props.title)} >
        <NavLink
          to={props.disabled ? '#' : props.pathTo}
          exact
          activeClassName={props.disabled ? '' : "active-link"}
          className={`d-flex align-items-center ms-2 me-2 py-1 my-4 mb-4 pb-2 text-decoration-none disable-link`}
          id={props.id}
         style={props.disabled?{opacity: 0.5}:{}}
        >
          <img src={props.icon} alt="" className="sidebar-icon mb-0" loading='lazy' />
          <p className="m-0 ms-2 text-white mb-0"
            style={{ 
              whiteSpace: 'nowrap', 
              justifySelf: 'center', 
              textAlign: 'center', 
              lineHeight: '100%',
              display: props.isOpen? 'block': 'none'}}
          >{props.title}</p>
        </NavLink>
      </div>

    </>
  );
};

export default SidebarItem;
