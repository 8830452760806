interface Props {
    title: string;
    small?: boolean
}

const EmptyCircle: React.FC<Props> = (props) => {
    const style = props.small ? { height: '40px', width: '40px' , minWidth:'40px'} : {
        height: '80px', width: '80px'
    }

    const styleText = props.small ? { fontSize: '25px' } : {
        fontSize: '50px'
    }

    const styleContainer = props.small ? { marginTop: '40%' } : {
        marginTop: '15%' 
    }

    return (
        <div style={{
            borderRadius: '100%', background: '#6b8ab8'
            , display: 'flex', justifyContent: 'center', alignItems: 'center', ...style
        }}>
            <div style={{...styleContainer}}>
                <p style={{ color: 'white', textAlign: 'center', textTransform: 'uppercase', alignSelf: 'center', ...styleText }}>
                    {props?.title?.charAt(0)}
                </p>
            </div>
        </div>
    );
};

export default EmptyCircle;
