import axios from 'axios';

import { R } from 'src/models';
import { IComment } from 'src/models/CommentModel';
import { BCreateComment, BEditComment } from 'src/models/Bodies';

const AddComment = async (model: string, body: BCreateComment): Promise<IComment> => {
  const { data } = await axios.post<R<IComment>>(`comments/${model}/create`, {
    ...body,
  });

  return data.data;
}

const EditComment = async (id: string, body: BEditComment): Promise<IComment> => {
  const { data } = await axios.put<R<IComment>>(`comments/${id}/edit`, {
    ...body,
  });

  return data.data;
}

const DeleteComment = async (id: string): Promise<string> => {
  const { data } = await axios.delete<R<string>>(`comments/${id}/delete`);

  return data.data;
}

export const CommentsService = {
  AddComment,
  EditComment,
  DeleteComment,
}
