import React, { useState } from "react";

interface Props {
  setViewModal: any;
  ApproveQuote: any;
  loadingQuote: any;
  filterDataRate: any;
}

const CreateNewQuote: React.FC<Props> = (props) => {
  const [secondMessage, setSecoundMessage] = useState<boolean>(false);

  const closeModal = () => {
    if (!props.loadingQuote) {
      props.setViewModal(false);
    }
  };

  const createNewQuote = () => {
    props.ApproveQuote();
    closeModal();
  };

  return (
    <div>
      {}
      {!props.loadingQuote ? (
        <>
          <div>
            <div>
              <h1 className="text-secondary fw-bold fs-2">
                Do you want to save the quote?
              </h1>
            </div>
            <div>
              <p
                className="text-primary"
                style={{ fontSize: "14px", padding: "12px", width: " 540px" }}
              ></p>
            </div>
            <div>
              <button
                className="btn btn-danger text-white me-2 p-2"
                style={{ width: "100px", height: "40px" }}
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-success text-white  p-2"
                style={{ width: "150px", height: "40px" }}
                onClick={createNewQuote}
              >
                Save quote
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex flex-column ">
          <h1 className="text-secondary fw-bold fs-2">Saving</h1>
          <p className="mb-4">Wait while we saves your changes</p>
          <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
        </div>
      )}
    </div>
  );
};

export default CreateNewQuote;
