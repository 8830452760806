import React, { useState } from "react";
import { TreeList, extendDataItem } from "@progress/kendo-react-treelist";
import { Employee } from "../../../../../../components/dataTree/interface";
import columnsPreview from "./columnsPreview";

interface Props {
  quoteForm: any;
  setQuoteForm: any;
  filterHeader: any;
  list: any;
}

const TablePreview: React.FC<Props> = (props) => {
  const expandField: string = "expanded";
  const subItemsField: string = "employees";

  const data: Employee[] = [...props.quoteForm.headers];
  const [expanded, setExpanded] = React.useState<number[]>([1, 2, 32]);

  const callback = (item: Employee) =>
    expanded.includes(item.id)
      ? extendDataItem(item, subItemsField, { [expandField]: true })
      : item;

  const tableColumns = React.useMemo(
    () =>
      columnsPreview(props.quoteForm, props.setQuoteForm, props.filterHeader),
    [props.quoteForm, props.setQuoteForm, props.filterHeader]
  );

  return (
    <div className="container-tree-list d-flex flex-column ">
      <div>
        <TreeList
          data={props.list}
          subItemsField={subItemsField}
          columns={tableColumns}
          className="tree-scroll"
        />
      </div>
    </div>
  );
};

export default TablePreview;
