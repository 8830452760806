import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import InputFloatingLabel from '../../UI/forms/InputFloatingLabel';
import TextArea from '../../UI/forms/TextArea';
import SignatureCanvas from 'react-signature-canvas'
import UserInfoScheme from './validations'
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useToast } from '../../../hooks/useToast'
import { changeStatus } from '../../../store/factory/factoryPublicSlice';
import { decodeBase64 } from '../../../util/converToBase64'

interface Props {
    title: string;
    view: boolean;
    setView: any
    toggle: any
    data?: any
    setData?: any
    sendData?: any;
    id?: any;
    response:any
}

const UserInfoModal: React.FC<Props> = (props) => {

    const dispatch = useDispatch()
    const params = useParams<{ id: any }>();

    const toast = useToast()

    const [name, setname] = useState('')
    const [last, setlast] = useState('')
    const [email, setemail] = useState('')
    const [description, setdescription] = useState('')

    const [imageUrl, setImageURL] = useState<any>('')


    const sigCanvas = useRef({});

    const clear = () => {
        sigCanvas.current.clear()
        setImageURL('')
    }




    const formik = useFormik({
        validationSchema: UserInfoScheme,
        initialValues: {
            FirstName: '',
            LastName: '',
            email: '',
            description: ''
        } as any,
        onSubmit: (values) => {
        }
    })

    const { setFieldValue, errors, setValues, setErrors } = formik




    const cleanForm = () => {
        setname('')
        setemail('')
        setlast('')
        setImageURL('')
        setdescription('')
    }

    useEffect(() => { console.log(imageUrl);
     } ,[imageUrl])

    const send = () => {        
        dispatch(changeStatus({
            data: {
                status: "Approved",
                assigned_client: {
                    first_name: name,
                    last_name: last,
                    email: email,
                    signature: imageUrl
                }
            },
            id: params.id
        }))        
    }

    const setInfo =  () => {
       
        send()

        props.setView(false)
        cleanForm()

        toast.success('answered successfully')

    }

    const save = () => {
     
    let getting:string = sigCanvas.current.getTrimmedCanvas().toDataURL()

    setImageURL(getting.slice(22))
    
    }



    return (
        <Modal isOpen={props.view} toggle={() => props.toggle()} centered >
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className="font-weight-bold text-secondary typo-display">
                            {props.title}
                        </h2>
                    </div>

                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            onChange={(value: any) => {
                                setFieldValue('FirstName', value)
                                setname(value)
                            }}
                            value={name}
                            placeHolder='First Name'
                            errorMessage={errors.name}
                            isRequired
                        />
                    </div>


                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            onChange={(value) => {
                                setFieldValue('LastName', value)
                                setlast(value)
                            }}
                            value={last}
                            placeHolder='Last Name'
                            errorMessage={errors.LastName}
                            isRequired
                        />
                    </div>

                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            onChange={(value) => {
                                setFieldValue('email', value)
                                setemail(value)
                            }}
                            value={email}
                            placeHolder='Email'
                            errorMessage={errors.email}
                            isRequired
                        />
                    </div>
                    <div
                        className='col-2 m-0 pb-0  ps-1'>
                        <p className='text-primary'>Signature</p>
                    </div>
                    <div className='col-12 rounded' style={{ border: '0.1px solid lightgrey' }}>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor='black'
                            canvasProps={{
                                width: 425, height: 200, className: 'sigCanvas',
                            }}
                            onEnd={() => save()}
                        />
                    </div>

                    <div className='col-12'>

                        <div className='row'>
                            <button className='btn btn-primary mt-2' onClick={() => clear()}>
                                CLEAR
                            </button>

                        </div>
                    </div>

                    <div className="col-lg-12 mt-3 p-0">
                        <TextArea
                            value={description}
                            onChange={(value) => {
                                setFieldValue('description', value)
                                setdescription(value)
                            }}
                            placeHolder='Description'
                            errorMessage={errors.description}
                            style={{ width: '100%' }}

                        />
                    </div>

                    <div className="col-12 mt-3">
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-danger text-white col-auto"
                                onClick={() => {
                                    props.setView(false)
                                    cleanForm()
                                }}
                            >
                                Cancel
                            </button>
                            {
                                <button
                                    className="btn btn-success  text-white col-auto"
                                    onClick={() => setInfo()}
                                    style={
                                        !name || !last || !email || !description || !imageUrl || errors.email
                                            ? { pointerEvents: 'none', opacity: 0.2 } : {}}
                                >
                                    Save
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UserInfoModal;
