import { forwardRef, useImperativeHandle } from 'react';

import { RootState } from 'src/store/store';
import { TabsContent } from '../../AddContact';
import TextArea from '../../../../UI/forms/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { createContactForm } from 'src/store/blackbook/createContactSlice';

const OrganizationNote = forwardRef((_props, ref: TabsContent['ref']) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      return true
    }
  }));

  return (
    <div className="container-fluid px-3 mt-3">
      <TextArea
        title="General note"
        value={`${data.note || ''}`}
        placeHolder="Type here..."
        /* @ts-ignore */
        onChange={(val) => dispatch(createContactForm.setField('note', val, false))}
      />
    </div>
  );
});

export default OrganizationNote;
