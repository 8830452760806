import React, { useState } from "react";
import { TreeList, extendDataItem } from "@progress/kendo-react-treelist";
import { Employee } from "../../../../../components/dataTree/interface";
import columnsPreview from "./columnsPreview";

interface Props {
  list: any;
  ItemData: any;
}

const TablePreview: React.FC<Props> = (props) => {
  const expandField: string = "expanded";
  const subItemsField: string = "employees";

  const data: Employee[] = [...props.list];
  const [expanded, setExpanded] = React.useState<number[]>([1, 2, 32]);

  const callback = (item: Employee) =>
    expanded.includes(item.id)
      ? extendDataItem(item, subItemsField, { [expandField]: true })
      : item;

  const tableColumns = React.useMemo(
    () => columnsPreview(props.list, props.ItemData),
    [props.list, props.ItemData]
  );

  return (
    <div className="container-tree-list d-flex flex-column ">
      {!props.list ? (
        <div className="d-flex m-auto justify-content-center">
          <div className="me-2">
            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
          </div>
          <div className="mt-3">
            <p>Loading...</p>
          </div>
        </div>
      ) : (
        <div>
          <TreeList
            data={props.list}
            subItemsField={subItemsField}
            columns={tableColumns}
            className="tree-scroll"
          />
        </div>
      )}
    </div>
  );
};

export default TablePreview;
