import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import IconButton from "../../../UI/buttons/IconButton";
// import ProjectDetails from "./tabs/ProjectDetails";
// import Suppliers from "./tabs/Suppliers";
// import Quotes from "./tabs/Quotes";
// import Roles from "./tabs/Roles";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuotesId,
  UpdateStatusQuote,
  GetQuoteVersion,
  getAllQuotes
} from "../../../../store/factory/factoryJobSlice";
import TrashIcon from "../../../../assets/icons/trash.svg";
import { useRequest } from 'src/hooks/useRequest';
import { IComment } from 'src/models/CommentModel';
// import ModalFactoryPreviewQuote from "./tabs/modalQuotePreview/ModalFactoryPreviewQuote ";
import { useToast } from "../../../../hooks/useToast";
import { AxiosResponse } from "axios";
import { ApiError } from "../../../../models";
import { QuoteStatusData, QuoteStatusType } from "../../../../models/QuotesModel";
import { UploadError } from "../../../../util";
import { factoryService } from "../../../../services/factory";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../store/store";
import ConfirmModal from "../../../Modals/ConfirmModal";
import QuoteDetails from "./tabs/QuoteDetails";
import QuoteNotes from "./tabs/QuoteNotes";
import { CommentsService } from 'src/services/comments';
import QuoteTimeline from "./tabs/QuoteTimeline";
import QuoteEmailModal from "../../../Modals/Factory/Quotes/QuoteEmailModal";


interface Props {
  setActiveControlView?: any;
  setActiveTask?: any;
  openModal?: boolean;
  setOpenModal?: any;
  setSelectJob?: any;
  selectJob?: any;
  activeTask?: any;
  activeControlView?: any;
  getDataQuotes?: any;
  selectProject?: any;
  idProject?: string;
  setSelectProject?: any;
}

const tabs = [
  "Details",
  "FeedBack",
  "Tasks",
  "Notes",
  "Timeline",
];

const tabsDisbled = [
  "Tasks",
  "FeedBack",
];


const QuoteView: React.FC<Props> = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState<boolean>(false);
  const [client, setClient] = useState<any>([]);
  const [deleteProjectModal, setdeleteProjectModal] = useState<boolean>(false);
  const [deleteJobModal, setDeleteJobModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [jodIdDelete, setjobIdDelete] = useState<string | null>(null);
  const [view, setView] = useState<boolean>(false);
  const [preview, setPreView] = useState<boolean>(false);
  const [idQuoteDelete, setIdQuoteDelete] = useState<string>("");
  const [getQuoteById, setGetQuoteById] = useState<string>("");
  const [viewOpenModalFactory, setViewOpenModalFactory] = useState(false);
  const [newActiveControlView, setNewActiveControlView] = useState("");
  const [newActiveTask, setNewActiveTask] = useState("");

  const dispatch = useDispatch();

  const history = useHistory();

  const { id }: any = useParams();

  const { loading, QuotesId, QuoteVersion, updateQuoteStatus } = useSelector(
    (state: RootState) => state.getJobsTypeSlice
  );
  // const { QuoteData } = useSelector(
  //   (state: RootState) => state.getJobsTypeSlice
  // );

  const getQuotes = () => {
    dispatch(getAllQuotes());
  };

  const getQuote = () => {
    dispatch(getQuotesId(id));
    dispatch(GetQuoteVersion(idVersion));
  };

  const versionQuote = QuotesId?.quote_versions?.map((e: any) => e._id);
  const idVersion = versionQuote && versionQuote[versionQuote?.length - 1];

  const toast = useToast();
  const { data: comments, mutate } = useRequest<IComment[]>({ url: `comments/${QuotesId?._id || id}` });

  useEffect(() => {
    if (!QuotesId?.length && id){
        getQuote();
    }
  },[]);

  // COMMENTS
  const createNote = (comment: string) => {
    CommentsService.AddComment("quote", { content: comment, assigned_to: QuotesId?._id || id})
      .then(() => {
        mutate(undefined, true);
        toast.success('Note created.');
      })
      .catch((err: AxiosResponse<ApiError>) => {
        return toast.error(err.data.message);
      })
  }

  const deleteNote = (id: string) => {
    CommentsService.DeleteComment(id)
      .then(() => {
        mutate(undefined, true);
        toast.success('Note deleted.');
      })
      .catch((err: AxiosResponse<ApiError>) => {
        return toast.error(err.data.message);
      })
  }

  const editNote = (data) => {
    CommentsService.EditComment(data.id,{content: data.content})
      .then(() => {
        mutate(undefined, true);
        toast.success('Note edited.');
      })
      .catch((err: AxiosResponse<ApiError>) => {
        return toast.error(err.data.message);
      })
  }
  // DELETE QUOTE

  const handleQuoteConfirmDelete = () => {
    factoryService
      .DeleteQuotes(QuotesId?._id || id)
      .then((res) => {
        toast.success(
          "The quote has been successfully deleted."
        );
        setIsOpenDeleteModal(false);
        history.push("/factory");
      })
      .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
        if (err?.data?.message) {
          return toast.error(err?.data?.message);
        }
      });
  };

  const activeModalJob = () => {
    // props.setSelectProject(props.selectProject);
    // history.replace(`/factory/create/job/${id}`);

    setViewOpenModalFactory(true);
  };

  const activeModaltask = () => {
    setViewOpenModalFactory(true);
    setNewActiveControlView("task");
    setNewActiveTask("task");
  };

  // const handleChange = () => {
  //   props.setOpenModal(true);
  //   props.setActiveTask("task");
  //   props.setActiveControlView(null);
  // };

  const handleChangeStatus = (status: QuoteStatusType) => {
    const data = {id: QuotesId._id, status};
    dispatch(UpdateStatusQuote(data));
  }
  // const editProject = () => {
  //   props.setActiveControlView("job");
  //   props.setSelectJob(props.selectProject._id);
  // };

  const messageComming = (message: any) => {
    toast.info(message + " is coming soon!");
  };


  const tabsContent: JSX.Element[] = [
    <QuoteDetails
      quoteData={QuotesId}
      updatedStatusData = {updateQuoteStatus?.status}
      handleChangeStatus = {handleChangeStatus}
    />,
    <></>,
    <></>,
    <QuoteNotes
      comments={comments}
      createNote={createNote}
      deleteNote={deleteNote}
      editNote={editNote}
    />,
    <QuoteTimeline/>,
  ];

  return (
    <div className="h-100 overflow-hidden overflow-x-none custom-scrollbar trans-all ">
      {/* <ModalFactoryPreviewQuote
        view={view}
        setView={setView}
        toggle={view}
        preview={preview}
        setPreView={setPreView}
        QuoteData={QuoteData}
        QuotesId={QuotesId}
        QuoteVersion={QuoteVersion}
      /> */}
      <QuoteEmailModal
        isOpen={isOpenSendEmailModal}
        onClose={() => setIsOpenSendEmailModal((prev) => !prev)}
      />
      {
        isOpenDeleteModal && 
        <ConfirmModal
          title={"Are you sure?"}
          onClose={()=>{}}
          description={
            "This Quote will be discarted"
          }
          buttons={
            <>
              <button
                className="btn btn-danger text-white col-auto"
                onClick={handleQuoteConfirmDelete}
              >
                DELETE
              </button>

              <button
                className="btn btn-success text-white col-auto"
                onClick={() => setIsOpenDeleteModal(false)}
              >
                CANCEL
              </button>
            </>
          }
        />
      }
      <div className="d-flex flex-column card px-4 py-2 h-25">
        {
          loading? 
          <div className="d-flex justify-content-center align-items-center  h-100">
            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
          </div>
          :
          <>
                    {/* Header */}
          <header className="d-flex justify-content-between align-items-start mt-3 ">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="ms-1">
                  <p className="m-0 text-black typo-body contact-title fw-bolder">
                    {QuotesId?.name}
                  </p>
                  <p className="m-0 text-black typo-body contact-subtitle">
                    {QuotesId?._id}
                  </p>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div
                onClick={() => messageComming("Share project")}
                style={{ opacity: 0.5 }}
              >
                <IconButton
                  color="primary"
                  icon="bi bi-share-fill"
                  tooltip="Share project"
                  outline
                  style={{ height: "30px", width: "32.5px" }}
                />
              </div>
              <Link to={`/factory/edit/project/`}>
                <IconButton
                  color="primary"
                  icon="bi bi-pencil-fill"
                  tooltip="Edit project"
                  outline
                  style={{ height: "30px", width: "32.5px" }}
                />
              </Link>

              <IconButton
                color="danger"
                // icon="bi bi-trash2"
                tooltip="Delete project"
                outline
                custom={TrashIcon}
                style={{ height: "30px", width: "32.5px" }}
                customStyles={{
                  height: "15px",
                  width: "15px",
                  color: " #0000",
                }}
                onClick={() => setIsOpenDeleteModal(true)}
              />
            </div>
          </header>

          <div className="row">
            <div className="col d-flex align-items-center mt-2">
              <button
                className="btn btn-primary mx-1 typo-body text-white"
              >
                View quote
              </button>

              <button
                className="btn btn-primary mx-1 typo-body text-white"
                onClick={ () => setIsOpenSendEmailModal(true)}
              >
                Send quote
              </button>

              <button
                className="btn btn-primary mx-1 typo-body text-white"
                // disabled
                onClick={activeModaltask}
              >
                Create task
              </button>

              <button
                className="btn btn-primary mx-1 typo-body text-white"
                disabled
              >
                Create invoice
              </button>
            </div>
          </div>

          {/* Body */}

          <ul className="nav nav-tabs mt-3">
            {tabs.map((item, index) => {
              let check = tabsDisbled.find((tab) => {
                if (item == tab) {
                  return true;
                }
                return false;
              });

              return (
                <li
                  key={index}
                  className="nav-item"
                  onClick={
                    check
                      ? () => messageComming(item)
                      : setCurrentTab.bind(null, index)
                  }
                  style={check ? { opacity: 0.5 } : {}}
                >
                  <p
                    className={`nav-link typo-body user-select-none ${
                      currentTab === index ? "active" : ""
                    }`}
                    aria-current="page"
                  >
                    {item}
                  </p>
                </li>
              );
            })}
          </ul>

          {/* Tabs */}
          </>
        }

      </div>

      <div className="card" style={{height: "72%"}}>
        {
          loading?
          <div className="d-flex justify-content-center align-items-center h-100">
            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
          </div>
          :
          tabsContent[currentTab]}
      </div>
    </div>
  );
};

export default QuoteView;
