import { OrganizationData } from "../validation";
import InputSelect from "src/components/UI/forms/InputSelect";
import InputFloatingLabel from "src/components/UI/forms/InputFloatingLabel";

export interface AddressProps {
  index: number;
  remove: (index: number) => void;
  value: Required<OrganizationData>['addresses'][number];
  errors: Record<keyof Required<OrganizationData>['addresses'][number], string>[];
  edit: (index: number, data: Required<OrganizationData>['addresses'][number]) => void;
}

export function Address({
  edit,
  index,
  value,
  errors,
  remove,
}: AddressProps) {
  const editField = (data: Partial<Required<OrganizationData>['addresses'][number]>) => {
    edit(index, {...value, ...data});
  }

  return (
    <section>
      <div className="d-flex gap-3 text-primary align-items-center">
        <p style={{ width: '15%' }} className="mb-0">Address #{index+1}</p>
        <hr className="w-100" />
        <button title="Remove skillset" className="btn btn-primary" onClick={() => remove(index)}>&times;</button>
      </div>

      <div className="row mt-2">
        <div className="col">
          <InputSelect
            select={{
              value: value.type,
              onChange: (val: string) => editField({ ...value, type: val }),
              options: [
                { value: 'billing', label: 'Billing' },
                { value: 'office', label: 'Office' },
              ],
            }}
            input={{
              isRequired: true,
              value: value.map_location,
              placeHolder: 'Location Map',
              errorMessage: errors?.[index]?.map_location,
              onChange: (val) => editField({ ...value, map_location: val }),
            }}
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-4">
          <InputFloatingLabel
            placeHolder="Continent"
            value={`${value.continent || ''}`}
            errorMessage={errors?.[index]?.continent}
            onChange={(val) => editField({ ...value, continent: val })}
          />
        </div>

        <div className="col-4">
          <InputFloatingLabel
            placeHolder="Country"
            value={`${value.country || ''}`}
            errorMessage={errors?.[index]?.country}
            onChange={(val) => editField({ ...value, country: val })}
          />
        </div>

        <div className="col-4">
          <InputFloatingLabel
            placeHolder="State / Territory"
            value={`${value.state || ''}`}
            errorMessage={errors?.[index]?.state}
            onChange={(val) => editField({ ...value, state: val })}
          />
        </div>
      </div>
      
      <div className="row">
        <div className="col-4">
          <InputFloatingLabel
            placeHolder="City"
            value={`${value.city || ''}`}
            errorMessage={errors?.[index]?.city}
            onChange={(val) => editField({ ...value, city: val })}
          />
        </div>

        <div className="col-4">
          <InputFloatingLabel
            placeHolder="Postal Code"
            value={`${value.zip || ''}`}
            errorMessage={errors?.[index]?.zip}
            onChange={(val) => editField({ ...value, zip: val })}
          />
        </div>

        <div className="col-4">
          <InputFloatingLabel
            placeHolder="Address"
            value={`${value.address || ''}`}
            errorMessage={errors?.[index]?.address}
            onChange={(val) => editField({ ...value, address: val })}
          />
        </div>
      </div>
    </section>
  )
}