import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import IconButton from "../../UI/buttons/IconButton";
import ProjectDetails from "./tabs/ProjectDetails";
import Suppliers from "./tabs/Suppliers";
import Quotes from "./tabs/Quotes";
import Roles from "./tabs/Roles";
import FactoryModal from "../../Modals/FactoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteProject,
  getAllProject,
  getProjectId,
} from "../../../store/factory/factorySlice";
import ConfirmModal from "../../Modals/ConfirmModal";
import { RootState } from "../../../store/store";
import {
  Deletejobs,
  DeleteQuotes,
  getAllQuotes,
  getJobsId,
  getQuotesId,
  GetQuoteVersion,
} from "../../../store/factory/factoryJobSlice";
import TrashIcon from "../../../assets/icons/trash.svg";
import ModalFactoryPreviewQuote from "./tabs/modalQuotePreview/ModalFactoryPreviewQuote ";
import { useToast } from "../../../hooks/useToast";
import { AxiosResponse } from "axios";
import { ApiError } from "../../../models";
import { UploadError } from "../../../util";
import { factoryService } from "../../../services/factory";
import { useHistory } from "react-router-dom";

interface Props {
  setActiveControlView?: any;
  setActiveTask?: any;
  openModal?: boolean;
  setOpenModal?: any;
  setSelectJob?: any;
  selectJob?: any;
  activeTask?: any;
  activeControlView?: any;
  getDataQuotes?: any;
  selectProject?: any;
  idProject?: string;
  setSelectProject?: any;
  closeModalJob: boolean;
  setCloseModalJob: any;
}

const tabs = [
  "Project details",
  "Quotes",
  "Roles",
  "Suppliers",
  "Calendar",
  "Tasks",
  "Comms",
  "Documents",
  "Completion report",
];

const tabsDisbled = [
  "Roles",
  "Suppliers",
  "Calendar",
  "Tasks",
  "Comms",
  "Documents",
  "Completion report",
];

const tab = "\u00A0";

const FactoryView: React.FC<Props> = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [client, setClient] = useState<any>([]);
  const [organization, setOrganization] = useState<any>([]);
  const [deleteProjectModal, setdeleteProjectModal] = useState<boolean>(false);
  const [deleteJobModal, setDeleteJobModal] = useState<boolean>(false);
  const [deleteQuoteModal, setDeleteQuoteModal] = useState<boolean>(false);
  const [jodIdDelete, setjobIdDelete] = useState<string | null>(null);
  const [view, setView] = useState<boolean>(false);
  const [preview, setPreView] = useState<boolean>(false);
  const [idQuoteDelete, setIdQuoteDelete] = useState<string>("");
  const [getQuoteById, setGetQuoteById] = useState<string>("");
  const [viewOpenModalFactory, setViewOpenModalFactory] = useState(false);
  const [newActiveControlView, setNewActiveControlView] = useState("");
  const [newActiveTask, setNewActiveTask] = useState("");

  const dispatch = useDispatch();

  const history = useHistory();

  const { id }: any = useParams();

  const ProjectData = useSelector((state: RootState) => state.factorySlice);
  const { jobsData, loading, jobsDataId, QuotesId, QuoteVersion } = useSelector(
    (state: RootState) => state.getJobsTypeSlice
  );

  const { QuoteData } = useSelector(
    (state: RootState) => state.getJobsTypeSlice
  );

  const [quoteAllState, setQuoteAllState] = useState(QuoteData.data);

  const getQuotes = () => {
    dispatch(getAllQuotes());
    // dispatch(getQuotesId(idQuotes));
  };

  const versionQuote = QuotesId?.quote_versions?.map((e: any) => e._id);
  const idVersion = versionQuote && versionQuote[versionQuote?.length - 1];

  const toast = useToast();

  useEffect(() => {
    if (loading || props.idProject) {
      getQuotes();
    }
  });

  useEffect(() => {
    if (loading || props.idProject) {
      setQuoteAllState(QuoteData?.data);
    }
  });

  const { id: idParamsProject }: any = useParams();

  const getJobsDataId = () => {
    dispatch(getJobsId(idParamsProject));
  };

  const getQuotesDataId = () => {
    dispatch(getQuotesId(getQuoteById));
    dispatch(GetQuoteVersion(idVersion));
  };

  const getQuotesDataVersion = () => {
    dispatch(GetQuoteVersion(idVersion));
  };

  useEffect(() => {
    getQuotesDataVersion();
  }, [idVersion]);

  useEffect(() => {
    getQuotesDataId();
  }, [getQuoteById, GetQuoteVersion]);

  useEffect(() => {
    if (loading) {
      getJobsDataId();
    }
  });

  const { projectDataId } = ProjectData;

  const selectData = () => {
    [projectDataId && projectDataId].map((e: any) => {
      if (e?.client) {
        setClient(e.client.entity);
      }
      if (e?.organization) {
        setOrganization(e.organization.entity);
      }
    });
  };

  useEffect(() => {
    selectData();
  }, [projectDataId]);

  const getDataId = () => {
    dispatch(getProjectId(idParamsProject));
  };

  // const getjobs = () => {
  //   dispatch(getAllJobs());
  // };

  // useEffect(() => {
  //   getjobs();
  // }, []);

  useEffect(() => {
    getDataId();
  }, [props.selectProject, idParamsProject]);

  const tabsContent: JSX.Element[] = [
    <ProjectDetails
      selectJob={props.selectJob}
      jobsData={jobsData}
      setDeleteJobModal={setDeleteJobModal}
      setjobIdDelete={setjobIdDelete}
      loading={loading}
      jobsDataId={jobsDataId}
      selectProject={props.selectProject}
      projectDataId={projectDataId}
      idParamsProject={idParamsProject}
    />,

    <Quotes
      QuoteData={QuoteData}
      view={view}
      setView={setView}
      preview={preview}
      setPreView={setPreView}
      deleteQuoteModal={deleteQuoteModal}
      setDeleteQuoteModal={setDeleteQuoteModal}
      setIdQuoteDelete={setIdQuoteDelete}
      getDataQuotes={props.getDataQuotes}
      setGetQuoteById={setGetQuoteById}
      jobsDataId={jobsDataId}
      loading={loading}
      quoteAllState={quoteAllState}
      setQuoteAllState={setQuoteAllState}
    />,
    <Roles />,
    <Suppliers />,
  ];

  // DELETE PROJECT

  const handleConfirmDelete = () => {
    factoryService
      .DeleteProject(id)
      .then((res) => {
        console.log("res from createProject ", res);

        toast.success(
          // res ||
          "The project has been successfully deleted."
        );
        setdeleteProjectModal(false);
        history.push("/factory");
        dispatch(getAllProject());
        // props.setActiveControlView(null);

        return;
      })
      .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
        console.log("err from delete Project", err);
        if (err?.data?.message) {
          return toast.error(err?.data?.message);
        }
      });
  };

  // DELETE JOB

  const handleJobConfirmDelete = () => {
    factoryService
      .DeleteJobs(jodIdDelete)
      .then((res) => {
        console.log("res from createJOB ", res);

        toast.success(
          // res ||
          "The job has been successfully deleted."
        );
        setDeleteJobModal(false);
        getJobsDataId();

        return;
      })
      .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
        console.log("err from delete job", err);
        if (err?.data?.message) {
          return toast.error(err?.data?.message);
        }
      });
  };

  // DELETE QUOTE

  const handleQuoteConfirmDelete = () => {
    factoryService
      .DeleteQuotes(idQuoteDelete)
      .then((res) => {
        console.log("res from createQUOTE ", res);

        toast.success(
          // res ||
          "The quote has been successfully deleted."
        );
        setDeleteQuoteModal(false);
        getQuotes();

        return;
      })
      .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
        console.log("err from delete quote", err);
        if (err?.data?.message) {
          return toast.error(err?.data?.message);
        }
      });
  };

  const onCancelModalClose = (value?: boolean) => {
    if (deleteProjectModal) {
      setdeleteProjectModal(false);
    } else if (deleteJobModal) {
      setDeleteJobModal(false);
    } else if (deleteQuoteModal) {
      setDeleteQuoteModal(false);
    }
  };

  const activeModalJob = () => {
    // props.setSelectProject(props.selectProject);
    // history.replace(`/factory/create/job/${id}`);
    setViewOpenModalFactory(true);
  };

  const activeModaltask = () => {
    setViewOpenModalFactory(true);
    setNewActiveControlView("task");
    setNewActiveTask("task");
  };

  // const handleChange = () => {
  //   props.setOpenModal(true);
  //   props.setActiveTask("task");
  //   props.setActiveControlView(null);
  // };

  const handleCloseModal = () => {
    props.setOpenModal(false);
    setViewOpenModalFactory(false);
  };
  const newJob = () => {
    history.replace(`/factory/create/job/${id}`);
    // props.setActiveControlView("job");
    // props.setSelectJob(props.selectProject._id);
  };

  // const editProject = () => {
  //   props.setActiveControlView("job");
  //   props.setSelectJob(props.selectProject._id);
  // };

  const messageComming = (message: any) => {
    toast.info(message + " is coming soon!");
  };

  return (
    <div className="h-100 overflow-hidden overflow-x-none custom-scrollbar trans-all ">
      <ModalFactoryPreviewQuote
        view={view}
        setView={setView}
        toggle={view}
        preview={preview}
        setPreView={setPreView}
        QuoteData={QuoteData}
        QuotesId={QuotesId}
        QuoteVersion={QuoteVersion}
      />

      <FactoryModal
        toggle={setViewOpenModalFactory}
        view={viewOpenModalFactory}
        setView={setViewOpenModalFactory}
        activeControlView={newActiveControlView}
        setActiveControlView={setNewActiveControlView}
        selectJob={props.selectJob}
        setSelectJob={props.setSelectJob}
        activeTask={newActiveTask}
        setActiveTask={setNewActiveTask}
        selectProject={projectDataId}
        setSelectProject={props.setSelectProject}
        closeModalJob={props.closeModalJob}
        setCloseModalJob={props.setCloseModalJob}
      />

      {deleteProjectModal || deleteJobModal || deleteQuoteModal ? (
        <ConfirmModal
          title="Are you sure?"
          description={
            deleteProjectModal
              ? "This Project will be discarted"
              : deleteJobModal
              ? "This job will be discarted"
              : deleteQuoteModal
              ? "This Quote will be discarted"
              : ""
          }
          onClose={onCancelModalClose}
          buttons={
            <>
              <button
                className="btn btn-danger text-white col-auto"
                onClick={
                  deleteProjectModal
                    ? handleConfirmDelete
                    : deleteJobModal
                    ? handleJobConfirmDelete
                    : deleteQuoteModal
                    ? handleQuoteConfirmDelete
                    : undefined
                }
              >
                DELETE
              </button>

              <button
                className="btn btn-success text-white col-auto"
                onClick={() => {
                  onCancelModalClose();
                }}
              >
                CANCEL
              </button>
            </>
          }
        />
      ) : (
        []
      )}

      <div className="d-flex flex-column card px-4 py-2">
        {/* Header */}
        <header className="d-flex justify-content-between align-items-start mt-3 ">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <img
                className="avatar-profile"
                src={process.env.REACT_APP_IMAGES_AWS + client.logo}
                alt=""
              />

              <div className="ms-3">
                <p className="m-0 text-black typo-body contact-title" style={{fontWeight: 'bold'}}>
                  {client.first_name + tab + client.last_name}
                </p>
                <p className="m-0 text-black typo-body contact-subtitle">
                  {organization.name}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div
              onClick={() => messageComming("Share project")}
              style={{ opacity: 0.5 }}
            >
              <IconButton
                color="primary"
                icon="bi bi-share-fill"
                tooltip="Share project"
                outline
                style={{ height: "30px", width: "32.5px" }}
              />
            </div>
            <Link to={`/factory/edit/project/${idParamsProject}`}>
              <IconButton
                color="primary"
                icon="bi bi-pencil-fill"
                tooltip="Edit project"
                outline
                style={{ height: "30px", width: "32.5px" }}
              />
            </Link>

            <IconButton
              color="danger"
              // icon="bi bi-trash2"
              tooltip="Delete project"
              outline
              custom={TrashIcon}
              style={{ height: "30px", width: "32.5px" }}
              customStyles={{
                height: "15px",
                width: "15px",
                color: " #0000",
              }}
              onClick={() => setdeleteProjectModal(true)}
            />
          </div>
        </header>

        <div className="row">
          <div className="col offset-1 d-flex align-items-center mt-2">
            <button
              className="btn btn-primary mx-1 typo-body text-white col-lg-2"
              onClick={newJob}
              style={{
                width:'130px'
              }}
            >
              Create new job
            </button>

            <button
              className="btn btn-primary mx-1 typo-body text-white col-lg-3"
              onClick={activeModalJob}
              style={{
                width:'160px'
              }}
            >
              Create job quote
            </button>

            <button
              className="btn btn-primary mx-1 typo-body text-white col-lg-2"
              // disabled
              onClick={activeModaltask}
              style={{
                width:'115px'
              }}
            >
              Create task
            </button>
          </div>
        </div>

        {/* Body */}

        <ul className="nav nav-tabs mt-3">
          {tabs.map((item, index) => {
            let check = tabsDisbled.find((tab) => {
              if (item == tab) {
                return true;
              }
              return false;
            });

            return (
              <li
                key={index}
                className="nav-item"
                onClick={
                  check
                    ? () => messageComming(item)
                    : setCurrentTab.bind(null, index)
                }
                style={check ? { opacity: 0.5 } : {}}
              >
                <p
                  className={`nav-link typo-body user-select-none ${
                    currentTab === index ? "active" : ""
                  }`}
                  aria-current="page"
                  style={{
                    color: '#344469' ,
                    fontSize: '13px'
                  }}
                >
                  {item}
                </p>
              </li>
            );
          })}
        </ul>

        {/* Tabs */}
      </div>

      <div className="card h-75 overflow-y">{tabsContent[currentTab]}</div>
    </div>
  );
};

export default FactoryView;
