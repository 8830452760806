import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import toolbar from "./toolbar";
import "quill/dist/quill.snow.css";
import "./index.scss";

interface Props {
  onSubmitControlForm: any;
  setOnSubmitControlForm: any;
  jobDataById: any;
  idJob: any;
}

const TextEdit: React.FC<Props> = (props) => {
  const { quill, quillRef }: any = useQuill({
    modules: {
      toolbar: toolbar,
    },
  });

  useEffect(() => {
    if (!props.idJob) {
      if (quill) {
        quill.on("text-change", (delta: any, oldDelta: any, source: any) => {
          props.setOnSubmitControlForm({
            ...props.onSubmitControlForm,
            client_brief: JSON.stringify(quill.getContents()),
          });
        });
      }
    } else {
      setTimeout(() => {
        if (quill) {
          quill?.on("text-change", (delta: any, oldDelta: any, source: any) => {
            props.setOnSubmitControlForm({
              ...props.onSubmitControlForm,
              client_brief: JSON.stringify(quill.getContents()),
            });
          });
        }
      }, 650);
    }
  }, [quill]);

  useEffect(() => {
    if (quill && props.jobDataById?.client_brief !== "" && props.idJob) {
      setTimeout(() => {
        quill.setContents(JSON?.parse(props.jobDataById?.client_brief));
      }, 500);
    }
  }, [quill, props.jobDataById, props.idJob]);
  return (
    <div className="editor">
      <div className="text-black" ref={quillRef}></div>
    </div>
  );
};

export default TextEdit;
