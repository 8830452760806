import { Datepicker } from '@mobiscroll/react';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import InputDate from '../UI/forms/InputDate';
import InputFloatingLabel from '../UI/forms/InputFloatingLabel';
import TextArea from '../UI/forms/TextArea';


interface Props {
    view: boolean;
    setView: any
    toggle: any
    data?: any
    setData?: any
    sendData?: any;
    id?: any
}

const AddMaintenanceModal: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const [name, setName] = useState<any>('')
    const [desc, setDesc] = useState<any>('')
    const [from, setFrom] = useState<any>('')
    const [to, setTo] = useState<any>('')

    const [date, setDate] = useState<any>([from, to])

    const [open, setOpen] = useState<any>(null)





    const setComment = () => {
        props.sendData({
            name: name,
            comment: desc,
            dates:
            {
                from: from,
                to: to
            }
        })
        props.setView(false)
    }


    return (
        <Modal isOpen={props.view} toggle={() => props.toggle} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className="font-weight-bold text-secondary typo-display">
                            Add maintenance date
                        </h2>
                    </div>
                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            value={name}
                            onChange={(value) => { setName(value) }}
                            placeHolder="Name"
                            className='text-primary'
                        />
                        <TextArea
                            placeHolder='Maintenance detail'
                            onChange={(value) => { setDesc(value) }}
                            value={desc}
                            className='typo-body-primary-important'
                        />
                        <p className='text-primary m-1' style={{ textAlign: 'left' }}>Date</p>
                        <InputDate
                            select={'range'}
                            //className='form-control mb-4'
                            onChange={(e: any) => {
                                setFrom(e?.value[0])
                                setTo(e?.value[1])
                                setDate(e.value)
                            }}
                            value={date}
                            placeholder='Select an range date'
                        />
                    </div>
                    <div className="col-12 mt-4">
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-danger text-white col-auto"
                                onClick={() => props.setView(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-success  text-white col-auto"
                                onClick={() => setComment()}
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AddMaintenanceModal;
