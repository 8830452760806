import { createRef, RefObject } from 'react';
import ItemModel from '../models/ItemModel';


type contactSectionType = {
  [key: string]: { items: any[]; ref: RefObject<HTMLDivElement> };
};

export const getSections = (contacts: any[], type: string): contactSectionType => {
  const ordered: any = contacts.length ? contacts.slice().sort((a, b) => {
    return a?.[type]?.toUpperCase()?.localeCompare(b?.[type]?.toUpperCase());
  }) : []

  const data: contactSectionType = {};
  const abc: any = ['A', 'B', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'
    , 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']



  for (let contact of ordered) {

    let letter = contact.name && contact?.name[0]?.toUpperCase();
    
    if (data[letter] === undefined) {
      data[letter] = {
        items: [],
        ref: createRef<HTMLDivElement>(),
      };
    }

    data[letter].items.push(contact);

  }



  return data;
};
