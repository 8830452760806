import api from '../axios'
import axios from 'axios';

import { R } from 'src/models';
import LocalStorageService from '../localStorage';
import { BRecoverPassword } from 'src/models/Bodies';
import TokenService, { TokenData } from '../TokenService';

const CheckSession = async () => {
  const token = LocalStorageService.getSessionUser()
  if (token) {
    const response: any = await api.get('users/me', { headers: { 'Authorization': 'Bearer ' + token } })
    if (!response) {
      return false
    }
    return { token }
  } else {
    return false
  }
}

const Login = async (UserData: any) => {
  const { data } = await axios.post<R<TokenData>>('auth/login', {
    email: UserData.email,
    password: UserData.password
  }, { authorize: false });
  
  if (data.data.access_token) {
    TokenService.createUserSession(data.data);
  }

  return data.data;
}

const Logout = () => {
  TokenService.removeUserSession();
}

const RefreshToken = async (token: string) => {
  const { data } = await axios.post<R<TokenData>>('auth/tokens/refresh',
    { refresh_token: token },
    { authorize: false }
  );

  return data;
}

const RecoverPasswordRequest = async (email: string) => {
  const { data } = await axios.post<R<string>>('auth/recover-password-request',
    { email: email },
    { authorize: false }
  );

  return data;
}

const RecoverPassword = async (RecoverData: BRecoverPassword) => {
  const { data } = await axios.put<R<string>>('auth/recover-password', {
    token: RecoverData.token,
    email: RecoverData.email,
    password: RecoverData.password,
  }, { authorize: false });

  return data;
}

//// TWO FACTOR 
const GetDetails = (password: string) => {
  api.post('oauth2/enable-details', { password: password })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
}

const Enable = (code: number) => {
  api.post('oauth2/enable-details', { code: code })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
}

const Disable = (TwoFactorData: any) => {
  api.delete('oauth2/disable', {
    params: {
      password: TwoFactorData.password,
      code: TwoFactorData.code
    }
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
}





const authService = {
  Login,
  CheckSession,
  Logout,
  RefreshToken,
  RecoverPasswordRequest,
  RecoverPassword,
  GetDetails,
  Enable,
  Disable
}

export default authService