import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AxiosRequestConfig } from "axios";

import { useRequest } from "./useRequest";
import { RootState } from "src/store/store";
import { IContact } from "src/models/ContactModel";

export default function useContacts() {
  const filters = useSelector((state: RootState) => state.blackbookFiltersSlice);

  const config: AxiosRequestConfig = useMemo(() => {
    const conf: AxiosRequestConfig = {
      url: filters.archivedOnly ? 'blackbook/contacts/archived' : 'blackbook/contacts/all',
      params: {}
    };

    if (filters.selectedCategory && filters.selectedCategory._id !== 'all') {
      conf.params.category = filters.selectedCategory._id
    }

    if (filters.search) {
      conf.params.search = filters.search;
    }

    return conf;
  }, [filters]);

  const { ...res } = useRequest<IContact[]>(config)

  return res
}