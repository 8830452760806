import ContactModel from '../../../models/ContactModel';

interface Props {
  onSelect?: (id: string) => void;
  contact: ContactModel;
}

const ContactItem: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid border-top p-3 py-2 contact-item">
      <div className="row align-items-center">
        {props.onSelect && (
          <div className="col-auto p-0 ps-2">
            <input className="form-check-input" type="checkbox" />
          </div>
        )}

        <div
          className={`col d-flex p-0 text-truncate ${
            props.onSelect ? 'ms-3' : 'ms-2'
          }`}
        >
          <img src={props.contact.image} alt="" className="avatar2" />

          <div className="ms-2">
            <p className="m-0 text-black typo-body contact-title">
              {props.contact.title}
            </p>
            <p className="m-0 text-black typo-body contact-subtitle">
              {props.contact.title}
            </p>
          </div>
        </div>

        {(props.contact.count1 || props.contact.count2) && (
          <div className="col-auto p-0 pe-1">
            {props.contact.count1 && (
              <p className="m-0 contact-trailing typo-body bg-primary my-1">
                {props.contact.count1}
              </p>
            )}
            {props.contact.count2 && (
              <p className="m-0 contact-trailing typo-body bg-success my-1">
                {props.contact.count2}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactItem;
