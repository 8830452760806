import EmptyCircle from "./EmptyCircle";

interface Props {
  title: string;
  subtitle?: string;
  leadingImage: string;
}

const ListTile: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid p-3 py-2">
      <div className="row align-items-center">
        <div className={`col d-flex p-0 text-truncate`}>

          {props.leadingImage && <img src={props.leadingImage} alt="" className="avatar2" />}

          {
            !props.leadingImage &&
            <div style={{ width: '40px', height: '40px', marginLeft: '20px' }} >
              <EmptyCircle title={props.title} small />
            </div>
          }

          <div className="ms-2 d-flex flex-column justify-content-center">
            <p className="m-0 text-black typo-body contact-title">
              {props.title}
            </p>

            {props.subtitle && (
              <p className="m-0 text-black typo-body contact-subtitle">
                {props.subtitle}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListTile;
