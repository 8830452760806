function EmployeeStatus() {
  return (
   <div className="card flex-grow-1">
    <header className="card-header bg-primary">
      Employee Status
    </header>

    <main className="card-body d-flex gap-2 flex-column">
      
      {Array.from({ length: 4 }).map((_, i) => (
        <div className="d-flex align-items-center gap-2 gap-xxl-4">
          <div className="flex-grow-1 d-flex gap-1">
            <span className="align-items-center d-flex fs-6 justify-content-center avatar2 text-primary bg-contrast" style={{ width: 25, height: 25 }}>
              B
            </span>
            <span style={{ fontWeight: 400 }} className="text-start text-primary d-inline-block">David Hamson</span>
          </div>

          <div className="d-flex flex-column">
            <small style={{ fontWeight: 600 }} className="text-center text-gray">Days</small>
            <span style={{ fontWeight: 400 }} className="text-center text-primary">30</span>
          </div>

          <div className="d-flex flex-column">
            <small style={{ fontWeight: 600 }} className="text-center text-gray">Hours</small>
            <span style={{ fontWeight: 400 }} className="text-center text-primary">45:24</span>
          </div>

          <div className="d-flex flex-column">
            <small style={{ fontWeight: 600 }} className="text-center text-gray">Tasks</small>
            <span style={{ fontWeight: 400 }} className="text-center text-primary">26</span>
          </div>
        </div>
      ))}

    </main>
   </div>
 ) 
}

export default EmployeeStatus;