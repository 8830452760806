const dataPrueba = {
  project: [
    {
      id: 0,
      name: "youtube",
      organization: "mediaGroup",
      client: "pedro",
      status: "Approved",
      projectNumber: "0000-01",
      jobs: "0001",
      starDate: "01/01/2022",
      dueDate: "21/07/2022",
    },

    {
      id: 1,
      name: "facebook",
      organization: "meta",
      client: "carlos",
      status: "Lost",
      projectNumber: "0000-02",
      jobs: "0002",
      starDate: "02/01/2022",
      dueDate: "22/07/2022",
    },
    {
      id: 2,
      name: "instagram",
      organization: "facebook",
      client: "mark",
      status: "Complete",
      projectNumber: "0000-03",
      jobs: "0003",
      starDate: "03/01/2022",
      dueDate: "23/07/2022",
    },
    {
      id: 3,
      name: "twitter",
      organization: "twitter",
      client: "carlos",
      status: "Approved",
      projectNumber: "0000-04",
      jobs: "0004",
      starDate: "04/01/2022",
      dueDate: "24/07/2022",
    },
    //   {
    //     id: 4,
    //     name: "MercadoLibre",
    //     organization: "MB",
    //     client: "carlos",
    //     status: "Active",
    //     projectNumber: "0000-05",
    //     jobs: "0005",
    //     starDate: "04/01/2022",
    //     dueDate: "24/07/2022",
    //   },
    //   {
    //     id: 5,
    //     name: "Amazon",
    //     organization: "Amazon",
    //     client: "carlos",
    //     status: "Complete",
    //     projectNumber: "0000-06",
    //     jobs: "0006",
    //     starDate: "04/01/2022",
    //     dueDate: "24/07/2022",
    //   },
    //   {
    //     id: 6,
    //     name: "Google",
    //     organization: "alphabet",
    //     client: "carlos",
    //     status: "On hold",
    //     projectNumber: "0000-07",
    //     jobs: "0007",
    //     starDate: "04/01/2022",
    //     dueDate: "24/07/2022",
    //   },
    //   {
    //     id: 0,
    //     name: "youtube",
    //     organization: "mediaGroup",
    //     client: "pedro",
    //     status: "Approved",
    //     projectNumber: "0000-01",
    //     jobs: "0001",
    //     starDate: "01/01/2022",
    //     dueDate: "21/07/2022",
    //   },

    //   {
    //     id: 7,
    //     name: "facebook",
    //     organization: "meta",
    //     client: "carlos",
    //     status: "Lost",
    //     projectNumber: "0000-02",
    //     jobs: "0002",
    //     starDate: "02/01/2022",
    //     dueDate: "22/07/2022",
    //   },
    //   {
    //     id: 8,
    //     name: "instagram",
    //     organization: "facebook",
    //     client: "mark",
    //     status: "Complete",
    //     projectNumber: "0000-03",
    //     jobs: "0003",
    //     starDate: "03/01/2022",
    //     dueDate: "23/07/2022",
    //   },
    //   {
    //     id: 9,
    //     name: "twitter",
    //     organization: "twitter",
    //     client: "carlos",
    //     status: "Approved",
    //     projectNumber: "0000-04",
    //     jobs: "0004",
    //     starDate: "04/01/2022",
    //     dueDate: "24/07/2022",
    //   },
    //   {
    //     id: 10,
    //     name: "MercadoLibre",
    //     organization: "MB",
    //     client: "carlos",
    //     status: "Active",
    //     projectNumber: "0000-05",
    //     jobs: "0005",
    //     starDate: "04/01/2022",
    //     dueDate: "24/07/2022",
    //   },
    // ],

    // job: [
    //   {
    //     id: 0,
    //     name: "video",
    //     status: "PROPOSAL",
    //   },
    //   {
    //     id: 1,
    //     name: "reel",
    //     status: "APPROVED",
    //   },
    //   {
    //     id: 2,
    //     name: "publicacion",
    //     status: "ACTIVE",
    //   },
    //   {
    //     id: 3,
    //     name: "comentario",
    //     status: "On HOLD",
    //   },
    //   {
    //     id: 4,
    //     name: "vistas",
    //     status: "LOST",
    //   },
    //   {
    //     id: 5,
    //     name: "meGusta",
    //     status: "COMPLETE ",
    //   },
  ],
};

export default dataPrueba;
