import React from "react";
import {
  TreeListTextEditor,
  TreeListNumericEditor,
  TreeListDateEditor,
  TreeListBooleanEditor,
} from "@progress/kendo-react-treelist";

interface TdWrapper {
  className: any;
  record: any;
}

export const TdWrapper: React.FC<TdWrapper> = (props) => {
  return (
    <td className={"TdWrapper"} style={{ zIndex: "1" }}>
      {props.children}
    </td>
  );
};

const formatNumber = (number: number) => {
  let n: string = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);

  return n;
};

export default (quoteForm: any, setQuoteForm: any, filterHeader: any) => {
  return [
    {
      field: "name",
      title: <span className="ps-2">Item</span>,
      width: "380px",
      editCell: TreeListTextEditor,
      expandable: true,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        return (
          <>
            <TdWrapper className="" record={""}>
              <>
                {dataItem.name && (
                  <div
                    className="container-column "
                    style={{
                      marginBottom: "-12px",
                      marginTop: "-12px",
                      zIndex: "1",
                    }}
                  >
                    <div className="row">
                      <div
                        className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                        style={{ height: "45px" }}
                      >
                        <p className="me-auto ps-4 pt-3">{dataItem.name}</p>
                      </div>
                    </div>
                  </div>
                )}
                {!dataItem.name && (
                  <div className="d-flex ms-3" style={{ zIndex: "1" }}>
                    <div className="d-flex">
                      <div className="d-flex flex-column mt-2">
                        <span
                          className="pb-2"
                          style={{ marginBottom: "-10px" }}
                        >
                          {dataItem.items?.name}
                        </span>
                        <small className="text-gray mb-2">
                          {dataItem.items?.category?.name}
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </TdWrapper>
          </>
        );
      },
    },
    {
      field: "quantity",
      title: <span>QTY</span>,
      width: "120px",
      editCell: TreeListTextEditor,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        return (
          <TdWrapper className="" record={""}>
            <>
              {dataItem.name && (
                <div
                  className="container-column "
                  style={{
                    marginBottom: "-12px",
                    marginTop: "-12px",
                    zIndex: "1",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                      style={{ height: "45px" }}
                    ></div>
                  </div>
                </div>
              )}
              {!dataItem.name && (
                <div className="d-flex ms-3" style={{ zIndex: "1" }}>
                  <div className="d-flex">
                    <div className="d-flex flex-column mt-2">
                      <span className="pb-2" style={{ marginBottom: "-10px" }}>
                        {dataItem.quantity}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          </TdWrapper>
        );
      },
    },
    {
      field: "rate",
      title: <span>RATE</span>,
      format: "{0}",
      width: "120px",
      editCell: TreeListDateEditor,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        return (
          <TdWrapper className="" record={""}>
            <>
              {dataItem.name && (
                <div
                  className="container-column "
                  style={{
                    marginBottom: "-12px",
                    marginTop: "-12px",
                    zIndex: "1",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                      style={{ height: "45px" }}
                    ></div>
                  </div>
                </div>
              )}
              {!dataItem.name && (
                <div className="d-flex ms-3" style={{ zIndex: "1" }}>
                  <div className="d-flex">
                    <div className="d-flex flex-column mt-2">
                      <span className="pb-2" style={{ marginBottom: "-10px" }}>
                        {dataItem.rate}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          </TdWrapper>
        );
      },
    },
    {
      field: "units_number",
      title: <span>UNITS</span>,
      width: "120px",
      editCell: TreeListNumericEditor,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        return (
          <TdWrapper className="" record={""}>
            <>
              {dataItem.name && (
                <div
                  className="container-column "
                  style={{
                    marginBottom: "-12px",
                    marginTop: "-12px",
                    zIndex: "1",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                      style={{ height: "45px" }}
                    ></div>
                  </div>
                </div>
              )}
              {!dataItem.name && (
                <div className="d-flex ms-3" style={{ zIndex: "1" }}>
                  <div className="d-flex">
                    <div className="d-flex flex-column mt-2">
                      <span
                        className="pb-2 d-flex flex-column"
                        style={{ marginBottom: "-10px" }}
                      >
                        <div>{dataItem.units_number}</div>
                        <div>{dataItem.units_type}</div>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          </TdWrapper>
        );
      },
    },
    {
      field: "fullTime",
      title: "TOTAL",
      width: "100px",
      editCell: TreeListBooleanEditor,
      cell: (props: any) => {
        const { dataItem, expanded, onExpandChange, level } = props;

        return (
          <TdWrapper className="" record={""}>
            <>
              {dataItem.name && (
                <div
                  className="container-column "
                  style={{
                    marginBottom: "-12px",
                    marginTop: "-12px",
                    zIndex: "1",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-md-12  bg-light-blue p-2  d-flex justify-content-start align-items-center"
                      style={{ height: "45px" }}
                    >
                      <p className="me-auto ps-4 pt-3">
                        {formatNumber(dataItem.total)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {!dataItem.name && (
                <div className="d-flex ms-3" style={{ zIndex: "1" }}>
                  <div className="d-flex">
                    <div className="d-flex flex-column mt-2">
                      <span
                        className="pb-2"
                        style={{ marginBottom: "-10px" }}
                      ></span>
                      <small className="text-gray mb-2"></small>
                    </div>
                  </div>
                </div>
              )}
            </>
          </TdWrapper>
        );
      },
    },
  ];
};
