import clsx from "clsx";

interface Props {
  title: string;
  count?: number;
  texts: {
    isFile?: boolean;
    fileHref?: string;
    text: React.ReactNode;
    isUnderline?: boolean;
  }[];
  style?:any
}

const InfoDisplay: React.FC<Props> = (props) => {
  return (
    <div className="pb-3">
      <div className="d-flex align-items-center mb-1">
        <p className="m-0 typo-body text-muted text-uppercase" >{props.title}</p>
        {props.count !== undefined && (
          <p style={{color: '#8898AA'}}
           className="m-0 typo-body ms-2 bg-primary text-white px-2 rounded">{props.count}</p>
        )}
      </div>

      {props.texts.map((item, index) => {
        const Tag = (!!item.fileHref) ? "a" : "p";

        return (
          <Tag
            key={index}
            target="_blank"
            href={item.fileHref}
            rel="noopener noreferrer"
            style={{ color: '#344469' , fontSize: '13.5px' }}
            download={item.isFile ? item.text : undefined}
            className={clsx("m-0 typo-body d-block", {
              'text-decoration-underline': item.isUnderline,
              'text-decoration-underline cursor-pointer': item.isFile && item.fileHref,
            })}
          >
            {item.text}
          </Tag>
        );
      })}
      {
        !props.texts && ('---')
      }
      {props.children}
    </div>
  );
};

export default InfoDisplay;
