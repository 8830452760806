import { Datepicker } from "@mobiscroll/react";

function Calendar() {
  return (
    <div className="card px-3 flex-grow-1">

        <Datepicker
            touchUi={true}
            display="inline"
            showOuterDays={false}
            dateFormat="YYYY-MM-DD"
            controls={['calendar']}
        />

    </div>
  )
}

export default Calendar;