import { FormikErrors, useFormik } from 'formik';
import { useRequest } from "src/hooks/useRequest";
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { PaymentInfo } from './PaymentInfo';
import { RootState } from 'src/store/store';
import { BookableSkill } from './BookableSkill';
import { TabsContent } from '../../../AddContact';
import { BCreateSkillset } from 'src/models/Bodies';
import { ISkillset } from 'src/models/SkillsetModel';
import { useFieldArray } from 'src/hooks/useFieldArray';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import { createContactForm } from 'src/store/blackbook/createContactSlice';
import InputFloatingLabel from '../../../../../UI/forms/InputFloatingLabel';
import { FreelanceData, FreelanceDetailsValidation } from '../PersonalInformation/validation';
import IconButton from 'src/components/UI/buttons/IconButton';

type PartiallyRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;
export type FormData = PartiallyRequired<Partial<FreelanceData>, 'bookable_skills' | 'credits_name' | 'payment_info' | 'languages'>

const FreelanceDetails = forwardRef((props: { for: 'teammember' | 'freelance' }, ref: TabsContent['ref']) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);

  const [showUnion, setShowUnion] = useState(false);

  const formik = useFormik<FormData>({
    initialValues: {
      bookable_skills: [{ skillset: '', rates: {} as BCreateSkillset['rates'] }],
      payment_info: [],
      credits_name: '',
      ...(data.data || {}),
    },
    validationSchema: FreelanceDetailsValidation,
    onSubmit: () => {},
  })

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      try {
        const errors = await formik.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }

        return true;
      } catch {
        return false;
      }
    }
  }));

  useEffect(() => {
    if (data.initialValues !== undefined && data.appliedInitialValues === false) {
      formik.setValues(data.initialValues || {});
      /* @ts-ignore */
      dispatch(createContactForm.setField('appliedInitialValues', true, ''));
    }

    if (data.data?.union_affiliation || data.initialValues?.union_affiliation) {
      setShowUnion(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  const { data: skillsets, error: skillsetsError } = useRequest<ISkillset[]>(formik.values.bookable_skills && formik.values.bookable_skills.length > 0 && formik.values.bookable_skills.map(s => typeof s === 'string' ? s : undefined).filter(a => !!a).length > 0 ? {
    url: 'skillsets/blackbook/many',
    params: {
      ids: formik.values.bookable_skills.map(skill => skill).filter(skill => typeof skill === 'string').join(','),
    }
  } : undefined);
  
  useEffect(() => {
    if (!skillsets && !skillsetsError) return
    if (skillsetsError || !skillsets) return

    const _skillsets = skillsets.map(skill => ({
      id: skill._id,
      skillset: skill.skillset,
      rates: skill.rate_card.rates,
      created_with: skill.created_with,
    } as BCreateSkillset))

    formik.setFieldValue('bookable_skills', _skillsets);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsets, skillsetsError]);

  const handleChange = (fieldName: string, value: any, path = 'data') => {
    formik.setFieldValue(fieldName, value);

    /* @ts-ignore */
    dispatch(createContactForm.setField(fieldName, value, path));
  }

  const bookableSkills = useFieldArray<FormData, BCreateSkillset>(formik, 'bookable_skills', handleChange);
  const languages = useFieldArray<FormData, FormData['languages'][number]>(formik, 'languages', handleChange);
  const paymentInfo = useFieldArray<FormData, FormData['payment_info'][number]>(formik, 'payment_info', handleChange);

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="row">
        <div className="col-6">
          <InputFloatingLabel
            placeHolder="Name in credits"
            value={`${formik.values.credits_name ? formik.values.credits_name : ''}`}
            onChange={(val) => handleChange('credits_name', val)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <p className="text-primary typo-body m-0">
            BOOKABLE SKILLSET AND STANDARD RATES
          </p>

          {(formik.values.bookable_skills as BCreateSkillset[])?.map((skill, index) => (
            <BookableSkill errors={formik.errors.bookable_skills?.[index] as FormikErrors<BCreateSkillset>} value={skill} index={index} key={index} edit={bookableSkills.set} remove={bookableSkills.remove} />
          ))}
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-4">
          <SimpleButton 
            onClick={() => {
              bookableSkills.add({ skillset: '', rates: {} as BCreateSkillset['rates'] });
            }}
          >
            ADD BOOKABLE SKILLSET & RATE
          </SimpleButton>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          {formik.values.payment_info?.map((payment, index) => (
            <PaymentInfo
              index={index}
              value={payment}
              edit={paymentInfo.set}
              remove={paymentInfo.remove}
              errors={formik.errors.payment_info?.[index] as FormikErrors<FormData['payment_info'][number]>}
            />
          ))}

          <SimpleButton
            onClick={() => {
              paymentInfo.add({ method: 'paypal', account: '', account_owner: '', type: '', bank: '' });
            }}
          >
            ADD PAYMENT DETAILS
          </SimpleButton>
        </div>

        <div className="col-6">
          {(formik.values?.languages || [])?.map((language, index) => (
            <div className="row g-2">
              <div className="col-10">
                <InputFloatingLabel
                  value={language}
                  placeHolder="Language"
                  onChange={(val) => languages.set(index, val)}
                />
              </div>
              <div className="col-2">
                <IconButton
                  outline
                  color="danger"
                  icon="bi bi-x"
                  tooltip="Remove language"
                  onClick={() => languages.remove(index)}
                />
              </div>
            </div>
          ))}

          <SimpleButton
            onClick={() => languages.add('')}
          >
            ADD LANGUAGE
          </SimpleButton>
          
          {props.for === 'freelance' && (
            showUnion ? (
              <div className="border-top py-2">
                <InputFloatingLabel
                  placeHolder="Union Affiliation"
                  value={formik.values.union_affiliation}
                  onChange={(val) => handleChange('union_affiliation', val)}
                />
              </div>
            ) : (
              <SimpleButton
                onClick={() => setShowUnion(true)}
              >
                ADD UNION AFFILIATION
              </SimpleButton>
            )
          )}
        </div>
      </div>
    </div>
  );
});

export default FreelanceDetails;
