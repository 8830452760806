import moment from "moment";
import { useMemo } from "react";

import { calcAGE, capitalizeText } from "src/util";
import { SocialAndComm } from "../SocialAndComm";
import InfoDisplay from "src/components/UI/InfoDisplay";
import { ContactType, IContact } from "src/models/ContactModel";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";

const TMContactData: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {
  const contactSinceMoment = useMemo(() => moment(contact.contact_since), [contact.contact_since]);
  /* @ts-ignore we can't use the hook after checking the contact type*/
  const dobMoment = useMemo(() => moment(contact.entity.date_of_birth), [contact.entity.date_of_birth]);

  if (contact.type !== ContactType.TEAM_MEMBER) return null;

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3 ">
        <InfoDisplay
          title="general note"
          texts={[
            {
              text: contact.note || 'No note.',
            },
          ]}
        />
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay title="nickname" texts={[{ text: contact.entity.nickname || 'No nickname.' }]} />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="work email"
            texts={[{ text: contact.entity.work_email }]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay title="skype" texts={[{ text: contact.entity?.communication?.skype || 'No skype.' }]} />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="personal email"
            texts={[{ text: contact.entity.personal_email }]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="phone numbers"
            texts={[{ text: (contact.entity.phone_numbers?.length || 0) > 0 ? `${contact.entity.phone_numbers?.map((p) => `(${capitalizeText(p.type)}) ${p.number}`).join(', ')}` : 'No phone numbers.' }]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="contact since"
            texts={[
              { text: contactSinceMoment.format('LL') },
              { text: contactSinceMoment.fromNow() }
            ]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="division"
            texts={[
              { text: contact.entity.division },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="position"
            texts={[
              { text: contact.entity.position },
            ]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="date of birth"
            texts={[{ text: contact.entity.date_of_birth ? dobMoment.format('DD/MM/YYYY') : 'Not configured.' }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="languages"
            texts={[{ text: contact.entity.languages?.join(', ') || 'Not configured.' }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="age"
            texts={[{ text: contact.entity.date_of_birth ? calcAGE(contact.entity.date_of_birth) : 'Not configured.' }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="gender"
            texts={[{ text: contact.entity.gender || 'Not configured.' }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="nationality"
            texts={[{ text: contact.entity.nationality || 'Not configured.' }]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="physical address"
            texts={[{ text: contact.entity.address || 'Not configured.' }]}
          />
          
          <InfoDisplay
            title="distance from office"
            texts={[{ text: '2.53 miles' }]}
          />
          
          <InfoDisplay
            title="estimated travel time to office"
            texts={[{ text: '0:32 hours' }]}
          />
        </div>

        <div className="col-6"></div>
      </div>

      <SocialAndComm
        social={contact.entity.social}
        communication={contact.entity.communication}
      />
    </div>
  );
};

export default TMContactData;
