import { forwardRef, Ref } from 'react';
import ContactModel from '../../../models/ContactModel';
import ContactItem from './ContactItem';

interface Props {
  letter: string;
  items: ContactModel[];
}

const ContactSection = forwardRef<HTMLDivElement, Props>(
  (props, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <p
              className="m-0 typo-body ps-3 py-1 text-primary"
              style={{ background: '#f3f3f3' }}
            >
              {props.letter}
            </p>
            <div>
              {props.items.map((item: ContactModel, index: number) => {
                return (
                  <ContactItem
                    key={index}
                    contact={item}
                    onSelect={()=>{}}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ContactSection;
