import { AnySchema, object, string } from 'yup';

import { TeamMemberData as TTeamMemberData } from 'src/models/ContactModel';
import FreelancePersonalValidation from '../Freelance/PersonalInformation/validation';

export type TeamMemberData = TTeamMemberData & {
  contact_since: string;
}

const TMPersonalValidation = FreelancePersonalValidation.omit(['email', 'title', 'union_affiliation']).concat(object<Partial<Record<keyof TeamMemberData, AnySchema>>>({
  work_email: string().email().min(1).max(100).required(),
  personal_email: string().email().min(1).max(100).optional(),

  division: string().min(1).max(150).required(),
  position: string().min(1).max(150).required(),
}));

export default TMPersonalValidation;