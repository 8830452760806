import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BlackbookService } from "src/services/blackbook";

interface data {
  id: any;
}

export const GetAllContacts = createAsyncThunk(
  "factory/getContactAll",
  async () => {
    try {
      const data = await BlackbookService.GetAllContacts();
      return { data: data };
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState = {
  loading: false,
  getContact: [] as any,
};

const getContactAll = createSlice({
  name: "get-contact-All",
  initialState,
  reducers: {
    cleanContactData: (state) => {
      state.getContact = [] as any;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(GetAllContacts.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(GetAllContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.getContact = [...action.payload?.data];
      });
  },
});

export const { cleanContactData } = getContactAll.actions;
export default getContactAll.reducer;
