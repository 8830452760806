const RemoveUserSession = () => {
    localStorage.removeItem('user')
}

const CreateUserSession = (token: string) => {
    localStorage.setItem('user', token)
}

const getSessionUser = () => {
   return localStorage.getItem('user')
}

const LocalStorageService = {
    RemoveUserSession ,
    CreateUserSession ,
    getSessionUser
}

export default LocalStorageService