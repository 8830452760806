import React, { useState } from "react";
import { Modal } from "reactstrap";
import Preview from "./Preview";

interface Props {
  view: boolean;
  setView: any;
  toggle: boolean;
  preview: boolean;
  setPreView: any;
  QuoteData: any;
  QuotesId: any;
  QuoteVersion: any;
}
const ModalFactoryPreviewQuote: React.FC<Props> = (props) => {
  const closeModal = () => {
    props.setView(false);
    // props.setPreView(false);
  };

  return (
    <Modal
      isOpen={props.view}
      toggle={() => props.toggle}
      centered
      className=""
      style={
        props.preview
          ? { width: "1050px", maxWidth: "1050px" }
          : { width: "none", maxWidth: "none" }
      }
    >
      <div
        className={
          props.preview
            ? "text-center d-flex flex-column custom-scrollbar overflow-hidden "
            : "text-center d-flex flex-column custom-scrollbar overflow-hidden"
        }
        style={props.preview ? { height: "720px" } : { height: "720px" }}
      >
        <div
          onClick={closeModal}
          className="cursor-pointer p-2"
          style={{ width: "30px" }}
        >
          <i
            className="bi bi-x-lg  "
            style={{
              fontSize: "18px",
              marginRight: "300px",
              height: "150px",
              padding: "20px",
            }}
          ></i>
        </div>
        <div className="row">
          <Preview
            QuoteData={props.QuoteData}
            QuotesId={props.QuotesId}
            QuoteVersion={props.QuoteVersion}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalFactoryPreviewQuote;
