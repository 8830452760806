import React from "react";
import IconButton from "../../../UI/buttons/IconButton";
import InfoDisplay from "../../../UI/InfoDisplay";
import moment from "moment";
import EmptyCircle from "../../../../components/UI/forms/EmptyCircle";
import { useHistory, useParams } from "react-router-dom";

interface Props {
  setActiveControlView: any;
  setSelectJob: any;
  projectData: any;
  loading: boolean;
  setSelectProject: any;
}

const TableFatory: React.FC<Props> = (props) => {
  const history = useHistory();

  const handleChange = (item: any) => {
    props.setActiveControlView("viewProject");
    props.setSelectJob(item);
    props.setSelectProject(item);
    history.push(`/factory/project/detail/${item._id}`);
  };

  const selectedColor = (item: any) => {
    let select;
    if (item.status === "Approved") {
      select = "approved";
    } else if (item.status === "Proposal") {
      select = "proposal";
    } else if (item.status === "Active") {
      select = "active";
    } else if (item.status === "On Hold") {
      select = "onHold";
    } else if (item.status === "Lost") {
      select = "lost";
    } else if (item.status === "Complete") {
      select = "complete";
    }

    return select;
  };

  // console.log("projectData", props.projectData);

  return (
    <div className="container-fluid p-0 m-0 ">
      {props.loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "88vw", height: "40vh" }}
        >
          <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
          <span
            className="text-black
                 ms-3"
          >
            Loading...
          </span>
        </div>
      ) : (
        <div
          className="col-lg-12 mt-4 custom-scrollbar overflow-hidden"
          style={{ overflow: "scroll" }}
        >
          <table className="table table-borderless ">
            <thead className="">
              <tr className="text-primary typo-body">
                <th scope="col" style={{ width: "450px" }} className="ps-4">
                  <p className="ms-5 HeadTableFonts">PROJECT NAME</p>
                </th>
                <th scope="col" style={{ width: "180px" }}>
                  <div className="d-flex flex-column">
                    <span className="HeadTableFonts">PROJECT</span>
                    <span className="HeadTableFonts">NUMBER</span>
                  </div>
                </th>
                <th scope="col" style={{ width: "140px" }}>
                  <p className="HeadTableFonts">JOBS</p>
                </th>
                <th scope="col" style={{ width: "140px" }}>
                  <p className="HeadTableFonts">ORG.</p>
                </th>
                <th scope="col" style={{ width: "140px" }}>
                  <p className="HeadTableFonts">CLIENT</p>
                </th>
                <th scope="col" style={{ width: "140px" }}>
                  <div className="d-flex flex-column">
                    <span className="HeadTableFonts">ACCOUNT</span>
                    <span className="HeadTableFonts">MANAGER</span>
                  </div>
                </th>
                <th scope="col" style={{ width: "180px" }}>
                  START DATE
                </th>
                <th scope="col" style={{ width: "180px" }}>
                  DUE DATE
                </th>
                <th scope="col" style={{ width: "150px" }}>
                  STATUS
                </th>
              </tr>
            </thead>

            <tbody>
              {props.projectData.map((item: any, index: any) => {
                return (
                  <tr
                    className={`${
                      (index / 2) % 1 > 0 ? "table-primary" : ""
                    } text-primary typo-body`}
                    key={index}
                    onClick={() => handleChange(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="d-flex align-items-center ps-3">
                      {item.file_cover.includes("prueba") ? (
                        <EmptyCircle title={item.name} small={true} />
                      ) : (
                        <img
                          className="avatar2"
                          src={
                            process.env.REACT_APP_IMAGES_AWS + item.file_cover
                          }
                          alt="factory"
                        />
                      )}

                      <p className="m-0 ms-3 general-text-table-factory">
                        {item.name}
                      </p>
                    </td>
                    {/* projectNumber */}
                    <td className="align-middle general-text-table-factory ">
                      {item._id}
                    </td>
                    {/* job */}
                    <td className="align-middle general-text-table-factory ">
                      {item.jobs}
                    </td>
                    <td className=" align-items-center">
                      <img
                        className="avatar2"
                        src={
                          process.env.REACT_APP_IMAGES_AWS +
                          item.organization.entity.logo
                        }
                        alt=""
                      />
                    </td>
                    <td className=" align-items-center">
                      <img
                        className="avatar2"
                        src={
                          process.env.REACT_APP_IMAGES_AWS +
                          item.client.entity.logo
                        }
                        alt=""
                      />
                    </td>
                    <td className=" align-items-center">
                      <img
                        className="avatar2"
                        src="https://m.media-amazon.com/images/I/51P84188A4L._AC_SX466_.jpg"
                        alt=""
                      />
                    </td>
                    <td className="align-middle general-text-table-factory ">
                      {moment(item.start_date.slice(0, -1)).format(
                        "MM/DD/YYYY"
                      )}
                    </td>
                    <td className="align-middle general-text-table-factory ">
                      {moment(item.end_date.slice(0, -1)).format("MM/DD/YYYY")}
                    </td>
                    <td className="align-middle general-text-table-factory ">
                      <span className={`${selectedColor(item)} baseBadge`}>
                        {item.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TableFatory;
