import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiError, R } from '../models';

export type GetRequest = AxiosRequestConfig | null | undefined;

interface Return<Data, Error>
  extends Pick<
    SWRResponse<Data, AxiosResponse<Error>>,
    'isValidating' | 'error' | 'mutate'
  > {
  data: Data | undefined;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<Data, AxiosResponse<Error>>,
    'fallbackData'
  > {
  fallbackData?: Data;
}

export function useRequest<Data = unknown, Error = ApiError>(
    request: GetRequest,
    { fallbackData, ...config }: Config<Data, Error> = {},
): Return<Data, Error> {
    const { data: response, error, isValidating, mutate } = useSWR<
      Data,
      AxiosResponse<Error>
    >(
      request ? JSON.stringify(request) : null,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      () => axios.request<R<Data>>(request!).then(({ data }) => data?.data),
      {
          ...config,
          fallbackData,
      },
    );

    return {
      error,
      mutate,
      isValidating,
      data: response,
    };
}