import React, { forwardRef, Ref } from "react";
import RateModel from "../../../../../models/RateModel";
import RateItemQuote from "./RateItemQuote";

interface Props {
  letter: string;
  items: RateModel[];
  onRateSelected: (id: string, rateVersion: string, name: string) => void;
  active?: string;
}

const RateSectionQuote = forwardRef<HTMLDivElement, Props>(
  (props, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            {props.items.length > 0 && (
              <p
                className="m-0 typo-body ps-3 py-1 text-primary"
                style={{ background: "#f3f3f3" }}
              >
                {props.letter}
              </p>
            )}
            <div>
              {props.items.map((item: RateModel, index: number) => {
                let active = props.active === item._id.toString();

                return (
                  <RateItemQuote
                    key={index}
                    contact={item}
                    onSelect={() =>
                      props.onRateSelected(
                        item._id.toString(),
                        item.rate_card_version[0]._id.toString(),
                        item.name
                      )
                    }
                    active={active}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default RateSectionQuote;
