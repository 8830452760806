import { FormikErrors } from "formik";
import { FormData } from "./FreelanceDetails";
import SelectInput from "src/components/UI/forms/Select";
import InputFloatingLabel from "src/components/UI/forms/InputFloatingLabel";
import SimpleButton from "src/components/UI/buttons/SimpleButton";

interface PaymentInfoProps {
  index: number;
  remove: (index: number) => void;
  value: FormData['payment_info'][number];
  errors: FormikErrors<FormData['payment_info'][number]>;
  edit: (index: number, data: FormData['payment_info'][number]) => void;
}

export function PaymentInfo({
  edit,
  index,
  value,
  remove,
  errors,
}: PaymentInfoProps) {
  const editField = (data: Partial<FormData['payment_info'][number]>) => {
    edit(index, {...value, ...data});
  }

  return (
    <div className="row border-bottom mb-3">
      <div className="col-3">
        <SelectInput
          placeholder={"Bank"}
          options={[
            { label: 'Paypal', value: 'paypal', },
            { label: 'Visa', value: 'visa', },
            { label: 'Wiretransfer', value: 'wiretransfer', },
            { label: 'PFP Coin', value: 'pfpcoin', },
            { label: 'Cryptocurrency', value: 'cryptocurrency', },
            { label: 'Existing Organization', value: 'existingorganization', },
            { label: 'Other', value: 'other', },
          ]}
          value={`${value?.method?.toLowerCase() || ''}`}
          onChange={(val: string) => editField({ method: val })}
        />

        <div className="mt-2">
          <SimpleButton
            type="danger"
            icon="bi-dash-circle-fill"
            onClick={() => remove(index)}
          >
            REMOVE
          </SimpleButton>
        </div>
      </div>
      <div className="col-9">
        <InputFloatingLabel
          placeHolder="Account number"
          errorMessage={errors?.account}
          value={`${value?.account || ''}`}
          onChange={(val) => editField({ account: val })}
        />
        <InputFloatingLabel
          placeHolder="Name of account owner"
          errorMessage={errors?.account_owner}
          value={`${value?.account_owner || ''}`}
          onChange={(val) => editField({ account_owner: val })}
        />
        <InputFloatingLabel
          errorMessage={errors?.type}
          placeHolder="Type of account"
          value={`${value?.type || ''}`}
          onChange={(val) => editField({ type: val })}
        />
        <InputFloatingLabel
          placeHolder="Bank name"
          errorMessage={errors?.bank}
          value={`${value?.bank || ''}`}
          onChange={(val) => editField({ bank: val })}
        />
      </div>
    </div>
  )
}