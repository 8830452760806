import { capitalizeText } from "src/util";
import InfoDisplay from "src/components/UI/InfoDisplay";
import { ContactType, IContact } from "src/models/ContactModel";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";

const EmergencyContacts: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {
  if (contact.type !== ContactType.FREELANCE && contact.type !== ContactType.TEAM_MEMBER) return null;

  return (
    <div className="container-fluid px-3">
      {(!contact.entity.emergency_contacts || contact.entity.emergency_contacts.length === 0) && (
        <h6 className="mt-2 text-primary">No emergency contacts found.</h6>
      )}

      {(contact.entity.emergency_contacts || []).map((ec) => (
        <div className="row border-bottom p-0 py-3">
          <div className="col-6">
            <InfoDisplay title="nickname" texts={[{ text: ec.name || 'Not configured.' }]} />
          </div>
          <div className="col-6">
            <InfoDisplay
              title="emails"
              texts={[{ text: ec.emails.map((e) => `(${capitalizeText(e.type)}) ${e.address}`).join(', ') }]}
            />
          </div>
          <div className="col-6">
            <InfoDisplay
              title="relationship to employee"
              texts={[{ text: ec.relationship }]}
            />
          </div>
          <div className="col-6">
            <InfoDisplay
              title="phone numbers"
              texts={[{ text: ec.phone_numbers && ec.phone_numbers.length > 0 ? ec.phone_numbers.map((p) => `(${capitalizeText(p.type)}) ${p.number}`).join(', ') : 'Not configured.' }]}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmergencyContacts;
