import { AnySchema, array, object, string } from 'yup';

import { OrganizationData as TOrganizationData } from 'src/models/ContactModel';
import FreelancePersonalValidation from '../Freelance/PersonalInformation/validation';

export type OrganizationData = TOrganizationData & {
  contact_since: string;
}

const OrganizationValidation = object<Record<keyof OrganizationData, AnySchema>>({
  logo: string().optional(),
  name: string().max(100).required('Name is required'),
  type: string().max(50).required('Type is required'),
  office_number: string().max(100).optional(),
  mobile_number: string().max(100).optional(),
  tax_id: string().max(100).optional(),
  home_url: string().max(100).url().optional(),
  addresses: array().of(object<Record<keyof Required<OrganizationData>['addresses'][number], AnySchema>>({
    map_location: string().max(100).required('Map location is required'),
    type: string().max(50).required('Type is required'),
    continent: string().max(50).optional(),
    country: string().max(50).optional(),
    state: string().max(50).optional(),
    city: string().max(50).optional(),
    zip: string().max(50).optional(),
    address: string().max(100).optional(),
  })),
  documents: object<Record<keyof Required<OrganizationData>['documents'], AnySchema>>({
    contract: string().optional(),
    docs_logo: string().optional(),
    insurance: string().optional(),
    style_guide: string().optional(),
    trade_license: string().optional(),
  }),
  contact_since: string().max(100).optional(),
  social: FreelancePersonalValidation.pick(['social']).optional(),
})

export default OrganizationValidation;