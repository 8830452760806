export enum QuoteStatusType {
    IN_REVIEW = 'In Review',
    FEEDBACK = 'FeedBack',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
}

export interface QuoteStatusData {
    id : string,
    status : QuoteStatusType
}