import React, {useState} from "react";
import CellInputValue from "./CellInputValue";
import CellReadonlyValue from "./CellReadonlyValue";

interface Props {
    type?:          string;
    value:          string | number;
    format?:        (value: string | number) => string | number;
    edit?:          boolean | false;
    onChange?:      (val: string | number) => void;   
    render?:        React.Component;
}

const TableCell: React.FC<Props> = (props) => {
    const {value, format} = props;
    const [editing, setediting] = useState(false);

    const editCell = (val: string | number) => {
        if(props.onChange){
            props.onChange(val);
        }

        setediting(false);
    }

    let renderValue = value;
    if(format){
        renderValue = format(value);
    }

    return (
        <>
            {!editing &&
                <td 
                    className="align-middle"
                    onDoubleClick={() => props.edit ? setediting(true) : ''}
                >
                    <CellReadonlyValue value={renderValue} />
                </td>
            }

            {editing &&
                <CellInputValue 
                    format={props.type} 
                    value={value}
                    onEdit={editCell} 
                    onBlur={() => setediting(false)} 
                />
            }
        </>
    )
}

export default TableCell