import moment from "moment";
import { useMemo } from "react";

import { calcAGE, capitalizeText } from "src/util";
import { SocialAndComm } from "../SocialAndComm";
import InfoDisplay from "src/components/UI/InfoDisplay";
import { ContactType, IContact } from "src/models/ContactModel";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";

const ProspectContactData: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {
  const contactSinceMoment = useMemo(() => moment(contact.contact_since), [contact.contact_since]);
  /* @ts-ignore */
  const dobMoment = useMemo(() => moment(contact.entity.date_of_birth), [contact.entity.date_of_birth]);

  if (contact.type !== ContactType.PROSPECT) return null;

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3 ">
        <InfoDisplay
          title="general note"
          texts={[
            {
              text: contact.note || 'No note.',
            },
          ]}
        />
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay title="nickname" texts={[{ text: contact.entity.nickname || 'No nickname.' }]} />
        </div>
        <div className="col-6">
          <InfoDisplay title="skype" texts={[{ text: contact.entity?.communication?.skype || 'No skype.' }]} />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="phone numbers"
            texts={[{ text: (contact.entity.phone_numbers?.length || 0) > 0 ? `${contact.entity.phone_numbers?.map((p) => `(${capitalizeText(p.type)}) ${p.number}`).join(', ')}` : 'No phone numbers.' }]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="personal emails"
            texts={[{ text: (contact.entity.emails?.filter((p) => p.type === 'personal').length || 0) > 0 ? `${contact.entity.emails?.filter((p) => p.type === 'personal')?.map((p) => p.address).join(', ')}` : 'No personal emails configured.' }]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="other emails"
            texts={[{ text: (contact.entity.emails?.filter((p) => p.type !== 'personal').length || 0) > 0 ? `${contact.entity.emails?.filter((p) => p.type !== 'personal')?.map((p) => `(${p.type}) ${p.address}`).join(', ')}` : 'No extra emails configured.' }]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="contact since"
            texts={[
              { text: contactSinceMoment.format('LL') },
              { text: contactSinceMoment.fromNow() }
            ]}
          />
        </div>
      </div>

      <SocialAndComm
        social={contact.entity.social}
        communication={contact.entity.communication}
      />
    </div>
  );
};

export default ProspectContactData;
