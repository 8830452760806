import ListTile from '../../../UI/ListTile';

const Kits: React.FC = () => {
  return (
    <div className="container-fluid px-3">
      <div className="row p-0 py-3 ">
        <p className="m-0 typo-body text-muted text-uppercase pb-1">Kits</p>

        <div className="col-6">
          <ListTile
            title="Podcast Kit"
            leadingImage="https://www.bbva.com/wp-content/uploads/2019/10/A-0210-Podcast-1920x1180.jpg?theia_smart_thumbnails_file_version=2"
          />
        </div>

        <div className="col-6">
          <ListTile
            title="Premium Podcast Kit"
            subtitle="010010104"
            leadingImage="https://s1.eestatic.com/2020/01/20/como/podcast-marketing-radio_461216627_142878540_1706x960.jpg"
          />
        </div>

        <div className="col-6">
          <ListTile
            title="Recording Kit"
            leadingImage="https://www.portafolio.co/files/article_multimedia/uploads/2021/08/17/611c156615606.jpeg"
          />
        </div>

        <div className="col-6">
          <ListTile
            title="Microphone Studio Kit"
            leadingImage="https://estaticos.muyinteresante.es/media/cache/500x375_thumb/uploads/images/dossier/607755f25cafe8ae96c39849/podcast.jpg"
          />
        </div>

        <div className="col-6">
          <ListTile
            title="Vocal Recording Kit"
            leadingImage="https://d500.epimg.net/cincodias/imagenes/2019/02/07/lifestyle/1549564261_212970_1549564525_noticia_normal.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default Kits;
