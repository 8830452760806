import React from "react";
import dataPrueba from "../../../../../components/dataPrueba";
import IconButton from "../../../../../components/UI/buttons/IconButton";
import InputSearch from "../../../../../components/UI/forms/InputSearch";
import Select from "../../../../../components/UI/forms/Select";
import SelectColorMergeQuote from "../../../../../components/UI/forms/SelectColorMergeQuote";

const MergeQuotes = () => {
  return (
    <div className="d-flex ">
      <div className="" style={{ width: "1000px", height: "650px" }}>
        <div>
          <h1 className="text-secondary fw-bold fs-2">
            Select quotes to merge
          </h1>
        </div>
        <div className="ps-3 pe-3">
          <InputSearch placeholder="search by name, Organization or client" />
        </div>
        <div>
          <table className="table table-borderless">
            <thead className="">
              <tr className="text-primary typo-body">
                <th scope="col" style={{ width: "250px" }}>
                  QUOTE NAME
                </th>

                <th scope="col" style={{ width: "140px" }}>
                  <div className="ms-2">VERSION</div>
                </th>
                <th scope="col" style={{ width: "120px" }}>
                  ORG
                </th>
                <th scope="col" style={{ width: "150px" }}>
                  PROJECT
                </th>

                <th scope="col" style={{ width: "140px" }}>
                  <div className="ms-2">JOB</div>
                </th>
                <th scope="col" style={{ width: "100px" }}>
                  CLIENT
                </th>
              </tr>
            </thead>

            <tbody>
              {dataPrueba.project.map((item: any, index: any) => {
                return (
                  <tr
                    className={`${
                      (index / 2) % 1 > 0 ? "table-primary" : ""
                    } text-primary typo-body`}
                    key={index}
                    // onClick={() => handleChange(item)}
                  >
                    <td
                      className="d-flex align-items-center"
                      style={{ height: "58px" }}
                    >
                      <p className="m-0 ms-2"> {item.name}</p>
                    </td>

                    <td
                      className="align-middle me-auto"
                      style={{ width: "50px" }}
                    >
                      <div className="d-flex me-auto">
                        <Select
                          placeholder="Version 3"
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {}}
                          value=""
                          options={[]}
                          className="w-1"
                        />
                      </div>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex me-auto">
                        <img
                          className="avatar2 "
                          src="https://m.media-amazon.com/images/I/51P84188A4L._AC_SX466_.jpg"
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="" style={{ padding: "20px" }}>
                      <div className="d-flex me-auto">{item.jobs}</div>
                    </td>
                    <td
                      className=""
                      style={{ padding: "20px", paddingRight: "20px" }}
                    >
                      <div className="d-flex me-auto">{item.jobs}</div>
                    </td>
                    <td className=" align-items-center">
                      <div className="d-flex me-auto">
                        <img
                          className="avatar2 "
                          src="https://m.media-amazon.com/images/I/51P84188A4L._AC_SX466_.jpg"
                          alt=""
                        />
                      </div>
                    </td>
                    <td className=" align-items-center">
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn "
                          style={{
                            width: 50,
                            height: 42,
                            border: "2px solid #6b8ab8",
                          }}
                        >
                          <i className="bi bi-plus fs-5 text-primary"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="border bg-contrast"
        style={{
          width: "350px",
          borderRadius: "0px 8px 8px 0px",
          height: "653px",
          marginTop: "-44px",
        }}
      >
        <div className="mt-4">
          <h1
            className="text-secondary fw-bold fs-2"
            style={{
              marginTop: "44px",
            }}
          >
            Merge quotes
          </h1>
        </div>
        <div>
          <table className="table table-borderless">
            <thead className="">
              <tr className="text-primary typo-body">
                <th scope="col" style={{ width: "250px" }}></th>

                <th scope="col" style={{ width: "200px" }}>
                  <div className="ms-2"></div>
                </th>
              </tr>
            </thead>

            <tbody>
              {dataPrueba.project.map((item: any, index: any) => {
                return (
                  <tr
                    className={`${
                      (index / 2) % 1 > 0 ? "table-primary" : ""
                    } text-primary typo-body`}
                    key={index}
                    // onClick={() => handleChange(item)}
                  >
                    <td
                      className="d-flex align-items-center"
                      style={{ height: "52px" }}
                    >
                      <p className="m-0 ms-2"> {item.name}</p>
                    </td>

                    <td
                      className="align-middle me-auto"
                      style={{ height: "52px" }}
                    >
                      <div className="d-flex me-auto">
                        <div>
                          <p
                            className="m-0 me-1 "
                            style={{ paddingTop: "11px" }}
                          >
                            Versión
                          </p>
                        </div>
                        <div>
                          {" "}
                          <SelectColorMergeQuote
                            placeholder="Version 3"
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {}}
                            value=""
                            options={[]}
                            className="w-1"
                          />
                        </div>
                        <div className="ms-1 mt-1">
                          <IconButton
                            color="danger"
                            icon="bi bi-trash2"
                            tooltip="Delete project"
                            outline
                            style={{
                              height: "30px",
                              width: "32.5px",
                            }}
                            customStyles={{
                              height: "15px",
                              width: "15px",
                              color: " #0000",
                            }}
                            // image={
                            //   "https://project-factory-33efa.web.app/static/media/trash.32e3d551.svg"
                            // }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <button
              className="btn btn-success btn-lg mt-2 text-white fw-bold"
              style={{ height: "50px", width: "150px" }}
            >
              Merge Quotes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MergeQuotes;
