import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setItemPackage } from "../../../store/stockpile/packageItems";
import { setIdItem } from "../../../store/stockpile/selectAssetsSlice";
import DoubleClick from "../../Behaviors/DoubleClicks";
import { RootState } from '../../../store/store'
import EmptyCircle from "src/components/UI/EmptyCircle";


interface Props {
  onSelect?: (id: string) => void;
  item: any;
  index: any
  setID: any,
  showAssets: any,
  itemsPre: Array<any>,
  setPre: any
}

const ContactItem: React.FC<Props> = (props) => {
  let count1 = 10
  let count2 = 20

  const dispatch = useDispatch()

  const PackageItems = useSelector((state: RootState) => state.packageItems.PackageItems);


  const [exist, setExist] = useState<boolean>(false)

  const handleAsset = () => {
    props.showAssets(true)
    dispatch(setIdItem(props.item._id))
  }


  const AddItemToPackage = (value: any) => {
    if (value) {
      props.setPre(props.itemsPre.concat(props.item))
    }
    if (!value) {
      let inx = props.itemsPre.findIndex((item) => item._id == props.item?._id);
      props.setPre(props.itemsPre.splice(inx, 0))
    }
  }

  const checkState = () => {
    const data: any = PackageItems.map((item: any) => {
      if (item?._id == props.item._id) {
        setExist(true)
      }
    })

    //  return data
  }

  useEffect(() => {
    checkState()
  }, [PackageItems])




  return (
    <DoubleClick onDoubleClick={handleAsset} >
      <div className="container-fluid border-top p-3 py-2 contact-item"
              style={exist ? { pointerEvents: 'none', opacity: 0.2 } : {}}

      >
        <div className="row align-items-center">
          {props.onSelect && (
            <div className="col-auto p-0 ps-2">
              <input className="form-check-input" type="checkbox"
                onChange={(value: any) => AddItemToPackage(value.target.checked)}
              />
            </div>
          )}

          <div
            className={`col d-flex p-0 text-truncate ${props.onSelect ? 'ms-3' : 'ms-2'
              }`}
          >
            {props.item.picture_url && <img loading="lazy" src={process.env.REACT_APP_IMAGES_AWS + props.item.picture_url} alt="" className="avatar2" />
            }
            {
              !props.item.picture_url && <EmptyCircle title={props.item.name} small />
            }
            <div className="ms-2">
              <p className="m-0 text-black typo-body contact-title">
                {props.item.name}
              </p>
              <p className="m-0 text-black typo-body contact-subtitle">
                {props.item.name}
              </p>
            </div>
          </div>

          <div className="col-auto pe-1">
            {count1 && (
              <p className="m-0 contact-trailing typo-body bg-primary my-1">
                {props.item.internal_assets}
              </p>
            )}
            {count2 && (
              <p className="m-0 contact-trailing typo-body bg-success my-1">
                {props.item.external_assets}
              </p>
            )}
          </div>
        </div>
      </div>
    </DoubleClick>
  );
};

export default ContactItem;
