import FlatList from "flatlist-react";
import FakeItems from "../../../dummy_data/inventory.json";
import React, { useEffect, useState } from "react";
import { getSections } from "../../../util/ItemsFunctions";
import IconButton from "../../UI/buttons/IconButton";
import ContactSection from "./ContactSection";
import AdvanceSearch from "../../Contacts/ContactList/AdvanceSearch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Table } from "reactstrap";
import ItemList from "./ItemList";
import AssetList from "./AssetList";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  cleanAsset,
  setDataAsset,
} from "../../../store/stockpile/selectAssetsSlice";
import StockView from "../StockView/StockView";
import Arrowback from "../../../assets/icons/Arrowback.svg";
import { setShowAssetLis } from "src/store/stockpile/RecursosVisualeSlice";
import { useRequest } from "src/hooks/useRequest";
import { ItemCategoryEvaluation } from "src/util/ItemCategoryEvaluation";

interface Props {
  onAddContact: () => void;
  onAddService: () => void;
  setTypeForm: any;
  toggleAddingkit: any;
}

const StockList: React.FC<Props> = (props) => {
  const assetList = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowAssetList
  );
  const categories = useSelector(
    (state: RootState) => state.categorieSlice.categories
  );

  const [itemID, setItemID] = useState<string>("");
  const [cat, setCat] = useState<string>("");

  const dispatch = useDispatch();
  const params = useParams<{ itemId: string }>();
  const history = useHistory();

  const { data: ITEM_DATA } = useRequest<any>({
    url: "stockpile/items/" + params.itemId,
  });

  const setAssetList = (val: boolean) => {
    dispatch(setShowAssetLis(val));
  };
  const ASSET_SELECTED = useSelector(
    (state: RootState) => state.selectAssetsSlice.data
  );
  /// 67807598690173950

  useEffect(() => {
    setCat(ITEM_DATA?.category?._id);
  }, [ITEM_DATA?.category?._id, assetList]);

  const ForceClean = () => {
    let cont = 0;

    if (ASSET_SELECTED?.serial) {
      var id = setInterval(() => {
        dispatch(cleanAsset());
        cont++;
        if (cont == 5) {
          clearInterval(id);
        }
      }, 100);
    }
  };

  return (
    <>
      {cat == "67807598690173953" ||
      (cat != "67807598690173956" &&
        cat != "67807598690173950" &&
        params.itemId) ? (
        <div className="p-0 m-0 pb-0 ">
          <div
            className="d-flex p-0 ms-3 mt-2 mb-0 "
            style={{ cursor: "pointer", flexDirection: "row", height: "10%" }}
            onClick={() => {
              setAssetList(false);
              dispatch(cleanAsset());
              dispatch(setDataAsset({}));
              setItemID("");
              history.push("/stockpile");
            }}
          >
            <div className="">
              <img src={Arrowback} alt="" />
            </div>
            <div
              className="ms-2"
              style={{
                marginTop: "2.2%",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#344469",
              }}
            >
              <p>SELECT THE ITEM YOU WANT TO REVIEW</p>
            </div>
          </div>
          <AssetList itemID={itemID} />
        </div>
      ) : (
        <ItemList
          showAssets={setAssetList}
          setID={setItemID}
          onAddContact={props.onAddContact}
          onAddService={props.onAddService}
          setTypeForm={props.setTypeForm}
          toggleAddingkit={props.toggleAddingkit}
        />
      )}
    </>
  );
};

export default StockList;
