
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import stockpileService from '../../services/stockpile';


export const GetCategories = createAsyncThunk('categories/getAll', async () => {
    try {
        const res = await stockpileService.GetAllCategories()
        return res
    } catch (error) {
        console.log(error);
    }
})

export const GetTotalInvetory = createAsyncThunk('total/value', async () => {
    try {
        const res = await stockpileService.getTotalPrice()
        return res
    } catch (error) {
        console.log(error);
    }
})

const initialState = {
    categories: [],
    categorySelectedFilter: 'any',
    sub_categorySelectedFilter: 'any',
    TotalPrice: '',
    categorieActive: 888876373900000
};

const selectAssetsSlice = createSlice({
    name: 'PackageItems',
    initialState,
    reducers: {
        setCategoryFilter: (state, action) => {
            state.categorySelectedFilter = action.payload
        },
        setSubcategoryFilter: (state, action) => {
            state.sub_categorySelectedFilter = action.payload
        },
        setCategorieActive: (state,action) => {
            state.categorieActive = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(GetCategories.fulfilled, (state, action) => {
            state.categories = action.payload
        }).addCase(GetTotalInvetory.fulfilled , (state , action) => {
            state.TotalPrice = action.payload[0]?.total_price            
        })
    }
});

export const { setCategoryFilter , setSubcategoryFilter , setCategorieActive} = selectAssetsSlice.actions;
export default selectAssetsSlice.reducer;
