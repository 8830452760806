import React, { useState } from "react";
import { Modal } from "reactstrap";
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import QuoteEmailScheme from './validationsEmail'
import InputFloatingLabel from "../../../UI/forms/InputFloatingLabel";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  width?: any
}

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [{ "list": "ordered" }, { "list": "bullet" },
    { "indent": "-1" }, { "indent": "+1" }],
    [{ size: [] }],
    [{ color: [] }, { background: [] }],
    ["clean"]
  ],
  clipboard: {
    matchVisual: false,
  }
}
const QuoteEmailModal: React.FC<Props> = (props) => {
  const initialValues = {
    clientEmail: "",
    cc: "",
    subject: "",
    htmlContent: "",
  }
  const [emailData, setEmailData] = useState(initialValues);

  const handleChange = (prop, value) => {
    setEmailData({
      ...emailData,
      [prop]: value,
    });
  }

  const handleSend = () => {
    console.log(emailData)
  }

  const formik = useFormik({
    validationSchema: QuoteEmailScheme,
    initialValues: {
      FirstName: '',
      LastName: '',
      email: '',
      description: ''
    } as any,
    onSubmit: (values) => {
    }
  })

  const { setFieldValue , errors } = formik

  return (
    <Modal isOpen={props.isOpen} toggle={props.onClose} centered style={{ maxWidth: props.width || "640px" }}
    >
      <div
        onClick={props.onClose}
        className="cursor-pointer pt-3"
      >
        <i className="bi bi-x-lg px-3"></i>
      </div>
      <div className="container">
        <div className="row px-4 mt-4">
          <div className="col-12 text-center">
            <h3 className="text-secondary fw-bold ">
              Send quote
            </h3>
          </div>
        </div>
        <div className="row px-1 mt-4">
          <div className="col-12 d-flex flex-column">
            <InputFloatingLabel
              value={emailData.clientEmail}
              onChange={(value) => {
                handleChange("clientEmail", value)
                setFieldValue('clientemail' , value)
              }}
              placeHolder="Client email"
              isRequired={true}
              errorMessage={errors.clientemail}
            />
            <InputFloatingLabel
              value={emailData.cc}
              onChange={(value) => {
                handleChange("cc", value)
                setFieldValue('cc' , value)
              }}
              placeHolder="CC"
              errorMessage={errors.cc}
            />
            <InputFloatingLabel
              value={emailData.subject}
              onChange={(value) => {
                handleChange("subject", value)
                setFieldValue('subject' , value)
            }}
              placeHolder="Subject"
              isRequired={true}
              errorMessage={errors.subject}
            />
            <div className="mb-3">
              <ReactQuill
                onChange={(value) => handleChange("htmlContent", value)}
                value={emailData.htmlContent}
                modules={modules}
                placeholder={"Write your message"}
                style={{ height: '150px' }}
              />
            </div>
          </div>
        </div>
        <div className="row px-4 my-5 d-flex justify-content-center">
          <button
            onClick={handleSend}
            className={
              "btn btn-success text-white col-auto fs-6 mt-4"
            }
            style={
              !emailData.clientEmail || !emailData.subject  ?
              {pointerEvents: 'none' , opacity: 0.3}: {}
            }
          >
            Send
          </button>
        </div>

      </div>
    </Modal>
  );
};

export default QuoteEmailModal;
