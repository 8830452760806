import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DUMMY_CONTACTS from '../../../dummy_data/contacts.json';
import CategoryItem from '../../Contacts/ContactCategories/CategoryItem';
import ContactItem from '../../Contacts/ContactList/ContactItem';
import PackageModal from '../../Modals/PackageModal/PackageModal';
import { RootState } from '../../../store/store';
import { GetCategories, setCategoryFilter, GetTotalInvetory, setSubcategoryFilter, setCategorieActive } from '../../../store/stockpile/categorieSlice';
import SimpleButton from '../../UI/buttons/SimpleButton';
import { useToast } from 'src/hooks/useToast';

interface Props {
  toggleAddingPackage: any
}

const StockCategories: React.FC<Props> = (props) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const categories: Array<any> = useSelector((state: RootState) => state.categorieSlice.categories);
  const TotalPrice = useSelector((state: RootState) => state.categorieSlice.TotalPrice);
  const selectedCat = useSelector((state: RootState) => state.categorieSlice.categorySelectedFilter);
  const ItemData = useSelector((state: RootState) => state.stockpileSlice.ItemData);

  const selected: any = useSelector((state: RootState) => state.categorieSlice.categorieActive);

  const setSelected = (cate: any) => {
    dispatch(setCategorieActive(cate))
  }

  const [itemsPreselect, setPreselectItems] = useState<Array<any>>([])


  const [CreatePackageModal, setViewPackageModal] = useState<boolean>(false)
  // const [selected, setSelected] = useState<any>(888876373900000)

  useEffect(() => {
    dispatch(GetCategories())
    dispatch(GetTotalInvetory())
  }, [])

  useEffect(() => {
console.log(categories);

  }, [categories])

  const messageComming = (message: any) => {
    toast.info(message + ' is coming soon!')
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="h-100 custom-scrollbar overflow-x-none overflow-y ">
        <ul className="list-unstyled pt-3 px-2 p-3 ps-4">

          <CategoryItem
            title="All items"
            category={{ _id: 'any' }}
            selected={selected}
            setSelect={setSelected}
            index={888876373900000}
            
          />
          {categories.map((cat: any, index) => {
console.log(cat._id);


            const subs = cat?.sub_categories?.map((sub: any) => {


              return { name: sub?.name, action: () => dispatch(setSubcategoryFilter(sub?._id)) }
            })



            return (
              <CategoryItem
                key={index}
                title={cat?.name}
                options={subs}
                count={cat?.sub_categories.length > 0 ? cat?.sub_categories.length : false}
                category={cat}
                selected={selected}
                setSelect={setSelected}
                index={index}
              />
            )
          })}
          <div style={{ opacity: 0.7 }}>
            <SimpleButton onClick={() => { messageComming('ADD NEW CATEGORY') }}>
              ADD NEW CATEGORY
            </SimpleButton>
          </div>

          <SimpleButton onClick={() => { setViewPackageModal(true) }} >
            CREATE PACKAGE
          </SimpleButton>
        </ul>
      </div>
      <PackageModal
        View={CreatePackageModal}
        setView={setViewPackageModal}
        toggleAddingPackage={props.toggleAddingPackage}
        itemsPreselect={itemsPreselect}
        setPreselectItems={setPreselectItems}
        Qtys={[]}
      />

      <div className="h-25 d-flex flex-column justify-content-end p-0">
        <div className='p-0 m-4 mb-1'>
          <p className="m-0 typo-body" style={{ color: '#8898AA', fontSize: '10px' }} >STOCKPILE TOTAL VALUE:</p>
          <p className="typo-body" style={{ color: '#8898AA', fontSize: '10px' }} >${TotalPrice}</p>
        </div>
      </div>
    </div>
  );
};

export default StockCategories;
