import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store/store';
import { ICategory } from 'src/models/Category';
import { useRequest } from 'src/hooks/useRequest';
import { blackbookFilters } from 'src/store/blackbook/filtersSlice';
import CategoryItem from 'src/components/DashboardLayout/LeftCard/CategoryItem';

const ContactCategories: React.FC = () => {
  const dispatch = useDispatch()
  const { data: categories, error } = useRequest<ICategory[]>({ url: 'categories/contacts/all' });
  const selectedCategory = useSelector((state: RootState) => state.blackbookFiltersSlice.selectedCategory);

  const handleSelect = (category: ICategory) => {
    dispatch(blackbookFilters.selectCategory(category));
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="custom-scrollbar overflow-x-none overflow-y">
        <ul className="list-unstyled pt-3 px-2">
          <CategoryItem category={{ _id: 'all', name: 'All contacts' } as ICategory} selected={!selectedCategory} onSelect={handleSelect} />
          
          {!categories && !error && (
            <div className="d-flex justify-content-center align-items-center h-100">
              <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
            </div>
          )}
          
          {!categories && error && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted">
              <i className="fs-3 bi bi-exclamation-triangle d-block"></i>
              <p className="h6">Error while loading the blackbook categories.</p>
            </div>
          )}

          {categories && !error && (
            categories.map((category: ICategory) => (
              <CategoryItem
                key={category._id}
                category={category}
                onSelect={handleSelect}
                selected={selectedCategory}
              />
            ))
          )}

          {/* <SimpleButton
            onClick={() => {}}
          >
            ADD CATEGORY
          </SimpleButton> */}
        </ul>
      </div>
    </div>
  );
};

export default ContactCategories;
