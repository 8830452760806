import { createSlice, createAsyncThunk, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { string } from 'yup/lib/locale';
import ItemModel, { AccesoriesModel, AssetModel, PickupInfo } from '../../models/ItemModel';
import { sizesModel, RatesModel, warehouse, purchase } from '../../models/ItemModel';


interface Date {
  type: string; date: string
}

const initialState = {
  data: {
    name: '',
    brand: '',
    consumption: '',
    consumption_unit: 'watts',
    category: '',
    sub_category: '',
    web_links: ['https://stackoverflow.com/'],
    description: '',
    discountable: false,
    dryhire: false,
    type: '4824715373839368',
    photos: [],
    picture_url: '',
    manual_url: '',
    status: {}
  } as ItemModel,
  files: {
    picture_name: '',
    photos_name: '',
    user_manual_uno: '',
    user_manual_dos: ['']
  },
  sizesG: {
    name: 'name',
    weight: '',
    length: '',
    width: '',
    height: '',
    weight_unit: 'kilograms',
    sizes_unit: 'centimeters'
  } as sizesModel,
  sizesA: {
    name: 'name',
    weight: '',
    length: '',
    width: '',
    height: '',
    weight_unit: 'kilograms',
    sizes_unit: 'centimeters'
  } as sizesModel,
  RatesData: {
    hour: '',
    daily: '',
    week: '',
    sale: '',
    holiday_day: '',
    holiday_hour: '',
    overtime_hour: '',
    holiday_overtime_hour: '',
  },
  AccesoriesMandatoryData: [''] as Array<string>,
  AccesoriesOptionalData: [''],
  AssetsDataEdit: [] as Array<AssetModel>,
  AssetsDataEditExternal: [] as Array<AssetModel>,
  pickupData: [] as Array<PickupInfo>,
  DatesData: [
    [{ type: 'date of purchase', date: '' }]
  ] as Array<Array<Date>>,
  AssetValues: {} as AssetModel,
  formsCount: [{ state: true }] as { state: boolean }[],
  formsExternalCount: [{ state: true }] as { state: boolean }[],
  GeneralErrors: false,
  AssetsErrors: false,
  RatesErrors: false,
  AllAssets: [] as any 
};

const EditFormSlice = createSlice({
  name: 'EditFormSlice',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = { ...state.data, ...action.payload } as ItemModel
    },
    setRates: (state, action) => {
      state.RatesData = { ...state.RatesData, ...action.payload }
    },
    setAccesoriesMandatory: (state, action) => {
      state.AccesoriesMandatoryData.push('')
    },
    setAccesoriesOptional: (state, action) => {
      state.AccesoriesOptionalData.push('')
    },
    updateAccesoriesMandatory: (state, action) => {
      state.AccesoriesMandatoryData[action.payload.index] = action.payload.data
    },
    updateAccesoriesOptional: (state, action) => {
      state.AccesoriesOptionalData[action.payload.index] = action.payload.data
    },
    updateAllAccesories: (state,action) => {
      state.AccesoriesMandatoryData = action.payload?.mandatory?.map((item:any) => {
        return item?._id
      })
      state.AccesoriesOptionalData = action.payload?.optional?.map((item:any) => {
        return item?._id
      })
    },
    DeleteMandatory: (state,action) => {
      if (action.payload > -1) {
        state.AccesoriesMandatoryData.splice(action.payload, 1);
      }
    },
    DeleteOptional: (state,action) => {
      if (action.payload > -1) {
        state.AccesoriesOptionalData.splice(action.payload, 1);
      }
    },
    setAsset: (state, action) => {
      state.AssetsDataEdit = [...state.AssetsDataEdit, action.payload]
    },
    setAssetExternal: (state, action) => {
      state.AssetsDataEditExternal = [...state.AssetsDataEditExternal, action.payload]
    },
    DeleteAsset: (state, action) => {
      if (action.payload > -1) {
        state.AssetsDataEdit.splice(action.payload, 1);
        state.formsCount.splice(action.payload, 1);
      }
    },
    DeleteDate: (state , action) => {
        state.DatesData[action.payload.indexExterno].splice(action.payload.index, 1);

        const updateCamp = (Asset:any) => {
          state.AssetsDataEdit[0] = Asset
        }
        
        if (action.payload.index == 0) {
          updateCamp({ ...state.AssetsDataEdit[0] , purchase: {...state.AssetsDataEdit[0]?.purchase ,date: '' } })
        }
    
        if (action.payload.index == 1) {
          updateCamp({ ...state.AssetsDataEdit[0] , end_of_life: '' })
    
        }
        if (action.payload.index == 2) {
          updateCamp({ ...state.AssetsDataEdit[0] , warranty_expiration: ''} )
        }
    },
    DeleteAssetExternal: (state, action) => {
      if (action.payload > -1) {
        state.AssetsDataEditExternal.splice(action.payload, 1);
        state.formsExternalCount.splice(action.payload, 1);
      }
    },
    updateValuesAssets: (state, action) => {
      state.AssetsDataEdit = action.payload
    },
    updateValuesExternalAssets: (state, action) => {
      state.AssetsDataEditExternal = action.payload
    },
    setValuesAssets: (state, action) => {
      state.AssetValues = { ...state.data, ...action.payload } as AssetModel
    },
    setValuesPickup: (state, action) => {
      state.pickupData = action.payload
    },
    setSizeG: (state, action) => {
      state.sizesG = { ...state.sizesG, ...action.payload } as sizesModel
    },
    setPickupData: (state, action) => {
      state.pickupData = { ...state.pickupData, ...action.payload }
    },
    setDatesData: (state, action) => {
      state.DatesData[action.payload?.index] = action.payload?.data
    },
    setFormsCount: (state, action) => {
      state.formsCount = state.formsCount.concat([{ state: true }])
    },
    setFormsExternalCount: (state, action) => {
      state.formsExternalCount = state.formsExternalCount.concat([{ state: true }])
    },
    setFormDate: (state, action) => {
      state.DatesData = [...state.DatesData, [{ type: 'date of purchase', date: '' }]]
    },
    setFormNameFile: (state, action) => {
      state.files.user_manual_dos.push('')
    },
    ChangeFormNameFile: (state, action) => {
      state.files.user_manual_dos[action.payload.index] = action.payload.name
    },
    AddFormDate: (state, action) => {
      state.DatesData[action.payload].push({ type: 'date of purchase', date: '' })
    },
    changeGeneralErrors: (state, action) => {
      state.GeneralErrors = action.payload
    },
    changeAssetsErrors: (state, action) => {
      state.AssetsErrors = action.payload
    },
    changeRatesErrors: (state, action) => {
      state.RatesErrors = action.payload
    },
    setNameFiles: (state, action) => {
      state.files = { ...state.files, ...action.payload }
    },
    EmptyForm: (state, action) => {
      state.data = {
        name: '',
        brand: '',
        consumption: '',
        consumption_unit: 'watts',
        category: '',
        sub_category: '',
        web_links: ['https://stackoverflow.com/'],
        description: '',
        discountable: false,
        dryhire: false,
        type: '4824715373839368',
        photos: [],
        picture_url: '',
        manual_url: '',
        status: {}
      } as any

      state.files = {
        picture_name: '',
        photos_name: '',
        user_manual_uno: '',
        user_manual_dos: ['']
      }

      state.sizesG = {
        name: 'name',
        weight: '',
        length: '',
        width: '',
        height: '',
        weight_unit: 'kilograms',
        sizes_unit: 'centimeters'
      } as sizesModel

      state.sizesA = {
        name: 'name',
        weight: '',
        length: '',
        width: '',
        height: '',
        weight_unit: 'kilograms',
        sizes_unit: 'centimeters'
      } as sizesModel

      state.RatesData = {
        hour: '',
        daily: '',
        week: '',
        sale: '',
        holiday_day: '',
        holiday_hour: '',
        overtime_hour: '',
        holiday_overtime_hour: '',
      }
      state.AccesoriesMandatoryData = [''] as Array<string>
      state.AccesoriesOptionalData = ['']
      state.AssetsDataEdit = [] as Array<AssetModel>
      state.AssetsDataEditExternal = [] as Array<AssetModel>
      state.pickupData = [] as Array<PickupInfo>
      state.DatesData = [
        [{ type: 'date of purchase', date: '' }]
      ] as Array<Array<Date>>
      state.AssetValues = {} as AssetModel
      state.formsCount = [{ state: true }] as { state: boolean }[]
      state.formsExternalCount = [{ state: true }] as { state: boolean }[]
      state.GeneralErrors = false
      state.AssetsErrors = false
      state.RatesErrors = false
    },
    UpdateInitialDate: (state,action) => {
      state.DatesData[action.payload.index][0] = { type: 'date of purchase', date: action.payload.date }
    },
    setFetchingData: (state, action) => {
      state.DatesData[0]?.push({type:'date' , date: action.payload.date })
    },
    setAllassets: (state,action) => {
      state.AllAssets = [...state.AssetsDataEdit, ...state.AssetsDataEditExternal]
    }
  }
});

export const {
  setData,
  setRates,
  setDatesData,
  AddFormDate,
  setFormDate,
  setFormsCount,
  setFormsExternalCount,
  setAccesoriesMandatory,
  setAccesoriesOptional,
  updateAccesoriesMandatory,
  updateAccesoriesOptional,
  setValuesPickup,
  setPickupData,
  setAsset,
  setAssetExternal,
  updateValuesExternalAssets,
  updateValuesAssets,
  setSizeG,
  changeAssetsErrors,
  changeGeneralErrors,
  changeRatesErrors,
  setNameFiles,
  setFormNameFile,
  ChangeFormNameFile,
  DeleteAsset,
  DeleteAssetExternal,
  EmptyForm,
  DeleteDate,
  UpdateInitialDate,
  setFetchingData,
  setAllassets,
  updateAllAccesories,
  DeleteMandatory,
  DeleteOptional
} = EditFormSlice.actions;
export default EditFormSlice.reducer;




/**
 *     UpdateInitialDate: (state,action) => {
      state.DatesData[action.payload.index][0] = { type: 'date of purchase', date: action.payload.date }
    },
    setFetchingData: (state, action) => {
      state.DatesData[action.payload.index].push({type:'date' , date: action.payload.date })
    },
 */