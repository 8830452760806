import React, { useState, useEffect } from "react";
import { ApiError } from "src/models";
import { AxiosResponse } from "axios";
import { useToast } from "src/hooks/useToast";
import { useHistory } from "react-router-dom";

import useQuery from "src/hooks/useQuery";
import { useRequest } from "src/hooks/useRequest";
import useRates from "src/hooks/useRates";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { createRateCardForm } from "src/store/rates/createRateCardSlice";

import AdvanceSearch from "../../Contacts/ContactList/AdvanceSearch";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import Checkbox from "../../UI/forms/Checkbox";
import TextArea from "../../UI/forms/TextArea";

import RateModel, {
  RateCardVersionsModel,
  RateCardItemModel,
  IRates,
  BAddRateCardItem,
  IRateCardItem,
} from "../../../models/RateModel";
import IconButton from "src/components/UI/buttons/IconButton";
import { RateCardService } from "src/services/rates";
import TableCell from "./table/TableCell";
import Filters, { RateCardFilters } from "../Filters/Filters";
import { IContact } from "src/models/ContactModel";
import { ContactTypeEx } from "src/util/ContactsFunctions";
import EmptyCircle from "src/components/UI/EmptyCircle";

interface Props {
  version?: boolean;
}

const Create: React.FC<Props> = (props) => {
  const version = { props };

  const toast = useToast();
  const query = useQuery();
  const history = useHistory();

  const type = query.get("create");

  const dispatch = useDispatch();
  const createRateCardData = useSelector(
    (state: RootState) => state.createRateCardSlice
  );

  const [loading, setloading] = useState<boolean>(true);
  const [sending, setsending] = useState<boolean>(false);

  const { data: rates, error: errRates, mutate } = useRates();
  const { data: client, error: clientErr } = useRequest<IContact>(
    createRateCardData.data.client_id !== ""
      ? { url: `blackbook/contacts/${createRateCardData.data.client_id}` }
      : null
  );
  const {
    data: items,
    error: errItems,
    mutate: mutateItems,
  } = useRequest<RateCardItemModel[]>(
    rates
      ? {
          url: `rate-card-versions/${
            rates.find((item) => item.blocked)?.rate_card_version[0]._id
          }/rate-card-items`,
        }
      : null
  );

  const [list, setlist] = useState<RateCardItemModel[]>([]);
  const [filters, setfilters] = useState<RateCardFilters>({
    search: "",
    category: "",
  });
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      createRateCardData.data.name !== "" &&
      createRateCardData.data.description !== ""
    ) {
      if (items && loading) {
        mutateItems(undefined, true);
        setlist([...items]);
        setloading(false);
      }
    } else {
      history.push("/rates");
    }
  }, [items]);

  const formatItemsToSendData = (
    items: IRateCardItem[]
  ): BAddRateCardItem[] => {
    let list = [...items];
    let newList: BAddRateCardItem[] = [];

    for (let i = 0; i < list.length; i++) {
      const dataItem = list[i];

      // conditional
      if (dataItem.item !== null) {
        let newItem: BAddRateCardItem = {
          item: dataItem.item !== null ? dataItem.item._id : "",
          rates: dataItem.rates,
        };

        newList.push(newItem);
      }
    }

    return newList;
  };

  const handleSave = async () => {
    if (list) {
      setsending(true);

      let newRateCardData = {
        name: createRateCardData.data.name,
        description: createRateCardData.data.description,
        rate_card_items: formatItemsToSendData(list),
      };

      if (createRateCardData.data.client_id !== "") {
        Object.assign(newRateCardData, {
          assigned_to: createRateCardData.data.client_id,
        });
      }

      RateCardService.CreateRateCard({
        ...newRateCardData,
      })
        .then((rateCard) => {
          setsending(false);
          setSaveModalOpen(false);
          toast.success("RateCard created successfully.");

          dispatch(createRateCardForm.clearData({}));
          mutate(undefined, true);
          history.push(`/rates/${type}`);
        })
        .catch((err: AxiosResponse<ApiError>) => {
          setsending(false);
          return toast.error(
            Array.isArray(err.data.message)
              ? err.data.message.join("\n")
              : err.data.message
          );
        });
    }
  };

  const editRateValue = (id: string, value: IRates) => {
    // var allData     = [...list];
    let allData = list.slice();

    let item = allData.find((item) => item._id === id);
    if (item) {
      let index = allData.indexOf(item);
      allData[index].rates = value;

      setlist(allData);
    }
  };

  const applyFilters = (): RateCardItemModel[] => {
    let searchItems: RateCardItemModel[] = [];

    if (list) {
      searchItems = [...list];

      if (filters.search && filters.search.length > 0) {
        searchItems = searchItems.filter((item) =>
          item.item?.name
            .toLocaleLowerCase()
            .includes(filters.search.trim().toLocaleLowerCase())
        );
      }

      if (filters.category && filters.category !== "") {
        searchItems = searchItems.filter(
          (item) =>
            item.item?.category?._id.toString() === filters.category.toString()
        );
      }
    }

    return searchItems;
  };

  let tableItems = applyFilters();

  return (
    <>
      <div className="container-fluid h-100">
        {!items && !errItems && (
          <div className="d-flex justify-content-center align-items-center py-5 h-100">
            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
          </div>
        )}

        {items && !errItems && (
          <React.Fragment>
            {saveModalOpen && (
              <ConfirmModal
                title="Do you want to save?"
                description={`Click save the rate card.`}
                buttons={
                  <>
                    {sending ? (
                      <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
                    ) : (
                      <div>
                        <button
                          className="btn btn-success text-white mx-1 px-3"
                          onClick={() => handleSave()}
                          disabled={sending}
                        >
                          SAVE
                        </button>
                        <button
                          className="btn btn-danger text-white mx-1 px-3"
                          onClick={() => setSaveModalOpen(false)}
                          disabled={sending}
                        >
                          KEEP EDITING
                        </button>
                      </div>
                    )}
                  </>
                }
                onClose={() => setSaveModalOpen(false)}
              />
            )}
            <div className="row" style={{ height: "98%" }}>
              <div className="col card h-100 custom-scrollbar overflow-y p-3">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="content-rate-description">
                      {/* name - description */}

                      <h1
                        onClick={() => mutateItems()}
                        className="typo-body h5 mt-2 fw-bold"
                      >
                        <i
                          onClick={() => history.push(`/rates/`)}
                          className={`bi bi-chevron-left text-primary h5 me-2`}
                        />

                        {createRateCardData.data.name}
                      </h1>

                      <div className="row">
                        <div
                          className={
                            createRateCardData.data.client_id !== "" && client
                              ? "col-4"
                              : "col-12"
                          }
                        >
                          <p className="m-0 typo-body text-muted mt-3">
                            DESCRIPTION
                          </p>
                          <p className="m-0 typo-body text-secondary">
                            {createRateCardData.data.description}
                          </p>
                        </div>

                        {createRateCardData.data.client_id !== "" && client && (
                          <>
                            <div className="col-4">
                              <p className="m-0 typo-body text-muted mt-3">
                                CLIENT NAME
                              </p>
                              <p className="m-0 typo-body text-secondary">
                                {ContactTypeEx[client.type].getTitle(client)}
                              </p>
                            </div>
                            <div className="col-4">
                              <p className="m-0 typo-body text-muted mt-3">
                                CLIENT INFO
                              </p>
                              <p className="m-0 typo-body text-secondary">
                                {ContactTypeEx[client.type].getSubtitle(client)}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Filters
                  col="4"
                  filterValue={filters}
                  onChangeFilters={(value: RateCardFilters) =>
                    setfilters(value)
                  }
                  advandedFilters
                />

                <div className="w-100 custom-scrollbar overflow-x mt-3 custom-table">
                  <table className="table table-borderless">
                    <thead>
                      <tr className="text-primary typo-body">
                        <th scope="col">ITEM</th>
                        <th scope="col">CATEGORY</th>
                        <th scope="col">HOUR</th>
                        <th scope="col">DAY</th>
                        <th scope="col">WEEK</th>
                        <th scope="col">SALE</th>
                        <th scope="col">OVERTIME HOUR</th>
                        <th scope="col">HOLIDAY HOUR</th>
                        <th scope="col">HOLIDAY DAY</th>
                        <th scope="col">HOLIDAY OVERTIME HOUR</th>
                      </tr>
                    </thead>
                    {tableItems.length > 0 ? (
                      <tbody>
                        {tableItems.map((item, index) => {
                          return (
                            <tr
                              className={`${
                                (index / 2) % 1 > 0 ? "" : "table-primary"
                              } text-primary typo-body`}
                              key={index}
                            >
                              <td className="d-flex align-items-center">
                                {item?.item?.picture_url && (
                                  <img
                                    loading="lazy"
                                    src={
                                      process.env.REACT_APP_IMAGES_AWS +
                                      item?.item?.picture_url
                                    }
                                    alt=""
                                    className="avatar2"
                                  />
                                )}
                                {!item?.item?.picture_url && (
                                  <EmptyCircle title={item?.item?.name} small />
                                )}
                                <p className="m-0 ms-2">
                                  {item.item && item.item.name
                                    ? `${item.item.name}`
                                    : ""}
                                  {item.item && item.item.brand
                                    ? `- ${item.item.brand}`
                                    : ""}
                                </p>
                              </td>

                              <TableCell
                                value={
                                  item.item?.category
                                    ? item.item.category.name
                                    : "-"
                                }
                              />

                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.hour}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    hour: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.daily}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    daily: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.week}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    week: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.sale}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    sale: Number(value),
                                  })
                                }
                              />

                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.overtime_hour}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    overtime_hour: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.holiday_hour}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    holiday_hour: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.holiday_day}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    holiday_day: Number(value),
                                  })
                                }
                              />
                              <TableCell
                                type="number"
                                format={(value) => `$ ` + value}
                                value={item.rates.holiday_overtime_hour}
                                edit
                                onChange={(value) =>
                                  editRateValue(item._id, {
                                    ...item.rates,
                                    holiday_overtime_hour: Number(value),
                                  })
                                }
                              />
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="table-primary text-primary text-center align-middle typo-body">
                          <td colSpan={10} className="align-middle py-3">
                            No items were found.
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>

                <div className="row justify-content-center">
                  <button
                    className="btn btn-success mb-2 mt-4 px-3 col-auto text-white"
                    onClick={() => setSaveModalOpen(true)}
                  >
                    CREATE NEW RATE CARD
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default Create;
