import { FormikErrors } from "formik";
import { BCreateSkillset } from "src/models/Bodies";
import InputFloatingLabel from "src/components/UI/forms/InputFloatingLabel";
import IconButton from "src/components/UI/buttons/IconButton";

interface BookableSkillProps {
  index: number;
  remove: (index: number) => void;
  value: BCreateSkillset;
  errors: FormikErrors<BCreateSkillset>;
  edit: (index: number, data: BCreateSkillset) => void;
}

export function BookableSkill({
  edit,
  index,
  value,
  remove,
  errors,
}: BookableSkillProps) {
  const editField = (data: Partial<BCreateSkillset>) => {
    edit(index, {...value, ...data});
  }

  return (
    <section className="mt-2">
      <div className="d-flex gap-3 text-primary align-items-center">
        <p style={{ width: '8%' }} className="mb-0">Skillset #{index+1}</p>
        <hr className="w-100" />
        <IconButton
          outline
          color="danger"
          icon="bi bi-x"
          tooltip="Remove skillset"
          onClick={() => remove(index)}
        />
      </div>

      <div className="row mt-2">
        <div className="col">
          <InputFloatingLabel
            isRequired
            value={value.skillset}
            placeHolder="Bookable skillset"
            errorMessage={errors?.skillset}
            onChange={(val) => editField({ skillset: val })}
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-3">
          <InputFloatingLabel
            placeHolder="Hour rate"
            errorMessage={errors?.rates?.hour}
            value={`${value?.rates?.hour || ''}`}
            onChange={(val) => editField({ rates: { ...value.rates, hour: parseFloat(val) } })}
          />
        </div>

        <div className="col-3">
          <InputFloatingLabel
            placeHolder="Day rate"
            errorMessage={errors?.rates?.daily}
            value={`${value?.rates?.daily || ''}`}
            onChange={(val) => editField({ rates: { ...value.rates, daily: parseFloat(val) } })}
          />
        </div>

        <div className="col-3">
          <InputFloatingLabel
            placeHolder="Week rate"
            errorMessage={errors?.rates?.week}
            value={`${value?.rates?.week || ''}`}
            onChange={(val) => editField({ rates: { ...value.rates, week: parseFloat(val) } })}
          />
        </div>

        <div className="col-3"></div>
      </div>
      
      <div className="row">
        <div className="col-3">
          <InputFloatingLabel
            placeHolder="Overtime hour rate"
            errorMessage={errors?.rates?.overtime_hour}
            value={`${value?.rates?.overtime_hour || ''}`}
            onChange={(val) => editField({ rates: { ...value.rates, overtime_hour: parseFloat(val) } })}
          />
        </div>

        <div className="col-3">
          <InputFloatingLabel
            placeHolder="Holiday hour rate"
            errorMessage={errors?.rates?.holiday_hour}
            value={`${value?.rates?.holiday_hour || ''}`}
            onChange={(val) => editField({ rates: { ...value.rates, holiday_hour: parseFloat(val) } })}
          />
        </div>

        <div className="col-3">
          <InputFloatingLabel
            placeHolder="Holiday day rate"
            errorMessage={errors?.rates?.holiday_day}
            value={`${value?.rates?.holiday_day || ''}`}
            onChange={(val) => editField({ rates: { ...value.rates, holiday_day: parseFloat(val) } })}
          />
        </div>

        <div className="col-3">
          <InputFloatingLabel
            placeHolder="Holiday overtime hour"
            errorMessage={errors?.rates?.holiday_overtime_hour}
            value={`${value?.rates?.holiday_overtime_hour || ''}`}
            onChange={(val) => editField({ rates: { ...value.rates, holiday_overtime_hour: parseFloat(val) } })}
          />
        </div>
      </div>
    </section>
  )
}