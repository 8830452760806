import { useState } from 'react';
import { useFormik } from 'formik';
import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import parseJwt from 'src/util/ParseJwt';
import useQuery from 'src/hooks/useQuery';
import authService from 'src/services/auth';
import { useToast } from 'src/hooks/useToast';
import { ApiError, InternalStatus } from 'src/models';
import logo from '../assets/images/PFP_logo_white.png';

const RecoverPassword: React.FC = () => {
  const toast = useToast();
  const query = useQuery();
  const history = useHistory();
  const token = query.get('token');

  const formik = useFormik({
    initialValues: { email: '', newPassword: '' },
    onSubmit: values => {
      if (!token) {
        recoverRequest(values.email);
      } else {
        recoverPassword(values.email, values.newPassword);
      }
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      const data = parseJwt<{ email: string }>(token);
      if (!data || !data.email) return;

      formik.setFieldValue('email', data.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const recoverRequest = (email: string) => {
    setIsLoading(true);
    authService.RecoverPasswordRequest(email)
      .then(() => {
        setIsLoading(false);
        return toast.success("Check your email to recover your password.");
      })
      .catch((err: AxiosResponse<ApiError>) => {
        setIsLoading(false);
        const code = err.data.statusCode.internal;

        switch (code) {
          case InternalStatus.USER_NOT_FOUND:
            formik.setErrors({ email: 'An user with the given email wasn\'t found.' });
            break;
          case InternalStatus.RECOVER_PASSWORD_REQUEST_ALREADY_SENT:
            formik.setErrors({ email: 'A recover password request was already sent to the given email.' });
            break;
          default:
            toast.error(`An error occurred while trying to recover your password. (${err.data.message})`);
            break;
        }
      })
  };

  const recoverPassword = (email: string, newPassword: string) => {
    if (!token) return;
    setIsLoading(true);

    authService.RecoverPassword({ email: email, token, password: newPassword, })
      .then(() => {
        setIsLoading(false);
        return toast.success("Your password was successfully changed.");
      })
      .catch((err: AxiosResponse<ApiError>) => {
        setIsLoading(false);
        const code = err.data.statusCode.internal;

        switch (code) {
          case InternalStatus.USER_NOT_FOUND:
            toast.error('An user with the given email wasn\'t found.');
            break;
          case InternalStatus.INVALID_TOKEN:
            toast.error("The token is invalid, please verify your email.");
            break;
          case InternalStatus.EXPIRED_TOKEN:
            toast.error("The token is expired, please generate a new one.");
            history.replace('/');
            break;
          default:
            toast.error(`An error occurred while trying to recover your password. (${err.data.message})`);
            break;
        }
      })
  }

  return (
    <div className='row col-lg-12 vh-100 '>
      <div className="d-flex flex-column align-items-center justify-content-center col-lg-6 pb-lg-0 pb-sm-5">
        <p className="text-white typo-display mb-4"></p>

        <div className="container-login">
          <div className="container-fluid bg-contrast d-flex justify-content-center">
            <form onSubmit={formik.handleSubmit} className="row w-100 pt-5 pb-5 px-3">
              <div className="col-12">
                <p className="typo-body text-primary text-center">Recovery password</p>
              </div>
              <div className="col-12">
                <fieldset className="input-icon mt-3 text-primary">
                  <i className="bi bi-envelope-fill mt-1 ms-2"></i>
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    id="input-recover-email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    disabled={isLoading || !!token}
                    className={"form-control py-2 typo-body-important text-primary" + (formik.errors.email ? " is-invalid" : "")}
                  />
                  {formik.errors.email && <div className="invalid-feedback">{formik.errors.email}</div>}
                </fieldset>

                {!!token && (
                  <fieldset className="input-icon mt-3 text-primary">
                    <i className="bi bi-lock-fill mt-1 ms-2"></i>
                    <input
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      id="input-recover-password"
                      onChange={formik.handleChange}
                      disabled={isLoading || !token}
                      value={formik.values.newPassword}
                      className={"form-control py-2 typo-body-important text-primary" + (formik.errors.newPassword ? " is-invalid" : "")}
                    />
                    {formik.errors.newPassword && <div className="invalid-feedback">{formik.errors.newPassword}</div>}
                  </fieldset>
                )}
              </div>

              <div className="col-12 d-flex justify-content-center mt-4">
                {!token && (
                  <button
                    type="submit"
                    className="btn btn-primary typo-body text-white"
                    disabled={isLoading || !!formik.errors.email}
                  >
                    Send email

                    {isLoading && (
                      <i className="bi bi-arrow-repeat animate-spin d-block"></i>
                    )}
                  </button>
                )}
                {!!token && (
                  <button
                    type="submit"
                    className="btn btn-primary typo-body text-white d-flex gap-1"
                    disabled={isLoading || !!formik.errors.newPassword}
                  >
                    Recover password

                    {isLoading && (
                      <i className="bi bi-arrow-repeat animate-spin d-block"></i>
                    )}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        <div className="row justify-content-between mt-2 container-login">
          <div className="col-auto p-0">
            <Link to="/login" className="typo-body text-primary">
              Do you have a account?
            </Link>
          </div>
          <div className="col-auto p-0">
            <p className="typo-body text-primary">Create new account</p>
          </div>
        </div>
        <div className='mt-5 me-3'>
          <img src={logo} className="sidebar-logo"  />
          <p className='text-muted'>@2022  All rights reserved</p>
        </div>
      </div>
      <div className='col-lg-6 vh-100 bg-primary  d-none d-sm-block'>

      </div>
    </div>
  );
};

export default RecoverPassword;
