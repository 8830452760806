import moment from 'moment';
import { useEffect, useState } from 'react';

import ConfirmModal from '../Modals/ConfirmModal';
import EditIcon from '../../assets/icons/edit.svg';
import TrashIcon from '../../assets/icons/trash.svg';

interface Props {
  title: string;
  isEditable?: boolean;
  texts: Array<{
    text: string;
    date?: any;
    transaction?: 'in' | 'out';
  }>;
  ActionDelete?: any;
  ActionUpdate?: any;
  style?: object
}

const QuickNotes: React.FC<Props> = (props) => {

  const [cancel, setCancel] = useState(false)
  const [item, setItem] = useState({} as any)

  useEffect(() => {
  } , [props.texts])


  const onDelete = (item: any, index: any) => {
    props.ActionDelete(item, index)
    setCancel(false)
  }

  return (
    <div className="pb-3">
      <p className="m-0 typo-body text-muted text-uppercase pb-1">
        {props.title}
      </p>
      {props.texts.map((item: any, index: any) => {
        return (
          <div className="container-fluid p-0 pb-1">
            <div className="row" >
              <div className="col-auto">
                <p key={index} className={`m-0 typo-body text-primary`}>
                  {moment(item?.date).format('DD/MM/YYYY HH:mma')}
                </p>
              </div>
              <div className="col-7 ps-2">
                <p key={index} className={`m-0 typo-body text-primary`}>
                  {item.text}
                </p>
              </div>

              <div className="col-auto d-flex align-items-center">
                {props.isEditable && (
                  <>
                    <img src={EditIcon} onClick={() => props.ActionUpdate(item?.id, item?.text)} style={{ width: '12px', height: '12px' }} className="mx-1" />
                    <img src={TrashIcon} onClick={() => {
                      setCancel(true)
                      setItem({ index: index, id: item?.id, })
                    }} style={{ width: '12px', height: '12px' }} className={'mx-1'} />
                  </>
                )}

                {item.transaction === 'in' && (
                  <i className="bi bi-arrow-left text-success h5 m-0 mx-1"></i>
                )}

                {item.transaction === 'out' && (
                  <i className="bi bi-arrow-right text-danger h5 m-0 mx-1"></i>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {cancel && <ConfirmModal
        title="Are you sure?"
        confirmText="CANCEL"
        cancelText="DELETE COMMENT"
        description="This comment will be deleted."
        onClose={(shouldDelete) => shouldDelete === false ? onDelete(item?.id, item?.index) : setCancel(false)}
      />}
      {/*props.isEditable && (
        <button className="btn btn-link btn-icon-text p-1">
          <i className="bi bi-plus-circle-fill"></i>
          <p>ADD QUICK NOTE</p>
        </button>
      )*/}
    </div>
  );
};

export default QuickNotes;
