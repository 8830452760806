import React, { useEffect, useState } from "react";
import logoIconblack from "../../../../../../assets/images/gameae.png";
import alps from "../../../../../../assets/images/alps.png";
import TablePreview from "./TablePreview";
import moment from "moment";

interface Props {
  ApproveQuote: any;
  projectDataId: any;
  quoteForm: any;
  setQuoteForm: any;
  QuotesId: any;
  Term_conditions: any;
  NotScrollable: boolean;
  totalQuote: number;
  setTotalQuote: (value: number) => void;
  totalTax: number;
  setTotaltax: (value: number) => void;
  totalAgency_fee: number;
  setTotalAgency_fee: (value: number) => void;
}

const Preview: React.FC<Props> = (props) => {
  const [client, setClient] = useState<any>([]);
  const [organization, setOrganization] = useState<any>([]);
  const selectData = () => {
    [props.projectDataId].map((e: any) => {
      if (e.client) {
        setClient(e.client.entity);
      }
      if (e.organization) {
        setOrganization(e.organization.entity);
      }
    });
  };
  const tab = "\u00A0";

  useEffect(() => {
    selectData();
  }, [props.projectDataId]);

  const filterHeader = props.quoteForm.headers.reduce((acc: any, curr: any) => {
    if (!acc[curr.name] && !curr.assigned_to) {
      acc = {
        ...acc,
        [curr.name]: props.quoteForm.headers.filter(
          (e: any) => e.assigned_to === curr.id
        ),
      };
    }

    return acc;
  }, {});

  const list = Object.keys(filterHeader).reduce((acc: any, curr) => {
    return [
      ...acc,

      {
        name: curr,
        total: filterHeader[curr].reduce(
          (acc2: any, current2: any) =>
            acc2 +
            Number(current2.quantity || 0) *
              Number(current2.rate || 0) *
              Number(current2.units_number || 0),
          0
        ),
      },
      ...filterHeader[curr],
    ];
  }, []);

  const totalItems = () => {
    const total2 = list.reduce((acc: any, el: any) => {
      return acc + (el.total || 0);
    }, 0);

    return total2;
  };

  const get_terms = props.Term_conditions?.filter(
    (fil: any) => fil._id === props.quoteForm.term
  );

  const get_content_term = get_terms?.map((cont: any) => cont.content);

  const formatNumber = (number: number) => {
    let n: string = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);

    return n;
  };

  const [tax, setTax] = useState<number>(0);
  const [agency_fee, setAgency_fee] = useState<number>(0);

  const TotalTable = [
    {
      title: "DISCOUNT",
      value: 0,
    },
    {
      title: "SUBTOTAL",
      value: formatNumber(totalItems()),
    },
    {
      title: "AGENCY FEE",
      value: <span>{agency_fee}</span>,
    },
    {
      title: "TAX",
      value: <span>{tax}</span>,
    },
  ];

  return (
    <div
      className={`${
        !props.NotScrollable && "custom-scrollbar overflow-auto "
      } m-0 p-0`}
      style={{ width: "100%", height: "690px" }}
    >
      <div className="d-flex flex-column mt-2 m-0 p-0">
        <div className="d-flex pe-4 ps-4">
          <div>
            <img
              src={logoIconblack}
              alt=""
              style={{ width: "200px", height: "65px" }}
              className=" "
            />
          </div>
          <div className="ms-auto">
            <img
              src={alps}
              alt=""
              style={{ width: "200px", height: "65px" }}
              className=""
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex col-3 mt-2 p-2 ms-3 justify-content-around"
        style={{ width: "1060px" }}
      >
        <div className="flex-column me-5">
          <p
            className="text-secondary  d-flex justify-content-start"
            style={{ fontSize: "15px" }}
          >
            <span className="fw-bolder me-1">Organization: </span>
            {organization.name}
          </p>
          <p
            className="text-secondary  ms-auto d-flex ms-auto"
            style={{ marginTop: "-17px", fontSize: "15px" }}
          >
            <span className="fw-bolder  d-flex justify-content-start me-1">
              Client:
            </span>
            {client.title + tab + client.first_name + tab + client.last_name}
          </p>
        </div>

        <div className="flex-column me-4">
          <p
            className="text-secondary  d-flex justify-content-start"
            style={{ fontSize: "14px" }}
          >
            <span className="fw-bolder me-1">Project:</span>
            {props.projectDataId.name}
          </p>
          <p
            className="text-secondary   d-flex justify-content-start"
            style={{ marginTop: "-17px", fontSize: "15px" }}
          >
            <span className="fw-bolder me-1">Job:</span>
            element
          </p>
        </div>

        <div className="flex-column me-4 ">
          <p
            className="text-secondary d-flex justify-content-start"
            style={{ fontSize: "15px" }}
          >
            <span className="fw-bolder me-1">Quote Number:</span>
            {props.QuotesId?._id}
          </p>
          <p
            className="text-secondary  d-flex justify-content-start"
            style={{ marginTop: "-17px", fontSize: "15px" }}
          >
            <span className="fw-bolder me-1">Date:</span>
            {moment(props.QuotesId?.created_at?.slice(0, -1)).format(
              "MM/DD/YYYY"
            )}
          </p>
        </div>
        <div className="flex-column me-auto">
          <p
            className="text-secondary d-flex justify-content-start"
            style={{ fontSize: "15px" }}
          >
            <span className="fw-bolder me-1 ">Quote expiration date:</span>

            {moment(props.QuotesId?.due_date?.slice(0, -1)).format(
              "MM/DD/YYYY"
            )}
          </p>
          <p
            className="text-secondary  ms-auto d-flex ms-auto"
            style={{ marginTop: "-17px", fontSize: "15px" }}
          >
            <span className="fw-bolder d-flex justify-content-start me-1">
              Payment terms:
            </span>
          </p>
        </div>
      </div>

      <div
        className="me-4 ms-4"
        style={{ textAlign: "justify", fontSize: "14px" }}
      >
        <p>{props.quoteForm.description}</p>
      </div>
      <TablePreview
        quoteForm={props.quoteForm}
        setQuoteForm={props.setQuoteForm}
        filterHeader={filterHeader}
        list={list}
      />
      <div
        className="container-column ms-auto mb-3"
        style={{
          width: "22.5vw",
          zIndex: "1",
          paddingTop: "50px",
        }}
      >
        {props.quoteForm.headers.length > 1 ? (
          <table className="table table-borderless ">
            <thead className="">
              <tr className="text-primary typo-body">
                <th scope="col" className="">
                  <p className="HeadTableFonts">QUOTE SUMMARY</p>
                </th>

                <th scope="col">
                  <p className="HeadTableFonts">COST</p>
                </th>
                <th scope="col">
                  <p className="HeadTableFonts ms-4">MARGIN</p>
                </th>
                <th scope="col">
                  <p className="HeadTableFonts ms-4">TOTAL</p>
                </th>
              </tr>
            </thead>
            {TotalTable.map((ee: any, index) => {
              return (
                <tbody>
                  <tr
                    className={`${
                      (index / 2) % 1 > 0 ? "table-secondary" : ""
                    } text-primary typo-body`}
                    key={index}
                    style={{ cursor: "pointer" }}
                  >
                    <td
                      className="align-middle general-text-table-factory"
                      style={{ height: "40px" }}
                    >
                      <span className="d-flex">{ee.title}</span>
                    </td>

                    <td className="align-middle general-text-table-factory ">
                      {index === 1 ? ee.value : ""}
                    </td>
                    <td className="align-middle general-text-table-factory ">
                      {
                        index === 1 ? ee.value : ""
                        // index === 2 && props.totalAgency_fee !== 0
                        // ? formatNumber(props.totalAgency_fee)
                        // : index === 3 && props.totalTax !== 0
                        // ? formatNumber(props.totalTax)
                        // : ""
                      }
                    </td>
                    <td
                      className="align-middle general-text-table-factory "
                      style={{ width: "10px" }}
                    >
                      <div style={{ width: "100px" }}>
                        {index === 1
                          ? ee.value
                          : index === 2 && props.totalAgency_fee !== 0
                          ? formatNumber(props.totalAgency_fee)
                          : index === 3 && props.totalTax !== 0
                          ? formatNumber(props.totalTax)
                          : ""}
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ) : (
          []
        )}

        <div
          className="col-md-12  bg-light-blue  p-2  d-flex  justify-content-center align-items-center text-black mt-4"
          style={{ height: "36px" }}
        >
          <span className="ms-2">Total:</span>
          <span className="ms-auto me-4">{formatNumber(props.totalQuote)}</span>
        </div>
      </div>
      <div
        className="container-column ms-auto mb-3"
        style={{
          width: "13.5vw",
        }}
      >
        {/* <div className="row mt-3">
          <p className="text-gray " style={{ fontSize: "15px" }}>
            QUOTE SUMMARY<span className="ms-4"> {}</span>
          </p>
          <div
            className="col-md-12  bg-light-blue  p-2  d-flex  justify-content-center align-items-center "
            style={{ height: "32px", marginTop: "-10px", width: "269px" }}
          >
            <span
              className="me-auto"
              style={{ fontSize: "15px", marginLeft: "45px" }}
            >
              Total:
            </span>
            <span className="me-4 " style={{ fontSize: "15px" }}>
              {formatNumber(totalItems())}
            </span>
          </div>
        </div> */}
      </div>

      <div>
        <h2 className="text-secondary fw-bold mt-5">Term & Conditions</h2>

        <div
          className="ms-4 me-4 p-2"
          style={{ textAlign: "justify", fontSize: "14px" }}
        >
          <p>{get_content_term}</p>
        </div>
        <div className="d-flex  justify-content-around p-5">
          <button
            className="btn btn-danger text-white fw-bold"
            style={{ height: "40px" }}
          >
            Reject quote
          </button>
          <button className="btn btn-info text-white fw-bold">
            Request changes
          </button>
          <button
            className="btn btn-success text-white fw-bold"
            // onClick={props.ApproveQuote}
          >
            Approve quote
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
