import { AxiosResponse } from 'axios';
import isEqual from 'lodash.isequal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createRef, ForwardedRef, ForwardRefExoticComponent, FunctionComponent, RefAttributes, useEffect, useMemo, useState } from 'react';

import { ApiError } from 'src/models';
import { UploadError } from 'src/util';
import { RootState } from 'src/store/store';
import { useToast } from 'src/hooks/useToast';
import { useRequest } from 'src/hooks/useRequest';
import { BCreateSkillset } from 'src/models/Bodies';
import ConfirmModal from '../../Modals/ConfirmModal';
import { SkillsetsService } from 'src/services/skillsets';
import { BlackbookService } from 'src/services/blackbook';
import { ContactTypeEx } from 'src/util/ContactsFunctions';
import StepButton from 'src/components/UI/buttons/stepButton';
import { createContactForm } from 'src/store/blackbook/createContactSlice';
import { ContactsFilesFields, ContactType, IContact } from 'src/models/ContactModel';

import Notes from '../AddContact/tabs/Freelance/Notes';
import EmergencyContacts from '../AddContact/tabs/Freelance/EmergencyContacts';
import FreelanceDetails from '../AddContact/tabs/Freelance/FreelanceDetails/FreelanceDetails';
import FreelancePersonalInformation from '../AddContact/tabs/Freelance/PersonalInformation/PersonalInformation';

import ClientOrganization from '../AddContact/tabs/Client/Organization';
import ClientPersonalInformation from '../AddContact/tabs/Client/PersonalInformation/PersonalInformation';

import TMPersonalInformation from '../AddContact/tabs/TeamMember/PersonalInformation';

import OrganizationNote from '../AddContact/tabs/Organization/Note';
import OrganizationDocuments from '../AddContact/tabs/Organization/Documents';
import OrganizationType from '../AddContact/tabs/Organization/OrganizationType';
import OrganizationGeneralInformation from '../AddContact/tabs/Organization/General/General';

import ProspectNotes from '../AddContact/tabs/Prospect/Notes';
import ProspectPersonalInformation from '../AddContact/tabs/Prospect/PersonalInformation';
import { layoutConfig } from 'src/store/layout';

interface TabRef {
  handleValidation: () => Promise<boolean>;
}

export interface TabsContent {
  title: string;
  ref: ForwardedRef<TabRef>;
  type?: ContactType | 'all';
  props?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Component: ForwardRefExoticComponent<any & RefAttributes<TabRef>> | FunctionComponent<{ ref: TabsContent['ref'] }>;
}

const EditContact: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ contactId: string; }>();

  const toast = useToast();

  const { data: contact, error } = useRequest<IContact>(params?.contactId ? { url: `blackbook/contacts/${params.contactId}` } : null);
  
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data)

  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  
  useEffect(() => {
    dispatch(layoutConfig.setModuleTitle('BLACKBOOK - EDIT CONTACT'));
  }, []);

  useEffect(() => {
    if (contact && !error) {
      /* @ts-ignore */
      dispatch(createContactForm.setData({ ...contact.entity, contact_since: contact.contact_since, note: contact.note, appliedInitialValues: data.appliedInitialValues }, 'initialValues', false));
      /* @ts-ignore */
      dispatch(createContactForm.setField('data', { ...contact.entity, contact_since: contact.contact_since, note: contact.note }, ''));

      for(const field of ContactsFilesFields) {
        /* @ts-ignore */
        if(contact.entity[field]) dispatch(createContactForm.setFiles({ [field]: contact.entity[field] }));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, error]);
  
  const isContactEdited = useMemo(() => {
    if (!contact) return false
    
    return !(isEqual(data.initialValues, { ...data.initialValues, ...data.data}) &&
    data.initialValues?.contact_since === contact.contact_since &&
    data.initialValues?.note === contact.note)
  }, [contact, data.data, data.initialValues]);

  const contactExInfo = useMemo(() => !contact ? ContactTypeEx.FREELANCE : ContactTypeEx[contact?.type], [contact]);
  
  const tabsContent = useMemo<TabsContent[]>(() => [
    { Component: FreelancePersonalInformation, ref: createRef(), title: 'Personal information', type: ContactType.FREELANCE },
    { Component: FreelanceDetails, props: { for: 'freelance' }, ref: createRef(), title: 'Freelance details', type: ContactType.FREELANCE },
    { Component: EmergencyContacts, ref: createRef(), title: 'Emergency contacts', type: ContactType.FREELANCE },
    { Component: Notes, ref: createRef(), title: 'Notes', type: ContactType.FREELANCE },
    
    { Component: ClientPersonalInformation, ref: createRef(), title: 'Personal information', type: ContactType.CLIENT },
    { Component: ClientOrganization, ref: createRef(), title: 'Organization', type: ContactType.CLIENT },
    { Component: Notes, ref: createRef(), title: 'Notes', type: ContactType.CLIENT },

    { Component: OrganizationType, ref: createRef(), title: 'Organization type', type: ContactType.ORGANIZATION },
    { Component: OrganizationGeneralInformation, ref: createRef(), title: 'General information', type: ContactType.ORGANIZATION },
    { Component: OrganizationDocuments, ref: createRef(), title: 'Corporate Documents', type: ContactType.ORGANIZATION },
    { Component: OrganizationNote, ref: createRef(), title: 'Note', type: ContactType.ORGANIZATION },

    { Component: ProspectPersonalInformation, ref: createRef(), title: 'Personal information', type: ContactType.PROSPECT },
    { Component: ProspectNotes, ref: createRef(), title: 'Notes', type: ContactType.PROSPECT },

    { Component: TMPersonalInformation, ref: createRef(), title: 'Personal information', type: ContactType.TEAM_MEMBER },
    { Component: FreelanceDetails, props: { for: 'teammember' }, ref: createRef(), title: 'Details', type: ContactType.TEAM_MEMBER },
    { Component: EmergencyContacts, ref: createRef(), title: 'Emergency contacts', type: ContactType.TEAM_MEMBER },
    { Component: Notes, ref: createRef(), title: 'Notes', type: ContactType.TEAM_MEMBER },
  ], []);

  const renderedTabs = useMemo(() => tabsContent.filter((tab) => (tab.type && tab.type === contact?.type) || tab.type === 'all'), [tabsContent, contact]);

  const handleSave = async () => {
    if (!contact) return

    const tab = renderedTabs[currentTab];

    ;(async () => {
      if (tab.ref) {
        /* @ts-ignore */
        const validateFn: TabRef['handleValidation'] = tab.ref?.current?.handleValidation;
  
        validateFn && validateFn()
          .then((isValid) => {
            if (!isValid) {
              toast.error({ message: 'Please fill all required fields.', uniqueId: 'add-contact-step-invalid' });
              return;
            }
          })
      }
    })();

    if (!isContactEdited) {
      toast.warn({ message: 'No changes detected.', uniqueId: 'add-contact-step-no-changes' });
      return
    }

    setIsSaving(true);

    const dataCopy = JSON.parse(JSON.stringify(data.data));

    if ('bookable_skills' in dataCopy) {
      let createdSkillsets: string[] = [];

      try {
        const createRes = await SkillsetsService.UpdateManySkillsets({
          skillsets: (dataCopy.bookable_skills as (BCreateSkillset & { id: string })[]).filter(skillset => typeof skillset !== 'string').filter((skillset) => skillset.id !== undefined)
        }, 'blackbook')

        createdSkillsets = createRes?.skillsets?.map((skillset) => skillset._id);
      } catch (error: any) {
        if (error.data) {
          const _err: AxiosResponse<ApiError<UploadError[]>> = error;
          toast.error({ message: [
            `Error while updating skillsets:`,
            ..._err.data.message.map((err) => typeof err === 'string' ? err : `${err.field} - ${err.error}`)
          ] });
          return
        }
        
        toast.error({ message: ['Error while updating skillsets.', error.message] });
        return;
      }

      dataCopy.bookable_skills = createdSkillsets;
    }

    BlackbookService.EditContact(contact._id, {
      contact_since: data.contact_since,
      ...(data.note ? { note: data.note } : {}),
      data: { ...data.initialValues, ...dataCopy },
    }, data.files).then((contact) => {
      setIsSaving(false);
      dispatch(createContactForm.clearData({}));
      toast.success('Contact updated successfully.');
      return history.push(`/blackbook/${contact._id}`);
    }).catch((err: AxiosResponse<ApiError>) => {
      setIsSaving(false);
      return toast.error(err.data.message);
    })
  }

  const onCancelModalClose = (confirm?: boolean) => {
    setIsCancelling(false);
    if(confirm !== undefined && !confirm) {
      dispatch(createContactForm.clearData({}));
      history.goBack();
    }
  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    const tab = renderedTabs[currentTab];

    if (tab.ref) {
      /* @ts-ignore */
      const validateFn: TabRef['handleValidation'] = tab.ref?.current?.handleValidation;

      validateFn && validateFn()
        .then((isValid) => {
          if (!isValid) {
            toast.error({ message: 'Please fill all required fields for the current step.', uniqueId: 'add-contact-step-invalid' });
            return;
          };

          if (currentTab < renderedTabs.length - 1) {
            return setCurrentTab((prev) => prev + 1);
          } else {
            return handleSave()
          }
        })
    }
  };

  const handleTabClick = (index: number) => {
    if (index === currentTab) return;

    if (index > currentTab) {
      for(let i = currentTab; i < index; i++) {
        nextStep();
      }
    } else {
      for(let i = currentTab; i > index; i--) {
        prevStep();
      }
    }
  }

  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new contact will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD EDITING"
          onClose={onCancelModalClose}
        />
      )}

      {isSaving && (
        <ConfirmModal
          title="Saving..."
          onClose={() => {}}
          buttons={(
            <div className="d-flex justify-content-center align-items-center h-100">
              <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
            </div>
          )}
          description="Wait while we save your changes..."
        />
      )}

      <div className="container-fluid h-100">
        <div className="row" style={{ height: '98%' }}>
          <div className="col card h-100 custom-scrollbar overflow-y">
            {!contact && !error && (
              <div className="d-flex justify-content-center align-items-center h-100">
                <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
              </div>
            )}
            
            {!contact && error && (
              <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted">
                <i className="fs-3 bi bi-exclamation-triangle d-block"></i>
                <p className="h6">Error while loading the contact information.</p>
              </div>
            )}

            {contact && !error && (
              <>
                {/* Top actions */}
                <div className="sticky-top bg-white row align-items-center p-2">
                  <button
                    className="btn btn-outline-danger typo-body col-auto border-0"
                    onClick={() => {
                      if(isContactEdited) { setIsCancelling(true); }
                      else {
                        dispatch(createContactForm.clearData({}));
                        history.goBack();
                      }
                    }}
                  >
                    Cancel {!isContactEdited && (<small>(Nothing changed)</small>)}
                  </button>

                  <div style={{ flex: 1 }}></div>

                  {renderedTabs[currentTab-1] !== undefined && (
                    <StepButton previous onClick={prevStep} />
                  )}

                  {currentTab < renderedTabs.length - 1 && (
                    <>
                      <StepButton onClick={nextStep} next />
                    </>
                  )}
                  
                  <button
                    onClick={handleSave}
                    className="btn btn-outline-success col-auto typo-body border-0 order-3"
                  >
                    Save contact
                  </button>
                </div>

                {/* Tab View */}
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <ul className="nav nav-tabs mt-3 justify-content-between">
                        <ul className="row">
                          {renderedTabs.map((item, index) => {
                            if (!contact || !item.type) return null;

                            return (
                              <li
                                key={index}
                                onClick={() => handleTabClick(index)}
                                className={`nav-item col-auto p-0 cursor-pointer` + (index === currentTab ? ' cursor-default' : '')}
                              >
                                <p
                                  className={`nav-link typo-body user-select-none ${
                                    currentTab === index ? 'active' : ''
                                  }`}
                                  aria-current="page"
                                >
                                  {item.title}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </ul>

                      <div className="pt-2" />

                      {renderedTabs.map((Tab, index) => {
                        const Component = Tab.Component;
                        if (!contact || !Tab.type) return null;
                        if (!tabsContent[index]) return null;
                        if (index !== currentTab) return null;

                        return (
                          <Component {...Tab.props} ref={Tab.ref} key={index} />
                        )
                      })}
                      
                      <div className="mb-5"></div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditContact;
