import React, { useState } from "react";
import moment from "moment";
import TextArea from "../../../../UI/forms/TextArea";
import EditIcon from '../../../../../assets/icons/edit.svg';
import TrashIcon from '../../../../../assets/icons/trash.svg';
import { IComment } from 'src/models/CommentModel';

interface Props {
}

const QuoteTimeline: React.FC<Props> = (props) => {
  const [note, setNote] = useState<string>("");

  return (
    <>
      <div className="container-fluid px-0 py-3 mt-2 w-100 container-project-detail overflow-y overflow-x-none custom-scrollbar"> 
        <div className="row mx-4 row g-0 gap-0 timeline-item">
          <div className="col-12 d-flex">
              <p className="me-3 text-primary typo-body timeline-item-date">Today at 12:36 AM</p>
              <div className="d-flex position-relative mx-2 px-4 pb-3 timeline-item-content">
                  <div className="position-absolute top-0 start-0 timeline-item-step-container">
                      <span className="timeline-item-step d-inline-block "></span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center w-100">
                      <div>
                          <p className="m-0 text-secondary typo-body contact-name fw-bolder timeline-item-title">Quote Status changed</p>
                          <p className="m-0 text-primary typo-body timeline-item-detail">The status changed from In Review to Approved</p>
                      </div>
                  </div>
              </div>
          </div> 
        </div>
        <div className="row mx-4 row g-0 gap-0 timeline-item">
          <div className="col-12 d-flex">
              <p className="me-3 text-primary typo-body timeline-item-date">Today at 10:36 AM</p>
              <div className="d-flex position-relative mx-2 px-4 pb-3 timeline-item-content">
                  <div className="position-absolute top-0 start-0 timeline-item-step-container">
                      <span className="timeline-item-step d-inline-block "></span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center w-100">
                      <div>
                          <p className="m-0 text-secondary typo-body contact-name fw-bolder timeline-item-title">FeedBack</p>
                          <p className="m-0 text-primary typo-body timeline-item-detail">FeedBack Added</p>
                      </div>
                  </div>
              </div>
          </div> 
        </div>
      </div>
    </>

  );
};

export default QuoteTimeline;
