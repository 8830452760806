import React from 'react';
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface Toast {
  id: string;
  module: string;
  timeout?: number;
  uniqueId?: string;
  content?: React.ReactNode;
  message: string | string[];
  type: 'primary' | 'success' | 'error' | 'info';
}

interface ToastsNotificationsState {
  toasts: Toast[];
}

const initialState: ToastsNotificationsState = {
  toasts: [],
};

const toastsNotificationsSlice = createSlice<ToastsNotificationsState, SliceCaseReducers<ToastsNotificationsState>>({
  name: 'toasts-notifications',
  initialState,
  reducers: {
    addToast: {
      reducer: (state, action: PayloadAction<Toast>) => {
        if (action.payload.uniqueId && state.toasts.some((toast) => toast.uniqueId === action.payload.uniqueId)) return

        state.toasts.push(action.payload);
      },
      prepare: (toast: Toast) => {
        return { payload: toast };
      }
    },
    removeToast: {
      reducer: (state, action: PayloadAction<string>) => {
        state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
      },
      prepare: (id: string) => {
        return { payload: id };
      }
    }
  },
});

export const toastsStore = toastsNotificationsSlice.actions;

export default toastsNotificationsSlice.reducer;
