import React from "react";
import InputSearch from "../../../UI/forms/InputSearch";

interface Props {}

const Suppliers: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid p-2">
      <div className="row justify-content-between p-0">
        <div className="col-4 d-flex">
          <InputSearch placeholder="Search supplier" className="w-100" />

          <button className="btn btn-outline-primary col-auto ms-2">
            <i className="bi bi-funnel-fill h5 m-0"></i>
          </button>
        </div>

        <button className="btn btn-link shadow-none col-auto">
          VIEW SUPPLIER REPORT
        </button>
      </div>

      <div className="w-100 custom-scrollbar overflow-x mt-4">
        <table className="table table-borderless">
          <thead>
            <tr className="text-primary typo-body">
              <th scope="col">SUPPLIER</th>
              <th scope="col">CATEGORY</th>
              <th scope="col">PRIMARY CONTACT</th>
              <th scope="col">PRIMARY EMAIL</th>
              <th scope="col">PRIMARY PHONE</th>
            </tr>
          </thead>

          <tbody>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
              return (
                <tr
                  className={`${
                    (index / 2) % 1 > 0 ? "table-primary" : ""
                  } text-primary typo-body`}
                  key={index}
                >
                  <td className="d-flex align-items-center">
                    <img
                      className="avatar2"
                      src="https://m.media-amazon.com/images/I/51P84188A4L._AC_SX466_.jpg"
                      alt=""
                    />

                    <p className="m-0 ms-2">Rentals inc.</p>
                  </td>

                  <td className="align-middle">Video</td>
                  <td className="align-middle">Mr. Bob parr</td>
                  <td className="align-middle">bp@financesplus.inc</td>
                  <td className="align-middle">+(000) 000-000-0000</td>

                  <td className="align-middle">
                    <div className="col-auto d-flex align-items-center">
                      <i className="bi bi-chat-left-fill text-primary mx-1"></i>

                      <input
                        className="form-check-input p-0 m-0 mx-1"
                        type="checkbox"
                      />

                      <i className="bi bi-trash text-danger mx-1"></i>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Suppliers;
