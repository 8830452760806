import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'src/hooks/useRequest';
import ListTile from '../../../../UI/ListTile';

const Items: React.FC = () => {
  const params = useParams<{ itemId: string }>();

  const { data: ITEMS } = useRequest<any>({ url: 'stockpile/item-groups/' + params.itemId })

useEffect(() => {
  
} , [ITEMS])

  return (
    <div className="container-fluid px-3">
      <div className="row p-0 py-3 ">
        <p className="m-0 typo-body text-muted text-uppercase pb-1">Items</p>

        {
          ITEMS?.items?.map((item: any) => 
            <div className="col-6">
              <ListTile
                title={item.name}
                leadingImage={item.picture_url && process.env.REACT_APP_IMAGES_AWS + item.picture_url}
              />
            </div>
          )
        }


      </div>
    </div>
  );
};

export default Items;
