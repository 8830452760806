import React, { useMemo, useState } from "react";
import moment from "moment";
import InfoDisplay from "../../../../UI/InfoDisplay";
import { Link } from "react-router-dom";
import QuoteStatusModal from "../../../../Modals/Factory/Quotes/QuoteStatusModal";
import { QuoteStatusType } from "../../../../../models/QuotesModel";

interface Props {
  quoteData: any;
  updatedStatusData: QuoteStatusType,
  handleChangeStatus: (status: QuoteStatusType) => void;
}

const QuoteDetails: React.FC<Props> = (props) => {
  const [isOpenChangeStatusModal, setIsOpenChangeStatusModal] = useState<boolean>(false);

  const handleChangeStatus = (status: QuoteStatusType) => {
    props.handleChangeStatus(status);
    setIsOpenChangeStatusModal(false);
  }

  const colorClass = useMemo(() => {
    const currentStatus = props.updatedStatusData || props.quoteData?.status || "";
    const kebabCase = currentStatus.trim().replace(" ","-").toLowerCase();
    return kebabCase;
  },[props.updatedStatusData, props.quoteData])

  return (
    <div className="container-fluid p-0 mt-2 w-100 container-project-detail overflow-y overflow-x-none custom-scrollbar">
      <QuoteStatusModal
        isOpen={isOpenChangeStatusModal}
        handleConfirm={handleChangeStatus}
        onClose={() => setIsOpenChangeStatusModal((prev) => !prev)}
        currentStatus={props.updatedStatusData || props.quoteData?.status}
      />
      <div className="row mx-4 py-2 border-bottom">
        <div className="col-3">
          <InfoDisplay
            title="quote description"
            texts={[
              {
                text: props.quoteData?.description,
              },
            ]}
          />
        </div>
      </div>
      <div className="row mx-4 mt-3 border-bottom">
        <div className="col-3">
          <div className="pb-3">
            <h6 className="m-0 typo-body text-muted mt-2">PROJECT</h6>
            <Link
              to={`/factory/project/detail/${props.quoteData?.project?._id}`}
              className="typo-body"
            >
              {props.quoteData?.project?.name}
            </Link>
          </div>
        </div>
        <div className="col-3">
          <div className="pb-3">
            <h6 className="m-0 typo-body text-muted mt-2">JOB</h6>
            <Link
              to={""}
              className="typo-body"
            >
              {props.quoteData?.job?.name}
            </Link>
          </div>
        </div>
        <div className="col-3">
          <div className="pb-3">
            <h6 className="m-0 typo-body text-muted mt-2">CLIENT</h6>
            <Link 
              to={`/blackbook/${props.quoteData?.organization?._id}`}
              className="typo-body"
            >
              {props.quoteData?.organization?.entity?.name}
            </Link>
          </div>
        </div>
        <div className="col-3">
          <div className="pb-3">
            <h6 className="m-0 typo-body text-muted mt-2">CLIENT</h6>
            <Link
              to={`/blackbook/${props.quoteData?.client?._id}`}
              className="typo-body"
            >
              {`${props.quoteData?.client?.entity?.first_name} ${props.quoteData?.client?.entity?.last_name}`}
            </Link>
          </div>
        </div>
        <div className="col-3">
          <InfoDisplay title="quote status" texts={[]}>
          <span
            className={`me-2 p-1 rounded-1 typo-body d-inline quote-status-badge ${colorClass}`}
          >
            {props.updatedStatusData || props.quoteData?.status}
          </span>
          <button
            type="button"
            className="btn btn-light-gray typo-body btn-sm "
            style={{
              fontSize: "10px",
            }}
            onClick={() => setIsOpenChangeStatusModal(true)}
          >
            CHANGE
          </button>
          </InfoDisplay>
        </div>
      </div>
      <div className="row mx-4 mt-3 border-bottom">
        <div className="col-3">
          <InfoDisplay
            title="quote number"
            texts={[
              {
                text: props.quoteData?._id,
              },
            ]}
          />
        </div>
        <div className="col-3">
          <InfoDisplay
            title="quote due date"
            texts={[
              {
                text: moment(props.quoteData?.due_date).calendar(),
              },
            ]}
          />
        </div>
      </div>
      <div className="row mx-4 mt-3 border-bottom">
        <div className="col-3">
          <InfoDisplay
            title="target budget"
            texts={[
              {
                text: props.quoteData?.target_budget,
              },
            ]}
          />
        </div>
        <div className="col-3">
          <InfoDisplay
            title="quote total"
            texts={[
              {
                text: props.quoteData?.total,
              },
            ]}
          />
        </div>
        <div className="col-3">
          <InfoDisplay
            title="cost"
            texts={[
              {
                text: props.quoteData?.cost,
              },
            ]}
          />
        </div><div className="col-3">
          <InfoDisplay
            title="margin"
            texts={[
              {
                text: props.quoteData?.margin,
              },
            ]}
          />
        </div><div className="col-3">
          <InfoDisplay
            title="difference target vs. total"
            texts={[
              {
                text: props.quoteData?.total - props.quoteData?.target_budget,
              },
            ]}
          />
        </div>
      </div>
      <div className="row mx-4 mt-3">
        <div className="col-3">
          <InfoDisplay
            title="quote link"
            texts={[
              {
                text: 'www.google.com',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteDetails;
