import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setItemPackage } from "../../../store/stockpile/kitItems";
import { setIdItem } from "../../../store/stockpile/selectAssetsSlice";
import DoubleClick from "../../Behaviors/DoubleClicks";
import { RootState } from '../../../store/store'
import { updateAccesoriesMandatory, updateAccesoriesOptional } from "src/store/stockpile/createFormSlice";
import EmptyCircle from "src/components/UI/EmptyCircle";


interface Props {
  onSelect?: (id: string) => void;
  item: any;
  setID: any,
  showAssets: any,
  index: any,
  setSelected: any,
  indexItem: any,
  indexItemSelected: any;
  preSave: any
}

const ContactItem: React.FC<Props> = (props) => {
  let count1 = 10
  let count2 = 20

  const dispatch = useDispatch()

  const Mandatory = useSelector((state: RootState) => state.createFormSlice.AccesoriesOptionalData);

  const [isChecked, setIsChecked] = useState(false);

  const [click, setClick] = useState<number>(0)

  const handleAsset = () => {
    props.showAssets(true)
    dispatch(setIdItem(props.item._id))
  }


  const AddItemToPackage = () => {
    setIsChecked(!isChecked);
    if(isChecked == false){
      let copy:Array<any> = props.indexItemSelected
      //copy.splice(props.index, 0, props.item?._id)
        copy[props.index] =  props.item?._id;
      
      props.setSelected([...copy])

    }

    if(isChecked == true){
      let copy = props.indexItemSelected
     copy[props.index] = '' 
      props.setSelected([...copy])
    }
  //  dispatch(updateAccesoriesMandatory({ index: props.index, data: props.item?._id }))

    props.preSave(props.item?._id)

    /*
    */
  }
  useEffect(() => {
    }, [props.indexItemSelected])

  useEffect(() => {
  // props.setSelected('')
  if(Mandatory[props.index] != ''){
    setIsChecked(!isChecked)
  }

  }, [])

  return (
    <DoubleClick onDoubleClick={() => {}} >
      <div className={`container-fluid border-top p-3 py-2 contact-item`}
        style={props.indexItemSelected[props.index] && props.indexItemSelected[props.index] != props.item?._id ? { pointerEvents: 'none', opacity: 0.2 } : {}}
      >
        <div className="row align-items-center">
          {props.onSelect && (
            <div className="col-auto p-0 ps-2">
              <input className="form-check-input" type="checkbox"
                onChange={() => AddItemToPackage()}
                checked={props.indexItemSelected[props.index] == props.item?._id && isChecked}
              />
            </div>
          )}

          <div
            className={`col d-flex p-0 text-truncate ${props.onSelect ? 'ms-3' : 'ms-2'
              }`}
          >
          {props.item.picture_url && <img loading="lazy" src={process.env.REACT_APP_IMAGES_AWS + props.item.picture_url} alt="" className="avatar2" />
          }
          {
            !props.item.picture_url && <EmptyCircle title={props.item.name} small />
          }
            <div className="ms-2">
              <p className="m-0 text-black typo-body contact-title">
                {props.item.name}
              </p>
              <p className="m-0 text-black typo-body contact-subtitle">
                {props.item.name}
              </p>
            </div>
          </div>

          <div className="col-auto p-0 pe-1">
            {count1 && (
              <p className="m-0 contact-trailing typo-body bg-primary my-1">
                {props.item.internal_assets}
              </p>
            )}
            {count2 && (
              <p className="m-0 contact-trailing typo-body bg-success my-1">
                {props.item.external_assets}
              </p>
            )}
          </div>
        </div>
      </div>
    </DoubleClick>
  );
};

export default ContactItem;
