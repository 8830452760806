interface Props {
  title: string;
  texts: {
    text: string;
    date: string;
  }[];
}

const InfoHistory: React.FC<Props> = (props) => {
  return (
    <div className="pb-3">
      <p className="m-0 typo-body text-muted text-uppercase pb-1">
        {props.title}
      </p>
      {props.texts.map((item, index) => {
        return (
          <div className="container-fluid p-0 pb-1">
            <div className="row">
              <div className="col-auto">
                <p key={index} className={`m-0 typo-body text-primary`}>
                  {item.date}
                </p>
              </div>
              <div className="col ps-2">
                <p key={index} className={`m-0 typo-body text-primary`}>
                  {item.text}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InfoHistory;
