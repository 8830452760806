import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddAsset, deleteAssetPackage } from "src/store/stockpile/packageItems";
import { RootState } from "src/store/store";

interface AssetItemProps {
    item: any,
    key: number
    selecteState: boolean
    onClick: any
    maxValue?: any;
    indexMaxValue: any,
    setMaxValue: any,
    view: any
}

const AssetSingleItem = (props: AssetItemProps) => {
    const dispatch = useDispatch()
    const item = props.item
    const AssetsPackage: any = useSelector((state: RootState) => state.packageItems.PackageAssets);

    const [itemValue, setItemValue] = useState<any>(false)

    const [test, setTest] = useState<any>(false)


    const checkState = () => {
        for (var i = 0; i < AssetsPackage[props.indexMaxValue].length; i++) {
            if (AssetsPackage[i]?._id == item?._id) {
                setItemValue(!itemValue)
                break;
            }
        }
    }

    const checkState2 = () => {

        AssetsPackage[props.indexMaxValue].map((item: any) => {
            if (item?._id == props.item?._id) {
                setItemValue(true)
            }
        })

    }


    useEffect(() => {
        // checkState2()
        /*let dt = AssetsPackage[props.indexMaxValue].filter((item:any) => item?._id == props.item?._id )
        dt[0] && setItemValue(true)*/
        let detalle = AssetsPackage[props.indexMaxValue]
        let k
        for (k in detalle) {
            if (detalle[k]._id == props.item?._id) {
                setItemValue(true)
            }
        }
    }, [])

    useEffect(() => {
        if (!test) {
            checkState2()
        }
    })



    return (
        <>
            <tr
                onClick={props.onClick}
                className={
                    props.selecteState ? 'selected-row' : 'normal-row'
                }
                style={{ ...props.selecteState ? { background: 'rgb(107, 138, 184 , 0.6)', height: '48px' } : { height: '48px' }, ...AssetsPackage[props.indexMaxValue].length == props.maxValue[props.indexMaxValue] && !itemValue ? { pointerEvents: 'none', opacity: 0.2, height: '48px' } : {} }}
            >
                <td>
                    <input className="form-check-input" type="checkbox"
                        onChange={(e: any) => {
                            setItemValue(e.target.checked)

                            if (e.target.checked == true) {
                                if (AssetsPackage[props.indexMaxValue].length < props.maxValue[props.indexMaxValue]) {
                                    dispatch(AddAsset({ asset: item, index: props.indexMaxValue }))
                                }
                            }

                            if (e.target.checked == false) {
                                setTest(true)
                                dispatch(deleteAssetPackage({ index: props.indexMaxValue, deleteId: props.key }))
                            }

                        }}
                        checked={itemValue}
                    />
                </td>
                <td style={{ textAlign: 'left' }}>{item._id}</td>
                <td style={{ textAlign: 'left' }}>120 hours</td>
                <td style={{ textAlign: 'left' }}>1 YEAR</td>
                <td style={{ textAlign: 'left' }}>{item.serial}</td>
                <td style={{ textAlign: 'left' }}>{item.condition}</td>
            </tr>
        </>
    )

}

export default AssetSingleItem