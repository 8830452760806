import React from 'react'

function RateListEmpty() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center py-4 text-muted mx-5 mt-5 text-center">
      <i className="fs-3 bi bi-back d-block"></i>
      <p className="h6">No rates were found  <br /> in the current filters.</p>
    </div>
  )
}

export default RateListEmpty