import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
// import toolbar from "../../createProyectForm/tabs/TextEdit/toolbar";
import "quill/dist/quill.snow.css";

interface Props {
  jobDataById: any;
}

const ModalClienBrief: React.FC<Props> = (props) => {
  const { quill, quillRef }: any = useQuill({
    readOnly: true,
    modules: {
      toolbar: false,
    },
  });

  useEffect(() => {
    if (quill && props.jobDataById && props.jobDataById?.client_brief) {
      quill.setContents(JSON?.parse(props.jobDataById?.client_brief));
    }
  }, [quill, props.jobDataById]);

  return (
    <div
      className="editor"
      style={{ width: "800px", height: "250px", padding: "5px" }}
    >
      <div className="text-black">
        <article ref={quillRef} />
      </div>
    </div>
  );
};

export default ModalClienBrief;
