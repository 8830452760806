import React, { useState, useRef, useEffect } from 'react'

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { useDebounceEffect } from './useBounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import { Button, Col, Container, Modal, Row } from 'reactstrap'
import { canvasPreview } from './canvasPreview'
import { decoderBase64All } from '../../util/converToBase64'
import { useToast } from 'src/hooks/useToast'


interface Props {
    e: React.ChangeEvent<HTMLInputElement>
    view: any
    setview: any
    setImage:any
    setpreviewCanvasRef?:any
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const ImageCropper: React.FC<Props> = (props) => {
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(16 / 9)
    const toast = useToast()

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(16 / 9)
            setCrop(centerAspectCrop(width, height, 16 / 9))
        }
    }

    const SendImage = async () => {
        props.setImage(previewCanvasRef.current?.toDataURL('img/jpeg'))
        props.setview(false)
        toast.success('cropped')
    }

    useEffect(() => {
        console.log()

    }, [completedCrop])

    useEffect(() => {
        if (props.view) {
            onSelectFile(props.e)
        }
    }, [props.view])

    useEffect(() => {
        props.setpreviewCanvasRef(previewCanvasRef.current?.toDataURL('img/jpeg'))
    }, [completedCrop, scale, rotate])

    return (
        <Modal isOpen={props.view} toggle={() => { }} centered
            style={{ width: '400px' }}
        >
            <Container>
                <Row>
                    <Col lg='12'>
                        <p style={{ fontWeight: 'bold', fontSize: '20px' }} className='ps-0 pb-1 p-3'>Select range to crop</p>
                    </Col>
                    <Col lg='12' className='m-auto mb-2'>
                        {!!imgSrc && (
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                    onLoad={onImageLoad}
                                    width={'700px'}
                                    height={'200px'}
                                />
                            </ReactCrop>
                        )}
                        {!!completedCrop && (
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: '1px solid black',
                                    objectFit: 'contain',
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                    display: 'none'
                                }}
                            />
                        )}
                    </Col>
                    <Col lg='12' style={{ marginTop: '0', paddingLeft: '60%' }} className='mb-2 mt-1'>
                        <Button className='align-self-end me-1' onClick={() => SendImage()} >Select</Button>
                        <Button className='align-self-end btn-default' onClick={() => props.setview(false)} >Cancel</Button>
                    </Col>
                </Row>
            </Container>
        </Modal>
    )
}

export default ImageCropper
