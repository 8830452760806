import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import authService from './auth';
import TokenService from './TokenService';
import { ApiError, InternalStatus } from '../models';

const defaultConfig: AxiosRequestConfig = {
  baseURL: "https://pfp-backend.herokuapp.com/",
  headers : {
    'Content-Type' : 'application/json',
    'Accept' : 'application/json',
  }
}

const api = axios.create(defaultConfig);

export default api;

export class APIInterceptors {
  static instance: APIInterceptors;
  
  isAlreadyFetchingAccessToken = false
  subscribers: ((accessToken: string) => void)[] = []

  constructor() {
    axios.interceptors.request.use(
      (config) => {
        const userToken = TokenService.getSessionUser();
        const authorize = config.authorize || true;
        const headers: AxiosRequestHeaders = {
          ...(config.headers || defaultConfig.headers),
          ...defaultConfig.headers
        };
        
        if (userToken?.access_token && authorize === true)
          headers.Authorization = `Bearer ${ userToken.access_token }`;
    
        return {
          ...config,
          headers,
          url: `${ process?.env.REACT_APP_API }${ config.url }`,
        }
      },
      error => Promise.reject(error),
    );
    
    /* Auth validators */
    axios.interceptors.response.use(
      response => response,
      error => {
          if (!error || !error.response || !error.config) return Promise.reject(error);

          const { config, response } = error as AxiosError<ApiError>;

          const originalRequest: AxiosRequestConfig = config;
          originalRequest.headers = {
            ...(originalRequest.headers || {}),
          }

          if (response) {
            const code = response.data.statusCode.internal;
            
            switch (code) {
              case InternalStatus.EXPIRED_TOKEN: {
                if (!this.isAlreadyFetchingAccessToken) {
                  authService.RefreshToken(TokenService.getSessionUser()?.refresh_token!)
                  .then(r => {
                      this.isAlreadyFetchingAccessToken = false;
                      TokenService.createUserSession(r.data);

                      this.onAccessTokenFetched(r.data.access_token);
                  }).catch(() => {
                    if (TokenService.getSessionUser()) {
                      TokenService.removeUserSession();
                    }
                    /* TODO: Alert expired session here */
                    return Promise.reject(error.response);
                  });
            }
  
                const retryOriginalRequest = new Promise(resolve => {
                    this.addSubscriber((accessToken: string) => {
                        originalRequest.headers!.Authorization = `Bearer ${ accessToken }`;
  
                        resolve(axios({
                            ...originalRequest,
                            /* As we add the base url in the request interceptor, we have to remove it from here. */
                            url: config.url?.split(`${ process?.env.REACT_APP_API }`)[1]
                        }));
                    });
                });
  
                return retryOriginalRequest;
              }
              case InternalStatus.INVALID_TOKEN:
              case InternalStatus.USED_REFRESH_TOKEN: {
                if (TokenService.getSessionUser()) {
                  TokenService.removeUserSession();
                }

                /* TODO: Alert expired session here */

                return Promise.reject(error.response);
              }
              default:
                return Promise.reject(error.response);
            }
          }
      },
    );
  }

  onAccessTokenFetched(accessToken: string) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken));
  }

  addSubscriber(callback: (accessToken: string) => void) {
      this.subscribers.push(callback);
  }

  static getInstance(): APIInterceptors {
      if (!APIInterceptors.instance) {
          APIInterceptors.instance = new APIInterceptors();
      }

      return APIInterceptors.instance;
  }
}