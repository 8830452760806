import { useMemo } from "react";
import { useFormik } from "formik";

class Wrapper<T> {
  wrapped() {
    /* @ts-ignore */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useFormik<T>()
  }
}

export function useFieldArray<FormData extends Record<string, any>, Value>(formik: ReturnType<Wrapper<FormData>['wrapped']>, valueName: string, changeFn?: (...args: any[]) => void) {
  const helpers = useMemo(() => {
    return {
      add: (newValue: Value, ...args: any) => {
        const _toChange = [...(formik.values?.[valueName] || []), newValue]

        if (changeFn) {
          changeFn(valueName, _toChange, ...args);
        } else {
          formik.setFieldValue(valueName, _toChange, true);
        }
      },
      remove: (index: number, ...args: any) => {
        const _toChange = [...(formik.values?.[valueName] || []).filter((_: Value, i: number) => i !== index)];

        if (changeFn) {
          changeFn(valueName, _toChange, ...args);
        } else {
          formik.setFieldValue(valueName, _toChange, true);
        }
      },
      set: (index: number, newValue: Value, ...args: any) => {
        const _toChange = [...(formik.values?.[valueName] || []).map((_: Value, i: number) => i === index ? newValue : _)];

        if (changeFn) {
          changeFn(valueName, _toChange, ...args);
        } else {
          formik.setFieldValue(valueName, _toChange, true);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return helpers;
}