import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  CaseReducer,
  createReducer,
} from "@reduxjs/toolkit";
import stockpileService from "../../services/stockpile";
import ItemModel, { AssetModel, RatesModel } from "../../models/ItemModel";
import FakeItems from "../../dummy_data/inventory.json";

interface data {
  id: any;
}

export const CreateAsset = createAsyncThunk(
  "stockpile/createAsset",
  async (AllDate: any) => {
    try {
      const data: any = await stockpileService.CreateAsset(
        AllDate[0],
        AllDate[1]
      );
      console.log("slice :" + data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const DeleteItem = createAsyncThunk(
  "stockpile/DeleteItem",
  async (id: any, thunkAPI) => {
    try {
      const data: any = await stockpileService.DeleteItemForId(id);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);


export const DeleteAsset = createAsyncThunk(
  "stockpile/DeleteAsset",
  async (id: any, thunkAPI) => {
    try {
      const data: any = await stockpileService.DeleteAssetForId(id);
      return data;

    } catch (error) {
      console.log(error);
    }
  }
);

export const CreateItem = createAsyncThunk(
  "stockpile/createItem",
  async (ItemData: ItemModel, thunkAPI) => {
    try {
      const data: any = await stockpileService.CreateItem(ItemData);
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const EditItem = createAsyncThunk(
  "stockpile/editItem",
  async (ItemData: ItemModel, thunkAPI) => {
    try {
      const data: any = await stockpileService.UpdateItem(ItemData);
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const EditItemService = createAsyncThunk(
  "stockpile/editItem",
  async (ItemData: ItemModel, thunkAPI) => {
    try {
      const data: any = await stockpileService.UpdateService(ItemData);
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const CreateAccesories = createAsyncThunk(
  "stockpile/createAccesories",
  async (Accesories: any) => {
    try {
      const data: any = await stockpileService.CreateAccesories(
        Accesories.data,
        Accesories.item
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const CreateRates = createAsyncThunk(
  "stockpile/createRates",
  async (Rates: any) => {
    try {
      const data = await stockpileService.CreateRates(Rates.data, Rates.item);
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);


export const getAllItems = createAsyncThunk("stockpile/getAllItems", async () => {
  try {
    const data = await stockpileService.GetAllItems()
    return { data: data }
  } catch (error) {
    console.error(error);
  }
})

export const getAllAssets = createAsyncThunk("stockpile/getAllAssets", async () => {
  try {
    const data = await stockpileService.GetAllAssets()
    return { data: data }
  } catch (error) {
    console.error(error);
  }
})

export const getAssetForItem = createAsyncThunk("stockpile/getAseetForItem", async (id: any) => {
  try {
    const data = await stockpileService.GetAssetsForItem(id)
    return { data: data }
  } catch (error) {
    console.error(error);
  }
})

export const GetItemForID = createAsyncThunk("stockpile/getItemForId", async (id: any) => {
  try {
    const data = await stockpileService.GetItemForID(id)
    return { data: data }
  } catch (error) {
    console.error(error);
  }
})

// export const CreateCommentForAsset = createAsyncThunk("stockpile/CreateCommentForAsset", async (dataComente: any) => {
//   try {
//     const response = await stockpileService.CreateCommentForAsset({
//       content: dataComente.comment,
//       assigned_to: dataComente.id
//     })
//     return { data: response }
//   } catch (error) {
//     console.error(error);
//   }
// );

// export const getAllAssets = createAsyncThunk(
//   "stockpile/getAllAssets",
//   async () => {
//     try {
//       const data = await stockpileService.GetAllAssets();
//       return { data: data };
//     } catch (error) {
//       console.error(error);
//     }
//   }
// );

// export const getAssetForItem = createAsyncThunk(
//   "stockpile/getAseetForItem",
//   async (id: any) => {
//     try {
//       const data = await stockpileService.GetAssetsForItem(id);
//       return { data: data };
//     } catch (error) {
//       console.error(error);
//     }
//   }
// );

export const CreateCommentForAsset = createAsyncThunk(
  "stockpile/CreateCommentForAsset",
  async (dataComente: any) => {
    try {
      const response = await stockpileService.CreateCommentForAsset({
        content: dataComente.comment,
        assigned_to: dataComente.id,
      });
      return { data: response };
    } catch (error) {
      console.error(error);
    }
  }
);

export const UpdateCommentForAsset = createAsyncThunk(
  "stockpile/UpdateCommentForAsset",
  async (dataComente: any) => {
    try {
      const response = await stockpileService.UpdateCommentForAsset(
        {
          content: dataComente.comment,
        },
        dataComente.id
      );
      return { data: response };
    } catch (error) {
      console.error(error);
    }
  }
);

export const DeleteCommentForAsset = createAsyncThunk(
  "stockpile/DeleteCommentForAsset",
  async (id: any) => {
    try {
      const response = await stockpileService.DeleteCommentForAsset(id);
      return { data: response };
    } catch (error) {
      console.error(error);
    }
  })

// export const CreateItemService = createAsyncThunk("stockpile/CreateItemService", async (servc: any) => {
//   try {
//     const data = await stockpileService.CreateService(servc)
//     console.log(data);
//     return data
//   } catch (error) {
//     console.error(error);
//   }
// );

export const CreateItemService = createAsyncThunk(
  "stockpile/CreateItemService",
  async (servc: any) => {
    try {
      const data = await stockpileService.CreateService(servc);
      console.log(data);
      return data._id.toString();
    } catch (error) {
      console.error(error);
    }
  }
);

export const UpdateAssetForId = createAsyncThunk(
  "stockpile/UpdateAsset",
  async (data: any) => {
    try {
      const res = await stockpileService.UpdateAsset(data.update, data.id);
      return { data: res };
    } catch (error) {
      console.error(error);
    }
  }
)

export const GetAllRateCards = createAsyncThunk(
  "stockpile/getAllRates",
  async () => {
    try {
      const res = await stockpileService.GetAllRateCards();
      return { data: res };
    } catch (error) {
      console.error(error);
    }
  })

export const GetAllRateForItem = createAsyncThunk("stockpile/getRateCardsForItem", async (id: any) => {
  try {
    const res = await stockpileService.GetRateCardsForItem(id)
    return { data: res }
  } catch (error) {
    console.error(error);
  }
})

// export const GetMaintenanceByID = createAsyncThunk("stockpile/getMaintenance", async (id: any) => {
//   try {
//     const res = await stockpileService.GetMaintenance(id)
//     return { data: res }
//   } catch (error) {
//     console.error(error);
//   }
// );

export const GetMaintenanceByID = createAsyncThunk(
  "stockpile/getMaintenance",
  async (id: any) => {
    try {
      const res = await stockpileService.GetMaintenance(id);
      return { data: res };
    } catch (error) {
      console.error(error);
    }
  }
);

export const CreateMaintenance = createAsyncThunk(
  "stockpile/CreateMaintenance",
  async (data: any) => {
    try {
      const res = await stockpileService.CreateMaintenance(data.data, data.id);
      return { data: res };
    } catch (error) {
      console.error(error);
    }
  }
);

export const AllWareHouses = createAsyncThunk("stockpile/AllWareHouses", async () => {
  try {
    const res = await stockpileService.GetWareHouses()
    return res
  } catch (error) {
    console.error(error);
  }
})

export const CreateItemsCSV = createAsyncThunk("stockpile/CreateItemsCSV", async (file: any) => {
  try {
    const res = await stockpileService.CreateItemsForCSV(file)
    return res
  } catch (error) {
    console.error(error);
  }
})

export const CreateAssetsCSV = createAsyncThunk("stockpile/CreateAssetsCSV", async (file: any) => {
  try {
    const res = await stockpileService.CreateAssetsForCSV(file)
    return res
  } catch (error) {
    console.error(error);
  }
})


const initialState = {
  loading: false,
  AssetData: [] as any,
  ItemData: [] as Array<any>,
  LoadingItems: false,
  LoadingAssets: false,
  AllAssets: [] as any,
  FilterAssetData: [] as any,
  AllWareHouses: [] as any,
  ItemResponse: null,
  DetailAssetRates: [] as any,
  DetailAssetMaintenance: [] as any,
  RatesForItem: [] as any,
  PercentUploadCSV: 0,
  ItemGetted: [] as any
};

const stockpileSlice = createSlice({
  name: "stockpile",
  initialState,
  reducers: {
    cleanAssetData: (state) => {
      state.AssetData = [] as any;
    },
    RefreshForNewItem: (state, action) => {
      let data = state.ItemData
      data.push(action.payload)
      state.ItemData = data
    },
    RefreshForDeleteItem: (state, action) => {
      var a = state.ItemData.filter((e: any) => e._id != action.payload);
      state.ItemData = a
    },
    CleanRates: (state) => {
      state.RatesForItem = []
    },
    setManualAssetData: (state, action) => {
      state.AssetData.push(action.payload)
    }
    ,
    RefreshForDeleteAsset: (state, action) => {
      var a = state.AssetData;
      a.splice(a.findIndex((e: any) => e._id === action.payload), 1);
      state.AssetData = a
    },
    cleanItemGetted: (state) => {
      state.ItemGetted = [] as any
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateItem.fulfilled, (state, action) => {
        state.loading = false;
        state.ItemResponse = action.payload;
      })
      .addCase(CreateItem.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(CreateItem.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(CreateItemService.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(CreateItemService.pending, (state, action) => {
        state.loading = true
      })
      .addCase(CreateItemService.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(getAllItems.fulfilled, (state, action) => {
        state.ItemData = action.payload?.data && [...action.payload?.data]
        state.LoadingItems = false

        let filterServices = action.payload?.data && [...action.payload?.data].filter((item) => item.category == '67807598690173958')

      })
      .addCase(getAllItems.pending, (state, action) => {
        state.LoadingItems = true
      })
      .addCase(getAssetForItem.fulfilled, (state, action: any) => {
        state.LoadingAssets = false
        if (action.payload?.data) {
          state.AssetData = action?.payload?.data;
          state.FilterAssetData = action?.payload?.data;
        } else {
          state.AssetData = action.payload;
        }

      }).addCase(getAssetForItem.pending, (state, action) => {
        state.LoadingAssets = true
      }).addCase(GetAllRateCards.fulfilled, (state, action) => {
        state.DetailAssetRates = action.payload?.data
      }).addCase(GetMaintenanceByID.fulfilled, (state, action) => {
        state.DetailAssetMaintenance = action.payload?.data
      }).addCase(getAllAssets.fulfilled, (state, action) => {
        state.AllAssets = action.payload?.data
      }).addCase(AllWareHouses.fulfilled, (state, action) => {
        state.AllWareHouses = action.payload
      }).addCase(GetAllRateForItem.fulfilled, (state, action) => {
        state.RatesForItem = action.payload?.data
      })
      .addCase(CreateItemsCSV.fulfilled, (state, action) => {
        state.PercentUploadCSV = 1
      })
      .addCase(GetItemForID.fulfilled, (state, action) => {
        state.ItemGetted = action.payload?.data
      })

  },
});

export const { cleanAssetData, RefreshForNewItem, cleanItemGetted, RefreshForDeleteItem, CleanRates, setManualAssetData, RefreshForDeleteAsset } = stockpileSlice.actions;
export default stockpileSlice.reducer;

