import InfoDisplay from '../../../UI/InfoDisplay';
import PhotoDisplay from '../../../UI/PhotoDisplay';
import QuickNotes from '../../../UI/QuickNotes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import AddComentsModal from 'src/components/Modals/AddComentsModal';
import { AllWareHouses, CreateCommentForAsset, DeleteCommentForAsset, UpdateCommentForAsset } from 'src/store/stockpile/stockpileSlice';
import { AddComment, deleteComment, GetAceesoriesbyID, updateComment } from 'src/store/stockpile/selectAssetsSlice';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import { ConvertSize } from 'src/util/sizesConvert';
import moment from 'moment';
import { getCategoryName } from 'src/util/NameTypes';
import { CheckValue } from 'src/util/CheckValue';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { useParams } from 'react-router-dom';
import { useRequest } from 'src/hooks/useRequest';
import { useToast } from 'src/hooks/useToast';

const EquipmentData: React.FC = () => {
  const params = useParams<{ itemId: string; }>();
  const ASSET_DATA: any = useSelector((state: RootState) => state.selectAssetsSlice.data) || [];
  const COMMENTS_ASSETS: Array<object> = useSelector((state: RootState) => state.selectAssetsSlice.comments);
  const categories = useSelector((state: RootState) => state.categorieSlice.categories);
  const WareHouses: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AllWareHouses);
  const Selected: any = useSelector((state: RootState) => state.RecursosVisualeSlice.selectedState);
  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [idForEdit, setIdForEdit] = useState('')
  const [subCat, setSubCat] = useState('')
  const toast = useToast()

  const dispatch = useDispatch()

  const [comment, setComment] = useState('')

  const [commentAdd, setCommentAdd] = useState('')

  const warer: any = WareHouses?.length > 0 ? WareHouses.map((war) => {
    return { title: war.name, value: war._id }
  }) : undefined

  const getNameWarer = (idWare: any) => {
    const name: any = warer?.map((war: any) => {
      if (war?._id == idWare) {
        return war?.title
      }
    })[0]

    return name
  }

  useEffect(() => {
    dispatch(GetAceesoriesbyID(params.itemId))
  }, [])


  const FormatDate = (timestamp: any) => {
    const options: any = { year: "numeric", month: "long", day: "numeric" }
    return new Date(timestamp).toLocaleDateString(undefined, options)
  }

  const cn: Array<object> = []

  const toggleModal = () => {
    setAddModal((prev) => !prev);
  };

  const comentarios = COMMENTS_ASSETS?.map((comment: any) => {
    const obj = {
      text: comment.content,
      date: comment.created_at,
      id: comment._id
    }

    return obj
  })


  const photos = ASSET_DATA?.item?.photos?.map((photo: string) => {
    return process.env.REACT_APP_IMAGES_AWS + photo
  })

  const i = () => {
    setAddModal(true)
  }

  const replaceUndefined = (value: any) => {
    if (value == '') {
      return '----'
    } else {
      return value
    }
  }

  const sendComment = async () => {
    const commentFinal = {
      comment: commentAdd,
      id: ASSET_DATA?._id
    }
    const data: any = await dispatch(CreateCommentForAsset(commentFinal))
    if (data.payload.data) {
      dispatch(AddComment(data.payload.data))
    }
  }

  const sendEditComment = () => {
    const update = {
      comment: comment,
      id: idForEdit.toString()
    }
    dispatch(UpdateCommentForAsset(update))
    dispatch(updateComment(update))
  }

  const deleteCommentForId = async (id: any, index: any) => {
    dispatch(deleteComment(index))
    dispatch(DeleteCommentForAsset(id))
  }

  const toggleEditComment = (id: any, currentContent: any) => {
    setEditModal((prev) => !prev);
    setIdForEdit(id)
    setComment(currentContent)
  }

  // 67807598690173953
  const getCategory = (id: any) => {
    setSubCat(getCategoryName(id, categories))
  }

  useEffect(() => {
    getCategory(ASSET_DATA?.item?.category?.category)
  }, [ASSET_DATA])

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3 ">
        <InfoDisplay
          title="Description"
          texts={[
            {
              text: ASSET_DATA?.item?.description,
            },
          ]}
        />
      </div>

      <div className="row border-bottom p-0 py-3">
        <QuickNotes
          title="Comments"
          texts={comentarios}
          isEditable
          ActionUpdate={toggleEditComment}
          ActionDelete={deleteCommentForId}
        />
        <div style={ASSET_DATA?.serial ? { maxWidth: '160px' } : { maxWidth: '160px',  opacity: 0.7 }}
      
       >
          <SimpleButton onClick={() => {
              if (!ASSET_DATA?.serial) {
                toast.info('Please create an assets to add a comment')
              }else{
                i()
              }
          }}>
            ADD COMMENTS
          </SimpleButton>
        </div>

      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay title="Sub-category" texts={[{ text: CheckValue(ASSET_DATA?.item?.category?.name) }]} />
        </div>

        <div className="col-6">
          <InfoDisplay title="brand" texts={[{ text: CheckValue(ASSET_DATA?.item?.brand) }]} />
        </div>

        <div className="col-6">
          <InfoDisplay title="category" texts={[{ text: CheckValue(subCat) }]} />
        </div>

        <div className="col-6">
          <InfoDisplay title="model" texts={[{ text: CheckValue(ASSET_DATA?.item?.model == 'undefined' ? '-' : ASSET_DATA?.item?.model) }]} />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="power consumption"
            texts={[{ text: CheckValue(ASSET_DATA?.item?.consumption == 'undefined' ? '-' : ASSET_DATA?.item?.consumption) + ' ' + CheckValue(ASSET_DATA?.item?.consumption != 'undefined' ? ASSET_DATA?.item?.consumption_unit : '', false) }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="serial number"
            texts={[{ text: CheckValue(ASSET_DATA?.serial) }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="user manual file"
            texts={[{ text: '-' }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="user manual link"
            texts={[
              { text: ASSET_DATA?.item?.manual_url ? 'Link' : '-', isUnderline: true, fileHref: CheckValue(ASSET_DATA?.item?.manual_url), isFile: true },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay title="discountable item" texts={[{ text: ASSET_DATA?.item?.discountable ? 'Yes' : 'No' }]} />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay title="price paid" texts={[{ text: CheckValue(ASSET_DATA?.purchase?.price && "$" + ASSET_DATA?.purchase?.price) }]} />
        </div>

        <div className="col-6">
          <InfoDisplay title="age" texts={[{ text: Number.isNaN(Math.round(ASSET_DATA?.age?.toFixed(2))) ? '-' : -Math.round(ASSET_DATA?.age?.toFixed(2)) + ' years' }]} />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="accumulated deprecation"
            texts={[{ text: CheckValue(ASSET_DATA?.accumulated_depreciation?.toFixed(2)) }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay title="current value" texts={[{ text: '-' }]} />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="date of purchase"
            texts={[{ text: CheckValue(ASSET_DATA?.purchase?.date && moment(ASSET_DATA?.purchase?.date).format('DD/MM/YYYY')) }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="End of life"
            texts={[{ text: CheckValue(ASSET_DATA?.end_of_life && moment(ASSET_DATA?.end_of_life).format('DD/MM/YYYY')) }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay title="total rental" texts={[{ text: '-' }]} />
        </div>

        <div className="col-6">
          <InfoDisplay title="total profit" texts={[{ text: '-' }]} />
        </div>

        <div className="col-6">
          <InfoDisplay title="general roi" texts={[{ text: '-' }]} />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="Digital receipt"
            texts={[{ text: CheckValue(ASSET_DATA?.digital_receipt?.substring(34)) }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="warranty expiration"
            texts={[{ text: CheckValue(ASSET_DATA?.warranty_expiration && moment(ASSET_DATA?.warranty_expiration).format('DD/MM/YYYY')) }]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="origin of purchase"
            texts={[{ text: CheckValue(ASSET_DATA?.purchase?.origin) }]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay title="item weight" texts={[{ text: ASSET_DATA?.sizes && CheckValue(ASSET_DATA?.sizes[0]?.weight) + ' ' + CheckValue(ASSET_DATA?.sizes[0]?.weight_unit, false) }]} />
        </div>
        <div className="col-6">
          <InfoDisplay title="case weight" texts={[{ text: ASSET_DATA?.sizes && CheckValue(ASSET_DATA?.sizes[0]?.weight) + ' ' + CheckValue(ASSET_DATA?.sizes[0]?.weight_unit, false) }]} />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="item dimensions"
            texts={[{ text: ASSET_DATA?.sizes && CheckValue(ASSET_DATA?.sizes[0]?.length, false, ' x ') + CheckValue(ASSET_DATA?.sizes[0]?.width, false, ' x ') + CheckValue(ASSET_DATA?.sizes[0]?.height, false) + ' ' + CheckValue(ASSET_DATA?.sizes[0]?.sizes_unit) }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="case dimensions"
            texts={[{ text: ASSET_DATA?.sizes && CheckValue(ASSET_DATA?.sizes[0]?.length, false, ' x ') + CheckValue(ASSET_DATA?.sizes[0]?.width, false, ' x ') + CheckValue(ASSET_DATA?.sizes[0]?.height, false) + ' ' + CheckValue(ASSET_DATA?.sizes[0]?.sizes_unit) }]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay title="combined weight" texts={[{ text: ASSET_DATA?.sizes && CheckValue(Number.isNaN(ASSET_DATA?.sizes[0]?.weight * 2) ? '' : ASSET_DATA?.sizes[0]?.weight) + ' ' + CheckValue(ASSET_DATA?.sizes[0]?.weight_unit) }]} />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay title="warehouse" texts={[{ text: CheckValue(ASSET_DATA?.warehouse?.warehouse ? 'WareHouse' : undefined) }]} />
        </div>

        <div className="col-6">
          <InfoDisplay title="shelf id" texts={[{ text: CheckValue(ASSET_DATA?.warehouse?.shelf_id) }]} />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="location within warehouse"
            texts={[{ text: CheckValue(ASSET_DATA?.warehouse?.location) }]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <PhotoDisplay
          title="photos"
          images={photos || []}
        />
      </div>

      <AddComentsModal
        title='Add Comment'
        view={addModal}
        setView={setAddModal}
        toggle={toggleModal}
        data={commentAdd}
        setData={setCommentAdd}
        sendData={sendComment}
      />

      <AddComentsModal
        title='Edit Comment'
        view={editModal}
        setView={setEditModal}
        toggle={toggleModal}
        data={comment}
        setData={setComment}
        sendData={sendEditComment}
      />

    </div>
  );
};

export default EquipmentData;
