import React, {useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { useRequest } from "src/hooks/useRequest";
import { ICategory } from "src/models/Category";

interface Props {
  inputDisabled?: boolean;
  value:          string;
  onChange:       (val: string) => void;
  resetFilters:   () => void;
  searchData:     () => void;
}

const AdvanceSearch: React.FC<Props> = (props) => {

  const { data: categories, error} = useRequest<ICategory[]>({ url: `categories/items/all`});   

  return (
    <div className="dropdown-menu p-2" style={{ width: 300 }}>
      <p className="text-primary typo-body">Advanced search</p>

      {categories && categories.length > 0 &&
        <select 
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          className="form-select text-primary typo-body-important"
        >
          <option value="">
            Select category
          </option>
          {categories.map((category: ICategory, index: number) => {
            return <option value={category._id} key={index}>
              {category.name}
            </option>
          })}
          
        </select>
      }

      {!props.inputDisabled &&
        <input
          className="form-control form-control-sm text-primary mt-2 typo-body-import"
          placeholder="Includes the words"
        />
      }

      <button 
        onClick={() => props.searchData()} 
        className="btn btn-primary mt-3 px-3 text-white typo-body"
        disabled={props.value === ""}
      >
        Search
      </button>
      {props.resetFilters &&
        <button 
          className="btn btn-outline-primary mt-3 px-3 ms-1 typo-body"
          onClick={() => props.resetFilters()}
        >
          clear filters
        </button>
      }
    </div>
  );
};

export default AdvanceSearch;
