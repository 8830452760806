import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Preview from "../components/PublicQuotes/Preview";
import UserInfoModal from "../components/Modals/UserInfoModal/UserInfoModal";
import { getPublicQuoteData } from "../store/factory/factoryPublicSlice";
import { RootState } from "../store/store";

const PublicQuote: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<{ id: string }>();

    const {
        QuoteData,
        QuotesId,
        QuoteVersion
    } = useSelector(
        (state: RootState) => state.factoryPublicSlice
    );

    useEffect(() => {
        dispatch(getPublicQuoteData(params.id))
    }, [params.id])

    const allQuotes =
        [
            {
                "_id": "181773324047614979",
                "created_by": "4824715373839364",
                "actived": 1,
                "job": "172772676077618178",
                "status": "In Review",
                "due_date": "2022-07-08T00:00:00.000Z",
                "version_number": 0,
                "description": "Desc",
                "name": "Quote A",
                "created_at": "2022-08-09T00:11:08.820Z",
                "updated_at": "2022-08-09T00:11:08.842Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "181773324173444100",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "181775316962771976",
                "created_by": "4824715373839364",
                "actived": 1,
                "job": "172772676077618178",
                "status": "In Review",
                "due_date": "2022-07-08T00:00:00.000Z",
                "version_number": 0,
                "description": "Desc",
                "name": "Quote B",
                "created_at": "2022-08-09T00:15:06.393Z",
                "updated_at": "2022-08-09T00:15:06.405Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "181775317013103625",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "208369421129876575",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "172772676077618178",
                "status": "In Review",
                "due_date": "2022-08-09T00:00:00.000Z",
                "version_number": 0,
                "description": "My quote description",
                "name": "My quote name Daniel",
                "created_at": "2022-09-14T16:52:50.657Z",
                "updated_at": "2022-09-14T16:52:50.672Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "208369421188596832",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "208397494277637234",
                "created_by": "4824715373839364",
                "actived": 1,
                "job": "172772676077618178",
                "status": "In Review",
                "due_date": "2022-07-08T00:00:00.000Z",
                "version_number": 0,
                "description": "Desc",
                "name": "Quote B",
                "created_at": "2022-09-14T17:48:37.238Z",
                "updated_at": "2022-09-14T17:48:37.250Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "208397494336357491",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "208399839732434039",
                "created_by": "4824715373839364",
                "actived": 1,
                "job": "172772676077618178",
                "status": "In Review",
                "due_date": "2022-09-09T00:00:00.000Z",
                "version_number": 0,
                "description": "Desc",
                "name": "Quote B",
                "created_at": "2022-09-14T17:53:16.838Z",
                "updated_at": "2022-09-14T17:53:16.850Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "208399839791154296",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "208399960360617084",
                "created_by": "4824715373839364",
                "actived": 1,
                "job": "172772676077618178",
                "status": "In Review",
                "due_date": "2022-12-09T00:00:00.000Z",
                "version_number": 0,
                "description": "Desc",
                "name": "Quote B",
                "created_at": "2022-09-14T17:53:31.217Z",
                "updated_at": "2022-09-14T17:53:31.230Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "208399960419337341",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "214407232173900806",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "213494008243029010",
                "status": "In Review",
                "due_date": "2022-09-03T00:00:00.000Z",
                "version_number": 0,
                "description": "para borrar",
                "name": "new quote job 1",
                "created_at": "2022-09-23T00:48:53.823Z",
                "updated_at": "2022-09-23T00:48:53.855Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "214407232299729927",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "214421150384522250",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "214195134181409809",
                "status": "In Review",
                "due_date": "2022-09-03T00:00:00.000Z",
                "version_number": 0,
                "description": "new quite 123",
                "name": "new quote 123",
                "created_at": "2022-09-23T01:16:33.003Z",
                "updated_at": "2022-09-23T01:16:33.019Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "214421150460019723",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "215838189808518164",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "207585158096749601",
                "status": "In Review",
                "due_date": "2022-09-03T00:00:00.000Z",
                "version_number": 0,
                "description": "new job",
                "name": "new quote job 1",
                "created_at": "2022-09-25T00:11:57.266Z",
                "updated_at": "2022-09-25T00:11:57.282Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "215838189884015637",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "215838966643950618",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "207585158096749601",
                "status": "In Review",
                "due_date": "2022-09-03T00:00:00.000Z",
                "version_number": 0,
                "description": "para borrar",
                "name": "new quote job 2",
                "created_at": "2022-09-25T00:13:29.871Z",
                "updated_at": "2022-09-25T00:13:29.886Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "215838966702670875",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "216144187655980034",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "207585158096749601",
                "status": "In Review",
                "due_date": "2022-09-21T00:00:00.000Z",
                "version_number": 0,
                "description": "quote",
                "name": "new quote",
                "created_at": "2022-09-25T10:19:55.052Z",
                "updated_at": "2022-09-25T10:19:55.070Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "216144187731477507",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "216156149911651355",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "207585048784798752",
                "status": "In Review",
                "due_date": "2022-09-15T00:00:00.000Z",
                "version_number": 0,
                "description": "quote",
                "name": "new quote",
                "created_at": "2022-09-25T10:43:41.064Z",
                "updated_at": "2022-09-25T10:43:41.077Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "216156149978760220",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "216157802777805855",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "207775657789228167",
                "status": "In Review",
                "due_date": "2022-09-03T00:00:00.000Z",
                "version_number": 0,
                "description": "quote 2",
                "name": "new quote 2",
                "created_at": "2022-09-25T10:46:58.100Z",
                "updated_at": "2022-09-25T10:46:58.113Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "216157802836526112",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "219843326721918013",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "213494008243029010",
                "status": "In Review",
                "due_date": "2022-09-07T00:00:00.000Z",
                "version_number": 0,
                "description": "quote 11111",
                "name": "quote 11111",
                "created_at": "2022-09-30T12:49:26.792Z",
                "updated_at": "2022-09-30T12:49:26.817Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "219843326839358526",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "226693597502637059",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "213494008243029010",
                "status": "In Review",
                "due_date": "2022-10-11T00:00:00.000Z",
                "version_number": 0,
                "description": "quote 12345667",
                "name": "quote 1234567",
                "created_at": "2022-10-09T23:39:42.705Z",
                "updated_at": "2022-10-09T23:39:42.724Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "226693597569745924",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "226695996896183307",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "213494008243029010",
                "status": "In Review",
                "due_date": "2022-10-27T00:00:00.000Z",
                "version_number": 0,
                "description": "Prueba",
                "name": "quote Prueba",
                "created_at": "2022-10-09T23:44:28.737Z",
                "updated_at": "2022-10-09T23:44:28.758Z",
                "term": "181773324047614979",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "226695997005235212",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "238043132241904646",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217575355614213",
                "status": "Approved",
                "due_date": "2022-10-26T00:00:00.000Z",
                "version_count": 2,
                "description": "new Quote editando arbol",
                "name": "new Quote 123456 edit quote",
                "created_at": "2022-10-25T15:29:12.678Z",
                "updated_at": "2022-11-03T04:00:20.144Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "238272037775213581",
                        "version": 2,
                        "description": "Version 2"
                    }
                ]
            },
            {
                "_id": "238360509907207168",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217527632823299",
                "status": "In Review",
                "due_date": "2022-10-04T00:00:00.000Z",
                "version_count": 1,
                "description": "quote 123456789",
                "name": "quote 123456789",
                "created_at": "2022-10-26T01:59:47.046Z",
                "updated_at": "2022-10-26T01:59:47.068Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "238360510033036289",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "238716211406308353",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217527632823299",
                "status": "In Review",
                "due_date": "2022-10-28T00:00:00.000Z",
                "version_count": 1,
                "description": "test 2",
                "name": "quote test 2",
                "created_at": "2022-10-26T13:46:29.969Z",
                "updated_at": "2022-10-26T13:46:29.989Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "238716211515360258",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "238723037040477195",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-10-27T00:00:00.000Z",
                "version_count": 2,
                "description": "quote test 4 edit",
                "name": "quote test 4 edit",
                "created_at": "2022-10-26T14:00:03.646Z",
                "updated_at": "2022-10-26T14:01:16.216Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "238723645759816718",
                        "version": 2,
                        "description": "Version 2"
                    }
                ]
            },
            {
                "_id": "238961106498880519",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217575355614213",
                "status": "In Review",
                "due_date": "2022-10-19T00:00:00.000Z",
                "version_count": 1,
                "description": "test5",
                "name": "test5 edit",
                "created_at": "2022-10-26T21:53:03.737Z",
                "updated_at": "2022-10-26T21:53:51.826Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "238961106582766600",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "238994512528016386",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-10-12T00:00:00.000Z",
                "version_count": 1,
                "description": "test 8",
                "name": "test8",
                "created_at": "2022-10-26T22:59:26.046Z",
                "updated_at": "2022-10-26T22:59:26.064Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "238994512603513859",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "238997348842210311",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-10-18T00:00:00.000Z",
                "version_count": 2,
                "description": "test 9",
                "name": "Test 9 edit ",
                "created_at": "2022-10-26T23:05:04.162Z",
                "updated_at": "2022-10-26T23:05:50.336Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "238997736127464458",
                        "version": 2,
                        "description": "Version 2"
                    }
                ]
            },
            {
                "_id": "239461080722572296",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-10-31T00:00:00.000Z",
                "version_count": 2,
                "description": "Test create quote",
                "name": "Test create quote",
                "created_at": "2022-10-27T14:26:25.309Z",
                "updated_at": "2022-10-27T14:29:38.465Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "239462700990596107",
                        "version": 2,
                        "description": "Version 2"
                    }
                ]
            },
            {
                "_id": "244526256618473011",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217527632823299",
                "status": "In Review",
                "due_date": "2022-11-17T00:00:00.000Z",
                "version_count": 2,
                "description": "new Quote 742",
                "name": "new Quote 742",
                "created_at": "2022-11-03T14:10:01.329Z",
                "updated_at": "2022-11-03T14:12:37.182Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "244527563941087137",
                        "version": 2,
                        "description": "Version 2"
                    }
                ]
            },
            {
                "_id": "244529026780104611",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-11-22T00:00:00.000Z",
                "version_count": 3,
                "description": "test 44",
                "name": "test 44",
                "created_at": "2022-11-03T14:15:31.558Z",
                "updated_at": "2022-11-03T14:17:17.511Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "244529915511178878",
                        "version": 3,
                        "description": "Version 3"
                    }
                ]
            },
            {
                "_id": "244541616210053100",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "227996319099126801",
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-11-30T00:00:00.000Z",
                "version_count": 1,
                "description": "test 1234",
                "name": "test 1234",
                "created_at": "2022-11-03T14:40:32.335Z",
                "updated_at": "2022-11-03T14:41:53.139Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "244541616293939181",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "244718597711070576",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "208379652606002281",
                "term": "235217460624622594",
                "status": "In Review",
                "due_date": "2022-11-04T00:00:00.000Z",
                "version_count": 1,
                "description": "This is my very first quote",
                "name": "Daniels Firts Quote",
                "created_at": "2022-11-03T20:32:10.173Z",
                "updated_at": "2022-11-03T20:32:10.196Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "244718597811733873",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "244720521327936883",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "208379652606002281",
                "term": "235217527632823299",
                "status": "In Review",
                "due_date": "2022-11-25T00:00:00.000Z",
                "version_count": 1,
                "description": "My second quote",
                "name": "My second quote",
                "created_at": "2022-11-03T20:35:59.487Z",
                "updated_at": "2022-11-03T20:35:59.500Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "244720521395045748",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "244721867699520887",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "208379652606002281",
                "term": "235217575355614213",
                "status": "In Review",
                "due_date": "2022-11-04T00:00:00.000Z",
                "version_count": 1,
                "description": "My third quote description",
                "name": "My third quote",
                "created_at": "2022-11-03T20:38:39.987Z",
                "updated_at": "2022-11-03T20:38:40.001Z",
                "cost": 0,
                "margin": 0,
                "target_budget": 0,
                "total": 0,
                "quote_version": [
                    {
                        "_id": "244721867758241144",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "245330024264107014",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "208379652606002281",
                "margin": 0,
                "cost": 0,
                "total": 0,
                "target_budget": 0,
                "term": "235217460624622594",
                "status": "In Review",
                "due_date": "2022-11-10T00:00:00.000Z",
                "version_count": 1,
                "description": "My firday quote description",
                "name": "My friday quote",
                "created_at": "2022-11-04T16:46:57.898Z",
                "updated_at": "2022-11-04T16:46:57.922Z",
                "quote_version": [
                    {
                        "_id": "245330024356381703",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "245492983032448778",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "232473117677585409",
                "margin": 0,
                "cost": 0,
                "total": 0,
                "target_budget": 0,
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-11-23T00:00:00.000Z",
                "version_count": 1,
                "description": "test ",
                "name": "New quote create rate",
                "created_at": "2022-11-04T22:10:44.099Z",
                "updated_at": "2022-11-04T22:10:44.116Z",
                "quote_version": [
                    {
                        "_id": "245492983107946251",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "247543043299738771",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "232473117677585409",
                "margin": 0,
                "cost": 0,
                "total": 0,
                "target_budget": 0,
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-11-24T00:00:00.000Z",
                "version_count": 1,
                "description": "new quote 1234 vk",
                "name": "new quote 1234 vk",
                "created_at": "2022-11-07T18:03:50.326Z",
                "updated_at": "2022-11-07T18:03:50.349Z",
                "quote_version": [
                    {
                        "_id": "247543043392013460",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "248096526339933187",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "233657389729973260",
                "margin": 0,
                "cost": 0,
                "total": 0,
                "target_budget": 0,
                "term": "235217551934620676",
                "status": "In Review",
                "due_date": "2022-11-24T00:00:00.000Z",
                "version_count": 1,
                "description": "new Quote test ",
                "name": "new Quote test ",
                "created_at": "2022-11-08T12:23:30.649Z",
                "updated_at": "2022-11-08T12:23:30.675Z",
                "quote_version": [
                    {
                        "_id": "248096526490928132",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "248868145341662214",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "232473117677585409",
                "margin": 0,
                "cost": 0,
                "total": 0,
                "target_budget": 12000,
                "term": "235217460624622594",
                "status": "In Review",
                "due_date": "2022-11-24T00:00:00.000Z",
                "version_count": 1,
                "description": "MY QUOTES DESCRIPTION",
                "name": "MY QUOTE",
                "created_at": "2022-11-09T13:56:34.799Z",
                "updated_at": "2022-11-09T13:56:34.821Z",
                "quote_version": [
                    {
                        "_id": "248868145433936903",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            },
            {
                "_id": "250256498796529372",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "232473117677585409",
                "margin": 0,
                "cost": 0,
                "total": 0,
                "target_budget": 0,
                "term": "235217527632823299",
                "status": "In Review",
                "due_date": "2022-11-18T00:00:00.000Z",
                "version_count": 2,
                "description": "new Quote test create rate card",
                "name": "new Quote test create rate card",
                "created_at": "2022-11-11T11:54:59.428Z",
                "updated_at": "2022-11-11T12:05:07.238Z",
                "quote_version": [
                    {
                        "_id": "250261597426025546",
                        "version": 2,
                        "description": "Version 2"
                    }
                ]
            },
            {
                "_id": "253456925889398566",
                "created_by": "4824715373839361",
                "actived": 1,
                "job": "208379652606002281",
                "margin": 0,
                "cost": 0,
                "total": 0,
                "target_budget": 0,
                "term": "235217460624622594",
                "status": "In Review",
                "due_date": "2022-11-16T00:00:00.000Z",
                "version_count": 1,
                "description": "test quote create quote clients rate",
                "name": "test quote create quote clients rate",
                "created_at": "2022-11-15T21:53:40.068Z",
                "updated_at": "2022-11-15T21:53:40.093Z",
                "quote_version": [
                    {
                        "_id": "253456925990061863",
                        "version": 1,
                        "description": "Version 1"
                    }
                ]
            }
        ]


    return (
        <div className="p-1">
            <Preview
                QuoteData={[]}
                QuotesId={QuotesId}
                QuoteVersion={QuoteVersion}
            />
        </div>
    );
};

export default PublicQuote;
