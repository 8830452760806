import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { ICategory } from 'src/models/Category';

interface FiltersState {
  search: string;
  sortBy: string;
  archivedOnly: boolean;
  selectedCategory?: ICategory;
}

const initialState: FiltersState = {
  sortBy: '',
  search: '',
  archivedOnly: false,
  selectedCategory: undefined,
};

const blackbookFiltersSlice = createSlice<FiltersState, SliceCaseReducers<FiltersState>>({
  name: 'blackbook-filters',
  initialState,
  reducers: {
    selectCategory: {
      prepare: (category: ICategory) => {
        if (category._id === 'all') {
          return { payload: undefined };
        }

        return { payload: category };
      },
      reducer: (state, data: PayloadAction<ICategory>) => {
        state.selectedCategory = data.payload;
      }
    },
    setSearch: {
      prepare: (search: string) => {
        return { payload: search };
      },
      reducer: (state, data: PayloadAction<string>) => {
        state.search = data.payload;
      }
    },
    setArchivedOnly: {
      reducer: (state, action) => {
        state.archivedOnly = action.payload;
      },
      prepare: (archivedOnly: boolean) => {
        return { payload: archivedOnly };
      }
    }
  },
});

export const blackbookFilters = blackbookFiltersSlice.actions;

export default blackbookFiltersSlice.reducer;
