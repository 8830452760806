import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../screens/Login";
import PublicQuote from "../screens/PublicQuote";
import RecoverPassword from "../screens/RecoverPassword";

const AuthRouter = () => {
  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>

      <Route path="/recover-password" exact>
        <RecoverPassword />
      </Route>

      <Route path="/quotes/public/:id" exact>
        <PublicQuote />
      </Route>

      <Route path="*">
        <Login />
      </Route>
    </Switch>
  );
};

export default AuthRouter;
