import React from 'react'
import Select, {
  components,
  GroupBase,
  PlaceholderProps,
  StylesConfig,
} from "react-select";

interface Props {
  onChange: any;
  value: any;
  options: any;
  placeholder?: any;
  searchable?: boolean;
  className?: any;
}

const Placeholder: any = (
  props: PlaceholderProps<{ label: string; value: string; placeholder: any }>
) => {
  return (
    <components.Placeholder {...props}>
      <div>
        {/* @ts-ignore */}
        {props.options?.find(
          (option: any) => option?.value === props?.selectProps?.value
        )?.label || props.selectProps.placeholder}
      </div>
    </components.Placeholder>
  );
};

const CaretDownIcon = () => {
  return (
    <>
      <svg
        enable-background="new 0 0 29 14"
        height="10px"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 29 14"
        width="7px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon fill="#8898AA" points="0.15,0 14.5,14.35 28.85,0 " />
      </svg>
    </>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}></components.DropdownIndicator>
  );
};

const IndicatorSeparator: any = (props: any) => {
  return "";
};

const colourStyles: StylesConfig<any, false, GroupBase<any>> = {
  option: (styles: any, sourc) => {
    const { isFocused } = sourc;

    return {
      ...styles,
      background: "none",
      color: "#6B8AB8",
      "&:hover": {
        color: "white",
        backgroundColor: isFocused ? "#6B8AB8" : null,
      },
    };
  },
  placeholder: (base) => ({
    ...base,
    fontSize: "12px",
    color: "#6B8AB8",
    fontWeight: 400,
    textDecoration: "underline",
  }),
  menu: (provided) => ({
    ...provided,
    borderBottom: "none",
    color: "#6B8AB8",
    width: "210px",
    zIndex: "1000",
  }),
  control: (base, state) => ({
    ...base,
    border: "none",
    boxShadow: "none",
    color: "none",
    height: "30px",
    width: "70px",
    minHeight: 0,
    background: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#6B8AB8",
  }),
};

const SelectInput: React.FC<Props> = (props) => {
  return (
    <>
      <Select
        components={{ Placeholder, DropdownIndicator, IndicatorSeparator }}
        // closeMenuOnSelect={false}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(newValue) => props.onChange(newValue.value)}
        styles={colourStyles}
        options={props.options}
        isSearchable={props.searchable}
        className={props.className}
        menuPortalTarget={document.body}
      />
    </>
  );
};

export default SelectInput;
