import React, { useEffect, useState } from "react";
import InputSearch from "../../../components/UI/forms/InputSearch";
import TableFatory from "./tableFactory/tableFactory";
import FactoryModal from "../../Modals/FactoryModal";
import FactoryModalFilter from "../../Modals/factoryModalFilter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getAllProject } from "../../../store/factory/factorySlice";
import CounterBar from "./CounterBar";
import { Link } from "react-router-dom";
import FilterIcon from '../../../assets/icons/filter.svg'

interface Props {
  openModal: boolean;
  setOpenModal: any;
  activeControlView: any;
  setActiveControlView: any;
  selectJob: string | null;
  setSelectJob: any;
  activeTask: string | null;
  setActiveTask: any;
  selectProject: string | null;
  setSelectProject: any;
  closeModalJob: boolean;
  setCloseModalJob: any;
}

interface Count {
  count1: number;
  count2: number;
  count3: number;
  count4: number;
  count5: number;
  count6: number;
}

const FactoryView: React.FC<Props> = (props) => {
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [viewOptions, setViewOptions] = useState(false);

  const [count, setCount] = useState<Count>({
    count1: 0,
    count2: 0,
    count3: 0,
    count4: 0,
    count5: 0,
    count6: 0,
  });

  const ProjectData = useSelector((state: RootState) => state.factorySlice);

  const { loading, projectData, ProjectResponse } = ProjectData;

  // console.log("projectData", projectData);
  // console.log("ProjectResponse", ProjectResponse);

  const dispatch = useDispatch();

  const countData = () => {
    if (projectData) {
      let counts: number = 0;
      let counts1: number = 0;
      let counts2: number = 0;
      let counts3: number = 0;
      let counts4: number = 0;
      let counts5: number = 0;

      projectData.forEach((e: any) => {
        if (e.status === "Proposal") {
          counts++;
        } else if (e.status === "Approved") {
          counts1++;
        } else if (e.status === "Active") {
          counts2++;
        } else if (e.status === "On Hold") {
          counts3++;
        } else if (e.status === "Complete") {
          counts4++;
        } else if (e.status === "Lost") {
          counts5++;
        }
      });

      setCount({
        count1: counts,
        count2: counts1,
        count3: counts2,
        count4: counts3,
        count5: counts4,
        count6: counts5,
      });
    }
  };

  useEffect(() => {
    countData();
  }, [projectData]);

  useEffect(() => {
    dispatch(getAllProject());
  }, []);

  useEffect(() => {
    if (ProjectResponse !== null) {
      dispatch(getAllProject());
    }
  }, [ProjectResponse, loading]);

  const openModalCreate = () => {
    props.setOpenModal(true);
    props.setCloseModalJob(true);
    setViewOptions(true);
  };

  return (
    <div className="col card overflow-y overflow-x-none custom-scrollbar trans-all p-0">
      <FactoryModal
        toggle={props.openModal}
        view={props.openModal}
        setView={() => props.setOpenModal(false)}
        activeControlView={props.activeControlView}
        setActiveControlView={props.setActiveControlView}
        selectJob={props.selectJob}
        setSelectJob={props.setSelectJob}
        activeTask={props.activeTask}
        setActiveTask={props.setActiveTask}
        selectProject={props.selectProject}
        setSelectProject={props.setSelectProject}
        closeModalJob={props.closeModalJob}
        setCloseModalJob={props.setCloseModalJob}
        viewOptions={viewOptions}
        setViewOptions={setViewOptions}
      />
      {openModalFilter && <FactoryModalFilter />}
      {/* Header */}
      <header className="mt-3 ">
        <div className="row col-lg-12 d-flex ps-4">
          <div className="col-sm-6 col-md-4 col-lg-4 ">
            <InputSearch className="w-100" placeholder="Search project" />
          </div>
          <div className="col-sm-3 col-md-3 col-lg-6 p-0 ">
            <button
              type="button"
              className="btn btn "
              onClick={() => setOpenModalFilter(!openModalFilter)}
              style={{
                width: 50,
                height: 43,
                border: "1.3px solid #DEE2E6",
              }}
            >
              <img src={FilterIcon} alt="" />
            </button>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-2 d-flex mt-1 mt-md-3 justify-content-lg-end">
            <button
              type="button"
              className="btn btn-primary btn-one-factory"
              onClick={openModalCreate}
            >
              Create
            </button>
          </div>
        </div>
      </header>

      {/** COUNTER BAR ////// */}
      <div className="col-lg-12">
        <CounterBar count={count} />
      </div>

      {/* Body */}
      <TableFatory
        setActiveControlView={props.setActiveControlView}
        setSelectJob={props.setSelectJob}
        setSelectProject={props.setSelectProject}
        projectData={projectData}
        loading={loading}
      />
    </div>
  );
};

export default FactoryView;
