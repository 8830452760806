import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useQuery from 'src/hooks/useQuery';
import { layoutConfig } from 'src/store/layout';
import UploadCSV from 'src/components/Contacts/AddContact/UploadCSV';
import AddContact from '../components/Contacts/AddContact/AddContact';
import ContactList from '../components/Contacts/ContactList/ContactList';
import ContactView from '../components/Contacts/ContactView/ContactView';
import ContactCategories from '../components/Contacts/ContactCategories/ContactCategories';

const Blackbook: React.FC = () => {
  const query = useQuery();
  const history = useHistory();

  const dispatch = useDispatch();
  
  const contactViewCard = useRef<HTMLDivElement>(null);
  const [isAddingContact, setIsAddingContact] = useState<boolean>(false);
  const [isContactViewOpen, setIsContactViewOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(layoutConfig.setModuleTitle('BLACKBOOK'));
  }, []);

  useEffect(() => {
    const shouldOpenCreateModal = query.get('create');

    if (shouldOpenCreateModal !== undefined && shouldOpenCreateModal !== null && !isAddingContact) {
      setIsAddingContact(true);
    }

    if (shouldOpenCreateModal === undefined || shouldOpenCreateModal === null) {
      setIsAddingContact(false);
    }
  }, [query, isAddingContact])

  const toggleAddingContact = (category?: string) => {
    if (isAddingContact) {
      query.delete('create');
      query.delete('category');
      history.replace({ search: query.toString() })
      dispatch(layoutConfig.setModuleTitle('BLACKBOOK'));
    } else {
      query.set('create', category || '');
      history.push({ search: query.toString() })
    }

    setIsAddingContact((prev) => !prev);
  };

  return (
    <>
      {isAddingContact && query?.get('create') !== 'csv' && (
        <AddContact onFinish={toggleAddingContact} />
      )}

      {isAddingContact && query.get('create') && query.get('create') === 'csv' && (
        <UploadCSV onFinish={toggleAddingContact} />
      )}

      <div
        className={`container-fluid h-100 ${
          isAddingContact ? 'd-none' : 'd-block'
        }`}
      >
        <div className="row" style={{ height: '98%' }}>
          
          <div className="col-2 card h-100">
            <ContactCategories />
          </div>

          <div
            className={`col-3 card h-100 ${
              isContactViewOpen ? 'd-none' : 'd-block'
            }`}
          >
            <ContactList
              onAddContact={toggleAddingContact}
              onUploadCSV={() => toggleAddingContact('csv')}
            />
          </div>

          <div
            ref={contactViewCard}
            style={{ flexGrow: isContactViewOpen ? 1 : 2 }}
            className="col card h-100 overflow-y overflow-x-none custom-scrollbar trans-all"
          >
            <ContactView
              isOpen={isContactViewOpen}
              contactViewCard={contactViewCard}
              toggleIsOpen={() => setIsContactViewOpen((prev) => !prev)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blackbook;
