import React, { forwardRef, Ref } from "react";
import AddRateItem from "./AddRateItem";

interface Props {
  letter: string;
  items: any;
  handleSelect: any;
  active?: string;
  addItems: any;
}

const AddItemsSection = forwardRef<HTMLDivElement, Props>(
  (props, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className="container-fluid p-0">
        <div className="row">
          <div className="col-12 d-flex flex-column">
            {props.items.length > 0 && (
              <p
                className="m-0 typo-body ps-3 py-1 text-primary"
                style={{ background: "#f3f3f3", paddingRight: "380px" }}
              >
                {props.letter}
              </p>
            )}
            <div>
              {props.items.map((item: any, index: number) => {
                let active = props.active === item._id.toString();
                return (
                  <AddRateItem
                    key={index}
                    items={item}
                    handleSelect={() => props.handleSelect(item)}
                    active={active}
                    addItems={props.addItems}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default AddItemsSection;
