import SidebarItem from './SidebarItem';
import logo from '../../assets/images/PFP_logo_white.png';
// Sidebar icons
import dashboardIcon from '../../assets/icons/sidebar/dashboard-sidebar-icon.svg';
import factoryIcon from '../../assets/icons/sidebar/factory-sidebar-icon.svg';
import blackbookIcon from '../../assets/icons/sidebar/blackbook-sidebar-icon.svg';
import reportsIcon from '../../assets/icons/sidebar/tasks-sidebar-icon.svg';
import commsIcon from '../../assets/icons/sidebar/communications-sidebar-icon.svg';
import coinIcon from '../../assets/icons/sidebar/coin-sidebar-icon.svg';
import schedulerIcon from '../../assets/icons/sidebar/scheduler-sidebar-icon.svg';
import stockpileIcon from '../../assets/icons/sidebar/stockpile-sidebar-icon.svg';
import ratesIcon from '../../assets/icons/sidebar/tasks-sidebar-icon.svg'; // TODO: Poner el icono correcto
import pxIcon from '../../assets/icons/sidebar/team-sidebar-icon.svg';
import pfpIcon from '../../assets/icons/sidebar/pfp-network-sidebar-icon.svg';

import { Tooltip } from 'reactstrap';
import React, { useState } from 'react';


interface Props {
  isOpen: boolean;
}

const options = [
  {
    path: '/',
    title: 'Dashboard',
    icon: dashboardIcon,
    id: 'db'
  },
  {
    path: '/factory',
    title: 'Factory',
    icon: factoryIcon,
    id: 'fy'
  },
  {
    path: '/blackbook',
    title: 'Blackbook',
    icon: blackbookIcon,
    id: 'bb'
  },
  {
    path: '/reports',
    title: 'Reports',
    icon: reportsIcon,
    id: 'rp',
    disabled: true
  },

  {
    path: '/comms',
    title: 'Comms',
    icon: commsIcon,
    id: 'cms',
    disabled: true
  },

  {
    path: '/coin',
    title: 'Coin',
    icon: coinIcon,
    id: 'cn',
    disabled: true
  },

  {
    path: '/scheduler',
    title: 'Scheduler',
    icon: schedulerIcon,
    id: 'sch',
    disabled: true
  },

  {
    path: '/stockpile',
    title: 'Stockpile',
    icon: stockpileIcon,
    id: 'stck'
  },

  {
    path: '/rates',
    title: 'Rates',
    icon: ratesIcon,
    id: 'rts'
  },

  {
    path: '/px',
    title: 'PX',
    icon: pxIcon,
    id: 'px',
    disabled: true
  },

  {
    path: '/pfp_network',
    title: 'PFP Network',
    icon: pfpIcon,
    id: 'ntw',
    disabled: true
  },
];

const Sidebar: React.FC<Props> = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const toggle = () => {
    setTooltipOpen(!tooltipOpen)
  }

  return (
    <div
      className={`sidebar bg-primary vh-100 pt-0 py-5 trans-width ${props.isOpen ? 'sidebar-open' : 'sidebar-closed'
        }`} 
    >
      <div className="d-flex flex-column justify-content-between h-100 pb-3 mt-0">
        <div className="overflow-auto hide-scrollbar sidebar-custom " >
          {options?.map((item, index) => {
            return (
                  <SidebarItem
                    key={index}
                    title={item.title}
                    icon={item.icon}
                    pathTo={item.path}
                    isOpen={props.isOpen}
                    id={item.id}
                    disabled={item.disabled}
                  />
            );
          })}
        </div>

        <div className="d-flex w-100 justify-content-center">
          <img src={logo} alt="" className="sidebar-logo" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
