import React, { useState } from "react";

interface Props {
  setView: any;
  activeControl: string;
  setActiveControl: any;
  getNewStatus: any;
  sendProject: any;
  setSendProject: any;
}

const Status: React.FC<Props> = (props) => {
  const ConfirmStatus = () => {
    props.getNewStatus();
    props.setView(false);
  };

  return (
    <div className="container-Selector-status">
      <div className="d-flex  m-auto justify-content-center mt-4">
        <h2 className="title-modal-factory text-secondary ">
          Change condition
        </h2>
      </div>

      <div className="p-4">
        {["Approved", "Proposal", "Active", "On Hold", "Lost", "Complete"].map(
          (e, index) => (
            <div
              key={index}
              className="container-selector-map-status"
              style={{ textTransform: "capitalize", cursor: "pointer" }}
              onClick={() =>
                props.setSendProject({ ...props.sendProject, status: e })
              }
            >
              <input
                value={props.sendProject.status}
                className="form-check-input me-3"
                type="radio"
                checked={props.sendProject.status === e ? true : false}
                onChange={() =>
                  props.setSendProject({ ...props.sendProject, status: e })
                }
              />
              <label className="text-black fw-normal">{e}</label>
            </div>
          )
        )}
      </div>
      <div className="row justify-content-evenly">
        <button
          onClick={ConfirmStatus}
          className={
            "btn btn-success  text-white col-auto btn-modal-factory fs-6"
          }
          //   disabled={activeButton}
          //   onClick={sendSelection}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Status;
