import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useToast } from "../../../hooks/useToast";
import { ApiError } from "../../../models";
import { AxiosResponse } from "axios";
import IconButton from "../../UI/buttons/IconButton";
import ComboBox from "../../UI/forms/ComboBox";
import AdvanceSearch from "../Search/AdvanceSearch";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

import RateModel, {
  RateCardVersionsModel,
  RateCardItemModel,
} from "../../../models/RateModel";
import { useRequest } from "../../../hooks/useRequest";
import Filters, { applyFilters, RateCardFilters } from "../Filters/Filters";

import { RateCardService } from "../../../services/rates";
import useRates from "../../../hooks/useRates";
import { ClientData, IContact } from "../../../models/ContactModel";
import { ContactTypeEx } from "../../../util/ContactsFunctions";
import EmptyCircle from "../../../components/UI/EmptyCircle";

interface Props {
  isOpen: boolean;
  typeRate: string;

  onCreateNewVersion: () => void;
  toggleIsOpen: () => void;
  onGlobalChange: () => void;
  onCompare: (id: string) => void;
}

interface rateCardVersionOpt {
  title: string;
  value: string;
}

const RateView: React.FC<Props> = (props) => {
  const params = useParams<{ rateCardId: string; rateCardVersion: string }>();
  const history = useHistory();
  const toast = useToast();
  const { mutate } = useRates();
  const {
    data: rate,
    error,
    mutate: mutateRate,
  } = useRequest<RateModel[]>(
    params?.rateCardId ? { url: `rate-cards/${params.rateCardId}` } : null
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [changeRateCardVersionModalOpen, setChangeRateCardVersionModalOpen] =
    useState<boolean>(false);

  const [rateCardVersion, setRateCardVersion] = useState<string>("");
  const [currentRateCardVersion, setcurrentRateCardVersion] =
    useState<string>("");
  const {
    data: items,
    error: errorItems,
    mutate: mutateItems,
  } = useRequest<RateCardItemModel[]>(
    (params?.rateCardId && rate && !params?.rateCardVersion) ||
      (params?.rateCardId && rate && params?.rateCardVersion)
      ? {
          url: params?.rateCardVersion
            ? `rate-card-versions/${params?.rateCardVersion}/rate-card-items`
            : `rate-card-versions/${
                rate[0].rate_card_versions[
                  rate[0].rate_card_versions.length - 1
                ]._id
              }/rate-card-items`,
        }
      : null
  );

  const clientInfo = useMemo(
    () =>
      rate && rate[0].assigned_to
        ? ContactTypeEx[(rate[0].assigned_to as IContact).type]
        : ContactTypeEx.CLIENT,
    rate && [rate[0].assigned_to]
  );

  const [filters, setfilters] = useState<RateCardFilters>({
    search: "",
    category: "",
  });
  const [deleting, setdeleting] = useState<boolean>(false);

  const rateCardVersionChange = (id: string) => {
    setRateCardVersion(id);
    setChangeRateCardVersionModalOpen(true);
  };

  const reloadItems = () => {
    mutateItems(undefined, true);
  };

  const changeRateCardVersion = () => {
    setcurrentRateCardVersion(rateCardVersion);
    history.push(
      `/rates/${props.typeRate}/${params.rateCardId}/version/${rateCardVersion}`
    );
    setChangeRateCardVersionModalOpen(false);
  };

  const getRateCardVersionDataById = (
    id: string
  ): RateCardVersionsModel | undefined => {
    let getRate = rate
      ? rate[0].rate_card_versions.find(
          (item) => item._id.toString() === id.toString()
        )
      : undefined;
    return getRate;
  };

  const cancelChangeRateCardVersion = () => {
    setRateCardVersion(currentRateCardVersion);
    setChangeRateCardVersionModalOpen(false);
  };

  const getVersions = (items: RateCardVersionsModel[]) => {
    return items.map((item) => {
      return {
        title: item.description.toString(),
        value: item._id.toString(),
      };
    });
  };

  const deleteRateCard = () => {
    setdeleting(false);

    if (rate) {
      RateCardService.DeleteRateCard(rate[0]._id.toString())
        .then((rateCard) => {
          mutate(undefined, true);
          setdeleting(false);
          toast.success("RateCard created successfully.");
          setDeleteModalOpen(false);
          // history.push(`/rates/${props.typeRate}/`);
          mutateRate(undefined, true);
          history.push(`/rates/`);
        })
        .catch((err: AxiosResponse<ApiError>) => {
          setdeleting(false);
          return toast.error(
            Array.isArray(err.data.message)
              ? err.data.message.join("\n")
              : err.data.message
          );
        });
    }
  };

  let tableItems = applyFilters(items ? items : [], filters);

  const restoreVersion = () => {
    setRateCardVersion("");
    history.push(`/rates/${props.typeRate}/${params.rateCardId}/`);
  };

  return (
    <div className="d-flex flex-column h-100">
      {deleteModalOpen && (
        <ConfirmModal
          title="What do you want to do?"
          description={`You are about to delete ${
            params?.rateCardVersion
              ? rate?.[0].name +
                " - " +
                getRateCardVersionDataById(params?.rateCardVersion)?.description
              : rate?.[0].name
          }`}
          buttons={
            <>
              <div>
                <button
                  className="btn btn-danger text-white mx-1"
                  onClick={() => deleteRateCard()}
                  disabled={deleting}
                >
                  DELETE PERMANENTLY
                </button>
                <button
                  className="btn btn-success text-white mx-1"
                  onClick={() => setDeleteModalOpen(false)}
                  disabled={deleting}
                >
                  CANCEL
                </button>
              </div>
            </>
          }
          onClose={() => setDeleteModalOpen(false)}
        />
      )}

      {!params.rateCardId && (
        <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted">
          <i className="fs-3 bi bi-hand-index d-block"></i>
          <p className="h6">Please select a rate card from the list.</p>
        </div>
      )}

      {(!rate || !items) && params.rateCardId && !error && (
        <div className="d-flex justify-content-center align-items-center py-5 h-100">
          <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
        </div>
      )}

      {(!rate && error) ||
        (!items && errorItems && (
          <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted">
            <i className="fs-3 bi bi-exclamation-triangle d-block"></i>
            <p className="h6">
              Error while loading the selected RateCard details.
            </p>
            <pre>
              {error && error.data
                ? error.data.message
                : error
                ? error.statusText
                : ""}
              {errorItems && errorItems.data
                ? errorItems.data.message
                : errorItems
                ? errorItems.statusText
                : ""}
            </pre>
          </div>
        ))}

      {rate && items && params.rateCardId && (
        <Fragment>
          {changeRateCardVersionModalOpen && (
            <ConfirmModal
              title={`${
                rate[0].rate_card_versions.find(
                  (item) => item._id === rateCardVersion
                )?.description
              }`}
              description={`You are about to see details of ${
                rate[0].rate_card_versions.find(
                  (item) => item._id === rateCardVersion
                )?.description
              } of ${rate[0].name}.`}
              buttons={
                <>
                  <div>
                    <button
                      className="btn btn-danger text-white mx-1 py-2 px-3"
                      onClick={() => cancelChangeRateCardVersion()}
                    >
                      CLOSE
                    </button>
                    <button
                      className="btn btn-success text-white mx-1 py-2 px-3"
                      onClick={() => changeRateCardVersion()}
                    >
                      SEE RATE CARD
                    </button>
                  </div>
                </>
              }
              onClose={() => cancelChangeRateCardVersion()}
            />
          )}

          <div className="px-4">
            {/* Header */}
            <header className="w-100 mt-3 d-flex justify-content-between align-items-center">
              {!params?.rateCardVersion && (
                <i
                  onClick={props.toggleIsOpen}
                  className={`${
                    props.isOpen
                      ? "bi bi-arrows-angle-contract"
                      : "bi bi-arrows-angle-expand"
                  } col-auto text-primary h5 m-0`}
                />
              )}

              {!params?.rateCardVersion && (
                <div className="d-flex align-items-center">
                  {!rate[0].blocked && (
                    <button
                      className="btn btn-primary me-2 text-uppercase px-3"
                      onClick={props.onCreateNewVersion}
                    >
                      Create new version
                    </button>
                  )}

                  {props.typeRate === "clients" && (
                    <button
                      className="btn btn-primary me-2"
                      onClick={props.onGlobalChange}
                    >
                      GLOBAL % CHANGE
                    </button>
                  )}

                  {rate[0].rate_card_versions && (
                    <ComboBox
                      className="mx-2"
                      autoHeight
                      value={
                        rateCardVersion !== ""
                          ? rateCardVersion
                          : rate[0].rate_card_versions[
                              rate[0].rate_card_versions.length - 1
                            ]._id.toString()
                      }
                      onChange={(value: string) => rateCardVersionChange(value)}
                      options={getVersions(rate[0].rate_card_versions)}
                    />
                  )}

                  <IconButton
                    color="primary"
                    icon="bi bi-arrow-clockwise"
                    tooltip=""
                    outline
                    onClick={() => reloadItems()}
                  />

                  <IconButton
                    color="primary"
                    icon="bi bi-arrow-left-right"
                    tooltip=""
                    outline
                    onClick={() =>
                      props.onCompare(
                        rate?.[0].rate_card_versions[
                          rate[0].rate_card_versions.length - 1
                        ]._id.toString()
                      )
                    }
                  />

                  {!rate[0].blocked && (
                    <IconButton
                      color="danger"
                      image="/static/media/trash.svg"
                      tooltip="Delete contact"
                      outline
                      onClick={() => setDeleteModalOpen(true)}
                      imageClass="btn-img"
                    />
                  )}
                </div>
              )}
              {/* Top buttons */}
            </header>

            <div className="content-rate-description mb-2">
              {/* name - description */}
              <h1 className="typo-body h5 mt-3 fw-bold">
                {params?.rateCardVersion && (
                  <i
                    onClick={() => restoreVersion()}
                    className={`bi bi-chevron-left text-primary h5 me-2`}
                  />
                )}

                {params?.rateCardVersion
                  ? rate[0].name +
                    " - " +
                    getRateCardVersionDataById(params?.rateCardVersion)
                      ?.description
                  : rate[0].name}
              </h1>
              <p className="m-0 typo-body text-muted mt-2">DESCRIPTION</p>
              <p className="m-0 typo-body text-secondary">
                {rate[0].description}
              </p>
              {/* name - description */}

              {rate &&
                rate[0].assigned_to &&
                typeof rate[0].assigned_to !== "string" && (
                  <div className="row pb-2">
                    <div className="col-md-4">
                      <h6 className="m-0 typo-body text-muted mt-2">
                        ORGANIZATION
                      </h6>
                      <Link to="/" className="typo-body">
                        {
                          (
                            (rate[0].assigned_to as IContact)
                              .entity as ClientData
                          ).organization
                        }
                      </Link>
                    </div>

                    <div className="col-md-4">
                      <h6 className="m-0 typo-body text-muted mt-2">CLIENT</h6>
                      <Link
                        to={`/blackbook/${
                          (rate[0].assigned_to as IContact)._id
                        }`}
                        className="typo-body"
                      >
                        {clientInfo.getTitle(rate[0].assigned_to as IContact)}
                      </Link>
                    </div>
                  </div>
                )}
            </div>

            <Filters
              col="6"
              advandedFilters
              filterValue={filters}
              onChangeFilters={(value: RateCardFilters) => setfilters(value)}
            />
          </div>

          {/* table */}
          <div className="w-100 custom-scrollbar overflow-x mt-3 custom-table">
            <table className="table table-borderless">
              <thead>
                <tr className="text-primary typo-body">
                  <th scope="col" style={{ minWidth: "200px" }}>
                    ITEM
                  </th>
                  <th scope="col">CATEGORY</th>
                  <th scope="col">HOUR</th>
                  <th scope="col">DAY</th>
                  <th scope="col">WEEK</th>
                  <th scope="col">SALE</th>
                  <th scope="col">OVERTIME HOUR</th>
                  <th scope="col">HOLIDAY HOUR</th>
                  <th scope="col">HOLIDAY DAY</th>
                  <th scope="col">HOLIDAY OVERTIME HOUR</th>
                </tr>
              </thead>

              {tableItems.length > 0 ? (
                <tbody>
                  {tableItems.map((item: any, index) => {
                    return (
                      <tr
                        className={`${
                          (index / 2) % 1 > 0 ? "" : "table-primary"
                        } text-primary typo-body`}
                        key={index}
                      >
                        <td className="d-flex align-items-center">
                          {item?.item?.picture_url && (
                            <img
                              loading="lazy"
                              src={
                                process.env.REACT_APP_IMAGES_AWS +
                                item?.item?.picture_url
                              }
                              alt=""
                              className="avatar2"
                            />
                          )}
                          {!item?.item?.picture_url && (
                            <EmptyCircle title={item?.item?.name} small />
                          )}

                          <p className="m-0 ms-2">
                            {item.item && item.item.name
                              ? `${item.item.name}`
                              : ""}
                            {item.item && item.item.brand
                              ? `- ${item.item.brand}`
                              : ""}
                          </p>
                        </td>
                        <td className="align-middle">
                          {item.item ? item.item.category?.name : "-"}
                        </td>
                        <td className="align-middle">${item.rates.hour}</td>
                        <td className="align-middle">${item.rates.daily}</td>
                        <td className="align-middle">${item.rates.week}</td>
                        <td className="align-middle">${item.rates.sale}</td>
                        <td className="align-middle">
                          ${item.rates.overtime_hour}
                        </td>
                        <td className="align-middle">
                          ${item.rates.holiday_hour}
                        </td>
                        <td className="align-middle">
                          ${item.rates.holiday_day}
                        </td>
                        <td className="align-middle">
                          ${item.rates.holiday_overtime_hour}
                        </td>

                        <td className="align-middle d-none">
                          <IconButton
                            color="primary bg-white"
                            outline
                            icon="bi bi-pencil-fill"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr className="table-primary text-primary text-center align-middle typo-body">
                    <td colSpan={10} className="align-middle py-3">
                      No items were found.
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          {params.rateCardVersion && (
            <div className="content-restore-version-btn">
              <div className="btn-container">
                <button
                  className="btn btn-primary px-4 py-2"
                  onClick={() => restoreVersion()}
                >
                  Restore version
                </button>
              </div>
            </div>
          )}

          {/* table */}
        </Fragment>
      )}
    </div>
  );
};

export default RateView;
