import { createSlice , createAsyncThunk , PayloadAction  } from '@reduxjs/toolkit';
import authService from '../services/auth';

export const recoverPasswordRequest = createAsyncThunk(
    "recover/Request",
    async (email: string, thunkAPI) => {
      try {
        const data = await authService.RecoverPasswordRequest(email);
        console.log(data);
        return data;
      } catch (error) {
        console.log(error);
      }
    }
  );

  export const recoverPassword = createAsyncThunk(
    "recover/Password",
    async (RecoverData: any, thunkAPI) => {
      try {
        const data = await authService.RecoverPassword({
            email: RecoverData.email ,
            token: RecoverData.token ,
            password: RecoverData.password,
        });
        console.log(data);
        return data;
      } catch (error) {
        console.log(error);
      }
    }
  );

const initialState = {
    loading: false,
    message: ''
};

const recoverSlice = createSlice({
    name: 'recover',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(recoverPasswordRequest.fulfilled, (state, action ) => {
        state.loading = true; 
      })
    }
  });

export const recoverActions = recoverSlice.actions;
export default recoverSlice.reducer;