import { Html5QrcodeScanner, Html5Qrcode, Html5QrcodeScanType } from 'html5-qrcode'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import ButtonIconText from '../UI/buttons/ButtonIconText';
import SimpleButton from '../UI/buttons/SimpleButton';
import RenderElemnt from './RenderElement'


interface Props {
    isOpen: boolean,
    setCode: any,
    setStateModal: any
    showScanner?: any
    showScannerValue?: any
    html5scanner?: any
}



const Scanner: React.FC<Props> = (props) => {

    const [showScanner, setShowScaner] = useState(false)

    return (
        <Modal isOpen={props.isOpen} toggle={() => props.setStateModal(false)}>
            <ModalBody>

                <div id='reader' />

                {
                    props.isOpen ?
                        <RenderElemnt
                            isOpen={props.isOpen}
                            setCode={props.setCode}
                            setStateModal={props.setStateModal}
                            showScanner={setShowScaner}
                            showScannerValue={showScanner}
                        /> :
                        <>
                        </>
                }
            </ModalBody>
        </Modal>
    )
}

export default Scanner
