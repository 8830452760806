import React, {useState} from "react";

enum format {
    text   = "text",
    number = "number"
}

interface Props {
    value:          string | number;
    format?:        string;
    onEdit:         (val: string | number) => void;
    onBlur:         () => void;
}

const CellInputValue: React.FC<Props> = (props) => {
    const {value, onEdit, onBlur} = props;
    const [inputValue, setinputValue] = useState(value);

    // let inputType = props.format && typeof props.format === "number" ? "number" : "text";
    let inputType = props.format ? props.format : "text";

    const checkInputEdit = () => {
        if(inputValue !== value){
            onEdit(inputValue);
        }else{
            onBlur();
        }
    }

    return (
        <td className="align-middle content-input-edit">
            <input 
                type={inputType}
                value={inputValue} 
                onChange={(e) => setinputValue(e.target.value)} 
                onBlur={() => checkInputEdit()}
                autoFocus 
            />
        </td>
    )
}
export default CellInputValue