import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

/* @ts-ignore */
import { motion } from 'framer-motion/dist/framer-motion';

import { Toast as IToast, toastsStore } from 'src/store/toasts';

interface ToastProps {
  toast: IToast;
}

function Toast({ toast }: ToastProps) {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);

  const closeToast = useCallback(() => {
    dispatch(toastsStore.removeToast(toast.id));
  }, [toast, dispatch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      closeToast();
    }, toast.timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [toast, closeToast]);

  const handleShowMore = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShowMore((prev) => !prev);
  }, [])

  return (
    <motion.div
      layout
      exit="exit"
      animate="animate"
      initial="initial"
      onClick={closeToast}
      variants={{
        animate: { x: 0, opacity: 1 },
        initial: { x: 700, opacity: 0 },
        exit: { scale: 0, opacity: 0, transition: { duration: 0.2 } },
      }}
      transition={{
        type: 'spring',
        damping: 100,
        stiffness: 1000 ,
        scaleY: { type: 'tween', duration: 5 }
      }}
      className={clsx("toastContainer", {
        "toastContainer__info": toast.type === 'info',
        "toastContainer__error": toast.type === 'error',
        "toastContainer__success": toast.type === 'success',
        "toastContainer__primary": toast.type === 'primary',
      })}
    >
      <header className="toastContainer-header">
        <div className="toastContainer-header__title">
          {/* icon */}
          <span>{toast.module}</span>
        </div>

        <div className="toastContainer-header__close">
          <i className="bi bi-x-circle-fill"></i>
        </div>
      </header>

      <main className="toastContainer-content">
        {toast.content ? toast.content : (
          Array.isArray(toast.message) && toast.message.length !== 1 ? (
            (toast.message.length > 3 && !showMore ? toast.message.slice(0, 3).map((err: string, idx) => (
              <p className="m-0" key={idx}>{idx+1}. {err}.</p>
            )) : toast.message.map((err: string, idx) => (
              <p className="m-0" key={idx}>{idx+1}. {err}.</p>
            )))
          ) : `${toast.message.slice(0, showMore ? toast.message.length : 70)}${!showMore && toast.message.length > 70 ? '...' : ''}`
        )}

        {Array.isArray(toast.message) && toast.message.length > 3 && (
          <span onClick={handleShowMore}>{showMore ? 'Read less...' : 'Read more...'}</span>
        )}
        {!Array.isArray(toast.message) && toast.message.length > 70 && (
          <span onClick={handleShowMore}>{showMore ? 'Read less...' : 'Read more...'}</span>
        )}
      </main>
    </motion.div>
  );
}

export default Toast;