import QuickNotes from '../../../UI/QuickNotes';

const History: React.FC = () => {
  const projectHistory:any = [
    {
      text: 'Blake Smith: It has a really nice and powerful sound.',
      date: "2022-05-12T19:50:56.019Z",
      transaction: 'in',
    },
    {
      text: 'Jake Henry: The best mic.',
      date: "2022-05-12T19:50:56.019Z",
      transaction: 'out',
    },
  ]

  const AssetHistory:any = [
    {
      text: 'Blake Smith: It has a really nice and powerful sound.',
      date: "2022-05-12T19:50:56.019Z",
    },
    {
      text: 'Jake Henry: The best mic.',
      date: "2022-05-12T19:50:56.019Z",
    },
  ]
  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3 ">
        <QuickNotes
          title="Asset history"
          isEditable
          texts={AssetHistory}
        />
      </div>

      <div className="row border-bottom p-0 py-3 ">
        <QuickNotes
          title="Project history"
          texts={projectHistory}
        />
      </div>
    </div>
  );
};

export default History;
