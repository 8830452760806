import { capitalizeText } from "src/util";
import BookableSkillsTable from "./BookableSkillsTable";
import InfoDisplay from "src/components/UI/InfoDisplay";
import { ContactType, IContact } from "src/models/ContactModel";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";

const FreelanceDetails: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {
  if (contact.type !== ContactType.FREELANCE) return null;

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3">
        <BookableSkillsTable skills={contact.entity.bookable_skills as string[]} />
      </div>

      <div className="row border-bottom p-0 py-3">
        <h6 className="text-primary">{contact.entity?.payment_info?.length === 0 ? 'No' : ''} Payment Accounts</h6>
        <div className="row">
          {contact.entity.payment_info?.map((paymentInfo, index) => (
            <div key={paymentInfo.type+'-'+paymentInfo.account} className="col-6">
              <InfoDisplay
                title={`Payment Account ${index + 1}`}
                texts={[
                  { text: capitalizeText(paymentInfo.method) },
                  { text: `${paymentInfo.account}, ${paymentInfo.account_owner}` },
                  { text: `${paymentInfo.bank}, ${paymentInfo.type}` },
                ]}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <InfoDisplay
          title="software/equipment"
          texts={[
            {
              text: 'Photoshop',
            },
          ]}
        />
      </div>

      <div className="row border-bottom p-0 py-3">
        <InfoDisplay
          title="union affiliation"
          texts={[
            {
              text: contact.entity.union_affiliation || 'No union affiliation.',
            },
          ]}
        />
      </div>

      <div className="row border-bottom p-0 py-3">
        {['LABOR CARD/WORD PERMIT', 'WORKING AGREEMENT FORM', 'ID CARD NUMBER', 'PASSPORT', 'VISA', 'DRIVING LICENSE', 'VEHICLE REGISTRATION CARD', 'DOCUMENT NAME', 'PROFESSIONAL DOCUMENT - CERTIFICATE', 'VEHICLE INFORMATION'].map((text) => (
          <div className="col-6">
            <InfoDisplay
              title={text}
              texts={[
                {
                  text: 'Not configured.',
                },
              ]}
              key={text}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FreelanceDetails;