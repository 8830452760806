import React from "react";
import RateModel from "../../../models/RateModel";

interface Props {
  onSelect?: (id: string) => void;
  contact: RateModel;
  active?: boolean;

  block?: boolean | false;
}

const RateItem: React.FC<Props> = (props) => {
  return (
    <div
      className={
        (props.active ? "active " : "") +
        (!props.block ? "border-top " : "") +
        " container-fluid p-3 py-2 contact-item"
      }
      onClick={props.onSelect?.bind(null, "0")}
    >
      <div className="row align-items-center">
        {/* {props.onSelect && (
          <div className="col-auto p-0 ps-2">
            <input className="form-check-input" type="checkbox" />
          </div>
        )} */}

        <div
          className={`col d-flex p-0 text-truncate ${
            // props.onSelect ? 'ms-3' : 'ms-2'
            "ms-2"
          }`}
        >
          <div className="ms-2">
            <p className="m-0 text-black typo-body contact-title">
              {props.contact.name}{" "}
              {props.block ? (
                <i className="ms-3 bi bi-lock-fill text-primary"></i>
              ) : (
                ""
              )}
            </p>
            {/* 
              <p className="m-0 text-black typo-body contact-subtitle">
                {props.contact.title}
              </p>
            */}
          </div>
        </div>

        {true && !props.block && (
          <div className="col-auto p-0 pe-1">
            <i className="bi bi-arrow-clockwise text-primary h4 m-0"></i>

            {/* <p className="m-0 contact-trailing typo-body bg-primary my-1">
                {props.contact.count1}
              </p> */}

            {/* {props.contact.count2 && (
              <p className="m-0 contact-trailing typo-body bg-success my-1">
                {props.contact.count2}
              </p>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default RateItem;
