import React from "react";
import { SWRConfig } from "swr";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import store from "./store/store";
import reportWebVitals from "./reportWebVitals";
import { APIInterceptors } from "./services/axios";

import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import "./index.scss";

import ToastsContainer from "./components/UI/Toasts/ToastsContainer";

/* This will initialize the api interceptors once */
APIInterceptors.getInstance();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          shouldRetryOnError: false,
        }}
      >
        <ToastsContainer />

        <App />
      </SWRConfig>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
