import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Toast, toastsStore } from "src/store/toasts";

const routes: { [key: string]: string } = {
  'rates': 'RATES',
  'factory': 'FACTORY',
  'blackbook': 'BLACKBOOK',
  'stockpile': 'STOCKPILE',
};

type AddParam = string | string[] | Partial<Toast>;

export function useToast() {
  const location = useLocation();
  const dispatch = useDispatch();

  const getToast = useCallback((toast: AddParam, type: Toast['type']): Toast => {
    let result: Toast = {
      id: `${Date.now()}`,
      module: routes[location.pathname.split('/')[1]],
      message: "",
      type: type,
      timeout: 7000,
    };

    if (typeof toast === "string") {
      result.message = toast;
    } else {
      result = {
        ...result,
        ...(typeof toast === 'string' || Array.isArray(toast) ? { message: toast } : toast),
      }
    }
    
    return result
  }, [location.pathname])

  return {
    error: (message: AddParam) => dispatch(toastsStore.addToast(getToast(message, 'error'))),
    success: (message: AddParam) => dispatch(toastsStore.addToast(getToast(message, 'success'))),
    primary: (message: AddParam) => dispatch(toastsStore.addToast(getToast(message, 'primary'))),
    info: (message: AddParam) => dispatch(toastsStore.addToast(getToast(message, 'info'))),
    warn: (message: AddParam) => dispatch(toastsStore.addToast(getToast(message, 'info'))),
  };
}