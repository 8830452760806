import clsx from 'clsx';
import { Line } from 'react-chartjs-2';
import { ChartDataset, ChartOptions } from 'chart.js';

interface Props {
  title?: string;
  labels: string[];
  data: number[];

  fullWidth?: boolean;
  chartOptions?: ChartOptions<'line'>;
  datasetProps?: Omit<ChartDataset<"line", number[]>, 'data'>;
}

const LineChart: React.FC<Props> = (props) => {
  return (
    <div className="pb-3">
      {props.title && (
        <p className="m-0 typo-body text-muted text-uppercase pb-3">
          {props.title}
        </p>
      )}
      <div className={clsx('chart', props.fullWidth && 'w-100')}>
        <Line
          options={{
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            ...(props.chartOptions || {}),
          }}
          data={{
            labels: props.labels,
            datasets: [
              {
                label: '',
                data: props.data,
                backgroundColor: '#000000',
                borderColor: '#000000',
          
                borderWidth: 1,
                tension: 0.2,

                ...(props.datasetProps || {}),
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default LineChart;
