import { forwardRef, Ref } from "react";
import LetterSection from "src/components/DashboardLayout/MidCard/LetterSection";
import RateModel from "../../../models/RateModel";
import RateItem from "./RateItem";

interface Props {
  letter: string;
  items: RateModel[];
  onRateSelected: (rate: RateModel) => void;
  active?: string;
}

const RateSection = forwardRef<HTMLDivElement, Props>(
  (props, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            {props.items.length > 0 && (
              <p
                className="m-0 typo-body ps-3 py-1 text-primary"
                style={{ background: "#f3f3f3" }}
              >
                {props.letter}
              </p>
            )}
            <div>
              {props.items.map((item: RateModel, index: number) => {
                let active = props.active === item._id.toString();

                return (
                  <RateItem
                    key={index}
                    contact={item}
                    onSelect={() => props.onRateSelected(item)}
                    active={active}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default RateSection;
