import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AssetModel from '../../models/AssetModel';
import stockpileService from '../../services/stockpile';

export const getCommentsForAsset = createAsyncThunk("selectAssets/getComments", async (id: any) => {
    try {
        const data = await stockpileService.GetCommentsForAsset(id)
        return { data: data }
    } catch (error) {
        console.error(error);
    }
})

export const getAssetForID = createAsyncThunk("stockpile/getAseetForID", async (id: any) => {
    try {
        const data:any = await stockpileService.GetAssetsForID(id)
        return data
    } catch (error) {
        return false
        console.error(error);
    }
})


export const GetAceesoriesbyID = createAsyncThunk("stockpile/getAceesoriesbyID", async (id: any) => {
    try {
        const data = await stockpileService.GetAccesories(id)
        return { data: data }
    } catch (error) {
        return false
        console.error(error);
    }
})


const initialState = {
    data: [] as any,
    dataConfirm: false ,
    ITEM_ID: "",
    comments: [] as Array<any>,
    AccesoriesSelected: [] as Array<any>
};

const selectAssetsSlice = createSlice({
    name: 'selectAssets',
    initialState,
    reducers: {
        setDataAsset: (state, action) => {
            state.data = action.payload
        },
        setIdItem: (state, action) => {
            state.ITEM_ID = action.payload
        },
        deleteComment: (state, action) => {
            const ar = state.comments
            if (action.payload > -1) {
                ar.splice(action.payload, 1);
            }
            state.comments = ar
        },
        updateComment: (state, action) => {
            const modify = state.comments.map((c: any, index: any) => {
                if (c._id == action.payload.id) {
                    c.content = action.payload.comment
                }
                return c
            })
            state.comments = modify
        },
        AddComment: (state , action) => {
            state.comments.push(action.payload)
        },
        cleanAsset: (state) => {
            state.data = []
        },
        RefreshAccesories: (state,action) => {
            state.AccesoriesSelected = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCommentsForAsset.fulfilled, (state, action) => {
                console.log(action.payload);
                state.comments = action.payload?.data
            })
            .addCase(getAssetForID.fulfilled, (state, action: any) => {
                state.data = action.payload
            })
            .addCase(GetAceesoriesbyID.fulfilled , (state , action:any) => {
                state.AccesoriesSelected= action.payload?.data
            })
    }
});

export const { setDataAsset, setIdItem, RefreshAccesories ,updateComment , deleteComment , AddComment, cleanAsset} = selectAssetsSlice.actions;
export default selectAssetsSlice.reducer;
