import React from "react";
import { useBarcode } from "react-barcodes";

interface Props {
    serial?: any
    valueCondition?: any
    changeCondition?: any
}

const BarcodePreview: React.FC<Props> = (props) => {

    const { inputRef } = useBarcode({
        value: props?.serial,
        options: {
            width: 0.5,
            height: 34,
            displayValue: false
        }
    });

    return (
        <div className='row '>
            <div className="col-sm-12 col-md-6 ">
                <p style={{ color: '#8898AA' }}>Owner <br /><span
                    style={{ color: '#344469' }}>Direct</span></p>
                <p style={{ color: '#8898AA' }} >
                    Condition <br />
                    <span className={`${!props.valueCondition && 'd-none'}`} style={{ color: '#344469', textTransform: 'capitalize' }}>{props.valueCondition}&nbsp;&nbsp;
                        &nbsp;</span>
                    <span
                        className="badge badgeCard"
                        onClick={props.changeCondition}
                    >CHANGE</span>
                </p>
            </div>
            <div className="col-sm-12 col-md-6" style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                <p style={{color: '#8898AA'}}>Asset Code</p>
                <div style={{ color: '#8898AA'}} >
                    <div style={{ marginTop: '-10%', maxWidth:'140px' , overflow:'hidden'}}>
                        <svg ref={inputRef} />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BarcodePreview