import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import factoryPublicService from "src/services/factory/publicService";



export const getPublicQuoteData = createAsyncThunk("factory/getPublicQuoteData", async (id: any) => {
    try {
        const data = await factoryPublicService.GetPublicData(id)
        console.log(data);
        return { data: data };
    } catch (error) {
        console.error(error);
    }
});

export const changeStatus = createAsyncThunk("factory/postChangeStatusQuote", async (allData: any) => {
    try {
        const res = await factoryPublicService.ChangeStatusQuote(allData.id , allData.data )
        return res ;
    } catch (error) {
        console.error(error);
    }
});



const initialState = {
    loading: false,
    QuoteData: [] as any,
    QuotesId: [] as any,
    QuoteVersion: [] as any,
    loadingChangeStatus: false,
    ResponseChangeStatus: '' as any,
    Items: [] as any
};

const publicFactory = createSlice({
    name: "publicFactory",
    initialState,
    reducers: {
        clean: (state) => {
            console.log('nothing');
            
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPublicQuoteData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getPublicQuoteData.fulfilled, (state, action) => {
                state.loading = false;
                state.QuoteVersion = action.payload?.data?.headers
                state.QuotesId =  action.payload?.data
                action.payload?.data.headers.map((head:any) => {
                   state.Items = [...state.QuoteData, ...head.items]
                })
            })
            .addCase(changeStatus.pending, (state, action) => {
                state.loadingChangeStatus = true
            })
            .addCase(changeStatus.fulfilled, (state, action) => {
                state.ResponseChangeStatus = action?.payload
            })
    },
});

export const { clean } = publicFactory.actions;
export default publicFactory.reducer;

