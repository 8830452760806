import React, { useState } from "react";
import { Modal } from "reactstrap";
import ModalClienBrief from "./ModalClienBrief";

interface Props {
  viewBrief: boolean;
  setViewBrief: (value: boolean) => void;
  toggle: any;

  jobDataById: any;
}
const ModalBrief: React.FC<Props> = (props) => {
  const closeModal = () => {
    props.setViewBrief(false);
  };
  return (
    <div className="containerModalTwo">
      <Modal
        isOpen={props.viewBrief}
        toggle={() => props.toggle}
        centered
        className="modalQuoteTwo"
        style={{
          width: "850px",
          maxWidth: "850px",
          height: "450px",
        }}
      >
        <div
          className={
            "text-center d-flex flex-column custom-scrollbar overflow-hidden"
          }
          style={{ height: "auto" }}
        >
          <div
            onClick={closeModal}
            className="cursor-pointer p-2"
            style={{ width: "30px" }}
          >
            <i
              className="bi bi-x-lg  "
              style={{
                fontSize: "18px",
              }}
            ></i>
          </div>
          <div className="d-flex justify-content-center ">
            <ModalClienBrief jobDataById={props.jobDataById} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalBrief;
