import { useCallback, useEffect, useMemo, useState } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'
import { Progress } from "reactstrap";
import ImageCropper from 'src/components/ImageCropper';
import { setTimeout } from 'timers';

interface Props {
    setFile: any,
    files: any,
    maxFiles?: any,
    errors: any,
    file?: any,
    setImage?: any,
    eventCropper?: any,
    vieCropper?: any,
    setvieCropper?: any,
}

const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    paddingLeft: '0px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#6b8ab8'
};

const acceptStyle = {
    borderColor: '#6b8ab8'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const thumbsContainer: any = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb: any = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner: any = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const DropZone: React.FC<Props> = (props) => {

    const [percent, setPercent] = useState<any>(null)
    const [previewCanvasRef , setpreviewCanvasRef ] = useState<any>(false)

    const files = props.files
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        maxFiles: props.maxFiles,
        onDrop: acceptedFiles => {
            props.setFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
    });

    const thumbs = files.map((file: any) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner} >
                {
                    !props.setImage && (<img
                        src={file.preview}
                        style={img}
                        // Revoke data uri after image is loaded
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />)
                }
                {
                    props.setImage && previewCanvasRef && (
                        <img
                            src={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: 100,
                                height: 100,
                            }}
                        />
                    )
                }
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, []);

    const style: any = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject || props.errors ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
        props.errors
    ]);

    return (<>
        <section className="">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <p className={!props.errors ? `text-primary` : `validation`}>
                    {
                        files.map((file: any, index: any) => (
                            <>
                                {file.name + ' '}{files.length > 0 &&
                                    index != files.length - 1 && ','}
                            </>
                        ))
                    }</p>

            </div>
            {<Progress color="info" value={percent} />
            }

            {!props.file && <aside style={thumbsContainer}>
                {!props.errors && thumbs}
            </aside>}
            <p className='validation'>{props.errors}</p>
        </section>
        {!props.file && (<ImageCropper setview={props.setvieCropper} view={props.vieCropper} e={{
            target: {
                files: files
            }
        } as any} setImage={props.setImage} setpreviewCanvasRef={setpreviewCanvasRef} />)}
    </>
    )
}

export default DropZone;

