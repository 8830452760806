import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
// import toolbar from "../../createProyectForm/tabs/TextEdit/toolbar";
import "quill/dist/quill.snow.css";

interface Props {
  brief: any;
}

const ClienBrief: React.FC<Props> = (props) => {
  const { quill, quillRef }: any = useQuill({
    readOnly: true,
    modules: {
      toolbar: false,
    },
  });

  console.log("from modal brief", props.brief);
  useEffect(() => {
    if (quill && props.brief) {
      quill.setContents(JSON?.parse(props.brief));
    }
  }, [quill, props.brief]);

  return (
    <div
      className="editord-flex justify-content-center m-auto mt-3"
      style={{ width: "950px", height: "250px" }}
    >
      <div className="text-black">
        <article ref={quillRef} />
      </div>
    </div>
  );
};

export default ClienBrief;
