import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import { CreateCommentForAsset } from 'src/store/stockpile/stockpileSlice';
import InputFloatingLabel from '../UI/forms/InputFloatingLabel';
import TextArea from '../UI/forms/TextArea';


interface Props {
  title:string;
  view: boolean;
  setView:any
  toggle:any
  data?:any
  setData?:any
  sendData?:any;
  placeHolder?: string;
  id?:any;
}

const AddComentsModal: React.FC<Props> = (props) => {

  const dispatch = useDispatch()


  const setComment = () => {
    props.sendData()
    props.setData('')
    props.setView(false)
  }




  return (
    <Modal isOpen={props.view} toggle={() => props.toggle} centered >
      <div className="container text-center">
        <div className="row p-4">
          <div className="col-12">
            <h2 className="font-weight-bold text-secondary typo-display">
              {props.title}
            </h2>
          </div>
          <div className="col-12 mt-3">
            <TextArea
              value={props.data}
              onChange={(value) => { props.setData(value) }}
              placeHolder={props.placeHolder || 'Minimun of 10 characters'}
            />
          </div>

          <div className="col-12 mt-3">
            <div className="row justify-content-evenly">
              <button
                className="btn btn-danger text-white col-auto"
                onClick={() => props.setView(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-success  text-white col-auto"
                onClick={() => setComment()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddComentsModal;
