import React, { useState } from "react";
import { Modal } from "reactstrap";
import InputSearch from "../../../../UI/forms/InputSearch";
import AddItems from "./AddItems";
import AddRates from "./AddRates";
import Preview from "./preview/Preview";

interface Props {
  view: boolean;
  setView: any;
  toggle: any;
  viewRates: boolean;
  setViewRates: any;
  ItemData: any;
  getItemSelect: any;
  setgetItemSelect: any;
  quoteForm: any;
  setQuoteForm: any;
  getHeader: any;
  getSubHeader: any;
  quantity: any;
  setQuantity: any;
  rate: number;
  setRate: any;
  getRates: any;
  setGetRates: any;
  rateQuoteVersion: any;
  preview: boolean;
  setPreView: any;
  addItems: boolean;
  setAddItems: any;
  setGetIdItem: any;
  ApproveQuote: any;
  projectDataId: any;
  typeRateCards: string;
  setTypeRateCards: any;
  QuotesId: any;
  Term_conditions: any;
  totalQuote: number;
  setTotalQuote: (value: number) => void;
  totalTax: Number;
  setTotaltax: (value: number) => void;
  totalAgency_fee: Number;
  setTotalAgency_fee: (value: number) => void;
}
const ModalsQuote: React.FC<Props> = (props) => {
  const [sendItemSelect, setSendItemSelect] = useState(false);
  const closeModal = () => {
    props.setView(false);
    props.setAddItems(false);
    props.setViewRates(false);
    props.setPreView(false);
  };

  return (
    <Modal
      isOpen={props.view}
      toggle={() => props.toggle}
      centered
      className=""
      style={
        props.preview
          ? { width: "1050px", maxWidth: "1050px" }
          : props.addItems || props.viewRates
          ? { width: "450px", maxWidth: "450px" }
          : { width: "none", maxWidth: "none" }
      }
    >
      <div
        className={
          props.preview
            ? "text-center d-flex flex-column custom-scrollbar overflow-hidden "
            : "text-center d-flex flex-column custom-scrollbar overflow-hidden"
        }
        style={props.preview ? { height: "720px" } : { height: "720px" }}
      >
        <div
          onClick={closeModal}
          className="cursor-pointer p-2 me-4 ps-0 "
          style={{ width: "30px" , marginLeft: '-10px'}}
        >
          <i
            className="bi bi-x-lg  "
            style={{
              fontSize: "18px",
              marginRight: "300px",
              height: "150px",
              padding: "20px",
            }}
          ></i>
        </div>
        <div className="row ">
          {props.preview ? (
            []
          ) : (
            <>
              <div className="p-3 d-flex flex-column">
                <h1 className="text-secondary fw-bold poppinsTextModalRate" style={props.viewRates?{
                  fontSize: '26px',
                  marginBottom: '-2%',
                  color: '#344469 !important',
                  textAlign: 'center',
                  marginRight: '6px'
                  }:{}}>
                  {props.viewRates
                    ? "Select rate card"
                    : props.addItems
                    ? "Add items"
                    : []}
                </h1>

                {!props.viewRates && (
                  <div className="p-2">
                    <InputSearch
                      className="w-100 p-0 m-0"
                      placeholder="Search item"
                    />
                  </div>
                )}

                {!props.viewRates && (
                  <small className="text-primary ms-auto text-decoration-underline pe-2">
                    ADVANCED SEARCH
                  </small>
                )}
              </div>
            </>
          )}

          {props.viewRates ? (
            <AddRates
              setGetRates={props.setGetRates}
              setView={props.setView}
              setViewRates={props.setViewRates}
              typeRateCards={props.typeRateCards}
              setTypeRateCards={props.setTypeRateCards}
            />
          ) : props.preview ? (
            <Preview
              ApproveQuote={props.ApproveQuote}
              projectDataId={props.projectDataId}
              quoteForm={props.quoteForm}
              setQuoteForm={props.setQuoteForm}
              QuotesId={props.QuotesId}
              Term_conditions={props.Term_conditions}
              totalQuote={props.totalQuote}
              setTotalQuote={props.setTotalQuote}
              totalTax={props.totalTax}
              setTotaltax={props.setTotaltax}
              totalAgency_fee={props.totalAgency_fee}
              setTotalAgency_fee={props.setTotalAgency_fee}
            />
          ) : props.addItems ? (
            <>
              <AddItems
                setView={props.setView}
                ItemData={props.ItemData}
                getItemSelect={props.getItemSelect}
                setgetItemSelect={props.setgetItemSelect}
                quoteForm={props.quoteForm}
                setQuoteForm={props.setQuoteForm}
                getHeader={props.getHeader}
                getSubHeader={props.getSubHeader}
                quantity={props.quantity}
                setQuantity={props.setQuantity}
                rate={props.rate}
                setRate={props.setRate}
                setViewRates={props.setViewRates}
                rateQuoteVersion={props.rateQuoteVersion}
                getRates={props.getRates}
                setGetIdItem={props.setGetIdItem}
                sendItemSelect={sendItemSelect}
                setSendItemSelect={setSendItemSelect}
              />
              <div className="cursor-pointer d-flex me-auto ps-3">
                <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6 me-1 "></i>
                <small className=" text-primary pt-1">QUICK ADD NEW ITEM</small>
              </div>
              <div className="d-flex align-items-end pt-2">
                <button
                  className="btn btn-success text-white m-auto "
                  style={{ width: "100px", height: "40px" }}
                  onClick={() => setSendItemSelect(true)}
                >
                  Add Items
                </button>
              </div>
            </>
          ) : (
            []
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalsQuote;
