import React, {useState, useEffect} from "react"
import { useLocation } from 'react-router-dom';
import AdvanceSearch from "../Search/AdvanceSearch";
import { RateCardItemModel } from "src/models/RateModel";

export interface RateCardFilters {
    search:     string;
    category:   string;
}

interface Props {
    advandedFilters?: boolean;
    placeholder?:     string;
    data?:            RateCardItemModel[];
    onChange?:        (items: RateCardItemModel[]) => void;
    onChangeFilters?: (items: RateCardFilters) => void;
    filterValue?:     RateCardFilters;
    col?:             string;
}

export const applyFilters = (list: RateCardItemModel[], filters: RateCardFilters): RateCardItemModel[] => {
    let searchItems: RateCardItemModel[] = [];

    if(list){

        searchItems = [...list];
        
        if(filters.search && filters.search.length > 0){
            searchItems = searchItems.filter(item => item.item?.name.toLocaleLowerCase().includes(filters.search.trim().toLocaleLowerCase()));
        }
    
        if(filters.category && filters.category !== ""){
            searchItems = searchItems.filter(item => item.item?.category?._id.toString() === filters.category.toString());
        } 
    }

    return searchItems;
}

const Filters: React.FC<Props> = (props) => {

    const location = useLocation();

    const {data, col} = props;
    const [search, setSearch]                                                 = useState<string>("");
    const [category, setcategory]                                             = useState<string>("");

    useEffect(() => {
        resetFilters();
    }, [location]);

    //search 
    const searchData  = (text: string) => {
        if(data && props.onChange){
            let newData     = [...data];
            let searchItems = newData;

            if(text && text.length > 0){
                searchItems = newData.filter(item => item.item?.name.toLocaleLowerCase().includes(text.trim().toLocaleLowerCase()));
                setSearch(text);
            }else{
                setSearch("");
            }

            if(category && category !== ""){
                searchItems = newData.filter(item => item.item?.category?._id.toString() === category.toString());
            }


            // setlist(searchItems);
            props.onChange(searchItems);
        }

        if(props.onChangeFilters && props.filterValue){
            let data = {
                search:   text, 
                category: ""   
            }

            if(category && category !== ""){
                data.category = category;
            }

            props.onChangeFilters(data);
        }
    }

    const resetFilters = () => {
        setSearch("");
        setcategory("");
    }

    const resetData = () => {
        setSearch("");
        setcategory("");

        if(props.onChange && data){
            props.onChange(data);
        }

        if(props.onChangeFilters && props.filterValue){
            let data = {
                search:   "", 
                category: ""   
            }

            props.onChangeFilters(data);
        }
    }

    const searchByCategory = () => {
        searchData(props.filterValue ? props.filterValue.search : search);
    }

    return (
        <div className="container-fluid p-0 mt-0">
            <div className="row">
                <div className={col ? `col-${col}` : "col-6"}>
                    <div className="input-icon">
                        <i className="bi bi-search text-primary"></i>
                        <input
                            className="form-control form-control-sm py-2 typo-body text-primary"
                            type="search"
                            id="rate-card-item-search-input"
                            placeholder={props.placeholder ?? "Search item"}
                            value={props.filterValue? props.filterValue.search :search}
                            onChange={(e) => searchData(e.target.value)}
                        />
                    </div>
                    {props.advandedFilters &&
                        <div className="row justify-content-end user-select-none">
                            <div className="col-auto">
                                <div className="dropdown col-auto p-0 me-1">
                                    <button
                                        className="btn btn-link typo-body p-0"
                                        data-bs-toggle="dropdown"
                                        style={{ boxShadow: 'none' }}
                                    >
                                        ADVANCED SEARCH
                                    </button>
                                    <AdvanceSearch 
                                        value={category} 
                                        onChange={(value => setcategory(value))} 
                                        inputDisabled 
                                        resetFilters={resetData}
                                        searchData={searchByCategory}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Filters;