import { useEffect, useState } from 'react';

interface Props {
  className?: string;
}

const Clock: React.FC<Props> = (props) => {
  const [time, setTime] = useState<string>('');

  useEffect(() => {
   
    const startTime = () => {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();

      setTime(h + ":" + (m < 10 ? `0${m}`: m) + ":" + (s < 10 ? `0${s}`: s));
    };
    startTime();

    let interval = setInterval(startTime, 1000);

    return () => {
        clearInterval(interval)
    }
  }, []);

  return (
    <div className={`d-flex align-items-center ${props.className}`}>
      <p className="m-0 me-2 text-white">{time}</p>
      <i className="bi bi-clock text-white h6 m-0"></i>
    </div>
  );
};

export default Clock;
