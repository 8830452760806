import React from "react";

interface Props {
  checkBoxDeliverable: any;
  setCheckBoxDeliverable: any;
  onSubmitControlForm: any;
  setOnSubmitControlForm: any;
  index: number;
  deliv: any;
}
const Deliverable: React.FC<Props> = (props) => {
  const handleCheckBox = (e: any, i: number) => {
    props.setCheckBoxDeliverable((prev: any) => {
      let newItem = {
        name: e.target.value,
        checked: e.target.checked,
      };

      prev[props.index].splice(i, 1, newItem);

      return prev;
    });
    props.setOnSubmitControlForm({
      ...props.onSubmitControlForm,
      deliverables: props.checkBoxDeliverable,
    });
  };

  return (
    <div className="container">
      <div className="row ">
        <small className="text-gray" style={{ marginLeft: "-15px" }}>
          {props.index === 0 ? "DELIVERABLES" : `DELIVERABLES ${props.index}`}
        </small>
        {props.deliv.map((e: any, i: number) => (
          <div className="col-md-3 " style={{ marginLeft: "-15px" }}>
            <div className="d-flex mb-2 " key={i}>
              <input
                type="checkbox"
                value={e.name}
                defaultChecked={e.checked}
                onChange={(e) => handleCheckBox(e, i)}
                className="form-check-input"
              />
              <label className="form-check-label typo-body text-primary ms-2  mt-1">
                {e.name}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Deliverable;
