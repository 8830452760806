import { Modal } from "reactstrap";

interface Props {
    view: boolean;
    setView: any;
}

const ModalItemCreate: React.FC<Props> = (props) => {
    return (
        <Modal isOpen={props.view} toggle={() => { }} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h3 className="font-weight-bold text-secondary typo-display">
                            Info
                        </h3>
                    </div>
                    <div className="col-12 mt-3">
                        <p>Your item has been created. If you want to add extra details or edit you would have to search it in the stockpile.
                        </p>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-success text-white col-auto"
                                onClick={() => props.setView(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalItemCreate