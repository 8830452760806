import { useEffect, useState } from 'react';

import IconButton from '../../UI/buttons/IconButton';
import EquipmentData from './tabs/EquipmentData';
import Rates from './tabs/Rates';
import Projects from './tabs/Projects';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import { AssetModel } from '../../../models/ItemModel';
import { setFullWidth, setShowAssetLis, setShowEditItem, setShowEditItemService } from '../../../store/stockpile/RecursosVisualeSlice';
import { CreateCommentForAsset, DeleteCommentForAsset, DeleteItem, getAllItems, GetAllRateForItem, RefreshForDeleteItem, UpdateCommentForAsset } from 'src/store/stockpile/stockpileSlice';

import TrashIcon from '../../../assets/icons/trash.svg'
import EditIcon from '../../../assets/icons/edit.svg'
import { useHistory, useParams } from 'react-router-dom';
import { AddComment, deleteComment, setDataAsset, setIdItem, updateComment } from 'src/store/stockpile/selectAssetsSlice';
import { useRequest } from 'src/hooks/useRequest';
import Description from './tabs/Description';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import useQuery from 'src/hooks/useQuery';
import { useToast } from 'src/hooks/useToast';
import { setCategoryFilter } from 'src/store/stockpile/categorieSlice';
import EmptyCircle from 'src/components/UI/EmptyCircle';
import Items from './tabs/package/Items';


interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const tabs = [
  'Description',
  'Rates',
  'Projects',
  'Items'
];

const PackageStockView: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const params = useParams<{ itemId: string; }>();
  const history = useHistory();
  const query = useQuery()
  const toast = useToast()


  const [currentTab, setCurrentTab] = useState(0);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false)
  const { innerHeight } = window

  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId });
  const ASSET_DATA: any = useSelector((state: RootState) => state.selectAssetsSlice.data);

  const FullWidth: any = useSelector((state: RootState) => state.RecursosVisualeSlice.FullWidth);

  const ShowEdit: any = useSelector((state: RootState) => state.RecursosVisualeSlice.ShowEditItem);


  const toggleOptionsState = () => setOptionsOpen((prev) => !prev);

  useEffect(() => {
    dispatch(GetAllRateForItem(params.itemId))
  }, [])

  useEffect(() => {
    dispatch(setDataAsset({ item: ITEM_DATA }))
  } , [ITEM_DATA])

  const toggleEditItem: any = (category: any) => {
    if (ShowEdit) {
      query.delete('editItem');
      history.replace({ search: query.toString() })
    } else {
      query.set('editItem', category || '');
      history.push({ search: query.toString() })
    }
    dispatch(setShowEditItem((prev: any) => !prev));
  };

  const toggleEditItemService: any = (category: any) => {
    if (ShowEdit) {
      query.delete('editItemService');
      history.replace({ search: query.toString() })
    } else {
      query.set('editItemService', category || '');
      history.push({ search: query.toString() })
    }
    dispatch(setShowEditItemService((prev: any) => !prev));
  }

  const tabsContent = [
    <Description Description={ASSET_DATA?.item?.description || ''} skill_sets={ASSET_DATA?.item?.skill_sets} />,
    <Rates />,
    <Projects />,
    <Items />
  ];

  const onCancelModalClose = async (value?: boolean) => {

    if (!value) {
      try {
        dispatch(setCategoryFilter(ITEM_DATA?.category?._id))

        const response: any = await dispatch(DeleteItem(ITEM_DATA?._id))
        
        if (response.payload == '200') {
          dispatch(RefreshForDeleteItem(ITEM_DATA?._id))
          toast.success('Item has been deleted')
          history.push('/stockpile')
        }else{
          toast.error('error for delete item')
        }  

      } catch (error) {
      }
    }

    setIsCancelling(false);

  };


  useEffect(() => {
  }, [ITEM_DATA])

  return (
    <div className="d-flex flex-column h-100 px-2">
      {/* Header */}

      <header className="w-100 mt-3 d-flex justify-content-between align-items-center ps-1">
        <i
          onClick={() => FullWidth ? dispatch(setFullWidth(false)) : dispatch(setFullWidth(true))}
          className={`${FullWidth
            ? 'bi bi-arrows-angle-contract'
            : 'bi bi-arrows-angle-expand'
            } col-auto  h5 m-0`} style={{ fontWeight: 'bold', color: '#344469' }}
        ></i>

        <div className="d-flex align-items-center">
          <IconButton
            color="primary"
            icon="bi bi-plus-lg"
            outline
          />

          <IconButton
            color="primary"
            icon="bi bi-share-fill"
            outline
          />

          <IconButton
            color="primary"
            custom={EditIcon}
            customStyles={{ width: '12px', height: '12px' }}
            onClick={() => ITEM_DATA?.category?._id == '67807598690173953' ? toggleEditItem() : toggleEditItemService()}
            outline
          />

          <IconButton
            color="danger"
            custom={TrashIcon}
            customStyles={{ width: '12px', height: '12px' }}
            onClick={() => {
              setIsCancelling(true)
            }}
            outline
          />
        </div>
      </header>

      <div className="w-100 mt-3 d-flex justify-content-between ps-1 ">
        <div className="d-flex align-items-center">
        {ITEM_DATA?.picture_url && <img
            className="avatar-profile"
            src={process.env.REACT_APP_IMAGES_AWS + ITEM_DATA?.picture_url}
            alt=""
          />
          }

          { 
          !ITEM_DATA?.picture_url && ITEM_DATA?.name && <EmptyCircle title={ITEM_DATA?.name} />
          }
          <div className="ms-3">
            <p className="m-0 text-black typo-body contact-title">
              {ASSET_DATA?.item?.name}
            </p>
            <p className="m-0 text-black typo-body contact-subtitle">
            </p>
          </div>
        </div>

        <div className="d-flex flex-column align-items-end d-none">
          <div className="d-flex align-item-en">
            <p className="m-0 text-black typo-body text-end">Stock:</p>
            <p className="m-0 contact-trailing typo-body bg-primary ms-3">10</p>
          </div>

          <div className="d-flex align-item-en me-3 my-1">
            <p className="m-0 text-black typo-body">Quarantine:</p>
            <p className="m-0  typo-body ms-3 text-black">3</p>
          </div>

          <div className="d-flex align-item-en me-3">
            <p className="m-0 text-black typo-body">Currently available:</p>
            <p className="m-0  typo-body ms-3 text-black">2</p>
          </div>
        </div>
      </div>

      <div className="row ms-4">
        <div className="col offset-1 d-flex align-items-cente">
          <button className="btn btn-primary mx-1 typo-body text-white">
            Book service
          </button>
        </div>
      </div>

      {/* Body */}

      <ul className="nav nav-tabs mt-3">
        {tabs.map((item, index) => {
          return (
            <li
              key={index}
              className="nav-item"
              onClick={setCurrentTab.bind(null, index)}
            >
              <p
                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : ''
                  }`}
                aria-current="page"
              >
                {item}
              </p>
            </li>
          );
        })}
      </ul>

      {/* Tabs */}
      <div style={{ maxHeight: innerHeight - 320 + 'px' }}>
        {tabsContent[currentTab]}
      </div>

      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This item will be deleted"
          confirmText="CANCEL"
          cancelText="DELETE ITEM"
          onClose={onCancelModalClose}

        />
      )}
    </div>
  );
};

export default PackageStockView;
