import { object, string, number, array, date, InferType, bool } from 'yup';
import { AssetModel, warehouse, purchase, PickupInfo } from '../../../../../models/ItemModel';


let sizesSchemaValidationm = object({
    weight: number().typeError('weight must be a number'),
    length: number().typeError('length must be a number'),
    width: number().typeError('length must be a number'),
    height: number().typeError('length must be a number'),
    weight_unit: string().typeError('length must be a text'),
    sizes_unit: string().typeError('length must be a text'),
});

const AssetScheme = object({
    serial: string(),
    item: string(),
    purchase: object({
        price: string().typeError('must be a number'),
        origin: string(),
        date: date()
    }),
    is_external: bool(),
    warehouse: object({
        location: string(),
        shelf_id: string().typeError('must be a number'),
        warehouse: string()
    }),
    warranty_expiration: string(),
    external: object({
        prices: object({
            hour: number().typeError('must be a number'),
            day: number().typeError('must be a number'),
            week: number().typeError('must be a number'),
            sell: number().typeError('must be a number'),
            holiday_hour: number().typeError('must be a number'),
            holiday_day: number().typeError('must be a number'),
            overtime_hour: number().typeError('must be a number'),
            holiday_overtime_hour: number().typeError('must be a number')
        }),
        company_owner: string(),
        pickup_location: object({
            country: string(),
            state: string(),
            zip: number().typeError('must be a number'),
            adress: string(),
            city: string()
        }),
    }),
    sizes: sizesSchemaValidationm
})

export default AssetScheme