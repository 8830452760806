import set from 'lodash.set';
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { mapObjToFiles } from 'src/util';

interface CreateContactState {
  data: Record<string, any>;
}

const initialState: CreateContactState = {
  data: {
    appliedInitialValues: false
  },
};

const _createContactForm = createSlice<CreateContactState, SliceCaseReducers<CreateContactState>>({
  name: 'blackbook-contact-form',
  initialState,
  reducers: {
    clearData: {
      prepare: (_) => {
        return { payload: {} };
      },
      reducer: (state) => {
        state.data = initialState.data;
      }
    },
    setFiles: {
      prepare: (payload: Record<string, any>) => {
        return { payload };
      },
      reducer: (state, action: PayloadAction<Record<string, any>>) => {
        const result = mapObjToFiles(action.payload);

        set(state, 'data.files', result);
      }
    },
    setData: {
      prepare: (data: CreateContactState['data'], key = 'data', merge = true) => {
        return { payload: { data, merge, key } };
      },
      reducer: (state, _: PayloadAction<{ data: CreateContactState['data'], key: string, merge: boolean }>) => {
        const { key, data, merge } = _.payload;
        
        const newData: any = {
          [key]: merge ? {
            ...(state.data.data ? state.data.data : state.data),
            ...data,
            note: state.data.note,
            contact_since: state.data.contact_since,
          } : data,
        };

        for(const _key of ['note', 'contact_since', 'appliedInitialValues']) {
          if (data?.[_key] !== undefined) {
            newData[_key] = data?.[_key];
          }
        }

        state.data = newData;
      }
    },
    setField: {
      prepare: (field: string, value: any, path = 'data') => {
        return { payload: { field, value, path } };
      },
      reducer: (state, _: PayloadAction<{ field: string, value: any, path: string }>) => {
        const { field, value, path } = _.payload;
        let key = (path && path !== '') ? `${path}.${field}` : field;

        set(state, `data.${key}`, value);
      }
    }
  },
});

export const createContactForm = _createContactForm.actions;

export default _createContactForm.reducer;
