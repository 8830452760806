const AdvanceSearch: React.FC = () => {
  return (
    <div className="dropdown-menu p-2" style={{ width: 300 }}>
      <p className="text-primary typo-body">Advanced search</p>

      {/* <select className="form-select text-primary typo-body-important">
        <option value="" selected>
          Select contact type
        </option>
        <option>CRM</option>
        <option>Freelance</option>
        <option>Organization</option>
        <option>Prospect</option>
        <option>Supplier</option>
        <option>Talent</option>
        <option>Team Member</option>
        <option>Venue</option>
      </select> */}

      <select className="form-select text-primary typo-body-important  mt-2">
        <option value="" selected className=" ">
          Bookable skillsets
        </option>
        <option>Actor</option>
        <option>Hairdresser</option>
        <option>Makeup</option>
        <option>Videographer</option>
      </select>

      <input
        className="form-control  text-primary mt-2 typo-body-import"
        placeholder="Includes the words"
      />

      <button className="btn btn-primary mt-3 text-white typo-body">
        Search
      </button>
    </div>
  );
};

export default AdvanceSearch;
