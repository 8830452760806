import { Doughnut } from "react-chartjs-2";

function QuotesVsConfirmed() {
  return (
    <div className="card">
      <div className="card-body text-primary">

        <h5 className="card-title text-center">
          Quotes vs Confirmed
        </h5>
        <div className="card-subtitle text-center mb-2 text-muted">
          This Week
        </div>

        <div className="p-4">
          <Doughnut
            options={{
              maintainAspectRatio: true,
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  display: false,
                },
              },
              cutout: '70%',
              /* onHover(event, elements, chart) {
                with "elements" we could highlight the hovered element in the card hover if needed
              }, */
            }}
            data={{
              labels: ["Quotes", "Confirmed", "Pending"],
              datasets: [
                {
                  label: '',
                  borderWidth: 1,
                  data: [0.7, 1, 0.4],
                  borderColor: ["#7289da", '#f3f3f3', "#ccc"],
                  backgroundColor: ["#7289da", '#f3f3f3', "#ccc"],
                },
              ],
            }}
          />
        </div>
      </div>

      <div className="card-footer d-flex gap-3 align-items-center text-bg-contrast fs-6 text justify-content-between" style={{ fontWeight: 600 }}>
        <div className="small d-flex flex-column">
          <span>$4,345.00</span>
          <span>
            <span style={{ height: 10, width: 10, backgroundColor: '#7289da' }} className="d-inline-block rounded-circle me-1"></span>
            Quotes
          </span>
        </div>

        <div className="small d-flex flex-column">
          <span>$12,268.00</span>
          <span>
            <span style={{ height: 10, width: 10, backgroundColor: '#f3f3f3' }} className="d-inline-block rounded-circle me-1"></span>
            Confirmed
          </span>
        </div>

        <div className="small d-flex flex-column">
          <span>$8,686.00</span>
          <span>
            <span style={{ height: 10, width: 10, backgroundColor: '#ccc' }} className="d-inline-block rounded-circle me-1"></span>
            Pending
          </span>
        </div>
      </div>
    </div>
  )
}

export default QuotesVsConfirmed;