
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nameFileCSV: '',
    FileValueCSV: ''
};

const CreateItemCsvForm = createSlice({
    name: 'CreateItemCsvForm',
    initialState,
    reducers: {
        setCSV: (state, action) => {
            state.FileValueCSV = action.payload
        },
        setNameFileCSV:(state, action) => {
            state.nameFileCSV = action.payload
        },
    }
});

export const { setNameFileCSV , setCSV } = CreateItemCsvForm.actions;
export default CreateItemCsvForm.reducer;
