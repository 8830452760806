import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonFiles from "src/components/UI/buttons/ButtonFile";
import DropZone from "src/components/UI/Dropzone";
import ProgressbarTitle from "src/components/UI/ProgressbarTitle";
import { setCSV, setNameFileCSV } from "src/store/stockpile/CreateItemsCsvForm";
import { RootState } from "src/store/store";
import { toBase64 } from "src/util/converToBase64";
import InputFloatingLabel from '../../../UI/forms/InputFloatingLabel';
import TextArea from '../../../UI/forms/TextArea';

const UploadCSV: React.FC = () => {

  const dispatch = useDispatch()
  const NameFileCSV = useSelector((state: RootState) => state.CreateItemsCsvForm.nameFileCSV);
  const percentUpload = useSelector((state: RootState) => state.stockpileSlice.PercentUploadCSV);
  const [files, setFiles] = useState([]);
  const [errorcsv, setErrorCsv] = useState<any>('')

  const handleCSV = async (dataFile: any) => {
    setFiles(dataFile)

    const file = dataFile[0]
    const value = await toBase64(file)
    dispatch(setNameFileCSV(dataFile[0].path))
    dispatch(setCSV(value))
  }

  return (
    <div className="container-fluid px-3 mt-3 ">
      <div className="row mt-5 pt-5">
        <div className="col-lg-5 col-md-12 col-sm-12 col-12">
          <DropZone
            setFile={handleCSV}
            files={files}
            errors={errorcsv}
            file={true}
          />
        </div>
        <div className="col-4 align-content-start mt-3 " style={{ marginRight: '26%', marginLeft: '1.5%', float: 'left' }}>
          <p className="poppinsText text-primary" style={{ marginLeft: '-3%', fontSize: '12px' }}>
            {percentUpload * 100}% UPLOAD COMPLETE</p>
          <ProgressbarTitle value={percentUpload} file width={'400px'} />
        </div>
      </div>
    </div>
  );
};

export default UploadCSV;
