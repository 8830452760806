import React, { useEffect, useState } from "react";
import {
  TreeList,
  createDataTree,
  mapTree,
  extendDataItem,
  TreeListExpandChangeEvent,
} from "@progress/kendo-react-treelist";
import { Employee } from "../../../dataTree/interface";
import columns from "./columns";
import TextArea from "../../../../components/UI/forms/TextArea";
import { RatesQuote } from "./CreateQuoteForm";
import { v4 as uuidv4 } from "uuid";
import update from "immutability-helper";
import SelectTax from "../../../UI/forms/SelectTax";
import InputSelect from "../../../UI/forms/InputSelect";

interface Props {
  // setActiveControlView: any;
  setView: any;
  ItemData: any;
  QuoteData: any;
  quoteForm: any;
  setQuoteForm: any;
  getHeader: any;
  setGetHeader: any;
  getSubHeader: any;
  setSubGetHeader: any;
  quantity: any;
  setQuantity: any;
  rate: number;
  setRate: any;
  units_number: number;
  setUnits_number: any;
  units_type: any;
  setUnits_type: any;
  getRates: RatesQuote;
  formControl: boolean;
  setformControl: any;
  addItems: boolean;
  setAddItems: any;
  setCountHeader: any;
  specificRatesbyItemId: any;
  getIdItem: any;
  setGetIdItem: any;
  getIdItemRate: any;
  setModalAssignAsset: any;
  setViewModal: any;
  indexSelected: any;
  setIndexSelected: any;
  deleteItemsColumn: any;
  setGetIdItemForAssets: any;
  totalQuote: number;
  setTotalQuote: (value: number) => void;
  totalTax: Number;
  setTotaltax: (value: number) => void;
  totalAgency_fee: Number;
  setTotalAgency_fee: (value: number) => void;
}

const expandField: string = "expanded";
const subItemsField: string = "employees";

const TreeTable: React.FC<Props> = (props) => {
  const [tax, setTax] = useState<number>(0);
  const [agency_fee, setAgency_fee] = useState<number>(0);

  const handle_Select_sub_Header = (dataItem: any) => {
    const itemsSelect = {
      name: "sub header",
      id: uuidv4(),
      assigned_to: null,
      quantity: 0,
      rate: 0,
      units_number: 0,
      units_type: "",
      level: "1",
      elements: null,
      headerassigned_to: dataItem.id,
      type: "sub header",
    };

    const SearchIndex = props.quoteForm.headers.findIndex(
      (i: any) => i.id === dataItem.id
    );

    const filter = props.quoteForm.headers.findIndex(
      (fil: any) => fil.headerassigned_to === dataItem.id
    );

    // if (filter === -1) {
    let SearchHeader = props.quoteForm.headers;

    SearchHeader.splice(SearchIndex + 1, 0, itemsSelect);

    props.setQuoteForm({
      ...props.quoteForm,
      headers: SearchHeader,
    });
    // } else {
    //   return null;
    // }
  };

  const formatNumber = (number: number) => {
    let n: string = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);

    return n;
  };

  const dataTree: Employee[] = createDataTree(
    props.quoteForm.headers.flat(2),
    (i) => i.id,
    (i) => i.assigned_to,
    subItemsField
  );

  const data: Employee[] = [...dataTree];
  const [expanded, setExpanded] = React.useState<number[]>([]);

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    setExpanded(
      e.value
        ? expanded.filter((id) => id !== e.dataItem.id)
        : [...expanded, e.dataItem.id]
    );
  };

  const expandById = (assigned_to: any) => {
    setExpanded((prev) => {
      const index = prev.indexOf(assigned_to);

      if (index === -1) {
        return update(prev, { $push: [assigned_to] });
      }

      return prev;
    });
  };

  useEffect(() => {
    expandById(props.getHeader?.id);
  }, [props.getIdItem]);

  const callback = (item: Employee) =>
    expanded.includes(item.id)
      ? extendDataItem(item, subItemsField, { [expandField]: true })
      : item;

  const tableColumns = React.useMemo(
    () =>
      columns(
        props.setView,
        handle_Select_sub_Header,
        props.setGetHeader,
        props.quantity,
        props.setQuantity,
        props.rate,
        props.setRate,
        props.units_number,
        props.setUnits_number,
        props.units_type,
        props.setUnits_type,
        props.quoteForm,
        props.setQuoteForm,
        props.formControl,
        props.setformControl,
        props.addItems,
        props.setAddItems,
        props.setCountHeader,
        props.specificRatesbyItemId,
        props.getIdItem,
        props.setGetIdItem,
        props.getIdItemRate,
        props.setModalAssignAsset,
        props.setViewModal,
        props.indexSelected,
        props.setIndexSelected,
        props.deleteItemsColumn,
        props.setGetIdItemForAssets,
        formatNumber
      ),
    [
      props.setView,
      handle_Select_sub_Header,
      props.setGetHeader,
      props.quantity,
      props.setQuantity,
      props.rate,
      props.setRate,
      props.units_number,
      props.setUnits_number,
      props.units_type,
      props.setUnits_type,
      props.quoteForm,
      props.setQuoteForm,
      props.formControl,
      props.setformControl,
      props.addItems,
      props.setAddItems,
      props.setCountHeader,
      props.specificRatesbyItemId,
      props.getIdItem,
      props.setGetIdItem,
      props.getIdItemRate,
      props.setModalAssignAsset,
      props.setViewModal,
      props.indexSelected,
      props.setIndexSelected,
      props.deleteItemsColumn,
      props.setGetIdItemForAssets,
      formatNumber,
    ]
  );

  const total = () => {
    const total = props.quoteForm.headers.reduce(
      (acc: any, curr: any) =>
        acc +
        Number(curr.quantity || 0) *
          Number(curr.rate || 0) *
          Number(curr.units_number || 0),
      0
    );

    return Number(total);
  };

  const TotalCountFee = total() * (agency_fee / 100);
  const TotalCountTax = total() * (tax / 100);
  const totalQuotewithTax = total() + TotalCountTax + TotalCountFee;

  useEffect(() => {
    props.setTotaltax(TotalCountTax);
    props.setTotalAgency_fee(TotalCountFee);
    props.setTotalQuote(totalQuotewithTax);
  }, [totalQuotewithTax, TotalCountFee, TotalCountTax]);

  const options = [
    {
      label: "IVA(12%)",
      value: 12,
    },
    {
      label: "ISR(25%)",
      value: 25,
    },
    {
      label: "L.A(9%)",
      value: 9,
    },
  ];

  const TotalTable = [
    {
      title: "DISCOUNT",
      value: 0,
    },
    {
      title: "SUBTOTAL",
      value: formatNumber(total()),
    },
    {
      title: "AGENCY FEE",
      value: (
        <span>
          <input
            placeholder={`${agency_fee}%`}
            type="number"
            className="inputFee"
            value={agency_fee}
            onChange={(e: any) => setAgency_fee(e.target.value)}
            min={0}
          />
        </span>
      ),
    },
    {
      title: "TAX",
      value: (
        <span>
          <SelectTax
            placeholder={`${tax}$`}
            onChange={(event: number) => setTax(event)}
            value={tax}
            options={options}
            className="SmallSelectTaxt"
          />
        </span>
      ),
    },
  ];

  useEffect(() => {
    total();
  }, [props.quoteForm]);

  return (
    <div
      className="container-tree-list d-flex flex-column "
      style={{ height: "500px" }}
    >
      <div>
        <TreeList
          data={mapTree(data, subItemsField, callback)}
          expandField={expandField}
          subItemsField={subItemsField}
          onExpandChange={onExpandChange}
          columns={tableColumns}
          className="tree-scroll"
        />
      </div>
      <div
        className="container-column ms-auto mb-3"
        style={{
          width: "29.5vw",
          zIndex: "1",
          paddingTop: "50px",
        }}
      >
        {props.quoteForm.headers.length > 1 ? (
          <table className="table table-borderless ">
            <thead className="">
              <tr className="text-primary typo-body">
                <th scope="col" className="">
                  <p className="HeadTableFonts">QUOTE SUMMARY</p>
                </th>
                <th scope="col">
                  <p className="HeadTableFonts">COST</p>
                </th>
                <th scope="col">
                  <p className="HeadTableFonts">MARGIN</p>
                </th>
                <th scope="col">
                  <p className="HeadTableFonts">TOTAL</p>
                </th>
              </tr>
            </thead>
            {TotalTable.map((ee: any, index) => {
              return (
                <tbody>
                  <tr
                    className={`${
                      (index / 2) % 1 > 0 ? "table-secondary" : ""
                    } text-primary typo-body`}
                    key={index}
                    style={{ cursor: "pointer" }}
                  >
                    <td
                      className="align-middle general-text-table-factory"
                      style={{ height: "40px" }}
                    >
                      <span className="ms-1">{ee.title}</span>
                    </td>

                    <td className="align-middle general-text-table-factory ">
                      {index === 1 ? ee.value : ""}
                    </td>
                    <td className="align-middle general-text-table-factory ">
                      {index === 1
                        ? ee.value
                        : index === 2 && TotalCountFee !== 0
                        ? formatNumber(TotalCountFee)
                        : index === 3 && TotalCountTax !== 0
                        ? formatNumber(TotalCountTax)
                        : ""}
                    </td>
                    <td
                      className="align-middle general-text-table-factory "
                      style={{ width: "10px" }}
                    >
                      <div style={{ width: "100px" }}>{ee.value}</div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ) : (
          []
        )}

        <div
          className="col-md-12  bg-light-blue  p-2 mb-2 d-flex  justify-content-center align-items-center text-black"
          style={{ height: "56px" }}
        >
          <span className="ms-2">Total:</span>
          <span className="ms-auto me-4">
            {formatNumber(totalQuotewithTax)}
          </span>
        </div>
      </div>
      <div className="mb-0 p-4 pb-0 ">
        <TextArea
          placeHolder="notes..."
          title=""
          value={""}
          onChange={(val) => []}
          rows={2}
        />
      </div>
    </div>
  );
};

export default TreeTable;
