import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddComentsModal from 'src/components/Modals/AddComentsModal';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import QuickNotes from 'src/components/UI/QuickNotes';
import { AddComment, deleteComment, updateComment } from 'src/store/stockpile/selectAssetsSlice';
import { CreateCommentForAsset, DeleteCommentForAsset, UpdateCommentForAsset } from 'src/store/stockpile/stockpileSlice';
import { RootState } from 'src/store/store';
import ListTile from '../../../UI/ListTile';

interface Props {
  Description: any,
  skill_sets: Array<any>
}

const Description: React.FC<Props> = (props) => {

  const dispatch = useDispatch()
  const COMMENTS_ASSETS: Array<object> = useSelector((state: RootState) => state.selectAssetsSlice.comments);
  const ASSET_DATA: any = useSelector((state: RootState) => state.selectAssetsSlice.data);

  const [comment, setComment] = useState('')

  const [commentAdd, setCommentAdd] = useState('')

  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [idForEdit, setIdForEdit] = useState('')

  const toggleModal = () => {
    setAddModal((prev) => !prev);
  };

  const i = () => {
    setAddModal(true)
  }

  const comentarios = COMMENTS_ASSETS?.map((comment: any) => {
    const obj = {
      text: comment.content,
      date: comment.created_at,
      id: comment._id
    }

    return obj
  })
  const sendComment = async () => {
    const commentFinal = {
      comment: commentAdd,
      id: ASSET_DATA?.item?._id
    }
    const data: any = await dispatch(CreateCommentForAsset(commentFinal))
    if (data.payload.data) {
      dispatch(AddComment(data.payload.data))
    }
  }

  const sendEditComment = () => {
    const update = {
      comment: comment,
      id: idForEdit.toString()
    }
    dispatch(UpdateCommentForAsset(update))
    dispatch(updateComment(update))
  }

  const deleteCommentForId = async (id: any, index: any) => {
    dispatch(deleteComment(index))
    dispatch(DeleteCommentForAsset(id))
  }

  const toggleEditComment = (id: any, currentContent: any) => {
    setEditModal((prev) => !prev);
    setIdForEdit(id)
    setComment(currentContent)
  }

  return (
    <div className="container-fluid px-3">
      <div className="row p-0 py-3" style={{ maxWidth: '700px' }}>
        <p className="m-0 typo-body text-uppercase pb-1">
          {props.Description}
        </p>

        {props?.skill_sets?.length > 0 && <p className='text-primary mt-4' style={{ fontSize: '14px' }}>
          Bookable Skillsets</p>
        }
        {
          props?.skill_sets?.map((skill: any) => {
            return (
              <p style={{ fontSize: '10px', marginBottom: '-10%', color: 'black' }} className='poppinsText'>{skill}</p>
            )
          })
        }
        
      </div>
      <div className="row border-bottom p-0 py-3">
        <QuickNotes
          title="Comments"
          texts={comentarios}
          isEditable
          ActionUpdate={toggleEditComment}
          ActionDelete={deleteCommentForId}
        />
        <div style={{ maxWidth: '160px' }}>
          <SimpleButton onClick={i}>
            ADD COMMENTS
          </SimpleButton>
        </div>
      </div>
      <hr />

      <AddComentsModal
        title='Add Comment'
        view={addModal}
        setView={setAddModal}
        toggle={toggleModal}
        data={commentAdd}
        setData={setCommentAdd}
        sendData={sendComment}
      />

      <AddComentsModal
        title='Edit Comment'
        view={editModal}
        setView={setEditModal}
        toggle={toggleModal}
        data={comment}
        setData={setComment}
        sendData={sendEditComment}
      />

    </div>
  );
};

export default Description;
