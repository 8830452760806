import { createRef, RefObject } from 'react';
import RateModel from '../models/RateModel';

type contactSectionType = {
  [key: string]: { items: RateModel[]; ref: RefObject<HTMLDivElement> };
};

export const getSections = (contacts: RateModel[]): contactSectionType => {
  const ordered = contacts.sort((a, b) => {
    return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
  });

  const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
  const data: contactSectionType = {};

  for (let letterref of alphabet) {

    let letter = letterref.toUpperCase();
    
    if (data[letter] === undefined) {
      data[letter] = {
        items: [],
        ref: createRef<HTMLDivElement>(),
      };
    }

  }

  for (let contact of ordered) {
    let letter = contact.name[0].toUpperCase();
    
    if (data[letter] === undefined) {
      data[letter] = {
        items: [],
        ref: createRef<HTMLDivElement>(),
      };
    }
    
    data[letter].items.push(contact);
  }

  return data;
};
