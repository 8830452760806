import { forwardRef, Ref, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import ItemModel from '../../../models/ItemModel';
import ContactItem from './ContactItem';

interface Props {
  letter: string;
  items: ItemModel[];
  setID: any;
  showAssets: any;
  index: any;
  setSelected: any;
  indexItem: any,
  indexItemSelected: Array<any>;
  preSave:any
}

const ContactSection = forwardRef<HTMLDivElement, Props>(

  (props, ref: Ref<HTMLDivElement>) => {
    const [filtered, setFiltered] = useState<any>([])
    //  const [ AllItems , setAllItems ] = useState<Array<any>>([])
    const Mandatory = useSelector((state: RootState) => state.createFormSlice.AccesoriesMandatoryData);
    const AllItems = !props.indexItemSelected[props.index] ? [...props.items?.filter(u => props.indexItemSelected?.every(s => u._id != s))] : props.items


    /**
     *  Mandatory[props.index] == '' && props.indexItemSelected.length > 0 ? props.indexItemSelected.reduce((acc: Array<object>, indexSelected: any , indexRe:any , Array:any ) => {
        return acc.concat(props.items
            .filter((item: any) => Array[indexRe] != item?._id))
    }, []) : props.items
     */



    return (
      <div ref={ref} className="container-fluid p-0" style={{ marginLeft: '.5em' }}>
        <div className="row">
          <div className="col-12">
            <p
              className={`m-0 typo-body ps-3 py-1 ${AllItems.length < 1 && 'd-none'} text-primary`}
              style={{ background: '#f3f3f3' }}
            >
              {props.letter}
            </p>
            <div>
              {AllItems.map((item: any, index: number) => {

                return (
                  <ContactItem
                    key={index}
                    item={item}
                    onSelect={() => { }}
                    setID={props.setID}
                    showAssets={props.showAssets}
                    index={props.index}
                    setSelected={props.setSelected}
                    indexItem={props.indexItem}
                    indexItemSelected={props.indexItemSelected}
                    preSave={props.preSave}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ContactSection;
