import React from "react";
import { Datepicker } from "@mobiscroll/react";
import moment from "moment";

interface Props {
  onChange?: any;
  value?: any;
  min?: any;
  max?: any;
  placeholder?: any;
}

const InputDate: React.FC<Props> = (props) => {
  const onChange = props.onChange;

  const value = props.value;

  return (
    <>
      <div className="input-group m-1 ml-2 mb-0">
        <Datepicker
          controls={["calendar"]}
          inputComponent="input"
          dateFormat="MM-DD-YYYY"
          touchUi={true}
          display="anchored"
          theme="ios"
          inputProps={{
            className: "form-control inputDatePicker searchWords",
            style: { background: "#fff", height: "37px", color: "#6B8AB8" },
            placeholder: props.placeholder,
          }}
          onChange={onChange}
          value={value}
          min={props.min}
          max={props.max}
          className="text-primary"
        ></Datepicker>
        <div className="input-group-append datepickerCustom">
          <span
            className="input-group-text bg-primary"
            id="basic-addon2"
            style={{ height: "37px" }}
          >
            <i className="bi bi-calendar" style={{ color: "#fff" }}></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default InputDate;
