import React, { useState } from "react";
import moment from "moment";
import TextArea from "../../../../UI/forms/TextArea";
import EditIcon from '../../../../../assets/icons/edit.svg';
import TrashIcon from '../../../../../assets/icons/trash.svg';
import { IComment } from 'src/models/CommentModel';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import AddComentsModal from 'src/components/Modals/AddComentsModal';

interface Props {
    comments: IComment[];
    createNote: (string) => void;
    deleteNote: (string) => void;
    editNote: (string) => void;
}

const QuoteNotes: React.FC<Props> = (props) => {
  const [note, setNote] = useState<string>("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState({} as any);

  const handleCreate = () => {
    props.createNote(note);
    setNote("");
  }

  const handleSelect= (isEdit: boolean, id:string, content?: string ) => {
    setSelectedNote({ id, content });
    isEdit? setIsOpenEditModal(true) : setIsOpenDeleteModal(true); 
  }

  const handleConfirmDelete = (shouldDelete: boolean) => {
    if(shouldDelete) props.deleteNote(selectedNote?.id);
    setIsOpenDeleteModal(false);
  }

  const handleConfirmEdit = () => {
    props.editNote(selectedNote)
  }

  return (
    <>
      <AddComentsModal
        title={'Edit Note'}
        view={isOpenEditModal}
        setView={setIsOpenEditModal}
        toggle={() => setIsOpenEditModal(false)}
        data={selectedNote.content}
        setData={(content) => setSelectedNote((prev) => ({...prev, content}))}
        sendData={handleConfirmEdit}
        placeHolder=" "
      />
      { isOpenDeleteModal && 
        <ConfirmModal
          title="Are you sure?"
          cancelText="CANCEL"
          confirmText="DELETE NOTE"
          description="This note will be deleted."
          onClose={handleConfirmDelete}
        />}
      <div className="container-fluid p-0 mt-2 w-100 container-project-detail overflow-y overflow-x-none custom-scrollbar">
        <div className="row mx-4 mt-3">
          <div className="col-12">
            <TextArea
              title="Note"
              placeHolder="Type here..."
              value={note}
              onChange={(value) => setNote(value)}
            />
            <div className="w-100 text-end pb-3">
              <button className="btn btn-primary typo-body text-white" disabled={!note} onClick={() => handleCreate()}>
                  Add Note
              </button>
            </div>
          </div>
        </div>    
        {
          props.comments?.map((comment, index) => (
              <div className="row mx-4 row g-0 gap-0 border-top quote-comment" key={index}>
                  <div className="col-12 d-flex pt-3 pb-1">
                      <div className="d-inline-block px-2 py-1 ">
                          {comment.created_by.image ? (
                          <img /*src={image} alt={`${title} Avatar`} */ className="avatar-profile cursor-pointer" alt={`${comment.created_by.name}-avatar`}/>
                          ) : (
                          <span className="align-items-center d-flex fs-4 justify-content-center avatar-profile text-primary bg-contrast">
                              {comment.created_by.name[0]}
                          </span>
                          )}
                      </div>
                      <div className="d-flex justify-content-between align-items-center w-100">
                          <div>
                              <p className="m-0 text-secondary typo-body contact-name fw-bolder">{`${comment.created_by.name} ${comment.created_by.last_name}`}</p>
                              <p className="m-0 text-primary typo-body date">{moment(comment.created_at).calendar()}</p>
                          </div>
                          <div>
                              <img src={EditIcon} style={{ width: '12px', height: '12px' }} className="mx-1 cursor-pointer" alt="edit-icon" onClick={() => handleSelect(true, comment._id, comment.content)}/>
                              <img src={TrashIcon} style={{ width: '12px', height: '12px' }} className="mx-1 cursor-pointer" alt="trash-icon" onClick={() => handleSelect(false, comment._id,)}/>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 d-flex pb-3 px-3">
                      <p className="m-0 text-black typo-body content">{comment.content}</p>   
                  </div>   
              </div>
          ))
        }
      </div>
    </>
  );
};

export default QuoteNotes;
