import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../../UI/buttons/IconButton";
import InputSearch from "../../../UI/forms/InputSearch";
import { getJobsId } from "../../../../store/factory/factoryJobSlice";
import { RootState } from "../../../../store/store";
import dataPrueba from "../../../dataPrueba";
import { useHistory } from "react-router-dom";

interface Props {
  setSelectJob: any;
  setActiveControlView: any;
  selectProject: any;
  setSelectProject: any;
  setView: any;
  setActiveTask: any;
  setActiveControl: any;
}

const SelectJobModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (item: any) => {
    // props.setActiveControlView("quote");
    // props.setSelectJob(item);
    // props.setView(false);
    // props.setActiveTask(false);
    // props.setActiveControl(false);
    history.replace(`/factory/${props.selectProject?._id}/${item._id}`);
  };

  const JobsDataId = useSelector((state: RootState) => state.getJobsTypeSlice);

  const { jobsDataId, loading } = JobsDataId;

  // console.log("selectProjectFactoryModalJob", props.selectProject._id);

  useEffect(() => {
    dispatch(getJobsId(props.selectProject?._id));
  }, [props.selectProject?._id]);

  const selectedColor = (item: any) => {
    let select;
    if (item.status === "Approved") {
      select = "aproved";
    } else if (item.status === "Proporsal") {
      select = "proporsal";
    } else if (item.status === "Active") {
      select = "active";
    } else if (item.status === "On Hold") {
      select = "onHold";
    } else if (item.status === "Lost") {
      select = "lost";
    } else if (item.status === "Complete") {
      select = "complete";
    }

    return select;
  };

  return (
    <div className="createProjectModal" style={{ margin: "-12px" }}>
      <div className=" ">
        <div className="row col px-3 py-3">
          <div className="col-sm-9 col-md-9 col-lg-9 ">
            <InputSearch className="w-100" placeholder="Search project" />
          </div>

          <div className="col-sm-3 col-md-3  col-lg-3  d-flex justify-content-end ">
            <button
              type="button"
              className="btn btn-primary btn-lg w-100"
              onClick={() => props.setActiveControlView("job")}
            >
              Create new job
            </button>
          </div>
        </div>
        {
          ////jobsDataId
        }
        <div className="">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              // style={{ width: "88vw", height: "40vh" }}
            >
              <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
              <span className="text-black ms-3">Loading...</span>
            </div>
          ) : (
            <table className="table table-borderless ">
              <thead className="">
                <tr className="text-primary typo-body">
                  <th scope="col" style={{ width: "250px" }} className="ps-1">
                    <p className=" m-0 p-0 ms-4 HeadTableFonts">JOB NAME</p>
                  </th>
                  <th scope="col" style={{ width: "200px" }}>
                    STATUS
                  </th>
                </tr>
              </thead>

              <tbody>
                {jobsDataId?.map((item: any, index: any) => {
                  return (
                    <tr
                      className={`${
                        (index / 2) % 1 > 0 ? "table-primary" : ""
                      } text-primary typo-body`}
                      key={index}
                      onClick={() => handleChange(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="d-flex align-items-center pe-0 me-0">
                        <p className="m-0 ms-4 general-text-table-factory">
                          {item.name}
                        </p>
                      </td>

                      <td className="text-left general-text-table-factory pe-5 me-5">
                        <span
                          className={`${selectedColor(item)} baseBadge onHold `}
                          style={{ marginLeft: "-11rem" }}
                        >
                          {item.status}
                          on Hold
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectJobModal;
