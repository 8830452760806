import moment from "moment";
import React, { useEffect, useState } from "react";
import IconButton from "../../../../../UI/buttons/IconButton";
import InputDate from "../../../../../UI/forms/InputDate";
import InputFloatingLabel from "../../../../../UI/forms/InputFloatingLabel";
import SelectColor from "../../../../../UI/forms/SelectColor";
import TrashIcon from "../../../../../../assets/icons/trash.svg";

interface Props {
  SelectOptions: object;
  mileStone: any;
  index: number;
  milestoneStateArray: any;
  setMilestoneStateArray: any;
  onSubmitControlForm: any;
  setOnSubmitControlForm: any;
  generarId: any;
}

const Milestones: React.FC<Props> = (props) => {
  const [controlState, setControlState] = useState({
    milestones_name: props.milestoneStateArray[props.index].milestones_name,
    location: props.milestoneStateArray[props.index].location,
    start_date: props.milestoneStateArray[props.index].start_date_milestone,
    end_date: props.milestoneStateArray[props.index].end_date_milestone,
    color: props.milestoneStateArray[props.index].color,
    id: props.milestoneStateArray[props.index].id,
  });

  const handleCHangeMilestone = () => {
    props.setMilestoneStateArray((prev: any) => {
      const newItemMilestone = {
        milestones_name: controlState.milestones_name,
        location: controlState.location,
        start_date_milestone: controlState.start_date,
        end_date_milestone: controlState.end_date,
        color: controlState.color,
        id: controlState.id,
      };

      prev.splice(props.index, 1, newItemMilestone);

      return prev;
    });
    // props.setOnSubmitControlForm({
    //   ...props.onSubmitControlForm,
    //   milestones: props.milestoneStateArray,
    // });
  };

  useEffect(() => {
    handleCHangeMilestone();
  }, [controlState, props.milestoneStateArray]);

  const handleDelete = (e: any) => {
    e.preventDefault();

    const deleteMilestone = props.milestoneStateArray.filter(
      (del: any) => del.id !== props.mileStone.id
    );

    // if (props.milestoneStateArray.length === 1) {
    //   return null;
    // } else {
    props.setMilestoneStateArray(deleteMilestone);
    // }
  };

  return (
    <div>
      <div className=" row g-3 mb-3">
        <div className="col-md-6">
          <div className="row ">
            <div className="col-md-1 margin-right">
              <i className=" bi bi-list text-black fs-2"></i>
            </div>
            <div className="col-md-11 p-1  m-0">
              <InputFloatingLabel
                placeHolder={
                  props.index === 0
                    ? `MILESTONES #${props.index + 1}`
                    : `MILESTONES #${props.index + 1}`
                }
                value={controlState.milestones_name}
                onChange={(val) =>
                  setControlState({ ...controlState, milestones_name: val })
                }
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-11  p-1 margin-right-input">
              <InputFloatingLabel
                placeHolder="Location"
                value={controlState.location}
                onChange={(val) =>
                  setControlState({ ...controlState, location: val })
                }
              />
            </div>
            <div className="col-md-1 p-2 ">
              <IconButton
                color="danger"
                // icon="bi bi-trash2"
                tooltip="Delete project"
                outline
                custom={TrashIcon}
                style={{ height: "32px", width: "32.5px" }}
                customStyles={{
                  height: "15px",
                  width: "15px",
                  color: " #0000",
                }}
                buttonProps={{ height: "35px", width: "35px", color: " #0000" }}
                onClick={(e: any) => handleDelete(e)}
              />
            </div>
          </div>
        </div>
        <div className="row  ">
          <div className="col-6 " style={{ marginLeft: "4px" }}>
            <div className="conatiner-date-form">
              <div className="row g-2">
                <div className="col-6">
                  <InputDate
                    // placeHolder="Start date & time"
                    onChange={(val: any) =>
                      setControlState({
                        ...controlState,
                        start_date: moment(val.value).format("DD/MM/YYYY"),
                      })
                    }
                    value={controlState.start_date}
                  />
                </div>
                <div className="col-6">
                  <InputDate
                    // placeHolder="End date & time"
                    onChange={(val: any) =>
                      setControlState({
                        ...controlState,
                        end_date: moment(val.value).format("DD/MM/YYYY"),
                      })
                    }
                    value={controlState.end_date}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-2  "
            style={{ marginLeft: "-4px", width: "105px" }}
          >
            <SelectColor
              placeholder=""
              options={props.SelectOptions}
              value={controlState.color}
              onChange={(val: string) =>
                setControlState({
                  ...controlState,
                  color: val,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Milestones;
