import { useFormik } from "formik";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "src/store/store";
import { TabsContent } from "../../AddContact";
import SelectInput from "src/components/UI/forms/Select";
import OrganizationValidation, { OrganizationData } from "./validation";
import { createContactForm } from "src/store/blackbook/createContactSlice";

const OrganizationType = forwardRef((_props, ref: TabsContent['ref']) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);

  const formik = useFormik<OrganizationData>({
    initialValues: {
      ...(data.data || {}),
    },
    validationSchema: OrganizationValidation.pick(['type']),
    onSubmit: () => {},
  });

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      try {
        const errors = await formik.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }

        return true;
      } catch {
        return false;
      }
    }
  }));

  useEffect(() => {
    if (data.initialValues !== undefined && data.appliedInitialValues === false) {
      formik.setValues(data.initialValues || {});
      /* @ts-ignore */
      dispatch(createContactForm.setField('appliedInitialValues', true, ''));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChange = (fieldName: string, value: any) => {
    formik.setFieldValue(fieldName, value);

    /* @ts-ignore */
    dispatch(createContactForm.setField(fieldName, value));
  }

  return (
    <div className="container-fluid px-3 mt-3 h-100 d-flex align-items-center justify-content-center">
      <div className="h-50 w-25">
        <SelectInput
          value={formik.values.type}
          placeholder='ORGANIZATION TYPE *'
          onChange={(val: string) => handleChange('type', val)}
          options={[
            { label: 'Client', value: 'CLIENT', },
            { label: 'Supplier', value: 'SUPPLIER', },
            { label: 'Venue', value: 'VENUE', },
          ]}
        />
      </div>
    </div>
  )
})

export default OrganizationType;