import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { ApiError } from "../../../../models";
import { UploadError } from "../../../../util";
import { factoryService } from "../../../../services/factory";
import JobForm from "./tabs/JobForm";
import CreateForm from "./tabs/CreateForm";
import { useDispatch, useSelector } from "react-redux";
import { GetAllContacts } from "../../../../store/blackbook/getContactSlice";
import { RootState } from "../../../../store/store";
import { useToast } from "../../../../hooks/useToast";
import {
  getAllJobs,
  getAllJobsTypes,
  getJobById,
} from "../../../../store/factory/factoryJobSlice";
import { getProjectId } from "../../../../store/factory/factorySlice";

interface Props {
  activeControlView?: string | null;
  setActiveControlView?: any;
  setOpenModal?: any;
  selectJob?: string | null;
  setSelectJob?: any;
  setSelectProject?: any;
}

const initialValue: any = [
  {
    type: "paragraph",
    children: [
      { text: "" },
      { text: "", bold: true },
      { text: "" },
      { text: "", italic: true },
      { text: "" },
      { text: "", code: true },
    ],
  },

  {
    type: "block-quote",
    children: [{ text: "" }],
  },
  {
    type: "paragraph",
    align: "center",
    children: [{ text: "" }],
  },
];
interface ControlProject {
  name: string;
  client: string;
  organization: string;
  start_date: string;
  end_date: string;
  file_cover: string;
  email: string;
}

interface StateArray {
  milestones_name: string;
  location: string;
  start_date_milestone: string;
  end_date_milestone: string;
  color: string;
  id: any;
}
interface ControlForm {
  name: string;
  project: string | null | undefined;
  // projectManager: string;
  // clientManager: string;
  start_date: string;
  end_date: string;
  file_client_brief: any;
  // project_asset_1: string;
  // project_asset_2: string;
  // job_type: string;
  // term_conditions: string;
  // milestones: [];
  // deliverables: [];
  // documents: [];
  // requeriments: [];
  // task: string;
  // asignTo: string;
  // dueDate: string;
  client_brief: string | {};
}

const CreateProjectForm: React.FC<Props> = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [errorFormulary, setErrorFormulary] = useState<boolean>(false);
  const [errorFormularyName, setErrorFormularyName] = useState<boolean>(false);
  const [errorFormularyFile, setErrorFormularyFile] = useState<boolean>(false);
  const [errorFormularyStartDate, setErrorFormularyStartDate] =
    useState<boolean>(false);
  const [errorFormularyOrganization, setErrorFormularyOrganization] =
    useState<boolean>(false);
  const [errorFormularyEndDate, setErrorFormularyEndDate] =
    useState<boolean>(false);
  const [errorFormularyClient, setErrorFormularyClient] =
    useState<boolean>(false);
  const [errorFormularyEmail, setErrorFormularyEmail] =
    useState<boolean>(false);

  const [errorFormularyNameJob, setErrorFormularyNameJob] =
    useState<boolean>(false);
  const [errorFormularyStartDateJob, setErrorFormularyStartDateJob] =
    useState<boolean>(false);
  const [errorFormularyEndDateJob, setErrorFormularyEndDateJob] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(true);

  const { id: idParamsProject }: any = useParams();

  const { id, idJob, idcJob }: any = useParams();

  const tabs =
    props.activeControlView === "job" || idJob || idcJob
      ? ["Create job"]
      : props.activeControlView === "project"
      ? ["Create Project", "Create job"]
      : ["Create Project"];

  const [onSubmitControlProject, setOnSubmitControlProject] =
    useState<ControlProject>({
      name: "",
      client: "",
      organization: "",
      start_date: "",
      end_date: "",
      file_cover: "",
      email: "",
    });

  const {
    name: nameProject,
    client: clientProject,
    organization: organizationProject,
    start_date: start_date_project,
    end_date: end_date_project,
    file_cover: file_cover_project,
    email: email_project,
  } = onSubmitControlProject;

  useEffect(() => {
    if (onSubmitControlProject.name !== "") {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [onSubmitControlProject]);

  const generarId = () => {
    const date = Date.now();
    const random = Math.random().toString(36).substring(2);

    return random + date;
  };

  const [milestoneStateArray, setMilestoneStateArray] = useState<StateArray[]>([
    {
      milestones_name: "",
      location: "",
      start_date_milestone: "",
      end_date_milestone: "",
      color: "",
      id: generarId(),
    },
  ]);

  const [onSubmitControlForm, setOnSubmitControlForm] = useState<ControlForm>({
    name: "",
    project: props.selectJob === undefined ? idcJob : props.selectJob,
    // projectManager: "",
    // clientManager: "",
    start_date: "",
    end_date: "",
    client_brief: "",
    file_client_brief: "",
    // project_asset_1: "",
    // project_asset_2: "",
    // job_type: "",
    // term_conditions: "",
    // milestones: [],
    // deliverables: [],
    // documents: [],
    // requeriments: [],
    // task: "",
    // asignTo: "",
    // dueDate: "",
  });

  const {
    name: nameJob,
    project: projectJob,
    start_date: start_date_job,
    end_date: end_date_job,
    client_brief,
    file_client_brief,
  } = onSubmitControlForm;

  const toast = useToast();

  const GetContact = useSelector((state: RootState) => state.getContactAll);
  const GetJobType = useSelector((state: RootState) => state.getJobsTypeSlice);
  const project = useSelector((state: RootState) => state.factorySlice);

  const ProjectData = useSelector((state: RootState) => state.factorySlice);

  const { projectDataId } = ProjectData;

  const [datesProject, setDatesProject] = useState<any>([
    onSubmitControlProject.start_date,
    onSubmitControlProject.end_date,
  ]);

  useEffect(() => {
    dispatch(getProjectId(idParamsProject));

    if (idParamsProject) {
      setOnSubmitControlProject({
        ...onSubmitControlProject,
        name: projectDataId?.name,
        client: projectDataId?.client?._id,
        organization: projectDataId?.organization?._id,
        start_date: projectDataId?.start_date,
        end_date: projectDataId?.end_date,
        // file_cover: projectDataId.file_cover,
        email: projectDataId?.client?.entity?.email,
      });
      setDatesProject([projectDataId?.start_date, projectDataId?.end_date]);
    }
  }, [idParamsProject]);

  // console.log("GetJobType", GetJobType);

  // console.log("onSubmitControlForm", onSubmitControlForm);

  // console.log("projectDataId", projectDataId);

  const { jobDataById } = GetJobType;

  // console.log("jobsDataById", jobDataById);

  const { getContact } = GetContact;

  const dispatch = useDispatch();

  const getJobs = () => {
    dispatch(getAllJobs());
  };

  const getJobsType = () => {
    dispatch(getAllJobsTypes());
  };

  const getJobsById = () => {
    dispatch(getJobById(idJob));
  };

  const getData = () => {
    dispatch(GetAllContacts());
  };

  useEffect(() => {
    getJobsById();
  }, [idJob]);

  useEffect(() => {
    getData();
    getJobsType();
    getJobs();
  }, []);

  const sendEditProject = {
    name: onSubmitControlProject.name,
    start_date: onSubmitControlProject.start_date,
    end_date: onSubmitControlProject.end_date,
    client: onSubmitControlProject.client,
    organization: onSubmitControlProject.organization,
    id: idParamsProject,
  };

  const sendEditJob = {
    name: onSubmitControlForm.name,
    start_date: onSubmitControlForm.start_date,
    end_date: onSubmitControlForm.end_date,
    client_brief: onSubmitControlForm.client_brief,
    id: idJob,
    file_client_brief: onSubmitControlForm.file_client_brief,
    project: idParamsProject,
    asset_links: [],
    types: "",
    milestones: JSON.stringify({
      name: "Start",
      color: "#FFFFFF",
      start_date: "12/05/2022",
      end_date: "12/05/2022",
      location: "Lima",
    }),
    documents: [],
    deliverables: [],
    requirements: [],
  };

  const history = useHistory();
  const [errors, setErrors] = useState<UploadError[]>([]);

  const handleSubmit = () => {
    // EDIT PROJECT
    if (
      props.activeControlView !== "project" &&
      props.activeControlView !== "job" &&
      idParamsProject !== undefined &&
      idJob === undefined
    ) {
      if (nameProject === "") {
        setErrorFormularyName(true);
      }
      //  else if (file_cover_project === "") {
      //   setErrorFormularyFile(true);
      // }
      else if (start_date_project === "") {
        setErrorFormularyStartDate(true);
      } else if (end_date_project === "") {
        setErrorFormularyEndDate(true);
      } else if (organizationProject === "") {
        setErrorFormularyOrganization(true);
      } else if (clientProject === "") {
        setErrorFormularyClient(true);
      } else if (email_project === "") {
        setErrorFormularyEmail(true);
      } else {
        setErrorFormulary(false);
        setLoading(true);

        factoryService
          .updateProject(sendEditProject)
          .then((res) => {
            toast.success("Successfully uploaded.");

            setOnSubmitControlProject({
              name: "",
              client: "",
              organization: "",
              start_date: "",
              end_date: "",
              file_cover: "",
              email: "",
            });
            setLoading(false);
            history.push("/factory");

            return;
          })
          .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
            if (err?.data?.message) {
              setErrorFormulary(false);
              setLoading(false);
              setErrors(err.data.message);
              toast.error("An error occurred could not update project.");
              return;
            }
          });
      }
    }

    // EDIT JOB

    if (
      props.activeControlView !== "project" &&
      props.activeControlView !== "job" &&
      idParamsProject !== undefined &&
      idJob !== undefined
    ) {
      if (nameJob === "") {
        setErrorFormularyNameJob(true);
      } else if (start_date_job === undefined) {
        setErrorFormularyStartDateJob(true);
      } else if (end_date_job === "") {
        setErrorFormularyEndDateJob(true);
      } else {
        setErrorFormulary(false);
        setLoading(true);

        factoryService
          .updateJobs(sendEditJob)
          .then((res) => {
            toast.success("Successfully uploaded.");

            setOnSubmitControlForm({
              name: "",
              start_date: "",
              end_date: "",
              project: "",
              client_brief: "",
              file_client_brief: "",
            });
            setLoading(false);

            history.push(`/factory/project/detail/${idParamsProject}`);

            return;
          })
          .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
            if (err) {
              setErrorFormulary(true);
              setLoading(false);
              // setErrors(err.data.message);
              toast.error("An error occurred could not update job.");
              return;
            }
          });
      }
    }

    if (props.activeControlView === "project") {
      if (nameProject === "") {
        setErrorFormularyName(true);
      }
      if (file_cover_project === "") {
        setErrorFormularyFile(true);
      }
      if (start_date_project === undefined) {
        setErrorFormularyStartDate(true);
      }
      if (end_date_project === "") {
        setErrorFormularyEndDate(true);
      }
      if (organizationProject === "") {
        setErrorFormularyOrganization(true);
      }
      if (clientProject === "") {
        setErrorFormularyClient(true);
      }
      if (email_project === "") {
        setErrorFormularyEmail(true);
      }
      if (nameJob === "") {
        setErrorFormularyNameJob(true);
      }
      if (start_date_job === undefined) {
        setErrorFormularyStartDateJob(true);
      }
      if (end_date_job === "") {
        setErrorFormularyEndDateJob(true);
      } else {
        setErrorFormularyName(false);
        setErrorFormularyClient(false);
        setErrorFormularyOrganization(false);
        setErrorFormularyStartDate(false);
        setErrorFormularyEndDate(false);
        setErrorFormularyFile(false);
        setErrorFormularyEmail(false);

        setErrorFormulary(false);
        setLoading(true);
        factoryService
          .CreateProject(onSubmitControlProject)
          .then((res) => {
            setOnSubmitControlProject({
              name: "",
              client: "",
              organization: "",
              start_date: "",
              end_date: "",
              file_cover: "",
              email: "",
            });

            const responseJob = {
              name: nameJob,
              project: res._id,
              start_date: start_date_job,
              end_date: end_date_job,
              client_brief,
              file_client_brief,
            };

            if (res._id) {
              setErrorFormulary(false);
              factoryService
                .CreateJobs(responseJob)
                .then((res) => {
                  console.log("res from createJob ", res);

                  console.log("paso por aqui");

                  toast.success(
                    // res ||
                    "Successfully create project and Job."
                  );

                  setLoading(false);
                  props.setActiveControlView(null);
                  props.setSelectJob(null);
                  props.setOpenModal(false);
                  props.setSelectProject(null);
                  setOnSubmitControlForm({
                    name: "",
                    start_date: "",
                    end_date: "",
                    project: "",
                    client_brief: "",
                    file_client_brief: "",
                  });
                  props.setSelectProject(null);

                  return;
                })
                .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
                  console.log("err from create JOB", err);
                  if (err?.data?.message) {
                    toast.error(err?.data?.message.map((e: any) => e));
                    setErrorFormulary(false);
                    setLoading(false);
                    setErrors(err.data.message);
                  }
                });
            }

            return;
          })
          .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
            setErrorFormulary(false);
            if (err?.data?.message) {
              setLoading(false);
              setErrors(err.data.message);
              toast.error(err?.data?.message);
              return;
            }
          });
      }
    }

    if (props.activeControlView === "job") {
      if (nameJob === "") {
        setErrorFormularyNameJob(true);
      }
      if (start_date_job === undefined) {
        setErrorFormularyStartDateJob(true);
      }
      if (end_date_job === undefined) {
        setErrorFormularyEndDateJob(true);
      } else {
        setErrorFormulary(false);
        setLoading(true);
        factoryService
          .CreateJobs(onSubmitControlForm)
          .then((res) => {
            console.log("res from createJob ", res);

            toast.success(
              // res ||
              "Successfully create Job."
            );

            props.setActiveControlView(null);
            props.setSelectJob(null);
            props.setOpenModal(false);
            setOnSubmitControlForm({
              name: "",
              start_date: "",
              end_date: "",
              project: "",
              client_brief: "",
              file_client_brief: "",
            });
            props.setSelectProject(null);
            setLoading(false);

            return;
          })
          .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
            console.log("err from create JOB", err);
            if (err?.data?.message) {
              toast.error(err?.data?.message.map((e: any) => e));
              setErrorFormulary(false);
              setLoading(false);
              return;
            }

            setErrors(err.data?.message);
          });
      }
    }

    // if (idcJob) {
    //   if ([onSubmitControlForm.name].includes("")) {
    //     setErrorFormulary(true);
    //   } else {
    //     setErrorFormulary(false);
    //     setLoading(true);
    //     factoryService
    //       .CreateJobs(onSubmitControlForm)
    //       .then((res) => {
    //         console.log("res from createJob ", res);
    //         toast.success(
    //           // res ||
    //           "Successfully create Job."
    //         );
    //         setOnSubmitControlForm({
    //           name: "",
    //           start_date: "",
    //           end_date: "",
    //           project: "",
    //           client_brief: "",
    //           file_client_brief: "",
    //         });
    //         history.replace("/factory");
    //         setLoading(false);

    //         return;
    //       })
    //       .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
    //         console.log("err from create JOB", err);
    //         if (err?.data?.message) {
    //           toast.error(err?.data?.message.map((e: any) => e));
    //           setErrorFormulary(false);
    //           setLoading(false);
    //           return;
    //         }

    //         setErrors(err.data?.message);
    //       });
    //   }
    // }
  };

  useEffect(() => {
    if (nameProject !== "") {
      setErrorFormularyName(false);
    }
    if (file_cover_project !== "") {
      setErrorFormularyFile(false);
    }
    if (start_date_project !== undefined) {
      setErrorFormularyStartDate(false);
    }
    if (end_date_project !== undefined) {
      setErrorFormularyEndDate(false);
    }
    if (organizationProject !== "") {
      setErrorFormularyOrganization(false);
    }
    if (clientProject !== "") {
      setErrorFormularyClient(false);
    }
    if (email_project !== "") {
      setErrorFormularyEmail(false);
    }
    if (nameJob !== "") {
      setErrorFormularyNameJob(false);
    }
    if (start_date_job !== "") {
      setErrorFormularyStartDateJob(false);
    }
    if (end_date_job !== "") {
      setErrorFormularyEndDateJob(false);
    }
  }, [
    nameProject,
    clientProject,
    organizationProject,
    start_date_project,
    end_date_project,
    file_cover_project,
    email_project,
    nameJob,
    start_date_job,
    end_date_job,
  ]);

  useEffect(() => {
    if (nameJob !== "") {
      setErrorFormularyNameJob(false);
    }
    if (start_date_job !== undefined) {
      setErrorFormularyStartDateJob(false);
    }
    if (end_date_job !== undefined) {
      setErrorFormularyEndDateJob(false);
    }
  }, [nameJob, start_date_job, end_date_job]);

  const tabsContent =
    props.activeControlView === "job" || idJob || idcJob
      ? [
          <JobForm
            onSubmitControlForm={onSubmitControlForm}
            setOnSubmitControlForm={setOnSubmitControlForm}
            GetJobType={GetJobType}
            milestoneStateArray={milestoneStateArray}
            setMilestoneStateArray={setMilestoneStateArray}
            generarId={generarId}
            errorFormulary={errorFormulary}
            setErrorFormulary={setErrorFormulary}
            jobDataById={jobDataById}
            idJob={idJob}
            loading={loading}
            activeControlView={props.activeControlView}
            errorFormularyNameJob={errorFormularyNameJob}
            errorFormularyStartDateJob={errorFormularyStartDateJob}
            errorFormularyEndDateJob={errorFormularyEndDateJob}
          />,
        ]
      : props.activeControlView === "project"
      ? [
          <CreateForm
            errorFormulary={errorFormulary}
            setErrorFormulary={setErrorFormulary}
            setCurrentTab={setCurrentTab}
            onSubmitControlProject={onSubmitControlProject}
            setOnSubmitControlProject={setOnSubmitControlProject}
            activeControlView={props.activeControlView}
            setActiveControlView={props.setActiveControlView}
            getContact={getContact}
            idParamsProject={idParamsProject}
            loading={loading}
            active={active}
            errorFormularyName={errorFormularyName}
            errorFormularyFile={errorFormularyFile}
            errorFormularyStartDate={errorFormularyStartDate}
            errorFormularyOrganization={errorFormularyOrganization}
            errorFormularyEndDate={errorFormularyEndDate}
            errorFormularyClient={errorFormularyClient}
            errorFormularyEmail={errorFormularyEmail}
            datesProject={datesProject}
            setDatesProject={setDatesProject}
          />,

          <JobForm
            onSubmitControlForm={onSubmitControlForm}
            setOnSubmitControlForm={setOnSubmitControlForm}
            GetJobType={GetJobType}
            milestoneStateArray={milestoneStateArray}
            setMilestoneStateArray={setMilestoneStateArray}
            generarId={generarId}
            errorFormulary={errorFormulary}
            setErrorFormulary={setErrorFormulary}
            jobDataById={jobDataById}
            idJob={idJob}
            loading={loading}
            activeControlView={props.activeControlView}
            errorFormularyNameJob={errorFormularyNameJob}
            errorFormularyStartDateJob={errorFormularyStartDateJob}
            errorFormularyEndDateJob={errorFormularyEndDateJob}
          />,
        ]
      : [
          <CreateForm
            errorFormulary={errorFormulary}
            setErrorFormulary={setErrorFormulary}
            setCurrentTab={setCurrentTab}
            onSubmitControlProject={onSubmitControlProject}
            setOnSubmitControlProject={setOnSubmitControlProject}
            setActiveControlView={props.setActiveControlView}
            activeControlView={props.activeControlView}
            getContact={getContact}
            loading={loading}
            active={active}
            errorFormularyName={errorFormularyName}
            errorFormularyFile={errorFormularyFile}
            errorFormularyStartDate={errorFormularyStartDate}
            errorFormularyOrganization={errorFormularyOrganization}
            errorFormularyEndDate={errorFormularyEndDate}
            errorFormularyClient={errorFormularyClient}
            errorFormularyEmail={errorFormularyEmail}
            datesProject={datesProject}
            setDatesProject={setDatesProject}
          />,
        ];

  const handleChange = () => {
    props.setActiveControlView(null);
    props.setOpenModal(false);
    props.setSelectProject(null);
    props.setSelectJob(null);

    setOnSubmitControlForm({
      ...onSubmitControlForm,
      name: "",
      start_date: "",
      end_date: "",
      project: "",
    });

    setOnSubmitControlProject({
      ...onSubmitControlProject,
      name: "",
      client: "",
      organization: "",
      start_date: "",
      end_date: "",
      file_cover: "",
      email: "",
    });
  };

  return (
    <div className="col-12 p-1 container-create">
      {loading ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center border bg-white shadow-modal-saving "
          style={{
            position: "absolute",
            top: "30vh",
            left: "35vw",
            width: "30vw",
            height: "30vh",
            margin: "auto",
            borderRadius: "10px",
          }}
        >
          <h1 className="text-secondary mb-3">Saving</h1>
          <span
            className="text-black
               ms-3 mt-2"
          >
            Wait while we save your changes...
          </span>
          <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block mt-3" />
        </div>
      ) : (
        <div className="  card px-4 py-2" style={{ height: "45rem" }}>
          <div className="d-flex flex-row  px-4 py-2 justify-content-between">
            {props.activeControlView === undefined ? (
              <Link to="/factory">
                <span className=" text-danger cursor-pointer">Cancel</span>
              </Link>
            ) : (
              <div>
                <span
                  onClick={handleChange}
                  className=" text-danger cursor-pointer"
                >
                  Cancel
                </span>
              </div>
            )}

            <div>
              <button
                className=" text-success mr-auto cursor-pointer"
                onClick={handleSubmit}
                style={{ background: "none", border: "none" }}
                // disabled={active}
              >
                {props.activeControlView === "job" || idcJob
                  ? "Create job"
                  : idJob
                  ? "Edit Job"
                  : idParamsProject
                  ? "Edit project"
                  : "Create project"}
              </button>
            </div>
          </div>

          <div className="container container-tabs">
            <div className="view-number-project">
              <p>
                PROJECT NO.:
                {(idParamsProject && idParamsProject) ||
                  (idJob && id) ||
                  idcJob ||
                  props.selectJob}
                {/* {props.activeControlView === "job" && props.selectJob} */}
                {/* fix getdata from project por view details projectk */}
              </p>
            </div>
            <div>
              <ul className="nav nav-tabs mt-3">
                {tabs.map((item, index) => (
                  <li
                    key={index}
                    className="nav-item"
                    onClick={setCurrentTab.bind(null, index)}
                  >
                    <p
                      className={`nav-link typo-body user-select-none ${
                        currentTab === index ? "active" : ""
                      }`}
                      aria-current="page"
                    >
                      {item}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            <div className=" px-4 py-2 container-formview overflow-auto">
              {tabsContent[currentTab]}
            </div>
          </div>
          {currentTab === 1 || props.activeControlView === "job" ? (
            <div className="containe-button-Form  px-4 py-4">
              {props.activeControlView === "job" ? (
                []
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={() => setCurrentTab(0)}
                >
                  <i className="bi bi-arrow-left">Previous step</i>
                </button>
              )}

              <button type="submit" className="btn btn-primary btn-lg" disabled>
                Create quote
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                onClick={handleSubmit}
                disabled
              >
                Create job<i className="bi bi-check2 ms-1 "></i>
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CreateProjectForm;
