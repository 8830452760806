import { useState } from 'react';
import { ICategory } from 'src/models/Category';

interface Props {
  category: ICategory;
  selected?: ICategory | boolean;
  onSelect?: (category: ICategory) => void;
}

const CategoryItem: React.FC<Props> = ({
  category,
  onSelect,
  selected,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSelect = (cat: ICategory) => {
    if(cat._id === category._id) setDropdownOpen(!dropdownOpen);

    onSelect && onSelect(cat);
  }

  return (
    <ul className="p-0">
      <li
        onClick={() => handleSelect(category)}
        className={
          "text-primary typo-body mb-1 row p-0 justify-content-between align-items-center dashboard-categories-item" +
          ((typeof selected === 'boolean' && selected) || (typeof selected !== 'boolean' && selected?._id === category._id) ? ' active' : '')
        }
      >
        <p className="col-auto m-0">{category.name}</p>
        {/* Should only show when the category returns the total items in it */}
        {/* {count && (
          <p className="m-0 col-auto contact-trailing typo-body bg-primary me-1">
            {count}
          </p>
        )} */}
      </li>

      {
        dropdownOpen &&
          (category?.sub_categories || [])?.map((cat) => (
            <li
              style={{ marginLeft: '3%' }}
              onClick={() => handleSelect(cat)}
              className={
                "text-primary typo-body mb-1 row p-0 justify-content-between align-items-center dashboard-categories-item" +
                ((typeof selected === 'boolean' && selected) || (typeof selected !== 'boolean' && selected?._id === cat._id) ? ' active' : '')
              }
            >
              <p className="col-auto m-0">{cat.name}</p>
            </li>
          ))
      }
    </ul>
  );
};

export default CategoryItem;
