import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import InputDate from "../UI/forms/InputDate";
import InputFloatingLabel from "../UI/forms/InputFloatingLabel";
import Select from "../UI/forms/Select";

interface Props {
  // view: boolean;
  // setView: any;
  // toggle: any;
  // activeControlView: string;
  // setActiveControlView: any;
}

const FactoryModalFilter: React.FC<Props> = (props) => {
  return (
    <div className="container-Select-factory-filter ">
      <div className="row p-2">
        <div className="col-md-12" style={{ height: "45px" }}>
          <InputDate
            // onChange={(e: any) =>
            //   handleChange("end_date", moment(e.value).format("DD/MM/YYYY"))
            // }
            // placeHolder="Project start date"
            onChange={(e: any) => {}}
            value={1234}
          />
        </div>
        <div className="col-md-12 mt-2">
          <InputDate
            // onChange={(e: any) =>
            //   handleChange("end_date", moment(e.value).format("DD/MM/YYYY"))
            // }
            // placeHolder="Project start date"
            onChange={(e: any) => {}}
            value={1234}
          />
        </div>
        <div className="col-md-12 mt-2">
          <Select
            placeholder="Primary account manager"
            onChange={() => {}}
            value=""
            options={[]}
          />
        </div>
        <div className="col-md-12 mt-2" style={{ height: "43px" }}>
          <InputFloatingLabel
            placeHolder="Organitacion name"
            onChange={() => {}}
            value=""
          />
        </div>
        <div className="col-md-12 mt-2" style={{ height: "43px" }}>
          <InputFloatingLabel
            placeHolder="Client name"
            onChange={() => {}}
            value=""
          />
        </div>
        <div className="col-md-12 mt-2" style={{ height: "43px" }}>
          <InputFloatingLabel
            placeHolder="Account manager"
            onChange={() => {}}
            value=""
          />
        </div>
        <small className=" text-black m-1 ml-2 mt-3 ">
          Project total between
        </small>
        <div className="container">
          <div className="row g-2 ">
            <div className="col-6">
              <InputFloatingLabel
                placeHolder="Min"
                onChange={() => {}}
                value=""
              />
            </div>
            <div className="col-6">
              <InputFloatingLabel
                placeHolder="Max"
                onChange={() => {}}
                value=""
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4 m-1  ">
            <button
              className="btn btn-primary btn-lg"
              style={{ width: "100px" }}
            >
              Search
            </button>
          </div>
          <div className="col-4 m-2  fs-6">
            <a className="">Clear filter</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactoryModalFilter;
