import { createSlice, createAsyncThunk, PayloadAction, CaseReducer } from '@reduxjs/toolkit';



const initialState = {
    Description: {
        name: '',
        description: '',
        picture: '',
        skill_sets: []
    },
    namePicture: '',
    RatesData: {
        hour: '',
        daily: '',
        week: '',
        sale: '',
        holiday_day: '',
        holiday_hour: '',
        overtime_hour: '',
        holiday_overtime_hour: '',
    },
    RatesErrors: false,
    DescriptionErrors:false
}

const ServiceSlice = createSlice({
    name: 'ServiceSlice',
    initialState,
    reducers: {
        setDescriptionData: (state, action) => {
            state.Description = action.payload
        },
        setRates: (state, action) => {
            state.RatesData = { ...state.RatesData, ...action.payload }
        },
        changeRatesErrors: (state, action) => {
            state.RatesErrors = action.payload
        },
        changeDescriptionErrors: (state, action) => {
            state.DescriptionErrors = action.payload
        },
        setNamePicture: (state , action ) => {
         state.namePicture = action.payload
        },
        EmptyFormService: (state, action) => {
            state.Description = {
                name: '',
                description: '',
                picture: '',
                skill_sets: []
            }
            state.RatesData = {
                hour: '',
                daily: '',
                week: '',
                sale: '',
                holiday_day: '',
                holiday_hour: '',
                overtime_hour: '',
                holiday_overtime_hour: '',
            }
           state.RatesErrors = false
           state.namePicture = ''
        },

    },
    extraReducers: (builder) => {

    }
});

export const { setDescriptionData , setRates , changeRatesErrors ,changeDescriptionErrors, EmptyFormService , setNamePicture} = ServiceSlice.actions;
export default ServiceSlice.reducer;