import Router from "./Router/Router";
import Layout from "./layout/Layout/Layout";
import AuthRouter from "./Router/AuthRouter";
import { useRequest } from "./hooks/useRequest";
import React from "react";
import PublicRouter from "./Router/PublicRouter";

function App() {
  const { data, error } = useRequest({ url: "users/me" });

  const renderSwitch = () => {
    /* The request is loading */
    if (!data && !error)
      return (
        <>
          <i className="text-secondary fs-1 bi bi-arrow-repeat animate-spin d-block" />
          <span className="text-white ms-3">Loading...</span>
        </>
      );

    /* User is not logged */
    if (!data && error) return <AuthRouter />;

    /* User is logged */
    if (data && !error)
      return (
        <Layout>
          <Router />
        </Layout>
      );

      if(!data && !error) return <PublicRouter />
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100vw", height: "100vh" }}
    >
        {renderSwitch()}
    </div>
  );
}

export default App;
