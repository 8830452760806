import React, { useEffect, useState } from "react";
import SmallInputDate from "../../../../components/UI/forms/SmallInputDate";
import InputFloatingLabel from "../../../../components/UI/forms/InputFloatingLabel";
import SelectFloatingLabel from "../../../../components/UI/forms/SelectFloatingLabel";
import Checkbox from "../../../../components/UI/forms/Checkbox";
import TextArea from "../../../../components/UI/forms/TextArea";
import TreeTable from "./TreeTable";
import { v4 as uuidv4 } from "uuid";
import { RatesQuote } from "./CreateQuoteForm";
import moment from "moment";

interface Props {
  // setActiveControlView: any;
  setView: (value: boolean) => void;
  setViewRates: (value: boolean) => void;
  ItemData: any;
  selectProject: any;
  selectJob: any;
  QuoteData: any;
  getItemSelect: any;
  quoteForm: any;
  setQuoteForm: any;
  getHeader: any;
  setGetHeader: any;
  getSubHeader: any;
  setSubGetHeader: any;
  quantity: any;
  setQuantity: any;
  rate: number;
  setRate: any;
  units_number: number;
  setUnits_number: any;
  units_type: any;
  setUnits_type: any;
  getRates: RatesQuote;
  addItems: boolean;
  setAddItems: any;
  viewModal: boolean;
  setViewModal: any;
  setEditColumns: any;
  countHeader: number;
  setCountHeader: any;
  specificRatesbyItemId: any;
  getIdItem: any;
  setGetIdItem: any;
  getIdItemRate: any;
  setModalAssignAsset: any;
  projectDataId: any;
  indexSelected: any;
  setIndexSelected: any;
  deleteItemsColumn: any;
  typeRateCards: string;
  setTypeRateCards: any;
  errorFormulary: boolean;
  setGetIdItemForAssets: any;
  jobDataById: any;
  modalBrief: boolean;
  setModalBrief: (value: boolean) => void;
  ActiveModalDiscount: any;
  Term_conditions: any;
  errorFormularyName: boolean;
  errorFormularyDue_date: boolean;
  errorFormularyTerm: boolean;
  errorFormularyDescription: boolean;
  setViewBrief: (value: boolean) => void;
  totalQuote: number;
  setTotalQuote: (value: number) => void;
  totalTax: Number;
  setTotaltax: (value: number) => void;
  totalAgency_fee: Number;
  setTotalAgency_fee: (value: number) => void;
}

const FormQuote: React.FC<Props> = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [targetBudget, setTargetBudget] = useState<number>(0);
  const [quoteNumber, setQuoteNumber] = useState<number>(0);
  const [client, setClient] = useState<any>([]);
  const [organization, setOrganization] = useState<any>([]);
  const [formControl, setformControl] = useState(true);

  const handleModalRates = () => {
    props.setViewRates(true);
    props.setView(true);
  };

  const newHeader = {
    id: uuidv4(),
    name: `Header ${props.countHeader + 1}`,
    assigned_to: null,
    quantity: 0,
    rate: 0,
    units_number: 0,
    units_type: "",
    level: "1",
    elements: null,
    type: `Header ${props.countHeader + 1}`,
  };

  const addNewHeader = () => {
    props.setCountHeader(props.countHeader + 1);

    {
      props.setQuoteForm({
        ...props.quoteForm,
        headers: [...props.quoteForm.headers, newHeader],
      });
    }
  };

  const hangleEditColumns = () => {
    props.setViewModal(true);
    props.setEditColumns(true);
  };

  const hangleClientBrief = () => {
    // props.setViewModal(true);
    // props.setModalBrief(true);
    props.setViewBrief(true);
  };

  const total = () => {
    const total = props.quoteForm.headers.reduce(
      (acc: any, curr: any) =>
        acc +
        Number(curr.quantity || 0) *
          Number(curr.rate || 0) *
          Number(curr.units_number || 0),
      0
    );

    return total;
  };

  const date = new Date();

  const now = moment(date).format("DD/MM/YYYY");

  const totalDifference = () => {
    const Difference = props.quoteForm.target_budget - props.totalQuote;

    return Difference;
  };

  const tab = "\u00A0";

  const selectData = () => {
    [props.projectDataId].map((e: any) => {
      if (e?.client) {
        setClient(e.client.entity);
      }
      if (e?.organization) {
        setOrganization(e.organization.entity);
      }
    });
  };
  useEffect(() => {
    selectData();
  }, [props.projectDataId]);

  const Color = (co: any) => {
    if (co >= 0) {
      return "text-success";
    } else {
      return "text-danger";
    }
  };

  const formatNumber = (number: number) => {
    let n: string = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);

    return n;
  };

  const Select_Term_conditions = props.Term_conditions.map((e: any) => {
    let obj = { label: e.title, value: e._id };

    return obj;
  });

  // console.log("Select_Term_conditions", Select_Term_conditions);

  return (
    <div className="form-quote overflow-y">
      <form className="row  custom-scrollbar ps-4 pe-4 overflow-y">
        {formControl && (
          <>
            <div className=" col-6">
              <div
                style={{ marginBottom: props.errorFormulary ? "-15px" : "" }}
              >
                <InputFloatingLabel
                  placeHolder="Quote name"
                  onChange={(value) =>
                    props.setQuoteForm({ ...props.quoteForm, name: value })
                  }
                  value={props.quoteForm.name}
                  errorMessage={props.errorFormularyName && true}
                />
              </div>
              {props.errorFormularyName && (
                <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                  <label style={{ fontSize: "12px", width: "450px" }}>
                    <span className="validation">
                      You must enter a quote name
                    </span>
                  </label>
                </div>
              )}
            </div>

            <div className="col-3 ">
              <SelectFloatingLabel
                placeholder="Terms & conditions"
                onChange={(val: React.ChangeEvent<HTMLSelectElement>) =>
                  props.setQuoteForm({
                    ...props.quoteForm,
                    term: val,
                  })
                }
                value={props.quoteForm.term}
                options={Select_Term_conditions}
              />
              {props.errorFormularyTerm && (
                <div style={{ marginBottom: "15px" }}>
                  <label style={{ fontSize: "12px", width: "450px" }}>
                    <span className=" validation">
                      You must select a quote term
                    </span>
                  </label>
                </div>
              )}
            </div>

            <div className="col-3 ">
              <SelectFloatingLabel
                placeholder="Payment terms"
                onChange={() => {}}
                value=""
                options={[]}
              />
            </div>
            <div className=" col-3" style={{ width: "420px" }}>
              <section>
                <p className="text-secondary  lh-1 d-flex flex-row">
                  <div>
                    <span className="fw-bolder me-2">Organization: </span>
                  </div>
                  {organization.name === undefined ? (
                    <div className="d-flex fle-row">
                      <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
                    </div>
                  ) : (
                    organization.name
                  )}
                </p>
                <p className="text-secondary  lh-1 d-flex flex-row">
                  <span className="fw-bolder me-2">Client: </span>
                  {client?.title === undefined ? (
                    <div className="d-flex flex-row">
                      <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
                    </div>
                  ) : (
                    client?.title +
                    tab +
                    client?.first_name +
                    tab +
                    client?.last_name
                  )}
                </p>
                <p className="text-secondary  lh-1">
                  <span className="fw-bolder">Target Budget:</span>
                  <input
                    min={0}
                    type="number"
                    className=" p-1 ms-1"
                    value={props.quoteForm.target_budget}
                    onChange={(e: any) =>
                      props.setQuoteForm({
                        ...props.quoteForm,
                        target_budget: e.target.value,
                      })
                    }
                    style={{ borderRadius: "5px", border: "1px solid #DEE2E6" }}
                  />
                </p>
                <p className="text-secondary  lh-1">
                  <span className="fw-bolder">Quote Budget: </span>
                  {formatNumber(props.totalQuote)}
                </p>
                <p className="text-secondary  lh-1">
                  <span className="fw-bolder">Difference:</span>{" "}
                  <span className={`${Color(totalDifference())}`}>
                    {formatNumber(totalDifference())}
                  </span>
                </p>
              </section>
            </div>
            <div className="col-3" style={{ width: "420px" }}>
              <section>
                <p className="text-secondary  lh-1 d-flex fle-row">
                  <span className="fw-bolder me-2">Project: </span>
                  {props.projectDataId?.name === undefined ? (
                    <div className="d-flex fle-row">
                      <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
                    </div>
                  ) : (
                    props.projectDataId?.name
                  )}
                </p>
                <p className="text-secondary  lh-1 d-flex fle-row">
                  <span className="fw-bolder me-2">Job: </span>
                  {props.jobDataById?.name === undefined ? (
                    <div className="d-flex fle-row">
                      <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
                    </div>
                  ) : (
                    props.jobDataById?.name
                  )}
                </p>

                <p
                  className="text-secondary  lh-1"
                  style={{ position: "relative", width: "185px" }}
                >
                  <span className="fw-bolder">Job type: </span>Film video
                  <i
                    className="bi bi-camera-video-fill p-0 ms-2  fs-2 "
                    style={{ position: "absolute", top: "-7px", right: "-7px" }}
                  />
                </p>
              </section>
            </div>
            <div className="col-3" style={{ width: "420px" }}>
              <section>
                <p className="text-secondary  lh-1">
                  <span className="fw-bolder">Quote Number:</span>
                  <input
                    type="number"
                    className=" p-1 ms-1 "
                    value={props.quoteForm.quoteNumber}
                    min={0}
                    onChange={(e: any) =>
                      props.setQuoteForm({
                        ...props.quoteForm,
                        quoteNumber: e.target.value,
                      })
                    }
                    style={{
                      width: "190px",
                      borderRadius: "5px",
                      border: "1px solid #DEE2E6",
                    }}
                  />
                </p>
                <p className="text-secondary  lh-1">
                  <span className="fw-bolder">Date: </span>
                  {now}
                </p>
              </section>
            </div>
            <div className="col-3 " style={{ width: "420px" }}>
              <section>
                <div className="d-flex">
                  <div>
                    <p className="text-secondary  lh-1 ">
                      <span className="fw-bolder">Quote due date:</span>
                    </p>
                  </div>

                  <div style={{ width: "10rem", marginTop: "-1rem" }}>
                    <SmallInputDate
                      placeholder="Please Select"
                      //  className='form-control mb-3'
                      onChange={(e: any) =>
                        props.setQuoteForm({
                          ...props.quoteForm,
                          due_date: moment(e.value).format("MM-DD-YYYY"),
                        })
                      }
                      value={props.quoteForm.due_date}
                    />
                    {props.errorFormularyDue_date && (
                      <div style={{ marginBottom: "15px" }}>
                        <label style={{ fontSize: "12px", width: "450px" }}>
                          <span className=" validation">
                            You must select a quote date
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex">
                  <div>
                    <p className="text-secondary">
                      <span className="fw-bolder">Expiration date:</span>
                    </p>
                  </div>

                  <div style={{ width: "10rem", marginTop: "-0.5rem" }}>
                    <SmallInputDate />
                  </div>
                </div>
              </section>
            </div>
            <div>
              <div style={{ marginBottom: "-15px" }}>
                <TextArea
                  className=""
                  placeHolder="Quote description"
                  title=""
                  onChange={(value) =>
                    props.setQuoteForm({
                      ...props.quoteForm,
                      description: value,
                    })
                  }
                  value={props.quoteForm.description}
                  errorMessage={
                    props.errorFormularyDescription
                      ? " You must enter a quote description"
                      : ""
                  }
                />
              </div>
              {props.errorFormularyDescription && (
                <div style={{ marginBottom: "15px" }}>
                  <label style={{ fontSize: "12px", width: "450px" }}>
                    <span className=" validation">
                      You must enter a quote description
                    </span>
                  </label>
                </div>
              )}

              <p
                className="text-primary text-decoration-underline cursor-pointer"
                style={{ marginTop: "-10px", fontSize: "14px", width: "10rem" }}
                onClick={hangleClientBrief}
              >
                REVIEW CLIENT BRIEF
              </p>
            </div>
          </>
        )}
        {!formControl && (
          <div className="d-flex">
            <div className="me-3">
              <p className="text-secondary  lh-1">
                <span className="fw-bolder">Target Budget:</span>
                <input
                  min={0}
                  type="number"
                  className=" p-1 ms-1"
                  value={props.quoteForm.target_budget}
                  onChange={(value) =>
                    props.setQuoteForm({
                      ...props.quoteForm,
                      target_budget: value,
                    })
                  }
                  style={{ borderRadius: "5px", border: "1px solid #DEE2E6" }}
                />
              </p>
            </div>
            <div className="me-3 mt-2">
              <p className="text-secondary  lh-1">
                <span className="fw-bolder">Quote Budget: </span>$
                {formatNumber(props.totalQuote)}
              </p>
            </div>
            <div className="mt-2">
              <p className="text-secondary  lh-1">
                <span className="fw-bolder">Difference:</span>{" "}
                <span className={`${Color(totalDifference())}`}>
                  {formatNumber(totalDifference())}
                </span>
              </p>
            </div>
          </div>
        )}

        <div className="col">
          <div className="row">
            <div className="col-6 text-gray">
              <div className="d-flex flex-wrap">
                <div className="">
                  <small>RATE CARD</small>
                  {/* {props.typeRateCards === "system" ? ( */}
                  <p>{props.getRates.name}</p>
                  {/* ) : (
                    <p className="text-secondary ">
                      {client.title +
                        tab +
                        client.first_name +
                        tab +
                        client.last_name}
                    </p>
                  )} */}
                </div>

                <div className="ms-3 me-4">
                  <button
                    type="button"
                    className="btn btn-light-gray text-black btn-sm w-100"
                    style={{
                      marginTop: "1.7rem",
                      height: "18px",
                      fontSize: "11px",
                      width: "80px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingBottom: "10px",
                      paddingTop: "1px",
                    }}
                    onClick={handleModalRates}
                  >
                    CHANGE
                  </button>
                </div>

                <div
                  className="cursor-pointer me-4 mt-4"
                  onClick={addNewHeader}
                >
                  <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                  <small className=" text-primary ms-2"> ADD NEW HEADER</small>
                </div>
                <div className="cursor-pointer mt-4">
                  <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                  <small className=" text-primary ms-2">
                    ADD NEW JOB MILESTONES AS HEADERS
                  </small>
                </div>
              </div>
            </div>

            <div className="col-6 text-gray  p-0 m-0 ">
              <div className="d-flex flex-wrap">
                <div className="ms-auto">
                  <div style={{ marginTop: "-6px", marginLeft: "18px" }}>
                    <Checkbox
                      title="Match target budget"
                      value={false}
                      onChange={(value) => {}}
                    />
                  </div>
                  <div style={{ marginTop: "-18px" }}>
                    <button
                      type="button"
                      className="btn btn-primary text-white btn-md w-100 mt-3"
                      onClick={props.ActiveModalDiscount}
                    >
                      APPLY GLOBAL DISCOUNT
                    </button>
                  </div>
                </div>

                <div className="ms-3 me-4 ">
                  <button
                    type="button"
                    className="btn btn-primary text-white btn-md w-100 mt-3"
                    onClick={hangleEditColumns}
                  >
                    EDIT COLUMNS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <TreeTable
        setView={props.setView}
        ItemData={props.ItemData}
        QuoteData={props.QuoteData}
        quoteForm={props.quoteForm}
        setQuoteForm={props.setQuoteForm}
        getHeader={props.getHeader}
        setGetHeader={props.setGetHeader}
        getSubHeader={props.getSubHeader}
        setSubGetHeader={props.setSubGetHeader}
        quantity={props.quantity}
        setQuantity={props.setQuantity}
        rate={props.rate}
        setRate={props.setRate}
        units_number={props.units_number}
        setUnits_number={props.setUnits_number}
        units_type={props.units_type}
        setUnits_type={props.setUnits_type}
        getRates={props.getRates}
        formControl={formControl}
        setformControl={setformControl}
        addItems={props.addItems}
        setAddItems={props.setAddItems}
        setCountHeader={props.setCountHeader}
        specificRatesbyItemId={props.specificRatesbyItemId}
        setGetIdItem={props.setGetIdItem}
        getIdItemRate={props.getIdItemRate}
        getIdItem={props.getIdItem}
        setModalAssignAsset={props.setModalAssignAsset}
        setViewModal={props.setViewModal}
        indexSelected={props.indexSelected}
        setIndexSelected={props.setIndexSelected}
        deleteItemsColumn={props.deleteItemsColumn}
        setGetIdItemForAssets={props.setGetIdItemForAssets}
        totalQuote={props.totalQuote}
        setTotalQuote={props.setTotalQuote}
        totalTax={props.totalTax}
        setTotaltax={props.setTotaltax}
        totalAgency_fee={props.totalAgency_fee}
        setTotalAgency_fee={props.setTotalAgency_fee}
      />
    </div>
  );
};

export default FormQuote;
