import clsx from 'clsx';
import { EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

function ProjectsSwiper() {
  return (
    <div className="card">
      <div className="card-body text-primary">

        <section className="w-100 d-flex justify-content-center mb-3">
          <div>Project Position</div>
        </section>
        
        <div>
          <Swiper
            grabCursor
            navigation
            slidesPerView={3}
            spaceBetween={-60}
            centeredSlides={true}
            effect="coverflow"
            pagination={{ clickable: true }}
            modules={[ EffectCoverflow, Pagination ]}
            coverflowEffect={{
              rotate: 60,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
          >
            {Array.from({ length: 6 }).map((_, i) => (
              <SwiperSlide>
                {({ isActive }) => (
                  <ProjectCard isActive={isActive} i={i} />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

      </div>
    </div>
  )
}

function ProjectCard({ isActive, i }: { isActive: boolean, i: any }) {
  return (
    <div className={clsx("card bg-tertiary mb-5 trans-opacity", !isActive && "opacity-75")}>
      <div className="card-body">
        <h5 className="card-title text-white fs-2 fw-bold">ABC INC. {i}</h5>
        <h6 className="card-subtitle text-light-gray fw-normal">Product Photography</h6>

        <section className="d-flex gap-2 flex-column mt-2">
          <div className="d-flex gap-2">
            <span className="d-inline-block flex-grow-1">Quote</span>
            <span className="d-inline-block text-lime fw-bold text-start">
              $1,368.00
            </span>
          </div>

          <div className="d-flex gap-2">
            <span className="d-inline-block flex-grow-1">Deadline</span>
            <span className="d-inline-block text-danger fw-bold text-start">
              10:30, 18 Mar
            </span>
          </div>

          <div className="d-flex gap-2">
            <span className="d-inline-block flex-grow-1">Team</span>
            <span className="d-inline-flex gap-1">
              {Array.from({ length: 3 }).map((_, i) => (
                <span className="align-items-center d-flex fs-6 justify-content-center avatar2 text-primary bg-contrast" style={{ width: 25, height: 25 }}>
                  {['A', 'B', 'C'][i]}
                </span>
              ))}
            </span>
          </div>

          <div className="d-flex gap-2 mt-2">
            <span className="d-inline-block flex-grow-1">Completed</span>
            <span className="d-inline-block text-start">
              100%
            </span>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ProjectsSwiper;