import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddAccesoriesModal from 'src/components/Modals/AddAccesoriesModal';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import useQuery from 'src/hooks/useQuery';
import { setAccesoryEditView, setEquipmentItem, setShowEditItem } from 'src/store/stockpile/RecursosVisualeSlice';
import { cleanAsset, setIdItem } from 'src/store/stockpile/selectAssetsSlice';
import { RootState } from 'src/store/store';
import ListTile from '../../../UI/ListTile';

const Accessories: React.FC = () => {
  const ASSET: any = useSelector((state: RootState) => state.selectAssetsSlice.AccesoriesSelected);
  const history = useHistory();
  const dispatch = useDispatch()
  const ShowEdit: any = useSelector((state: RootState) => state.RecursosVisualeSlice.ShowEditItem);

  const [accesorieModal, setAccesorieModal] = useState<any>(false)
  const [accesorieModalOptional, setAccesorieModaloptional] = useState<any>(false)

  const [valueInpt, setValueInpt] = useState<any>('')

  useEffect(() => { console.log(ASSET);
   } ,[])

   const query = useQuery()


   const handleAsset = (id:any) => {
   // dispatch(cleanAsset())
   // props.showAssets(true)
    dispatch(setEquipmentItem(true))
    history.push(`/stockpile/${id}`)
    dispatch(setIdItem(id))
  }

  const toggleEditItem: any = (category: any) => {
    dispatch(setAccesoryEditView(true))
    if (ShowEdit) {
      query.delete('editItem');
      history.replace({ search: query.toString() })
    } else {
      query.set('editItem', category || '');
      history.push({ search: query.toString() })
    }
    dispatch(setShowEditItem((prev: any) => !prev));
  };


  return (
    <div className="container-fluid px-3">
      <div className="row p-0 py-3">
        <p className="m-0 typo-body text-muted text-uppercase pb-1">
          Mandatory Accessories
        </p>

        {
          ASSET?.mandatory?.map((item: any, index: any) => {
            return (
              <div onClick={() => {
                if (item.category == '67807598690173953') {
                  handleAsset(item._id)
                } else {
                  dispatch(setEquipmentItem(false))
                  dispatch(setIdItem(item._id))
                  dispatch(cleanAsset())
                  history.push(`/stockpile/${item._id}`)
                }
              }} className="col-6">s
                <ListTile
                  title={item?.name}
                  leadingImage={item?.picture_url && process.env.REACT_APP_IMAGES_AWS + item?.picture_url}
                />
              </div>
            )
          })
        }

      </div>
      <div style={{ maxWidth: '200px' }}>
        <SimpleButton onClick={() => { toggleEditItem() }}>
          ADD MANDATORY ACCESSORY
        </SimpleButton>
      </div>

      <div className="row p-0 py-3 border-top">
        <p className="m-0 typo-body text-muted text-uppercase pb-1">
          optional accessories
        </p>

        {
          ASSET?.optional?.map((item: any, index: any) => {
            return (
              <div className="col-6">
                <ListTile
                  title={item?.name}
                  leadingImage={process.env.REACT_APP_IMAGES_AWS + item?.picture_url}
                />
              </div>
            )
          })
        }

        <div >
          <SimpleButton onClick={() => { setAccesorieModaloptional(true) }}>
            ADD OPTIONAL ACCESSORY
          </SimpleButton>
        </div>
        <AddAccesoriesModal
          title='Add Accesories Mandatory'
          setView={setAccesorieModal}
          view={accesorieModal}
          sendData={() => { }}
          toggle={() => { }}
          setData={setValueInpt}
          data={valueInpt}
        />
        <AddAccesoriesModal
          title='Add Accesories Optional'
          setView={setAccesorieModaloptional}
          view={accesorieModalOptional}
          sendData={() => { }}
          toggle={() => { }}
          setData={setValueInpt}
          data={valueInpt}
        />
      </div>
    </div>
  );
};

export default Accessories;
