import InfoDisplay from '../../../UI/InfoDisplay';

interface Props {}

const Projects: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="primary account manager"
            texts={[
              {
                text: 'Amy Hail',
              },
            ]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="secondary account manager"
            texts={[
              {
                text: 'James Girvan',
              },
            ]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="current projects"
            texts={[
              {
                text: 'Alps Electric Corporate Video 2021',
                isUnderline: true,
              },
            ]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="current proposals"
            texts={[
              {
                text: 'Alps Electric Facebook & Instagram Ad',
                isUnderline: true,
              },
              {
                text: 'Alps Electric Corporate Video 2022',
                isUnderline: true,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Projects;
