function TasksTracking() {
   return (
    <div className="card">
      <header className="card-header bg-primary">
        RT Global Tasks Tracking
      </header>

      <main className="card-body d-flex gap-2 flex-column">
        
        {Array.from({ length: 4 }).map((_, i) => (
          <div className="p-2 border border-1 border-primary rounded">
            <div className="mt-1 d-flex gap-4">
              <span style={{ fontWeight: 600 }} className="flex-grow-1 text-gray d-inline-block">Project</span>
              <span style={{ fontWeight: 400 }} className="text-start text-primary d-inline-block">Product Photography</span>
            </div>

            <div className="mt-1 d-flex gap-4">
              <span style={{ fontWeight: 600 }} className="flex-grow-1 text-gray d-inline-block">Task</span>
              <span style={{ fontWeight: 400 }} className="text-start text-primary d-inline-block">Light Setup</span>
            </div>

            <div className="mt-1 d-flex gap-4">
              <span style={{ fontWeight: 600 }} className="flex-grow-1 text-gray d-inline-block">Assign</span>
              <span style={{ fontWeight: 400 }} className="text-start text-primary d-inline-block">Mr. X</span>
            </div>

            <div className="mt-1 d-flex gap-4">
              <span style={{ fontWeight: 600 }} className="flex-grow-1 text-gray d-inline-block">Status</span>
              <span style={{ fontWeight: 400 }} className="text-start text-primary d-inline-block">Pending</span>
            </div>
          </div>
        ))}

      </main>
    </div>
  ) 
}

export default TasksTracking;