function ProjectsProgress() {
  return (
    <div className="card">
      <div className="card-body text-primary">

        {/* HEADER AND FILTERS */}
        <section className="d-flex gap-2 flex-wrap">
          {/* DATE */}
          <div>
            Today, 19 Jul 2022
          </div>

          {/* TITLE */}
          <h6 className="text-center flex-grow-1 fs-4 fw-bold">
            Projects Progress
          </h6>

          {/* FILTERS */}
          <div className="d-flex gap-2">
            <span className="rounded border d-flex align-items-center justify-content-center px-2">
              <i className="bi bi-search"></i>
            </span>
            <span className="rounded border d-flex align-items-center justify-content-center px-2">Filter</span>
            <span className="rounded border d-flex align-items-center justify-content-center px-2">Month</span>
          </div>
        </section>

        {/* TABLE */}
        <section className="mt-2 table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr className="text-primary typo-body">
                <th scope="col">Project Status</th>
                <th scope="col">Phase</th>
                <th scope="col">Members</th>
                <th scope="col">Status</th>
                <th scope="col">Completion</th>
                <th scope="col">Deadline</th>
              </tr>
            </thead>

            <tbody>
              {Array.from({ length: 3 }).map((_, i) => (
                <tr
                  key={i}
                  className={`text-primary typo-body`}
                >
                  <td className="align-middle">
                    Client
                  </td>

                  <td className="align-middle">ABC Inc</td>
                  <td className="align-middle">
                    <span className="d-inline-flex gap-1">
                      {Array.from({ length: 3 }).map((_, i) => (
                        <span className="align-items-center d-flex fs-6 justify-content-center avatar2 text-primary bg-contrast" style={{ width: 25, height: 25 }}>
                          {['A', 'B', 'C'][i]}
                        </span>
                      ))}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span style={{ height: 8, width: 8, backgroundColor: '#e39140' }} className="d-inline-block rounded-circle me-1"></span>
                    Pending
                  </td>
                  <td className="align-middle">
                    <div className="progress" style={{ height: 4 }}>
                      <div className="progress-bar" role="progressbar" style={{ width: '75%', backgroundColor: "#e39140" }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                  </td>
                  <td className="align-middle">
                    10:30, 18 Mar
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

      </div>
    </div>
  )
}

export default ProjectsProgress;