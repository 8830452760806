import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SmallSelect from "../../../UI/forms/SmallSelect";
import { AxiosResponse } from "axios";
import { ApiError } from "../../../../models";
import { UploadError } from "../../../../util";
import { factoryService } from "../../../../services/factory";
import update from "immutability-helper";
import {
  CreateQuote,
  getAllJobsTypes,
  getAllQuotes,
  getJobsId,
  getQuotesId,
  getRatequoteVersion,
  GetSpecificRatesbyItem_Id,
  getJobById,
  GetQuoteVersion,
  GetTermsAndConditions,
} from "../../../../store/factory/factoryJobSlice";
import {
  getAllItems,
  getAssetForItem,
} from "../../../../store/stockpile/stockpileSlice";
import { RootState } from "../../../../store/store";
import FormQuote from "./FormQuote";
import ModalsQuote from "./modal/ModalsQuote";
import ModalsQuoteTwo from "./modals/ModalsQuoteTwo ";
import { v4 as uuidv4 } from "uuid";
import { getProjectId } from "../../../../store/factory/factorySlice";
import { useToast } from "../../../../hooks/useToast";
import { useParams, useHistory } from "react-router-dom";
import GlobalDiscount from "./modal/applyGlobalDiscount/GlobalDiscount";
import { RateCardService } from "../../../../services/rates";
import AddRateCardModal from "../../../Modals/AddRateCardModal";
import useRates from "../../../../hooks/useRates";
import QuoteEmailModal from "../../../Modals/Factory/Quotes/QuoteEmailModal";
import ModalBrief from "./modal/modalBrief/ModalBrief";
import { Col, Row } from "reactstrap";
import SelectInput from "../../../UI/forms/Select";
import SelectUnitQuote from "../../../UI/forms/SelectUnitQuote";
import { setEditQuote } from "../../../../store/factory/factorySetTableQuote";

interface Props {
  setActiveControlView?: any;
  selectProject?: any;
  selectJob?: any;
  setSelectProject?: any;
}

export interface RatesQuote {
  rate_id: string;
  rate_version: string;
  name: string;
}

const CreateQuoteForm: React.FC<Props> = (props) => {
  const { idproject, idjob, idquote }: any = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [countHeader, setCountHeader] = useState<number>(1);
  const [view, setView] = useState<boolean>(false);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [viewBrief, setViewBrief] = useState<boolean>(false);
  const [viewCreateRate, setViewCreateRate] = useState<boolean>(false);
  const [savingRate, setSavingRate] = useState<boolean>(false);
  const [preview, setPreView] = useState<boolean>(false);
  const [editColumns, setEditColumns] = useState<boolean>(false);
  const [modalQuotes, setModalQuotes] = useState<boolean>(false);
  const [modalNewQuotes, setModalNewQuotes] = useState<boolean>(false);
  const [modalAssignAsset, setModalAssignAsset] = useState<boolean>(false);
  const [modalBrief, setModalBrief] = useState<boolean>(false);
  const [modalcreateQuote, setModalcreateQuote] = useState<boolean>(false);
  const [modalEditVersion, setModalEditVersion] = useState<boolean>(false);
  const [addItems, setAddItems] = useState<boolean>(false);
  const [viewRates, setViewRates] = useState<boolean>(false);
  const [getItemSelect, setgetItemSelect] = useState([]);
  const [getHeader, setGetHeader] = useState(null);
  const [getSubHeader, setSubGetHeader] = useState(null);
  const [quantity, setQuantity] = useState<any>("");
  const [getIdItemRate, setGetIdItemRate] = useState<any>([]);
  const [getRateInfo, seGetRateInfo] = useState<any>({});
  const [rate, setRate] = useState(0);
  const [units_number, setUnits_number] = useState(0);
  const [indexSelected, setIndexSelected] = useState(0);
  const [getItemByRateVersion, setGetItemByRateVersion] = useState<any>([]);
  const [errorFormulary, setErrorFormulary] = useState<boolean>(false);
  const [getIdItemForAssets, setGetIdItemForAssets] = useState<any>("");
  const [selectProject, setSelectProject] = useState<any>(idproject);
  const [selectJob, setSelectJob] = useState<any>(idjob);
  const [viewModalDiscount, setViewModalDiscount] = useState<boolean>(false);
  const [is_new_version, setIs_new_version] = useState<boolean>(false);
  const [errorFormularyName, setErrorFormularyName] = useState<boolean>(false);
  const [errorFormularyDue_date, setErrorFormularyDue_date] =
    useState<boolean>(false);
  const [errorFormularyEndDate, setErrorFormularyEndDate] =
    useState<boolean>(false);
  const [errorFormularyTerm, setErrorFormularyTerm] = useState<boolean>(false);
  const [errorFormularyDescription, setErrorFormularyDescription] =
    useState<boolean>(false);
  const [IsOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const [secondMessage, setSecoundMessage] = useState<boolean>(false);
  const [closeMessage, setCloseMessage] = useState<boolean>(false);
  const [totalQuote, setTotalQuote] = useState<number>(0);
  const [totalTax, setTotaltax] = useState<number>(0);
  const [totalAgency_fee, setTotalAgency_fee] = useState<number>(0);

  const [units_type, setUnits_type] = useState({
    months: "months",
    days: "days",
  });
  const [getRates, setGetRates] = useState<RatesQuote>({
    rate_id: "178585002051634218",
    rate_version: "178585002236183595",
    name: "Standard Rate Card",
  });
  const [getIdItem, setGetIdItem] = useState<any>("");
  const [typeRateCards, setTypeRateCards] = useState<any>("system");
  const [quoteForm, setQuoteForm] = useState<any>({
    name: "",
    description: "",
    job: selectJob,
    quoteNumber: "",
    due_date: "",
    term: "",
    target_budget: "",
    headers: [
      {
        id: uuidv4(),
        name: `Header ${countHeader}`,
        assigned_to: null,
        quantity: 0,
        rate: 0,
        units_number: 0,
        units_type: "",
        level: "1",
        elements: null,
        type: `Header ${countHeader}`,
      },
    ],
  });

  // ----------------change value array from redux----------------

  // const QuoteValue: any = useSelector(
  //   (state: RootState) => state.factorySetTableQuote
  // );

  // console.log("QuoteValue", QuoteValue);

  // useEffect(() => {
  //   dispatch(setEditQuote(quoteForm.headers));
  // }, [quoteForm]);

  // --------------------------------------------------------------

  const [samequoteForm, setSameQuoteForm] = useState<any>({
    headers: [],
  });

  const toast = useToast();

  const newQuoteForm = quoteForm?.headers
    .filter((fil: any) => fil.type !== "sub header")
    .map((i: any) => {
      let newArray = {
        id: i.id,
        name: i.name,
        elements: i.elements,
        assigned_to: i.assigned_to,
      };

      return newArray;
    });

  const arrayToTree = (arr: any, assigned_to = null) =>
    arr
      .filter((item: any) => item.assigned_to === assigned_to)
      .map((child: any, index: number) => ({
        ...child,

        elements: arrayToTree(quoteForm.headers, child.id).map((fil: any) => {
          let newArray = {
            quantity: Number(fil.quantity),
            rate: Number(fil.rate),
            units_number: Number(fil.units_number),
            units_type: fil.units_type,
            item: fil.items?._id,
          };

          return newArray;
        }),

        sub_headers: quoteForm.headers
          .filter((fil: any) => fil.headerassigned_to === child.id)
          .map((ma: any) => {
            let newArray = {
              id: ma.id,
              name: ma.name,
              elements: quoteForm.headers
                .filter((fil: any) => fil.assigned_to === ma.id)
                .map((it: any) => {
                  let newArray = {
                    quantity: Number(it.quantity),
                    rate: Number(it.rate),
                    units_number: Number(it.units_number),
                    units_type: it.units_type,
                    item: it.items?._id,
                  };
                  return newArray;
                }),
            };
            return newArray;
          }),
      }));

  const newSend = {
    name: quoteForm.name,
    description: quoteForm.description,
    job: quoteForm.job,
    due_date: quoteForm.due_date,
    term: quoteForm.term,
    // is_new_version: is_new_version,
    is_new_version: true,
    target_budget: Number(quoteForm.target_budget),
    headers: arrayToTree(newQuoteForm),
  };

  // console.log("newSend", newSend);
  // const Json = JSON.stringify(newSend);
  // console.log("Json", Json);

  const { rate_id, rate_version } = getRates;

  const ProjectData = useSelector((state: RootState) => state.factorySlice);

  const { projectDataId } = ProjectData;

  const getallItems = useSelector((state: RootState) => state.stockpileSlice);

  const {
    rateQuoteVersion,
    QuoteData,
    jobsTypeData,
    message,
    loading: loadingQuote,
    jobDataById,
    QuotesId,
    QuoteVersion,
    Term_conditions,
  } = useSelector((state: RootState) => state.getJobsTypeSlice);

  const { specificRatesbyItemId } = useSelector(
    (state: RootState) => state.getJobsTypeSlice
  );

  const getDataId = () => {
    dispatch(getProjectId(selectProject));
    dispatch(getJobById(selectJob));
    dispatch(GetTermsAndConditions("quote"));
  };
  useEffect(() => {
    getDataId();
  }, [selectProject]);

  const { ItemData, loading } = getallItems;

  const getData = () => {
    dispatch(getAllItems());
    dispatch(getAllQuotes());
    dispatch(getQuotesId(idquote));
    getAllJobsTypes();
  };

  const getInfoItemRate = useCallback(() => {
    const option = getRateInfo[getIdItem];

    const result =
      option && option[quoteForm?.headers[indexSelected]?.units_type];

    if (result) {
      setQuoteForm((prev: any) =>
        update(prev, {
          headers: {
            [indexSelected]: { rate: { $set: result } },
          },
        })
      );
    } else {
      return null;
    }
  }, [
    getRateInfo,
    quoteForm?.headers,
    quoteForm?.headers[indexSelected]?.units_type.length,
    quoteForm?.headers[indexSelected]?.rates,
    quoteForm?.headers.length,
  ]);

  useEffect(() => {
    getInfoItemRate();
  }, [
    quoteForm?.headers[indexSelected]?.units_type,
    quoteForm?.headers.length,
    quoteForm?.headers[indexSelected]?.rates,
  ]);

  useEffect(() => {
    const getInfo = specificRatesbyItemId?.map((items: any) => items.rates);

    seGetRateInfo((prev: any) =>
      update(prev, { $merge: { [getIdItem]: getInfo?.length - 1 } })
    );
  }, [quoteForm?.headers, getRateInfo, specificRatesbyItemId, loading]);

  const getDataRateById = () => {
    if (rate_id !== "" && getIdItem !== "") {
      dispatch(GetSpecificRatesbyItem_Id({ rate_id, getIdItem }));
    }
  };

  useEffect(() => {
    getDataRateById();
  }, [getRates, getIdItem]);

  useEffect(() => {
    dispatch(getRatequoteVersion(rate_version));
  }, [getRates, idquote]);

  useEffect(() => {
    getData();
  }, []);

  const activeModalPreview = () => {
    setView(true);
    setPreView(true);
  };
  const activeModalMergeQuotes = () => {
    setViewModal(true);
    setModalQuotes(true);
  };

  const saveQuote = () => {
    setViewModal(true);
    setModalNewQuotes(true);
    // dispatch();
    addNewVersionRateCard();
    setModalcreateQuote(true);
    setCloseMessage(false);
  };

  const EditQuote = () => {
    setViewModal(true);
    setModalEditVersion(true);
    setCloseMessage(false);
    addNewVersionRateCard();
  };

  // -------------- create and edit quote -----------------------

  const ApproveQuote = () => {
    if (quoteForm.name === "") {
      setErrorFormularyName(true);
    } else if (quoteForm.term === "") {
      setErrorFormularyTerm(true);
    } else if (quoteForm.due_date === "") {
      setErrorFormularyDue_date(true);
    } else if (quoteForm.description === "") {
      setErrorFormularyDescription(true);
    } else {
      setErrorFormulary(false);
      {
        if (!idquote) {
          factoryService
            .CreateQuotes(newSend)
            .then((res) => {
              toast.success("Quote has been successfully create.");

              setCountHeader(0);
              setViewModal(false);
              history.push(
                `/factory/project/detail/${idproject ? idproject : selectProject
                }`
              );
              return;
            })
            .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
              if (err?.data?.message) {
                setErrorFormulary(false);
                toast.error("An error occurred could not update quote.");
                return;
              }
            });
        } else {
          factoryService
            .EditQuotes(idquote, newSend)
            .then((res) => {
              console.log("res", res);

              toast.success("Quote has been successfully edit.");

              setCountHeader(0);
              setViewModal(false);
              history.push(
                `/factory/project/detail/${idproject ? idproject : selectProject
                }`
              );
              return;
            })
            .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
              console.log("err", err);
              if (err?.data?.message) {
                setErrorFormulary(false);
                toast.error(err?.data?.message);
                return;
              }
            });
        }
      }
    }
  };

  useEffect(() => {
    if (quoteForm.name !== "") {
      setErrorFormularyName(false);
    }
    if (quoteForm.due_date !== "") {
      setErrorFormularyDue_date(false);
    }
    if (quoteForm.description !== "") {
      setErrorFormularyDescription(false);
    }
    if (quoteForm.term !== "") {
      setErrorFormularyTerm(false);
    }
  }, [
    quoteForm.name,
    quoteForm.due_date,
    quoteForm.description,
    quoteForm.term,
  ]);

  const deleteItemsColumn = (dataItem: any) => {
    const deleteFilter = quoteForm.headers.filter(
      (item: any) => item.id !== dataItem.id && item.assigned_to !== dataItem.id
    );

    dataItem.name === "Header 1"
      ? setCountHeader(0)
      : setCountHeader(countHeader - 1);

    if (countHeader === -1) {
      setCountHeader(0);
    }

    setQuoteForm((prev: any) =>
      update(prev, { headers: { $set: deleteFilter } })
    );
  };

  // ---------------------------Find same rate value---------------------------
  const filterDataRate = () => {
    const ItemRate = rateQuoteVersion.reduce((acc: any, current: any) => {
      return { ...acc, [current.item?._id]: current.rates };
    }, {});

    const ItemRateQuote = quoteForm.headers
      .filter((fil: any) => fil.assigned_to !== null)
      .reduce((acc: any, current1: any) => {
        return {
          ...acc,
          [current1.items?._id]: {
            [current1.units_type]: current1.rate,
          },
        };
      }, {});

    const searchRate = Object.keys(ItemRateQuote).reduce(
      (acc: any, current: any) => {
        return { ...acc, [current]: ItemRate[current] };
      },
      {}
    );
    // console.log("ItemRateQuote", ItemRateQuote);
    // console.log("searchRate", searchRate);
    // console.log("rateQuoteVersion", rateQuoteVersion);

    const unit = quoteForm.headers
      .filter((ee: any) => ee.units_type !== "")
      .map((ee: any) => ee.units_type);

    const sameObject = Object.keys(ItemRateQuote)?.map((e: any, index) => {
      if (unit) {
        return searchRate[e][unit[index]] == ItemRateQuote[e][unit[index]];
      }
    });

    const compareConfirm = sameObject.every((so: any) => so === true);

    // console.log("compareConfirm", compareConfirm);

    return compareConfirm;
  };

  const compareArray =
    quoteForm.headers.length === samequoteForm.headers.length ? true : false;

  // ----------------Get new version rate-----------------

  const modifyData = () => {
    const ItemRate = rateQuoteVersion
      .filter((ff: any) => ff.item !== null)
      .map((ac: any) => {
        return {
          item: ac.item?._id,
          rates: ac.rates,
        };
      });
    return ItemRate;
  };

  const [newCreateRateSend, setNewCreateRateSend] = useState(modifyData());

  const newDatasend = {
    rate_card_items: newCreateRateSend,
  };

  // console.log("newCreateRateSend", newCreateRateSend);
  // console.log("newDatasendddddddddddddddddddddddddddddddddddd", newDatasend);

  const NewVersionRateCard = () => {
    const ItemRate = rateQuoteVersion.reduce((acc: any, current: any) => {
      return { ...acc, [current.item?._id]: current.rates };
    }, {});

    const ItemRateQuote = quoteForm.headers
      .filter((fil: any) => fil.assigned_to !== null)
      .reduce((acc: any, current1: any) => {
        return {
          ...acc,
          [current1.items?._id]: {
            [current1.units_type]: current1.rate,
          },
        };
      }, {});

    const searchRate = Object.keys(ItemRateQuote).reduce(
      (acc: any, current: any) => {
        return { ...acc, [current]: ItemRate[current] };
      },
      {}
    );

    return searchRate;
  };

  useEffect(() => {
    NewVersionRateCard();
    setNewCreateRateSend(modifyData());
  }, [rateQuoteVersion, quoteForm.headers]);

  const addNewVersionRateCard = () => {
    const newObject = quoteForm.headers
      .filter((ff: any) => ff.assigned_to !== null)
      .map((e: any, index) => {
        const indexed = newCreateRateSend.findIndex(
          (se: any) => se.item === e.items?._id
        );
        console.log("indexed", indexed);

        setNewCreateRateSend((prev: any) =>
          update(prev, {
            [indexed]: {
              rates: {
                [e.units_type]: { $set: Number(e.rate) },
              },
            },
          })
        );
      });

    console.log("newObject", newObject);
  };

  // console.log("newCreateRateSend", newCreateRateSend);
  // console.log("newDatasend", newDatasend);

  // -----------------------create new version rate------------------------------------------

  const handleSave = async () => {
    if (newCreateRateSend) {
      RateCardService.CreateRateCardVersion(getRates.rate_id.toString(), {
        ...newDatasend,
      })
        .then((rateCard) => {
          setSavingRate(false);
          toast.success("RateCard version created successfully.");
          return;
        })

        .catch((err: AxiosResponse<ApiError>) => {
          setSavingRate(false);
          toast.error(
            Array.isArray(err.data.message)
              ? err.data.message.join("\n")
              : err.data.message
          );

          return;
        });
    }
  };

  // ------------------------------------------------------------------------------------------

  const filterItemRateVersion = useCallback(() => {
    const ItemRate = rateQuoteVersion.reduce((acc: any, current: any) => {
      return { ...acc, [current.item?._id]: current.rates };
    }, {});

    setQuoteForm((prev: any) =>
      update(prev, {
        headers: {
          $apply: (oldData) => {
            return oldData.map((item: any) => {
              return {
                ...item,
                ...(item?.units_type
                  ? { rate: ItemRate[item.items?._id][item?.units_type] }
                  : {}),
              };
            });
          },
        },
      })
    );
  }, [
    getRates,
    getIdItem,
    rateQuoteVersion,
    quoteForm?.headers[indexSelected]?.units_type,
    quoteForm?.headers[indexSelected]?.rate,
  ]);

  useEffect(() => {
    filterItemRateVersion();
  }, [
    // getRates,
    // getIdItem,
    rateQuoteVersion,
    quoteForm?.headers[indexSelected]?.units_type,
    quoteForm?.headers.length,
  ]);

  const AssetsData = useSelector(
    (state: RootState) => state.stockpileSlice.AssetData
  );

  useEffect(() => {
    dispatch(getAssetForItem(getIdItemForAssets));
  }, [getIdItemForAssets]);

  const versionQuote = QuotesId?.quote_versions?.map((e: any) => e._id);
  const idVersion = versionQuote && versionQuote[versionQuote?.length - 1];

  const getQuotesDataId = () => {
    dispatch(GetQuoteVersion(idVersion));
  };

  useEffect(() => {
    getQuotesDataId();
  }, [idVersion]);

  const flatten = QuoteVersion?.reduce((acc, curr) => {
    return [
      ...acc,
      {
        name: curr.name,
        assigned_to: null,
        quantity: 0,
        rate: 0,
        units_number: 0,
        units_type: "",
        level: "1",
        elements: null,
        type: `Header ${countHeader}`,
        id: curr._id,
      },

      ...curr.elements.map((e: any) => {
        return {
          item: e.item,
          quantity: e.quantity,
          rate: e.rate,
          units_number: e.units_number,
          units_type: e.units_type,
          assigned_to: curr._id,
        };
      }),
      ...curr.sub_headers?.reduce((acc, curr) => {
        return [
          ...acc,
          {
            name: curr.name,
            assigned_to: null,
            quantity: 0,
            rate: 0,
            units_number: 0,
            units_type: "",
            level: "1",
            elements: null,
            type: "sub header",
            id: curr._id,
          },
          ...curr.elements.map((e: any) => {
            return {
              item: e.item,
              quantity: e.quantity,
              rate: e.rate,
              units_number: e.units_number,
              units_type: e.units_type,
              assigned_to: curr._id,
            };
          }),
        ];
      }, []),
    ];
  }, []);

  // console.log("flatten", flatten);

  const itemQuote = ItemData.reduce(
    (acc: any, el: any) => ({
      ...acc,
      [el._id]: el,
    }),
    {}
  );

  const newlist = () => {
    const searchItem = flatten.map((se: any) => {
      let dataitem = {
        items: itemQuote[se.item],
        id: se.item,
        quantity: se.quantity,
        rate: se.rate,
        units_number: se.units_number,
        units_type: se.units_type,
        level: "2",
        elements: null,
        assigned_to: se.assigned_to,
      };
      if (se.item === undefined) {
        return se;
      } else {
        return dataitem;
      }
    });

    return searchItem;
  };

  useEffect(() => {
    if (idquote) {
      setQuoteForm({
        ...quoteForm,
        name: QuotesId?.name,
        description: QuotesId?.description,
        due_date: QuotesId?.due_date,
        quoteNumber: QuotesId?._id,
        headers: newlist(),
        term: QuotesId?.term?._id,
        target_budget: QuotesId?.target_budget,
      });
    }
  }, [idquote, QuotesId, QuoteVersion]);

  useEffect(() => {
    if (idquote) {
      setSameQuoteForm({ headers: newlist() });
    } else {
      setSameQuoteForm({ headers: quoteForm.headers });
    }
  }, [quoteForm.headers.length !== 0]);

  useEffect(() => {
    if (!idquote) {
      setSameQuoteForm({ headers: quoteForm.headers });
    }
  }, [quoteForm.headers]);

  const messageComming = (message: any) => {
    toast.info(message + " is coming soon!");
  };

  const ActiveModalDiscount = () => {
    setViewModalDiscount(true);
  };

  const oldArray = () => {
    setSameQuoteForm({ headers: quoteForm?.headers });
  };

  const closeModalRate = () => {
    setSavingRate(false);
  };

  const { data: rates, error } = useRates();

  // console.log("typeRateCards", typeRateCards);

  return (
    <div className="container-fluid p-0 overflow-none">
      <ModalsQuote
        view={view}
        setView={setView}
        toggle={view}
        viewRates={viewRates}
        setViewRates={setViewRates}
        ItemData={ItemData}
        getItemSelect={getItemSelect}
        setgetItemSelect={setgetItemSelect}
        quoteForm={quoteForm}
        setQuoteForm={setQuoteForm}
        getHeader={getHeader}
        getSubHeader={getSubHeader}
        quantity={quantity}
        setQuantity={setQuantity}
        rate={rate}
        setRate={setRate}
        getRates={getRates}
        setGetRates={setGetRates}
        rateQuoteVersion={rateQuoteVersion}
        preview={preview}
        setPreView={setPreView}
        addItems={addItems}
        setAddItems={setAddItems}
        setGetIdItem={setGetIdItem}
        ApproveQuote={ApproveQuote}
        projectDataId={projectDataId}
        typeRateCards={typeRateCards}
        setTypeRateCards={setTypeRateCards}
        QuotesId={QuotesId}
        Term_conditions={Term_conditions}
        totalQuote={totalQuote}
        setTotalQuote={setTotalQuote}
        totalTax={totalTax}
        setTotaltax={setTotaltax}
        totalAgency_fee={totalAgency_fee}
        setTotalAgency_fee={setTotalAgency_fee}
      />

      {viewCreateRate && (
        <AddRateCardModal
          onClose={closeModalRate}
          onSave={() => { }}
          // type={addRateCardModalType}
          type={"clients"}
          // type={typeRateCards}

          systemRateCardId={
            rates
              ? rates
                .filter((item) => !item.assigned_to && item.blocked)[0]
                ._id.toString()
              : ""
          }
          viewCreateRate={viewCreateRate}
          setViewCreateRate={setViewCreateRate}
          setViewModal={setViewModal}
          newCreateRateSend={newCreateRateSend}
          savingRate={savingRate}
          setSavingRate={setSavingRate}
          modalConfirm={modalConfirm}
          setModalConfirm={setModalConfirm}
          secondMessage={secondMessage}
          setSecoundMessage={setSecoundMessage}
          setModalNewQuotes={setModalNewQuotes}
          projectDataId={projectDataId}
        />
      )}

      <ModalsQuoteTwo
        viewModal={viewModal}
        setViewModal={setViewModal}
        toggle={viewModal}
        modalQuotes={modalQuotes}
        setModalQuotes={setModalQuotes}
        editColumns={editColumns}
        setEditColumns={setEditColumns}
        modalAssignAsset={modalAssignAsset}
        setModalAssignAsset={setModalAssignAsset}
        modalNewQuotes={modalNewQuotes}
        setModalNewQuotes={setModalNewQuotes}
        ApproveQuote={ApproveQuote}
        loadingQuote={loadingQuote}
        modalBrief={modalBrief}
        setModalBrief={setModalBrief}
        jobDataById={jobDataById}
        idquote={idquote}
        projectDataId={projectDataId}
        modalEditVersion={modalEditVersion}
        setModalEditVersion={setModalEditVersion}
        filterDataRate={filterDataRate}
        handleSave={handleSave}
        setIs_new_version={setIs_new_version}
        compareArray={compareArray}
        savingRate={savingRate}
        setSavingRate={setSavingRate}
        viewCreateRate={viewCreateRate}
        setViewCreateRate={setViewCreateRate}
        modalConfirm={modalConfirm}
        setModalConfirm={setModalConfirm}
        secondMessage={secondMessage}
        setSecoundMessage={setSecoundMessage}
        modalcreateQuote={modalcreateQuote}
        setModalcreateQuote={setModalcreateQuote}
        closeMessage={closeMessage}
        setCloseMessage={setCloseMessage}
        typeRateCards={typeRateCards}
        getRates={getRates}
      />

      <ModalBrief
        viewBrief={viewBrief}
        setViewBrief={setViewBrief}
        jobDataById={jobDataById}
        toggle={viewBrief}
      />

      <GlobalDiscount
        view={viewModalDiscount}
        setView={setViewModalDiscount}
        toggle={viewModal}
      />

      <div
        className="row card container-form-quote tree-scroll "
      // style={{
      //   height: "100vh",
      // }}
      >
        <div
          className="col-12 col-sm-12 col-md-12 col-lg-12 "
          style={{
            width: "100%",
            padding: "0",
            margin: "0",
          }}
        >
          <div className="d-flex flex-wrap p-4">
            <Col lg="4">
              <div className="d-flex align-content-start flex-wrap  gap-3  ">
                <Col lg="4">
                  <SmallSelect
                    placeholder="Blank Template"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { }}
                    value=""
                    options={[]}
                    className="SmallSelect"
                  />
                </Col>
                <Col lg="3">
                  <SmallSelect
                    placeholder="Version 1"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { }}
                    value=""
                    options={[]}
                    className="SmallSelect"
                  />
                </Col>
                <Col lg="3">
                  <SmallSelect
                    placeholder="Status"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { }}
                    value=""
                    options={[]}
                    className="SmallSelect"
                  />
                </Col>
              </div>
            </Col>
            <Col lg="8">
              <div className=" d-flex ms-auto flex-wrap gap-3 ">
              <Col style={{maxWidth: '100px'}}>
                  <div
                    onClick={() => messageComming("Compare")}
                    style={{ opacity: 0.5 }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary btn-md border"
                      style={{
                        width: "100%",
                        height: "2.1rem",
                        pointerEvents: "none",
                      }}
                    >
                      Compare
                    </button>
                  </div>
                </Col>

                <Col lg='2'>
                  <div
                    onClick={() => messageComming("Merge quotes")}
                    style={{ opacity: 0.5 }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary btn-md"
                      style={{
                        width: "100%",
                        height: "2.1rem",
                        pointerEvents: "none",
                      }}
                      onClick={activeModalMergeQuotes}
                    >
                      Merge quotes
                    </button>
                  </div>
                </Col>
                <Col style={{maxWidth: '100px'}}>
                  <button
                    type="button"
                    className="btn btn-primary btn-md"
                    style={{
                      width: "100%",
                      height: "2.1rem",
                      // pointerEvents: "none",
                    }}
                    onClick={activeModalPreview}
                  >
                    Preview
                  </button>
                </Col>
                <Col lg="2">
                  <button
                    type="button"
                    className="btn btn-primary btn-md"
                    style={{
                      width: "100%"
                      , height: "2.1rem"
                    }}
                    onClick={idquote ? EditQuote : saveQuote}
                  >
                    Save quote
                  </button>
                </Col>
                <Col lg="2">
                  <div
                    onClick={() => messageComming("Download quote")}
                    style={{ opacity: 0.5 }}
                  >
                    <button
                      type="button"
                      className="btn btn-info text-white btn-md"
                      style={{
                        width: "100%",
                        height: "2.1rem",
                        // pointerEvents: "none",
                      }}
                    // onClick={addNewVersionRateCard}
                    >
                      Download quote
                    </button>
                  </div>
                </Col>
                <Col style={{maxWidth: '120px'}}>
                  <div onClick={() => setIsOpenSendEmailModal(true)}>
                    <button
                      type="button"
                      className="btn btn-success  text-white btn-md"
                      style={{
                        width: "100%",
                        height: "2.1rem",
                      }}
                    >
                      Send quote
                    </button>
                  </div>
                </Col>
              </div>
            </Col>
          </div>
          <FormQuote
            setView={setView}
            setViewRates={setViewRates}
            ItemData={ItemData}
            selectProject={selectProject}
            selectJob={selectJob}
            QuoteData={QuoteData}
            getItemSelect={getItemSelect}
            quoteForm={quoteForm}
            setQuoteForm={setQuoteForm}
            getHeader={getHeader}
            setGetHeader={setGetHeader}
            getSubHeader={getSubHeader}
            setSubGetHeader={setSubGetHeader}
            quantity={quantity}
            setQuantity={setQuantity}
            rate={rate}
            setRate={setRate}
            units_number={units_number}
            setUnits_number={setUnits_number}
            units_type={units_type}
            setUnits_type={setUnits_type}
            getRates={getRates}
            addItems={addItems}
            setAddItems={setAddItems}
            viewModal={viewModal}
            setViewModal={setViewModal}
            setEditColumns={setEditColumns}
            countHeader={countHeader}
            setCountHeader={setCountHeader}
            specificRatesbyItemId={specificRatesbyItemId}
            getIdItem={getIdItem}
            setGetIdItem={setGetIdItem}
            getIdItemRate={getIdItemRate}
            setModalAssignAsset={setModalAssignAsset}
            projectDataId={projectDataId}
            indexSelected={indexSelected}
            setIndexSelected={setIndexSelected}
            deleteItemsColumn={deleteItemsColumn}
            typeRateCards={typeRateCards}
            setTypeRateCards={setTypeRateCards}
            errorFormulary={errorFormulary}
            setGetIdItemForAssets={setGetIdItemForAssets}
            jobDataById={jobDataById}
            modalBrief={modalBrief}
            setModalBrief={setModalBrief}
            ActiveModalDiscount={ActiveModalDiscount}
            Term_conditions={Term_conditions}
            errorFormularyName={errorFormularyName}
            errorFormularyDue_date={errorFormularyDue_date}
            errorFormularyTerm={errorFormularyTerm}
            errorFormularyDescription={errorFormularyDescription}
            setViewBrief={setViewBrief}
            totalQuote={totalQuote}
            setTotalQuote={setTotalQuote}
            totalTax={totalTax}
            setTotaltax={setTotaltax}
            totalAgency_fee={totalAgency_fee}
            setTotalAgency_fee={setTotalAgency_fee}
          />
        </div>
      </div>
      <QuoteEmailModal
        isOpen={IsOpenSendEmailModal}
        onClose={() => setIsOpenSendEmailModal((prev) => !prev)}
        width={"460px"}
      />
    </div>
  );
};

export default CreateQuoteForm;
