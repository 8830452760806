import { Bar } from 'react-chartjs-2';
import { ChartDataset, ChartOptions } from 'chart.js';
import clsx from 'clsx';

interface Props {
  title?: string;
  labels: string[];
  data: number[];

  fullWidth?: boolean;
  chartOptions?: ChartOptions<'bar'>;
  datasetProps?: Omit<ChartDataset<"bar", number[]>, 'data'>;
}

const BarChart: React.FC<Props> = (props) => {
  return (
    <div className="pb-3">
      {props.title && (
        <p className="m-0 typo-body text-muted text-uppercase pb-3">
          {props.title}
        </p>
      )}

      <div className={clsx("chart", props.fullWidth && "w-100")}>
        <Bar
          options={{
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            ...(props.chartOptions || {}),
          }}
          data={{
            labels: props.labels,
            datasets: [
              {
                label: '',
                data: props.data,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(255, 205, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(201, 203, 207, 0.2)',
                ],
                borderColor: [
                  'rgb(255, 99, 132)',
                  'rgb(255, 159, 64)',
                  'rgb(255, 205, 86)',
                  'rgb(75, 192, 192)',
                  'rgb(54, 162, 235)',
                  'rgb(153, 102, 255)',
                  'rgb(201, 203, 207)',
                ],
                borderWidth: 1,

                ...(props.datasetProps || {}),
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default BarChart;
