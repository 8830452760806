import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { RootState } from 'src/store/store';
import { useToast } from 'src/hooks/useToast';
import { ROImageMimeTypesArray } from 'src/util';
import { TabsContent } from '../../../AddContact';
import { toBase64 } from 'src/util/converToBase64';
import { useFieldArray } from 'src/hooks/useFieldArray';
import Checkbox from 'src/components/UI/forms/Checkbox';
import SelectInput from 'src/components/UI/forms/Select';
import InputDate from 'src/components/UI/forms/InputDate';
import InputSelect from 'src/components/UI/forms/InputSelect';
import ButtonFiles from 'src/components/UI/buttons/ButtonFile';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import FreelancePersonalValidation, { FreelanceData } from './validation';
import { createContactForm } from 'src/store/blackbook/createContactSlice';
import InputFloatingLabel from 'src/components/UI/forms/InputFloatingLabel';
import SocialAndCommForm from '../../SocialAndComm';

type SocialKeys = "facebook" | "twitter" | "instagram" | "linkedin";
type CommunicationKeys = "discord" | "website" | "skype";

const FreelancePersonalInformation = forwardRef((_props, ref: TabsContent['ref']) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);

  const [showDOB, setShowDOB] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showNickname, setShowNickname] = useState(false);
  const [socials, setSocials] = useState<{ type: SocialKeys }[]>([]);
  const [communication, setCommunication] = useState<{ type: CommunicationKeys }[]>([]);

  const formik = useFormik<FreelanceData>({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      phone_numbers: [{ type: 'mobile', number: '', primary: true }],
      contact_since: data.contact_since || '',
      ...(data.data || {}),
    },
    validationSchema: FreelancePersonalValidation,
    onSubmit: () => {},
  });

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      try {
        const errors = await formik.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }

        return true;
      } catch {
        return false;
      }
    }
  }));

  useEffect(() => {
    if (data.initialValues !== undefined && data.appliedInitialValues === false) {
      formik.setValues(data.initialValues || {});
      /* @ts-ignore */
      dispatch(createContactForm.setField('appliedInitialValues', true, ''));
    }

    for (const key in (data.data?.social || data?.initialValues?.social || {})) {
      if ((data?.data?.social?.[key] || data?.initialValues?.social?.[key]) && socials.length === 0) {
        setSocials(socials => [...socials, { type: key as SocialKeys }]);
      }
    }

    for (const key in (data.data?.communication || data.initialValues?.communication || {})) {
      if ((data.data?.communication?.[key] || data.initialValues?.communication?.[key]) && communication.length === 0) {
        setCommunication(communication => [...communication, { type: key as CommunicationKeys }]);
      }
    }
  
    if (data.data?.address || data.initialValues?.address) {
      setShowAddress(true);
    }

    if (data.data?.nickname || data.initialValues?.nickname) {
      setShowNickname(true);
    }

    if (data.data?.date_of_birth || data.initialValues?.date_of_birth) {
      setShowDOB(true);
    }
  }, [communication.length, data, socials.length])

  const handleChange = (fieldName: string, value: any, path = 'data') => {
    formik.setFieldValue(fieldName, value);

    /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
    if (value instanceof File) {
      toBase64(value as File).then(base64 => {
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, base64 as string, 'data' ));
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, value.name, 'files' ));
      }).catch(err => {
        console.log(err);
      })

      return;
    }

    /* @ts-ignore */
    dispatch(createContactForm.setField(fieldName, value, path));
  }

  const phoneNumbers = useFieldArray<FreelanceData, Required<FreelanceData>['phone_numbers'][number]>(formik, 'phone_numbers', handleChange);

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="row">
        <div className="col">
          <InputFloatingLabel
            disabled
            value=""
            placeHolder="PFP User ID"
            onChange={() => {}}
          />
        </div>
        <div className="col">
          <ButtonFiles
            label="Profile picture"
            error={formik.errors.logo}
            selectedName={data.files?.logo}
            accept={ROImageMimeTypesArray.join(',')}
            onChange={(val) => handleChange('logo', val.currentTarget.files?.item(0))}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col">
          <InputSelect
            select={{
              placeholder: 'Title',
              value: `${formik.values.title}`,
              onChange: (value: string) => { handleChange('title', value) },
              options: [
                { value: 'Mr', label: 'Mr' },
                { value: 'Ms', label: 'Ms' },
                { value: 'Mrs', label: 'Mrs' },
                { value: 'Miss', label: 'Miss' },
              ],
            }}
            input={{
              isRequired: true,
              placeHolder: 'First name',
              value: formik.values.first_name,
              errorMessage: formik.errors.first_name,
              onChange: (val) => { handleChange('first_name', val) },
            }}
          />
        </div>

        <div className="col">
          <InputFloatingLabel
            placeHolder="Middle name"
            value={formik.values.middle_name}
            errorMessage={formik.errors.middle_name}
            onChange={(val) => handleChange('middle_name', val)}
          />
        </div>

        <div className="col">
          <InputFloatingLabel
            isRequired
            placeHolder="Last name"
            value={formik.values.last_name}
            errorMessage={formik.errors.last_name}
            onChange={(val) => handleChange('last_name', val)}
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-6">
          <InputFloatingLabel
            isRequired
            placeHolder="Email"
            value={formik.values.email}
            errorMessage={formik.errors.email}
            onChange={(val) => handleChange('email', val)}
          />
        </div>

        <div className="col-6">
          {(formik.values.phone_numbers || []).map((phone, index) => (
            <div className="mb-2">
              <InputSelect
                select={{
                  value: phone.type,
                  onChange: (value: string) => {
                    phoneNumbers.set(index, { ...phone, type: value });
                  },
                  options: [
                    { value: 'mobile', label: 'Mobile' },
                    { value: 'home', label: 'Home' },
                    { value: 'office', label: 'Office' },
                  ],
                }}
                input={{
                  isRequired: true,
                  placeHolder: 'Phone',
                  value: phone.number,
                  /* @ts-ignore */
                  errorMessage: formik.errors.phone_numbers?.[index]?.number,
                  onChange: (val) => {
                    phoneNumbers.set(index, { ...phone, number: val });
                  },
                }}
              />

              <div className="row" style={{ marginTop: -10 }}>
                <div className="col-3">
                  <SimpleButton
                    onClick={() => {
                      if ((formik.values.phone_numbers?.length || 0) > index + 1) {
                        phoneNumbers.remove(index);
                      } else {
                        phoneNumbers.add({ type: 'mobile', number: '' });
                      }
                    }}
                    icon={(formik.values.phone_numbers?.length || 0) > index + 1 ? 'bi-dash-circle-fill' : 'bi-plus-circle-fill'}
                  >
                    {(formik.values.phone_numbers?.length || 0) > index + 1 ? 'REMOVE' : 'ADD PHONE'}
                  </SimpleButton>
                </div>
                <div className="col ps-0 pe-3">
                  <Checkbox
                    title="Primary phone"
                    value={phone.primary || false}
                    onChange={(value) => {
                      phoneNumbers.set(index, { ...phone, primary: value });
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-6">
          <SelectInput
            onChange={(e: string) => handleChange('gender', e)}
            options={[
              { label: 'Male', value: 'Male' },
              { label: 'Female', value: 'Female' },
              { label: 'Other', value: 'Other' }
            ]}
            value={formik.values.gender}
            placeholder="Gender"
          />
        </div>

        <div className="col-6">
          <InputFloatingLabel
            placeHolder="Nationality"
            value={formik.values.nationality}
            errorMessage={formik.errors.nationality}
            onChange={(val) => handleChange('nationality', val)}
          />
        </div>
      </div>

      <SocialAndCommForm
        formik={formik}
        socials={socials}
        communication={communication}

        setSocials={setSocials}
        handleChange={handleChange}
        setCommunication={setCommunication}
      />

      <div className="row mt-2">
        <div className="col-6">
          {!showNickname ? (
            <SimpleButton
              onClick={() => setShowNickname(true)}
            >
              ADD NICKNAME
            </SimpleButton>
          ) : (
            <InputFloatingLabel
              placeHolder="Nickname"
              value={formik.values.nickname}
              errorMessage={formik.errors.nickname}
              onChange={(val) => handleChange('nickname', val)}
            />
          )}
        </div>

        <div className="col-6">
          {!showAddress ? (
            <SimpleButton
              onClick={() => setShowAddress(true)}
            >
              ADD ADDRESS
            </SimpleButton>
          ) : (
            <InputFloatingLabel
              placeHolder="Address"
              value={formik.values.address}
              errorMessage={formik.errors.address}
              onChange={(val) => handleChange('address', val)}
            />
          )}
        </div>
      </div>

      <div className="row mt-2 mb-4">
        <div className="col-6">
          {!showDOB ? (
            <SimpleButton
              onClick={() => setShowDOB(true)}
            >
              ADD DATE OF BIRTH
            </SimpleButton>
          ) : (
            <>
              <p className="typo-body-important m-1 opacity-75 p-0 text-muted text-opacity-50">DATE OF BIRTH:</p>
              <InputDate
                max={new Date()}
                value={formik.values.date_of_birth}
                onChange={(e: any) => handleChange('date_of_birth', e.valueText)}
              />
            </>
          )}
        </div>

        <div className="col-6"></div>
      </div>

      <div className="row">
        <div className="col-3">
          <p className="typo-body-important m-1 opacity-75 p-0 text-muted text-opacity-50">CONTACT SINCE:</p>
          <InputDate
            max={new Date()}
            value={formik.values.contact_since}
            error={formik.errors.contact_since}
            onChange={(e: any) => handleChange('contact_since', e.valueText, '')}
          />
        </div>
      </div>
    </div>
  );
});

export default FreelancePersonalInformation;
