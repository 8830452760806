import React, {useEffect, useState} from "react";
import { Modal } from "reactstrap";
import { QuoteStatusType } from "../../../../models/QuotesModel";

interface Props {
  isOpen: boolean;
  currentStatus: QuoteStatusType;
  onClose: () => void;
  handleConfirm: (status: QuoteStatusType) => void;
}
const QuoteStatusModal: React.FC<Props> = (props) => {
  const [selectedStatus, setSelectedStatus ] = useState<QuoteStatusType>(props.currentStatus);
  const options = [
    {id: "in-review-status", label: "In Review", value: QuoteStatusType.IN_REVIEW},
    {id: "approved-status", label: "Approved", value: QuoteStatusType.APPROVED},
    {id: "feedback-status", label: "FeedBack", value: QuoteStatusType.FEEDBACK},
    {id: "rejected-status", label: "Rejected", value: QuoteStatusType.REJECTED},
  ]

  useEffect(() =>{
    setSelectedStatus(props.currentStatus);
  }
  ,[props.currentStatus]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.onClose} centered style={{maxWidth:"400px"}}>
      <div
        onClick={props.onClose}
        className="cursor-pointer pt-2"
      >
        <i className="bi bi-x-lg px-3"></i>
      </div>
      <div className="container">
        <div className="row px-4 mt-4">
          <div className="col-12 text-center">
            <h2 className="fw-bolder text-secondary typo-display">
              Change Quote status
            </h2>
          </div>
        </div>
        <div className="row px-4 mt-4">
          <div className="col-12 d-flex flex-column align-items-start">
            {options.map((option, index) => (
              <div className="form-check  my-1 border rounded-2 py-2 px-5 w-100" key={index}>
                <input
                    className="form-check-input me-2"
                    type="radio"
                    name="quote-status"
                    id={option.id}
                    value={option.value}
                    checked={selectedStatus === option.value}
                    onChange={() => setSelectedStatus(option.value)}
                />
                <label 
                    className="form-check-label d-block"  
                    htmlFor={option.id}
                    >
                    {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="row px-4 my-4 d-flex justify-content-center">
          <button
            onClick={() => props.handleConfirm(selectedStatus)}
            className={
              "btn btn-success text-white col-auto fs-6"
            }
          >
            Confirm
          </button>
        </div>

      </div>
    </Modal>
  );
};

export default QuoteStatusModal;
