import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getProjectId } from "../../../../store/factory/factorySlice";
import { RootState } from "../../../../store/store";
import InfoDisplay from "../../../UI/InfoDisplay";
import TrashIcon from "../../../../assets/icons/trash.svg";
import ModalStatus from "./modalStatus/ModalStatus";
import { updateProject } from "../../../../store/factory/factorySlice";
import { useHistory } from "react-router-dom";
import ClienBrief from "./modalBrief/ClientBrief";
import ModalBrief from "./modalBrief/ModalBrief";
import EditIcon from '../../../../assets/icons/edit.svg'
import IconButton from '../../../UI/buttons/IconButton';

interface Props {
  selectJob: any;
  jobsData: any;
  setDeleteJobModal: any;
  setjobIdDelete: any;
  loading: boolean;
  jobsDataId: any;
  selectProject: any;
  projectDataId: any;
  idParamsProject: any;
}

const tab = "\u00A0";

const ProjectDetails: React.FC<Props> = (props) => {
  const [client, setClient] = useState<any>([]);
  const [organization, setOrganization] = useState<any>([]);
  const [view, setView] = useState<boolean>(false);
  const [viewBrief, setViewBrief] = useState<boolean>(false);
  const [activeControl, setActiveControl] = useState<string>("");
  const [brief, setBrief] = useState<string>("");

  const dispatch = useDispatch();
  const history = useHistory();

  const selectData = () => {
    [props.projectDataId && props.projectDataId].map((e: any) => {
      if (e?.client) {
        setClient(e.client.entity);
      }
      if (e?.organization) {
        setOrganization(e?.organization.entity);
      }
    });
  };

  const selectedColor = (item: any) => {
    let select;
    if (item === "Approved") {
      select = "approved";
    } else if (item === "Proposal") {
      select = "proposal";
    } else if (item === "Active") {
      select = "active";
    } else if (item === "On Hold") {
      select = "onHold";
    } else if (item === "Lost") {
      select = "lost";
    } else if (item === "Complete") {
      select = "complete";
    }

    return select;
  };

  // const status = (
  //   <span className={`${selectedColor(projectDataId)}`}>
  //     {projectDataId.status}
  //   </span>
  // );

  // const { _id } = props.selectProject;

  const { updateProjectStatus } = useSelector(
    (state: RootState) => state.factorySlice
  );

  const [sendProject, setSendProject] = useState({
    name: props.projectDataId?.name,
    start_date: moment(props.projectDataId?.start_date).format("MM/DD/YYYY"),
    end_date: moment(props.projectDataId?.end_date).format("MM/DD/YYYY"),
    status: props.projectDataId?.status,
    id: props.idParamsProject,
  });

  useEffect(() => {
    setSendProject({ ...sendProject, name: props.projectDataId?.name });
  }, [sendProject.status]);

  useEffect(() => {
    setSendProject((prev) => ({
      ...prev,
      status: props.projectDataId?.status,
    }));
  }, [props.projectDataId]);

  const getNewStatus = () => {
    dispatch(updateProject(sendProject));
  };

  useEffect(() => {
    selectedColor(props.projectDataId);
    selectData();
  }, [props.projectDataId, updateProjectStatus]);

  const handleDeletedJob = (e: any, item: any) => {
    props.setDeleteJobModal(true);
    props.setjobIdDelete(item._id);
  };

  const editProject = (e, item) => {
    history.push(
      `/factory/edit/project/${props.idParamsProject}/job/${item._id}`
    );
  };

  const viewBriefCLient = (brief: any) => {
    setBrief(brief.client_brief);
    setViewBrief(true);
    console.log("brief", brief.client_brief);
  };

  return (
    <div className="container-fluid p-0 mt-4 w-100 container-project-detail">
      <ModalStatus
        view={view}
        setView={setView}
        toggle={view}
        activeControl={activeControl}
        setActiveControl={setActiveControl}
        getNewStatus={getNewStatus}
        sendProject={sendProject}
        setSendProject={setSendProject}
      />
      <ModalBrief
        view={viewBrief}
        setView={setViewBrief}
        toggle={viewBrief}
        brief={brief}
      />
      <div className="row px-4 py-2">
        <div className="col-3">
          <InfoDisplay
            title="project name"
            texts={[
              {
                text: props.projectDataId?.name,
              },
            ]}
            style={{
              color: '#8898AA'
            }}
          />
        </div>
        <div className="col-3">
          <InfoDisplay
            title="project number"
            texts={[
              {
                text: props.projectDataId?._id,
              },
            ]}
          />
        </div>
        <div className="col-3">
          <InfoDisplay
            title="organization name"
            texts={[
              {
                text: organization?.name,
                isUnderline: true,
              },
            ]}
          />
        </div>
        <div className="col-3">
          <InfoDisplay
            title="Client name"
            texts={[
              {
                text: client?.first_name + tab + client?.last_name,
                isUnderline: true,
              },
            ]}
          />
        </div>
        <div className="col-3">
          <InfoDisplay
            title="project start rate"
            texts={[
              {
                text: moment(props.projectDataId?.start_date)
                  .add(10, "days")
                  .calendar(),
              },
            ]}
          />
        </div>
        <div className="col-3">
          <InfoDisplay
            title="project due date"
            texts={[
              {
                text: moment(props.projectDataId?.end_date)
                  .add(10, "days")
                  .calendar(),
              },
            ]}
          />
        </div>
        <div className="col-3" style={{ position: "relative" }}>
          <InfoDisplay title="project status" texts={[]} />
          <button
            type="button"
            className="btn btn-light-gray text-black btn-sm "
            style={{
              marginTop: ".95rem",
              width: "70px",
              height: "18px",
              fontSize: "10px",
              position: "absolute",
              top: "10px",
              left: "84px",
              padding: "0",
            }}
            onClick={() => setView(true)}
          >
            CHANGE
          </button>
          <span
            style={{
              marginTop: ".95rem",
              width: "auto",
              height: "30px",
              fontSize: "12.5px",
              position: "absolute",
              top: "4.5px",
              left: "12.5px",
              padding: "5px",
              color: "#344469",
              textAlign: "center",
            }}
            className={`${selectedColor(
              !updateProjectStatus?.status
                ? props.projectDataId?.status
                : updateProjectStatus?.status
            )} baseBadge`}
          >
            {!updateProjectStatus?.status
              ? props.projectDataId?.status
              : updateProjectStatus?.status}
          </span>
        </div>
      </div>

      <div
        className="scrollbar-none overflow-x mt-4 m-0 w-100"
        style={{ height: "300px", width: "100vw", padding: "0" }}
      >
        {props.loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "88vw", height: "30vh" }}
          >
            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
            <span
              className="text-black
                 ms-3"
            >
              Loading...
            </span>
          </div>
        ) : (
          <table className="table table-borderless px-0">
            <thead>
              <tr className="text-primary typo-body">
                <th scope="col" className="ps-4 HeadTableFonts">
                  JOB NAME
                </th>
                <th scope="col">JOB TYPE</th>
                <th scope="col">JOB START DATE</th>
                <th scope="col">JOB DUE DATE</th>
                <th scope="col">STATUS</th>
                <th scope="col">CLIENT BRIEF</th>
              </tr>
            </thead>

            <tbody>
              {props.jobsDataId?.map((item: any, index: any) => {
                return (
                  <>
                    <tr
                      className={`${(index / 2) % 1 > 0 ? "table-primary" : ""
                        } general-text-table-factory typo-body`}
                      key={index}
                    >
                      <td className="align-middle ps-3 general-text-table-factory">
                        <div className="d-flex">
                          <img
                            className="avatar2 "
                            src="https://m.media-amazon.com/images/I/51P84188A4L._AC_SX466_.jpg"
                            alt=""
                            style={{ display: "none" }}
                          />

                          <p className="d-flex m-0 ms-2 m-2 general-text-table-factory">
                            {item.name}
                          </p>
                        </div>
                      </td>

                      <td className="align-middle">{item._id}</td>
                      <td className="align-middle">
                        {moment(item.start_date?.slice(0, -1)).format(
                          "MM/DD/YYYY"
                        )}
                        {/* {moment(item.end_date).add(10, "days").calendar()} */}
                      </td>
                      <td className="align-middle">
                        {moment(item.end_date?.slice(0, -1)).format(
                          "MM/DD/YYYY"
                        )}
                        {/* // {moment(item.end_date).add(10, "days").calendar()} */}
                      </td>
                      <td className="align-middle general-text-table-factory">
                        <span className={`complete baseBadge`}>
                          In progress
                        </span>
                      </td>
                      <td className="align-middle">
                        <button
                          className="m-0 typo-body general-text-table-factory text-decoration-underline"
                          style={{
                            background: "none",
                            border: "none",
                          }}
                          onClick={() => viewBriefCLient(item)}
                        >
                          VIEW
                        </button>
                      </td>

                      <td className="align-middle">
                        <div className="col-auto d-flex align-items-center">
                          <img
                            src={EditIcon}             
                            className="text-primary mx-1 cursor-pointer"
                            onClick={(e) => editProject(e, item)}
                            style={{ width: '12px', height: '12px' }}
                          />
      
                          <i className="bi bi-files text-primary mx-1" />

                          <div className="cursor-pointer">
                            <img
                              className="mx-1"
                              src={TrashIcon}
                              alt=""
                              style={{
                                height: "12.8px",
                                width: "12.8px",
                                marginBottom: "5px",
                                color: " #0000",
                                marginTop: "3px",
                              }}
                              onClick={(e) => handleDeletedJob(e, item)}
                            />
                          </div>
                        </div>
                      </td>
                      {/* <div>{brief ? brief : ""}</div> */}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
