import React from "react";
import { useSelector } from "react-redux";
import InputSearch from "../../../UI/forms/InputSearch";
import { layoutConfig } from "../../../../store/layout";
import { RootState } from "../../../../store/store";
import { useHistory } from "react-router-dom";

interface Props {
  selectProject: string | null;
  setSelectProject: any;
  activeControl: string | null;
  setActiveControl: any;
  selectJob: string | null;
  setSelectJob: any;
  setActiveJob: any;
  setActiveControlView: any;
}

const JobDetailProjectModal: React.FC<Props> = (props) => {
  const ProjectData = useSelector((state: RootState) => state.factorySlice);

  const { loading, projectData } = ProjectData;

  const history = useHistory();

  const handleChange = (item: any) => {
    if (props.activeControl === "job") {
      props.setActiveControlView("job");
      props.setSelectJob(item._id);
      props.setSelectProject(item);
    } else {
      props.setSelectProject(item);
      props.setActiveJob(null);
      history.replace(`/factory/${item._id}`);
    }
  };

  const selectedColor = (item: any) => {
    let select;
    if (item.status === "Approved") {
      select = "aproved";
    } else if (item.status === "Proporsal") {
      select = "proporsal";
    } else if (item.status === "Active") {
      select = "active";
    } else if (item.status === "On Hold") {
      select = "onHold";
    } else if (item.status === "Lost") {
      select = "lost";
    } else if (item.status === "Complete") {
      select = "complete";
    }

    return select;
  };

  return (
    <div className="container-fluid p-0   custom-scrollbar overflow-hidden createProjectModal">
      <div className=" w-100">
        <div className="row col py-3 px-3">
          <div className="col-sm-9 col-md-9 col-lg-9">
            <InputSearch className="w-100" placeholder="Search project" />
          </div>

          <div className="col-sm-3 col-md-3  col-lg-3  d-flex justify-content-end ">
            <button
              type="button"
              className="btn btn-primary btn-lg w-100"
              onClick={() => props.setActiveControlView("project")}
            >
              Create new project
            </button>
          </div>
        </div>
        <table className="table table-borderless ">
          <thead className="text-center">
            <tr className="text-primary typo-body ">
              <th scope="col">PROJECT NAME</th>
              <th scope="col">ORGANITACION.</th>
              <th scope="col">CLIENT</th>
              <th scope="col">STATUS</th>
            </tr>
          </thead>

          <tbody>
            {projectData.map((item: any, index: any) => {
              return (
                <tr
                  className={`${
                    (index / 2) % 1 > 0 ? "table-primary" : ""
                  } text-primary typo-body  `}
                  key={index}
                  onClick={() => handleChange(item)}
                >
                  <td className="d-flex align-items-center">
                    <p className="row m-0 py-2">{item.name}</p>
                  </td>

                  <td className="align-middle ">
                    <p className="row m-0 ">{item.organization._id}</p>
                  </td>
                  <td className="align-middle">
                    <p className="row m-0 ">{item.client._id}</p>
                  </td>
                  <td className="align-middle">
                    <span
                      className={`${selectedColor(item)} row m-0 `}
                      style={{ width: "70px" }}
                    >
                      {item.status}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobDetailProjectModal;
