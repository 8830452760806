import React from "react";
import { Switch, Route } from "react-router-dom";
import Rates from "../screens/Rates";
import Factory from "../screens/Factory";
import Blackbook from "../screens/Blackbook";
import Dashboard from "../screens/Dashboard";
import Stockpile from "../screens/Stockpile";
import EditContact from "../components/Contacts/EditContact/EditContact";
import CreateForm from "../components/Factory/FactoryView/createProyectForm/tabs/CreateForm";
import CreateProjectForm from "../components/Factory/FactoryView/createProyectForm/CreateProjectForm";
import FatoryViewDetailProject from "../components/Factory/FactoryView/FactoryViewDetailProject";
import CreateQuoteForm from "../components/Factory/FactoryView/createQuoteForm/CreateQuoteForm";
import QuoteView from "../components/Factory/Quotes/QuoteView";

const Router = () => {
  return (
    <Switch>
      <Route path={["/", "/dashboard"]} exact>
        <Dashboard />
      </Route>

      <Route path="/blackbook" exact>
        <Blackbook />
      </Route>

      <Route path="/blackbook/:contactId" exact>
        <Blackbook />
      </Route>

      <Route path="/blackbook/edit/:contactId" exact>
        <EditContact />
      </Route>

      <Route path="/stockpile" exact>
        <Stockpile />
      </Route>

      <Route path="/rates/" exact>
        <Rates />
      </Route>

      <Route path="/rates/:rateType" exact>
        <Rates />
      </Route>

      <Route path="/rates/:rateType/:rateCardId" exact>
        <Rates />
      </Route>

      <Route path="/rates/:rateType/:rateCardId/version/:rateCardVersion" exact>
        <Rates />
      </Route>

      <Route path="/stockpile/:itemId" exact>
        <Stockpile />
      </Route>

      <Route path="/stockpile/:itemId/:assetId" exact>
        <Stockpile />
      </Route>

      <Route path="/rates" exact>
        <Rates />
      </Route>

      <Route path="/factory" exact>
        <Factory />
      </Route>
      <Route path="/factory/create/job/:idcJob" exact>
        <CreateProjectForm />
      </Route>
      <Route path="/factory/:id" exact>
        <Factory />
      </Route>
      <Route path="/factory/:idproject/:idjob" exact>
        <CreateQuoteForm />
      </Route>
      <Route path="/factory/edit/:idproject/:idjob/:idquote" exact>
        <CreateQuoteForm />
      </Route>
      <Route path="/factory/project/detail/:id" exact>
        <FatoryViewDetailProject />
      </Route>

      <Route path="/factory/edit/project/:id" exact>
        <CreateProjectForm />
      </Route>
      <Route path="/factory/edit/project/:id/job/:idJob" exact>
        <CreateProjectForm />
      </Route>
      <Route path="/factory/quotes/detail/:id" exact>
        <QuoteView />
      </Route>


      <Route path={"*"}>404</Route>
    </Switch>
  );
};

export default Router;
