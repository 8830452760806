import QuickNotes from '../../../UI/QuickNotes';
import { Datepicker, setOptions, localeEs , localeEn} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import { useEffect, useState } from 'react';
import { CreateMaintenance, GetMaintenanceByID } from 'src/store/stockpile/stockpileSlice';
import AddMaintenanceModal from 'src/components/Modals/AddMaintenanceModal';
import DetailMantenance from 'src/components/Modals/DetailMaintenance';
import moment from 'moment';
import { useToast } from 'src/hooks/useToast';


setOptions({
  locale: localeEn,
  theme: 'material',
  themeVariant: 'light',
});

const Maintenance: React.FC = () => {
  const dispatch = useDispatch()
  const [addMain, setAddMain] = useState<any>('')
  const toast = useToast()

  const [detail, setDetail] = useState<any>('')

  const [ deteiledM , setDetailed ] = useState<any>('')

  const toggle: any = () => {
    setDetail((prev: any) => !prev);
  };

  const mydisabledDates = ['2020-12-25 12:00', '2020-12-25 12:00', '2020-12-25 12:00', '2020-12-25 12:00'];

  const ASSET_CURRENT: any = useSelector((state: RootState) => state.selectAssetsSlice.data);

  const MAINTENANCE: Array<any> = useSelector((state: RootState) => state.stockpileSlice.DetailAssetMaintenance) || [];


  useEffect(() => {
    dispatch(GetMaintenanceByID(ASSET_CURRENT?._id))
  }, [])

  const CreateMain = (data: any) => {
    let obj = {
      id: ASSET_CURRENT?._id,
      data: data
    }
    dispatch(CreateMaintenance(obj))
  }

  const History: any = MAINTENANCE?.map((main: any, index: any) => {
    return {
      text: main.comment,
      date: main.created_at
    }
  })

  const CoomingMaintenance = MAINTENANCE?.map((item: any, index: any) => {
    return item?.dates?.to
  })

  const PastMaintenance = MAINTENANCE?.map((item: any, index: any) => {
    return item?.dates?.from
  })


  useEffect(() => {
    console.log(MAINTENANCE);
  }, [MAINTENANCE])

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3 ">
        <div className="col-12">
          <Datepicker
            controls={['calendar']}
            display="inline"
            theme='ios'
            pages={2}
            value={PastMaintenance.concat(CoomingMaintenance)}
            selectMultiple={true}
            onCellClick={(event: any) => {
              const dt = MAINTENANCE?.map((item: any) => {
                let from = moment(item?.dates?.from).format('YYYY-DD-MM')
                let to = moment(item?.dates?.to).format('YYYY-DD-MM')
                let eventDate = moment(event.date).format('YYYY-DD-MM')
    
                
                if (from === eventDate || to === eventDate){
                  setDetailed(item);
                } 
                
              })
              setDetail(true)
            }}
          />
        </div>
        <div style={ASSET_CURRENT?.serial ? { maxWidth: '200px' } : { maxWidth: '200px',  opacity: 0.7 }}>
          <SimpleButton 
          onClick={() => {
            if (!ASSET_CURRENT?.serial) {
              toast.info('Please create an assets to add a maintenance')
            }else{
              setAddMain(true) 
            }
        }}
          >
            ADD MAINTENANCE DATE
          </SimpleButton>
        </div>
      </div>

      <div className="row border-bottom p-0 py-3 ">
        <QuickNotes
          title="maintenance history"
          texts={History}
        />
      </div>
      <AddMaintenanceModal
        view={addMain}
        setView={setAddMain}
        sendData={CreateMain}
        toggle={() => setAddMain(false)}
      />

      <DetailMantenance view={detail} setView={setDetail} toggle={toggle} >
        <QuickNotes
          title=""
          texts={
            [
              {
                text: deteiledM.comment,
                date: deteiledM.created_at
              }
            ]
          }
        />
      </DetailMantenance>
    </div>
  );
};

export default Maintenance;
