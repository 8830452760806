import LineChart from "src/components/UI/charts/LineChart";

interface SalesCardProps {}

// eslint-disable-next-line no-empty-pattern
function SalesCard({}: SalesCardProps) {
  return (
    <div className="card text-primary">
      <div className="card-body">

        <div className="card-title align-items-center d-flex gap-2 mb-3">
          <section className="d-flex align-items-center gap-5 flex-grow-1">
            <h5 className="m-0">Sales</h5>
            <span style={{ background: '#ECE9F3' }} className="badge rounded-pill bg-contrast text-primary">-0,7%</span>
          </section>

          {/* Selector */}
          <section>
            This Week
          </section>
        </div>

        <div>
          <LineChart
            fullWidth
            datasetProps={{
              fill: true,
              borderColor: 'rgb(107, 138, 184)',
              backgroundColor: 'rgba(53, 162, 235, 0.2)',
              tension: 0.5,
            }}
            chartOptions={{
              interaction: {
                intersect: false,
                mode: 'index'
              },
              scales: {
                y: {
                  display: false
                },
                x: {
                  grid: {
                    display: false
                  }
                }
              },
            }}
            data={[ 250, 315, 175, 1000, 700 ]}
            labels={[ 'Jan', 'Feb', 'Mar', 'Apr', 'May' ]}
          />
        </div>

      </div>
    </div>
  )
}

export default SalesCard;