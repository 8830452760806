import React, { useState, useRef, useEffect } from 'react';
import ConfirmModal from '../../Modals/ConfirmModal';
import Checkbox from '../../UI/forms/Checkbox';
import ProgressbarTitle from '../../UI/ProgressbarTitle';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import checkFields from '../../../util/CheckFields'
import UploadCSV from './tabs/UploadCSV'
import { CreatePackage } from '../../../store/stockpile/packageItems';
import { PackageModel } from '../../../models/ItemModel';
import { CreateItemsCSV, CreateRates } from 'src/store/stockpile/stockpileSlice';
import StepButton from 'src/components/UI/buttons/stepButton';
import { useToast } from 'src/hooks/useToast';
import UploadAssets from './tabs/UploadAssets';
import { CreateAssetsCSV } from '../../../store/stockpile/stockpileSlice';


interface Props {
  onFinish: () => void;
}

const tabs: string[] = [
  'Upload CSV file',
  'Upload Assets CSV'
];


const AddStockCSV: React.FC<Props> = (props) => {
  const dispatch = useDispatch()

  const toast = useToast()

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);


  /////Errors 
  const Rtes = useSelector((state: RootState) => state.kitItems.RatesErrors);

  /////FORMS
  const DescriptionData = useSelector((state: RootState) => state.kitItems.Description);
  const rates = useSelector((state: RootState) => state.kitItems.RatesData);
  const ItemsPackage = useSelector((state: RootState) => state.kitItems.Items);
  const Assets = useSelector((state: RootState) => state.kitItems.Assets);
  const FileCSV = useSelector((state: RootState) => state.CreateItemsCsvForm.FileValueCSV);
  const FileAssetsCSV = useSelector((state: RootState) => state.CreateAssetCsvForm.FileValueCSV);


  //const accesories = useSelector((state: RootState) => state.createFormSlice.AccesoriesData);

  const progress = {
    ...DescriptionData, ...rates
  }

  // const percenCompletion = (1 - checkFields(progress).percent)
  const percenCompletion = 0.05

  const tabsContent: JSX.Element[] = [
    <UploadCSV />,
    <UploadAssets />
  ];

  const onCancelModalClose = (value?: boolean) => {
    setIsCancelling(false);

    if (value === undefined) {
      return;
    }

    if (!value) {
      props.onFinish();
    }
  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    setCurrentTab((prev) => {
      if (prev < tabs.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  const setItem = async () => {

    if (FileCSV) {
      const res: any = await dispatch(CreateItemsCSV(FileCSV))

      if (res.payload) {
        const resAssets: any = await dispatch(CreateAssetsCSV(FileAssetsCSV))

        if (resAssets.payload) {
          toast.success('Save items succesfull')
          props.onFinish()
        } else {
          toast.error('error in creation item')
        }

      } else {
        toast.error('error in creation item')
      }




    } else {
      alert('hay errores en el formulario')
    }
  }


  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new item will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD ITEM"
          onClose={onCancelModalClose}
        />
      )}

      <div className="container-fluid h-100">
        <div className="row" style={{ height: '90vh' }}>
          <div className="col-12 card h-100 " >

            {/* Top actions */}
            <div className="row justify-content-between align-items-center p-2
col-lg-12 col-md-auto col-sm-12 col-12 align-self-center
"
              style={{ position: 'absolute', background: 'white', zIndex: 1 }}>

              <div className='p-0 m-0 border-0 col-auto row'>

                <button
                  onClick={setIsCancelling.bind(null, true)}
                  className="btn btn-outline-danger typo-body col-auto border-0">
                  Cancel
                </button>

              </div>

              <h4
                className='text-primary col-auto' >Update Items CSV</h4>

              <div className='p-0 m-0 border-0 col-auto row justify-content-between'
              onClick={FileCSV ? () => {} : () => toast.error('you have to upload the items first') }
              >



                <button onClick={setItem} className="btn btn-outline-success typo-body col-auto border-0"
                style={FileCSV ? {} : { pointerEvents: 'none' }}>
                  Save csv
                </button>

              </div>

            </div>

            <div className='h-100 custom-scrollbar overflow-y'>
              {/* Tab View */}

              <div className={`container mt-5`}  >
                <div className="row mt-4">
                  <div className="col">
                    <ul className="nav nav-tabs mt-3 justify-content-between">
                      <div className="row">
                        {tabs.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="nav-item col-auto p-0"
                              onClick={FileCSV ? setCurrentTab.bind(null, index) : () => toast.error('you have to upload the items first')}
                            >
                              <p
                                style={FileCSV ? {} : { pointerEvents: 'none' }}
                                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : ''
                                  }`}
                                aria-current="page"
                              >
                                {item}
                              </p>
                            </li>
                          );
                        })}
                      </div>
                    </ul>


                    {tabsContent[currentTab]}


                    <div className='backprimary w-100 h-25 p-2 mt-3'>
                      <h5>Output</h5>
                      <p style={{ textTransform: 'uppercase', fontSize: '16px' }}>
                        item no , 849 no was created . name is empty asset with sku 12345
                        was no created .duplicate sku
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStockCSV;
