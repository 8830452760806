import React from "react";
import dataPrueba from "../../../../../components/dataPrueba";
import Checkbox from "../../../../../components/UI/forms/Checkbox";
import InputSearch from "../../../../../components/UI/forms/InputSearch";

const AssingAsset = () => {
  return (
    <div className="">
      <div>
        <h1 className="text-secondary fw-bold fs-2">Assign asset</h1>
      </div>
      <div className="ps-3 pe-3">
        <InputSearch placeholder="Search asset number" />
      </div>
      <div>
        <table className="table table-borderless">
          <thead className="">
            <tr className="text-primary typo-body">
              <th scope="col" style={{ width: "250px", marginLeft: "120px" }}>
                <span className="ms-5">ASSET NUMBER</span>
              </th>

              <th scope="col" style={{ width: "140px" }}>
                <div className="ms-2">USAGE</div>
              </th>
              <th scope="col" style={{ width: "120px" }}>
                AGE
              </th>
              <th scope="col" style={{ width: "150px" }}>
                SERIAL NUMBER
              </th>

              <th scope="col" style={{ width: "140px" }}>
                <div className="ms-2">CONDITION</div>
              </th>
              <th scope="col" style={{ width: "100px" }}>
                OWNER
              </th>
            </tr>
          </thead>

          <tbody>
            {dataPrueba.project.map((item: any, index: any) => {
              return (
                <tr
                  className={`${
                    (index / 2) % 1 > 0 ? "table-primary" : ""
                  } text-primary typo-body`}
                  key={index}
                  // onClick={() => handleChange(item)}
                >
                  <td
                    className="d-flex align-items-center ps-5"
                    style={{ height: "58px" }}
                  >
                    <Checkbox
                      title=""
                      value={false}
                      onChange={(value) => {}}
                      className=""
                    />
                    <p className="m-0 ms-2"> {item.name}</p>
                  </td>

                  <td
                    className="align-middle me-auto"
                    style={{ width: "50px" }}
                  >
                    <div className="d-flex me-auto">{item.jobs}</div>
                  </td>
                  <td className="align-middle">
                    <div className="d-flex me-auto">
                      <div className="d-flex me-auto">{item.jobs}</div>
                    </div>
                  </td>

                  <td className="" style={{ padding: "20px" }}>
                    <div className="d-flex me-auto">{item.jobs}</div>
                  </td>
                  <td
                    className=""
                    style={{ padding: "20px", paddingRight: "20px" }}
                  >
                    <div className="d-flex me-auto">{item.jobs}</div>
                  </td>
                  <td className=" align-items-center">
                    <div className="d-flex me-auto">
                      <div className="d-flex me-auto">{item.jobs}</div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex cursor-pointer mb-2 me-auto ms-2">
        <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-5 me-1"></i>
        <small className=" text-primary mt-1">Quick add new asset</small>
      </div>
      <div>
        <button
          className="btn btn-success btn-lg mt-2 text-white fw-bold"
          style={{ height: "50px", width: "200px" }}
        >
          Assign especific asset
        </button>
      </div>
    </div>
  );
};

export default AssingAsset;
