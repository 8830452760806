import React, { useState } from "react";
import { Datepicker } from "@mobiscroll/react";
import { parseInputError } from "../../../util";

interface InputDateProps {
  min?: any;
  max?: any;
  error?: string;
  placeholder?: string;
  value?: Date | string | number;
  onChange?(args: any, inst: any): void;
  ref?:any
}

const InputDate: React.FC<InputDateProps> = ({
  max,
  min,
  error,
  value,
  onChange,
  placeholder,
  ref
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="input-group m-1 ml-2 mb-0 cursor-pointer">
        <Datepicker
        ref={ref}
          theme="ios"
          touchUi={false}
          display="anchored"
          inputComponent="input"
          controls={["calendar"]}
          dateFormat="MM/DD/YYYY"
          inputProps={{
            className:
              "form-control inputDatePicker searchWords" +
              (error ? " is-invalid" : ""),
            style: { background: "#fff", height: "44.8px", color: "#6B8AB8" },
            placeholder: placeholder || "Please Select...",
            onClick: () => setOpen(true),
          }}
          min={min}
          max={max}
          value={value}
          isOpen={open}
          onChange={onChange}
          className="text-primary"
          onClose={() => setOpen(false)}
        ></Datepicker>
        <div className={"input-group-append  datepickerCustom "}>
          <span
            style={{
              height: "44.8px",
              borderRadius: "0px 3px 3px 0px",
              background: "#6b8ab8",
            }}
            onClick={() => setOpen(!open)}
            className="input-group-text cursor-pointer  datepickerCustomBtn"
          >
            <i className="bi bi-calendar" style={{ color: "#fff" }}></i>
          </span>
        </div>
      </div>

      {error && <div className="validation">{parseInputError(error)}</div>}
    </div>
  );
};

export default InputDate;
