import { Doughnut } from 'react-chartjs-2';

interface Props {
  title: string;
  value: number;
  color: string;
}

const DoughnutChart: React.FC<Props> = (props) => {
  return (
    <div className="pb-3">
      <p className="m-0 typo-body text-muted text-uppercase pb-3">
        {props.title}
      </p>

      <div className="d-flex">
        <div className="chart-doughnut">
          <Doughnut
            options={{
              maintainAspectRatio: true,
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  display: false,
                },
              },
              cutout: '80%',
            }}
            data={{
              labels: ['', ''],
              datasets: [
                {
                  label: '',
                  data: [props.value, 1 - props.value],
                  backgroundColor: [props.color, '#f3f3f3'],
                  borderColor: [props.color, '#f3f3f3'],
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>

        <p className="m-0 typo-display text-primary ps-2">
          {props.value * 100}%
        </p>
      </div>
    </div>
  );
};

export default DoughnutChart;
