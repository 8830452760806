export const getCategoryName = (id: any, categories: any) => {
    
    const name = categories.map((category: any) => {
        if (id == category?._id) { 
            return category?.name
         }
    })

    
    return name
}

export const getCategoryID = (name: any, categories: any) => {

    const id = categories.map((category: any) => {
        if (name == category?.name) return category?._id
    })

    return id

}