import React from "react";
import ButtonIconText from "./ButtonIconText";

interface Props {
    status?: any,
    title?:string,
    onClick?:()=>void;
}


const ButtonAssetFilters = (props: Props) => {
    return (
        <button
            style={{
               // top: '112px',
               // left: '370px',
                minWidth: '4.25em',
                height: '1.438em',
                background: '#D3D7E9',
                borderRadius: '5px',
                opacity: 1,
                display: 'flex',
                margin: 4,
                border:'none'
            }}
            className={'btn-icon-text'}
            onClick={props.onClick}
        >
            {
                props.status == 'primary' ? <i className="pointPrimary"></i> : <></>
            }
            {
                props.status == 'success' ? <i className="pointGreen"></i> : <></>
            }
            {
                props.status == 'warning' ? <i className="pointYellow"></i> : <></>
            }

            <p style={{
              //  fontSize: '10px',
                marginTop: '4px',
                marginLeft: '5px',
                textTransform: 'uppercase'
            }} className="text-responsive-ultra-small">
                {props.title}
            </p>

        </button >
    )
}

export default ButtonAssetFilters