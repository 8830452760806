import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

interface LayoutConfigState {
  moduleTitle: string;
}

const initialState: LayoutConfigState = {
  moduleTitle: '',
};

const LayoutConfigSlice = createSlice<LayoutConfigState, SliceCaseReducers<LayoutConfigState>>({
  name: 'layout-configuration',
  initialState,
  reducers: {
    setModuleTitle: {
      reducer: (state, action: PayloadAction<string>) => {
        state.moduleTitle = action.payload;
      },
      prepare: (moduleTitle: string) => {
        return { payload: moduleTitle };
      }
    }
  },
});

export const layoutConfig = LayoutConfigSlice.actions;

export default LayoutConfigSlice.reducer;
