interface Props {
  title: string;
  images: string[];
}

const PhotoDisplay: React.FC<Props> = (props) => {
  return (
    <div className="pb-3">
      <p className="m-0 typo-body text-muted text-uppercase mb-1">
        {props.title}
      </p>
      <div className="d-flex align-items-center custom-scrollbar overflow-x">
        {props.images.map((image, index) => {
          return (
            <img
              key={index}
              className="mx-2 my-1"
              src={image}
              alt=""
              style={{ height: '100px', width: '100px', borderRadius: 5 }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PhotoDisplay;
