import InfoDisplay from "src/components/UI/InfoDisplay";
import { ContactType, IContact } from "src/models/ContactModel";
import { getFileUrl } from "src/util";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";

const OrganizationDocuments: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {
  if (contact.type !== ContactType.ORGANIZATION) return null;

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="logo for document branding"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.docs_logo),
                text: contact.entity.documents?.docs_logo?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="contract"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.contract),
                text: contact.entity.documents?.contract?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="trade license"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.trade_license),
                text: contact.entity.documents?.trade_license?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="style guide"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.style_guide),
                text: contact.entity.documents?.style_guide?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="insurance"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.insurance),
                text: contact.entity.documents?.insurance?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationDocuments;
