import { useSelector } from 'react-redux';

/* @ts-ignore */
import { AnimatePresence, motion, LayoutGroup } from 'framer-motion/dist/framer-motion';

import Toast from './Toast';
import { RootState } from 'src/store/store';


function ToastsContainer() {
  const toasts = useSelector((state: RootState) => state.toastsSlice.toasts);

  return (
    <motion.div
      layout
      key="toasts-container"
      className="toastsContainer"
    >
      <LayoutGroup id="toasts">
        <AnimatePresence>
          {toasts.map((toast) => <Toast key={toast.id} toast={toast} />)}
        </AnimatePresence>
      </LayoutGroup>
    </motion.div>
  );
}

export default ToastsContainer;