import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import RatesSchema from './validations';
import { useFormik, Form, FormikProvider } from 'formik';
import { RatesModel } from '../../../../../models/ItemModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { setRates, changeRatesErrors } from '../../../../../store/stockpile/EditFormSlice';
import { useEffect } from 'react';
import { Input } from 'reactstrap';


const Rates: React.FC = () => {

  const dispatch = useDispatch()
  const data = useSelector((state: RootState) => state.EditFormSlice.RatesData);

  const Ratesdata = useSelector((state: RootState) => state.stockpileSlice.RatesForItem) || [];



  const formik = useFormik({
    validationSchema: RatesSchema,
    initialValues: {
      ...Ratesdata[0]?.rate_card_item?.rates
    },
    onSubmit: () => {

    }
  })

  const { errors, touched, handleSubmit, values, setFieldValue, setValues } = formik

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeRatesErrors(true))
    } else {
      dispatch(changeRatesErrors(false))
    }
  }, [errors])

  useEffect(() => {

    //setValues(Ratesdata[0]?.rate_card_item)

  }, [Ratesdata[0]])


  return (
    <div className="container-fluid px-3 mt-3">
      <p className="m-0 text-danger typo-body">Rates can only be edited in the Rate cards module</p>
      <p className="m-0 text-primary typo-body">STANDARD RATE CARD</p>

      <FormikProvider value={formik} >
        <Form autoComplete="off" noValidate onSubmit={(e) => { }}  >
          <div className="row mt-2" >
            <div className="col-lg-3 col-sm-12 col-md-12 col-12" style={{pointerEvents: 'none' , opacity: 0.7}}>
              <InputFloatingLabel
                placeHolder="Hour rate"
                value={values.hour}
                onChange={(value) => {
                  setFieldValue('hour', value)
                  dispatch(setRates({ hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.hour}
              />
            </div>

            <div className="col-lg-3 col-sm-12 col-md-12 col-12" style={{pointerEvents: 'none' , opacity: 0.7}}>
              <InputFloatingLabel
                placeHolder="Day rate"
                value={values.daily}
                onChange={(value) => {
                  setFieldValue('daily', value)
                }}
                errorMessage={errors?.daily}
              />

            </div>

            <div className="col-lg-3 col-sm-12 col-md-12 col-12" style={{pointerEvents: 'none' , opacity: 0.7}}>
              <InputFloatingLabel
                placeHolder="Week rate"
                value={values.week}
                onChange={(value) => {
                  setFieldValue('week', value)
                }}
                errorMessage={errors?.week}
              />
            </div>

            <div className="col-lg-3 col-sm-12 col-md-12 col-12" style={{pointerEvents: 'none' , opacity: 0.7}}>
              <InputFloatingLabel
                placeHolder="Sell rate"
                value={values.sale}
                onChange={(value) => {
                  setFieldValue('sale', value)
                }}
                errorMessage={errors?.sale}
              />
            </div>
          </div>

          <div className="row mt-2" >
            <div className="col-lg-3 col-sm-12 col-md-12 col-12" style={{pointerEvents: 'none' , opacity: 0.7}}>
              <InputFloatingLabel
                placeHolder="Overtime hour rate"
                value={values.overtime_hour}
                onChange={(value) => {
                  setFieldValue('overtime_hour', value)
                }}
                errorMessage={errors?.overtime_hour}
              />
            </div>

            <div className="col-lg-3 col-sm-12 col-md-12 col-12" style={{pointerEvents: 'none' , opacity: 0.7}}>
              <InputFloatingLabel
                placeHolder="Holiday hour rate"
                value={values.holiday_hour}
                onChange={(value) => {
                  setFieldValue('holiday_hour', value)
                }}
                errorMessage={errors?.holiday_hour}
              />
            </div>

            <div className="col-lg-3 col-sm-12 col-md-12 col-12" style={{pointerEvents: 'none' , opacity: 0.7}}>
              <InputFloatingLabel
                placeHolder="Holiday day rate"
                value={values.holiday_day}
                onChange={(value) => {
                  setFieldValue('holiday_day', value)
                }}
                errorMessage={errors?.holiday_day}
              />
            </div>

            <div className="col-lg-3 col-sm-12 col-md-12 col-12" style={{pointerEvents: 'none' , opacity: 0.7}}>
              <InputFloatingLabel
                placeHolder="Holiday overtime rate"
                value={values.holiday_overtime_hour}
                onChange={(value) => {
                  setFieldValue('holiday_overtime_hour', value)
                }}
                errorMessage={errors?.holiday_overtime_hour}
              />
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default Rates;
