import UserModel from "./userModel";
import { ICategory } from "./Category";
import { IEntity, SoftDelete } from ".";
import { BCreateSkillset } from "./Bodies";

interface ContactModel {
    image: string;
    title: string,
    subtitle: string,
    count1?: number;
    count2?: number;
};

export type IContact = IEntity & {
	note: string;
	status: SoftDelete;
  entityModel: string;
	contact_since: string; // In Date format
	category: string | ICategory;
	created_by: string | UserModel;
} & ({
    type: ContactType.CLIENT;
    entity: IEntity & ClientData;
} | {
    type: ContactType.FREELANCE;
    entity: IEntity & FreelanceData;
} | {
    type: ContactType.ORGANIZATION;
    entity: IEntity & OrganizationData;
} | {
    type: ContactType.TEAM_MEMBER;
    entity: IEntity & TeamMemberData;
} | {
    type: ContactType.PROSPECT;
    entity: IEntity & ProspectData;
})

export interface OrganizationData  {
  logo?: string;
  name: string;
  type: string;
  tax_id?: string;
  home_url?: string;
  office_number?: string;
  mobile_number?: string;
  documents: {
    contract?: string;
    docs_logo?: string;
    insurance?: string;
    style_guide?: string;
    trade_license?: string;
  };
  addresses?: {
    map_location: string;
    continent?: string;
    country?: string;
    state?: string;
    city?: string;
    zip?: string;
    address?: string;
    type: string;
  }[];
  social?: {
    facebook: string;
    twitter: string;
    instagram: string;
    linkedin: string;
  };
}

export interface FreelanceData {
  logo?: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  nickname?: string;
  email: string;
  nationality?: string;
  address?: string;
  gender?: string;
  date_of_birth: string;
  title?: string;
  health_note?: string;
  credits_name?: string;
  bookable_skills?: BCreateSkillset[] | string[];
  phone_numbers?: {
    type: string;
    number: string;
    primary?: boolean;
  }[];
  emergency_contacts?: {
    name: string;
    relationship: string;
    phone_numbers: FreelanceData['phone_numbers'];
    emails: { type: string, address: string }[];
  }[];
  payment_info?: {
    account: string;
    account_owner: string;
    type: string;
    bank: string;
    method: string;
  }[];
  social?: {
    facebook: string;
    twitter: string;
    instagram: string;
    linkedin: string;
  };
  communication?: {
    skype: string;
    website: string;
    discord: string;
  };
  union_affiliation?: string;
  languages?: string[];
}

export interface TeamMemberData extends Omit<FreelanceData, 'email' | 'title' | 'union_affiliation'> {
  work_email: string;
  personal_email: string;

  division: string;
  position: string;
}

export interface ProspectData extends Omit<FreelanceData, 'email' | 'address' | 'union_affiliation' | 'nationality' | 'gender' | 'payment_info' | 'bookable_skills' | 'languages'> {
  emails: {
    type: string;
    address: string;
    primary?: boolean;
  }[];

  source?: string;
}

export interface ClientData extends Omit<FreelanceData, 'bookable_skills' | 'credits_name' | 'gender' | 'nationality' | 'payment_info' | 'languages'> {
  organization?: string;
  personal_email: string;
  logo?: string;
  phone?: string;
  
  contact_since: string;
}

export enum ContactType {
	CLIENT = 'CLIENT',
  PROSPECT = 'PROSPECT',
  FREELANCE = 'FREELANCE',
  TEAM_MEMBER = 'TEAM_MEMBER',
	ORGANIZATION = 'ORGANIZATION',
}

export const ContactsFilesFields: (keyof (FreelanceData & ClientData & OrganizationData & ProspectData & TeamMemberData))[] = ['logo', 'documents']

export default ContactModel;