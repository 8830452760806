import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { FormGroup, Label, Input, ListGroup, ListGroupItem } from "reactstrap";
import { RootState } from '../../store/store'

interface Props {
    view: any,
    setView: any
    toggle: any
    setValue?: any
    setUpdate?:any
}

const DetailMaintenance: React.FC<Props> = (props) => {

    return (
        <Modal className="ModalOptions" isOpen={props.view} toggle={props.toggle} centered>
            <div className="container ">
                <div className="row ">
                    <p className='poppinsText' style={{
                        fontSize: '22px',
                        fontWeight: 'lighter',
                        cursor: 'pointer',
                        fontFamily: 'revert',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginBottom: '-1%'
                    }}
                        onClick={() => props.setView(false)}
                    >
                        x
                    </p>
                    <div className="col-12" style={{
                        display: 'flex', alignSelf: 'center',
                        justifyContent: 'center'

                    }}>
                        <p className="ModalTitle"
                            style={{
                                fontSize: '24px', fontWeight: 'bold',
                                justifyContent: 'center', alignSelf: 'center',
                                display: 'flex', color: '#344469'
                            }}
                        >
                            Maintenance detail
                        </p>
                    </div>
                    <div className="col-auto ps-4  w-100 justify-content-center">
                            {props.children}
                    </div>

                    <div className="col-12 mt-3" style={{ marginBottom: '5%' }}>
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-success  text-white col-auto"
                                onClick={() => props.setView(false)}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DetailMaintenance