import { ClientData as TClientData } from 'src/models/ContactModel';
import { object, string } from 'yup';
import FreelancePersonalValidation, { FreelanceData } from '../../Freelance/PersonalInformation/validation';

export type ClientData = TClientData & Omit<FreelanceData, 'bookable_skills' | 'credits_name' | 'gender' | 'nationality' | 'payment_info' | 'languages'> & {
  contact_since: string;
}

const ClientPersonalValidation = FreelancePersonalValidation.concat(object({
  organization: string().min(1).max(100).optional(),
  personal_email: string().max(100).email().optional(),
  phone: string().min(1).max(50).required(),
}));

export default ClientPersonalValidation;