import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AssetModel, sizesModel } from '../../models/ItemModel';
import stockpileService from '../../services/stockpile';


const initialState = {
    Asset: {
    /*    serial: '',
        purchase: { date: '', origin: '', price: '' },
        digital_receipt: '',
        has_digital_receipt: false,
        condition: '',
        warranty_expiration: '',
        end_of_life: '',
        is_external: false,
        sizes: {
            name: '',
            weight: '',
            weight_unit: '',
            length: '',
            sizes_unit: '',
            width: '',
            height: ''
        }*/
    } as AssetModel,
    dates: [
        { type: 'date of purchase', date: '' }
    ],
    isExternal: false
};

const EditAssetFormSlice = createSlice({
    name: 'EditAsset',
    initialState,
    reducers: {
        setDataAsset: (state, action) => {
            state.Asset = { ...state.Asset, ...action.payload }
        },
        setDates: (state, action) => {
            state.dates = action.payload
        },
        UpdateValueDateChargue: (state, action) => {
            const { type, date } = action.payload
            if(state.dates[type]?.date){state.dates[type].date = date}
        },
        UpdateValueDate: (state, action) => {
            const { type, date } = action.payload
            state.dates[type].date = date
        },
        AddDate: (state) => {
            if (state.dates.length < 3) {
                state.dates.push({ type: '', date: '' })
            }
        },
        DiscardDate: (state, action) => {
            state.dates.splice(action.payload, 1)
            if (action.payload == 1) { state.Asset.end_of_life = '' }
            if (action.payload == 2) { state.Asset.warranty_expiration = '' }
        },
        setTypeAsset: (state) => {
            state.isExternal = !state.isExternal
        }
    },
    extraReducers: (builder) => {
    }
});

export const { setDataAsset, setDates, UpdateValueDate, AddDate, DiscardDate, UpdateValueDateChargue, setTypeAsset } = EditAssetFormSlice.actions;
export default EditAssetFormSlice.reducer;
