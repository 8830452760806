import InfoDisplay from '../../../UI/InfoDisplay';

interface Props {}

const Projects: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3 ">
        <div className="col-6">
          <InfoDisplay
            title="current projects"
            count={6}
            texts={[
              {
                text: "Aaron is Cindy's nephew. He's our primary contact for Alps Electric.",
                isUnderline: true,
              },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="penciled projects"
            count={8}
            texts={[
              {
                text: "Aaron is Cindy's nephew. He's our primary contact for Alps Electric.",
                isUnderline: true,
              },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="project conflicts"
            count={0}
            texts={[
              {
                text: "Aaron is Cindy's nephew. He's our primary contact for Alps Electric.",
                isUnderline: true,
              },
            ]}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="penciled conflicts"
            count={2}
            texts={[
              {
                text: "Aaron is Cindy's nephew. He's our primary contact for Alps Electric.",
                isUnderline: true,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Projects;
