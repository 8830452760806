
import React, { useState } from "react";
import InputSearch from "../../../UI/forms/InputSearch";

interface Props { }

const Roles: React.FC<Props> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expanded2, setExpanded2] = useState<string>("");


  const handleChange = (item: string) => {
    setExpanded(!expanded);
    setExpanded2(item)
  };

  return (
    <div className="scrollbar-none overflow-x" style={{ height: "600px" }}>
      <div className="row p-0 mb-3">
        <div className="col-4">
          <InputSearch placeholder="Search role" />
        </div>

        <button className="btn btn-outline-primary col-auto ms-2">
          <i className="bi bi-funnel-fill h5 m-0"></i>
        </button>
      </div>

      <div
        className="accordion-collapse col-lg-11"
      >
        <div className="accordion-body text-primary">
          <div className="w-100 custom-scrollbar overflow-x">

            <table className="table table-borderless">
              <thead>
                <tr className="text-primary typo-body">
                  <th scope="col" className="col-2 ps-4">NAME</th>
                  <th scope="col" className="col-2"></th>
                  <th scope="col" className="col-1 ps-3">
                    <p className="m-0 p-0">ROLE</p>
                  </th>
                  <th scope="col" className="ps-5">ACTIVE</th>
                </tr>
              </thead>

            </table>
          </div>
        </div>
      </div>


      {["Project", "Edit Video", "Film-video", "Rent a camera"].map(
        (item, index) => (
          <div className="accordion mt-0 mb-0 p-0">
            <div className="accordion-item p-0 border-0" key={index}>
              <p className="accordion-header p-0">
                <div onClick={() => handleChange(item)}>
                  <button
                    className="accordion-button bg-primary-acordion rounded-0 m-0 border-0 "
                    style={{ background: '#6b8ab859', color: '#53607F' }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                  >
                    {item}
                    {expanded && expanded2 === item ? (
                      <i className="bi bi-caret-up-fill ms-1" />
                    ) : (
                      <i className="bi bi-caret-down-fill ms-1" />
                    )}
                  </button>
                </div>
              </p>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
              >
                <div className="accordion-body text-primary">
                  <div className="w-100 custom-scrollbar overflow-x">
                    <table className="table table-borderless">
              

                      <tbody>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                          return (
                            <tr
                              className={`${(index / 2) % 1 > 0 ? "table-primary" : ""
                                } text-primary typo-body`}
                              key={index}
                            >
                              <td className="d-flex align-items-center">
                                <img
                                  className="avatar2"
                                  src="https://m.media-amazon.com/images/I/51P84188A4L._AC_SX466_.jpg"
                                  alt=""
                                />

                                <p className="m-0 ms-2">Denisse Rogers</p>
                              </td>

                              <td className="align-middle">Client</td>
                              <td className="align-middle">
                                Task name currently working on
                              </td>

                              <td className="align-middle">
                                <div className="col-auto d-flex align-items-center">
                                  <i className="bi bi-pencil-fill text-primary mx-1"></i>
                                  <i className="bi bi-chat-left-fill text-primary mx-1"></i>

                                  <input
                                    className="form-check-input p-0 m-0 mx-1"
                                    type="checkbox"
                                  />

                                  <i className="bi bi-trash text-danger mx-1"></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Roles;
