import React, { useEffect, useState } from "react";
import InputDateFatoryForm from "../../../../../components/UI/forms/InputDateFatoryForm";
import InputFloatingLabel from "../../../../../components/UI/forms/InputFloatingLabel";
import SelectFloatingLabel from "../../../../../components/UI/forms/SelectFloatingLabel";
import SelectFloatingLabelJobType from "../../../../../components/UI/forms/SelectFloatingLabelJobType";
import TextArea from "../../../../../components/UI/forms/TextArea";
import TextEdit from "./TextEdit/TextEdit";
import moment from "moment";
import Milestones from "./milestones";
import Deliverable from "./deliverable";
import Document from "./document";
import Requiriment from "./requirement";
import InputDate from "../../../../UI/forms/InputDate";

interface Props {
  onSubmitControlForm?: any;
  setOnSubmitControlForm?: any;
  GetJobType?: any;
  milestoneStateArray?: any;
  setMilestoneStateArray?: any;
  generarId?: any;
  errorFormulary?: boolean;
  setErrorFormulary?: any;
  jobDataById: any;
  idJob: any;
  loading: any;
  activeControlView: any;
  errorFormularyNameJob: boolean;
  errorFormularyStartDateJob: boolean;
  errorFormularyEndDateJob: boolean;
}

const JobForm: React.FC<Props> = (props) => {
  const [textView, setTextView] = useState<boolean>(false);
  const [milestones, setMilestones] = useState<[]>([]);

  const [dates, setDates] = useState<any>([
    props.onSubmitControlForm.start_date,
    props.onSubmitControlForm.end_date,
  ]);

  const milestoneOject = {
    milestones_name: "",
    location: "",
    start_date_milestone: "",
    end_date_milestone: "",
    color: "",
    id: props.generarId(),
  };

  interface Check {
    name: string;
    checked: boolean;
  }
  const [checkBoxDeliverable, setCheckBoxDeliverable] = useState<any>([
    [
      { name: "Deliverable 1", checked: false },
      { name: "Deliverable 2", checked: false },
      { name: "Deliverable 3", checked: false },
      { name: "Deliverable 4", checked: false },
      { name: "Deliverable 5", checked: false },
      { name: "Deliverable 6", checked: false },
      { name: "Deliverable 7", checked: false },
      { name: "Deliverable 8", checked: false },
    ],
  ]);

  const [checkBoxDocument, setCheckBoxDocument] = useState<any>([
    [
      { name: "Document 1", checked: false },
      { name: "Document 2", checked: false },
      { name: "Document 3", checked: false },
      { name: "Document 4", checked: false },
      { name: "Document 5", checked: false },
      { name: "Document 6", checked: false },
      { name: "Document 7", checked: false },
      { name: "Document 8", checked: false },
    ],
  ]);
  const [checkBoxRequirement, setCheckBoxRequirement] = useState<any>([
    [
      { name: "Requirement 1", checked: false },
      { name: "Requirement 2", checked: false },
      { name: "Requirement 3", checked: false },
      { name: "Requirement 4", checked: false },
      { name: "Requirement 5", checked: false },
      { name: "Requirement 6", checked: false },
      { name: "Requirement 7", checked: false },
      { name: "Requirement 8", checked: false },
    ],
  ]);

  const newDeliverable = [
    { name: "Deliverable 1", checked: false },
    { name: "Deliverable 2", checked: false },
    { name: "Deliverable 3", checked: false },
    { name: "Deliverable 4", checked: false },
    { name: "Deliverable 5", checked: false },
    { name: "Deliverable 6", checked: false },
    { name: "Deliverable 7", checked: false },
    { name: "Deliverable 8", checked: false },
  ];

  const newDocument = [
    { name: "Document 1", checked: false },
    { name: "Document 2", checked: false },
    { name: "Document 3", checked: false },
    { name: "Document 4", checked: false },
    { name: "Document 5", checked: false },
    { name: "Document 6", checked: false },
    { name: "Document 7", checked: false },
    { name: "Document 8", checked: false },
  ];

  const newRequirement = [
    { name: "Requirement 1", checked: false },
    { name: "Requirement 2", checked: false },
    { name: "Requirement 3", checked: false },
    { name: "Requirement 4", checked: false },
    { name: "Requirement 5", checked: false },
    { name: "Requirement 6", checked: false },
    { name: "Requirement 7", checked: false },
    { name: "Requirement 8", checked: false },
  ];

  const { jobsData, jobsTypeData } = props.GetJobType;

  const selectData = () => {
    if (jobsTypeData) {
      let type: any = [];

      jobsTypeData.forEach((e: any) => {
        if (e.milestones) {
          type.push(e.milestones);
        }
      });

      setMilestones(type);
    }
  };

  useEffect(() => {
    selectData();
  }, [jobsTypeData]);

  let mil = milestones.flat(3);

  const SelectOptions = mil.map((e: any) => {
    let obj = { label: e.color, value: e.color };

    return obj;
  });

  useEffect(() => {
    if (props.idJob) {
      props.setOnSubmitControlForm({
        ...props.onSubmitControlForm,
        name: props.jobDataById?.name,
        start_date: props.jobDataById?.start_date,
        end_date: props.jobDataById?.end_date,
        project: props.jobDataById?.project?._id,
        file_client_brief: props.jobDataById?.file_client_brief,
        client_brief: props.jobDataById?.client_brief,
      });
      setDates([props.jobDataById?.start_date, props.jobDataById?.end_date]);
    }
  }, [props.idJob, props.jobDataById]);

  const ErrorDate =
    props.errorFormularyStartDateJob && "You must enter a job start date";

  return (
    <div className="custom-scrollbar">
      <div className="">
        <form className="row g-3 ">
          <div className="col-md-6 mt-5">
            <div
              style={{
                marginBottom: "-18px",
              }}
            >
              <InputFloatingLabel
                placeHolder="Job Name"
                value={props.onSubmitControlForm.name}
                isRequired
                onChange={(val) =>
                  props.setOnSubmitControlForm({
                    ...props.onSubmitControlForm,
                    name: val,
                  })
                }
                // errorMessage={props.errorFormularyNameJob && true}
              />
            </div>

            <div>
              {props.errorFormularyNameJob ? (
                <span className=" validation">You must enter a job name</span>
              ) : null}
            </div>
          </div>

          <div className="col-md-6 mt-5">
            <SelectFloatingLabel
              placeholder="Project manager"
              onChange={() => {}}
              value=""
              options={[]}
            />
          </div>
          <div className="col-md-6 " style={{ marginTop: "25px" }}>
            <SelectFloatingLabel
              placeholder="Client manager of job"
              onChange={() => {}}
              value=""
              options={[]}
            />
          </div>
          <div className="col-6" style={{ marginTop: "20px" }}>
            {/* <label
              className="text-primary"
              htmlFor="start_date"
              style={{ fontSize: "12px" }}
            >
              Date
            </label> */}
            <InputDate
              select={"range"}
              touchUi={true}
              onChange={(e: any) => {
                setDates(e.value);

                props.setOnSubmitControlForm({
                  ...props.onSubmitControlForm,
                  start_date: e.value[0],
                  end_date: e.value[1],
                });
              }}
              value={dates}
              // error={ErrorDate}
              placeholder="Select date"
            />
            <div>
              {props.errorFormularyStartDateJob ? (
                <span className=" validation">You must enter a job date</span>
              ) : null}
            </div>
          </div>
          <div>
            <button
              type="button"
              className={
                textView ? "btn btn-default btn-lg" : "btn btn-primary btn-lg"
              }
              onClick={() => setTextView(false)}
            >
              UPLOAD CLIENT BRIEF
            </button>
            <button
              type="button"
              className={
                textView
                  ? "btn btn-primary btn-lg margin-left"
                  : "btn btn-default btn-lg margin-left"
              }
              onClick={() => setTextView(true)}
            >
              TYPE CLIENT BRIEF
            </button>
          </div>
          {textView ? (
            <TextEdit
              onSubmitControlForm={props.onSubmitControlForm}
              setOnSubmitControlForm={props.setOnSubmitControlForm}
              jobDataById={props.jobDataById}
              idJob={props.idJob}
            />
          ) : (
            <div className="col-md-12  container-input-file">
              <div className="custom-input-file col-md-6 col-sm-6 col-xs-6">
                <input
                  type="file"
                  name="files"
                  multiple
                  // accept="image/*"
                  id=""
                  className="input-file"
                  onChange={(val) =>
                    props.setOnSubmitControlForm({
                      ...props.onSubmitControlForm,
                      file_client_brief: val.currentTarget.files?.item(0),
                    })
                  }
                />
                <span
                  className={
                    props.onSubmitControlForm?.file_client_brief !== "" &&
                    props.onSubmitControlForm?.file_client_brief?.name
                      ? "text-success"
                      : ""
                  }
                >
                  {props.onSubmitControlForm?.file_client_brief !== "" &&
                  props.onSubmitControlForm?.file_client_brief?.name
                    ? props.onSubmitControlForm?.file_client_brief?.name
                    : "Select cover picture"}
                </span>
                {props.onSubmitControlForm?.file_client_brief !== "" &&
                props.onSubmitControlForm?.file_client_brief?.name
                  ? ""
                  : " or drag and drop here."}
              </div>
            </div>
          )}

          <div className="col-md-6 mt-5">
            <div className="row">
              <div className="d-flex col-md-12 container-input-file-file">
                <div className="custom-input-file-file col-md-6 col-sm-6 col-xs-6">
                  <input
                    type="file"
                    id=""
                    className="input-file-file "
                    value=""
                    onChange={(val) =>
                      props.setOnSubmitControlForm({
                        ...props.onSubmitControlForm,
                        project_asset_1: val.currentTarget.files?.item(0),
                      })
                    }
                  />
                  <span>Select file </span>
                </div>
              </div>
            </div>
            <div className="cursor-pointer ">
              <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
              <small className=" text-primary "> ADD PROJECT ASSETS</small>
            </div>
          </div>
          <div className="col-md-6 mt-5">
            <div className="row">
              <div className="d-flex col-md-12 container-input-file-file">
                <div className="custom-input-file-file col-md-6 col-sm-6 col-xs-6">
                  <input
                    type="file"
                    id=""
                    className="input-file-file "
                    value=""
                    onChange={(val) =>
                      props.setOnSubmitControlForm({
                        ...props.onSubmitControlForm,
                        project_asset_2: val.currentTarget.files?.item(0),
                      })
                    }
                  />
                  <span>Select file </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            <SelectFloatingLabelJobType
              className="text-black"
              placeholder="Job type"
              value={props.onSubmitControlForm.job_type}
              options={[]}
              onChange={(val: React.ChangeEvent<HTMLSelectElement>) =>
                props.setOnSubmitControlForm({
                  ...props.onSubmitControlForm,
                  job_type: val,
                })
              }
            />
          </div>
          <div className="col-md-6 ">
            <SelectFloatingLabel
              className="text-black"
              placeholder="Terms & conditions"
              value={props.onSubmitControlForm.term_conditions}
              options={[]}
              onChange={(val: React.ChangeEvent<HTMLSelectElement>) =>
                props.setOnSubmitControlForm({
                  ...props.onSubmitControlForm,
                  term_conditions: val,
                })
              }
            />
          </div>
          <div>
            <small className="text-gray">MILESTONES</small>

            {props.milestoneStateArray.map((mileStone: any, index: number) => (
              <Milestones
                key={mileStone.id}
                index={index}
                mileStone={mileStone}
                SelectOptions={SelectOptions}
                milestoneStateArray={props.milestoneStateArray}
                setMilestoneStateArray={props.setMilestoneStateArray}
                onSubmitControlForm={props.onSubmitControlForm}
                setOnSubmitControlForm={props.setOnSubmitControlForm}
                generarId={props.generarId()}
              />
            ))}
          </div>

          <div>
            <div className=" row g-3 ">
              <div
                className="cursor-pointer "
                onClick={() =>
                  // props.milestoneStateArray.length > 1
                  //   ? null
                  //   :
                  props.setMilestoneStateArray([
                    ...props.milestoneStateArray,
                    milestoneOject,
                  ])
                }
                style={{ marginTop: "-10px" }}
              >
                <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6" />
                <small className=" text-primary "> ADD MILESTONE</small>
              </div>

              <div>
                {checkBoxDeliverable.map((deliv: any, index: number) => (
                  <Deliverable
                    key={index}
                    deliv={deliv}
                    index={index}
                    checkBoxDeliverable={checkBoxDeliverable}
                    setCheckBoxDeliverable={setCheckBoxDeliverable}
                    onSubmitControlForm={props.onSubmitControlForm}
                    setOnSubmitControlForm={props.setOnSubmitControlForm}
                  />
                ))}
                <div
                  className="cursor-pointer mb-3"
                  onClick={() =>
                    // checkBoxDeliverable.length > 1
                    //   ? null
                    //   :
                    setCheckBoxDeliverable([
                      ...checkBoxDeliverable,
                      newDeliverable,
                    ])
                  }
                >
                  <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                  <small className=" text-primary "> ADD DELIVERABLE</small>
                </div>
                <div>
                  <div className="container ">
                    <div className="row">
                      {checkBoxDocument.map((docu: any, index: number) => (
                        <Document
                          key={index}
                          docu={docu}
                          index={index}
                          checkBoxDocument={checkBoxDocument}
                          setCheckBoxDocument={setCheckBoxDocument}
                          onSubmitControlForm={props.onSubmitControlForm}
                          setOnSubmitControlForm={props.setOnSubmitControlForm}
                        />
                      ))}
                    </div>
                  </div>
                  <div
                    className="cursor-pointer mb-3"
                    onClick={() =>
                      // checkBoxDeliverable.length > 1
                      //   ? null
                      //   :
                      setCheckBoxDocument([...checkBoxDocument, newDocument])
                    }
                  >
                    <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                    <small className=" text-primary "> ADD DOCUMENTS</small>
                  </div>
                </div>
                <div className="container ">
                  <div className="row">
                    {checkBoxRequirement.map((requi: any, index: number) => (
                      <Requiriment
                        key={index}
                        requirement={requi}
                        index={index}
                        checkBoxRequirement={checkBoxRequirement}
                        setCheckBoxRequirement={setCheckBoxRequirement}
                        onSubmitControlForm={props.onSubmitControlForm}
                        setOnSubmitControlForm={props.setOnSubmitControlForm}
                      />
                    ))}
                  </div>
                </div>
                <div
                  className="cursor-pointer mb-3"
                  onClick={() =>
                    // checkBoxDeliverable.length > 1
                    //   ? null
                    //   :
                    setCheckBoxRequirement([
                      ...checkBoxRequirement,
                      newRequirement,
                    ])
                  }
                >
                  <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                  <small className=" text-primary ">ADD REQURIMENT</small>
                </div>
              </div>
              <div>
                <small className="text-gray">PRE-JOB -TASK</small>
                <div className="row">
                  <div className="col-md-4 mt-1">
                    <InputFloatingLabel
                      placeHolder="Task"
                      value={props.onSubmitControlForm.task}
                      onChange={(val) =>
                        props.setOnSubmitControlForm({
                          ...props.onSubmitControlForm,
                          task: val,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-4 mt-1">
                    <InputFloatingLabel
                      placeHolder="Assign to"
                      value={props.onSubmitControlForm.asignTo}
                      onChange={(val) =>
                        props.setOnSubmitControlForm({
                          ...props.onSubmitControlForm,
                          asignTo: val,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-4 ">
                    <InputDateFatoryForm
                      // placeHolder="End date & time"
                      onChange={(e: any) =>
                        props.setOnSubmitControlForm({
                          ...props.onSubmitControlForm,
                          dueDate: moment(e.value).format("DD/MM/YYYY"),
                        })
                      }
                      value={props.onSubmitControlForm.dueDate}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <TextArea
                    placeHolder="Task description"
                    title=""
                    value={props.onSubmitControlForm.textAreaJob}
                    onChange={(val) =>
                      props.setOnSubmitControlForm({
                        ...props.onSubmitControlForm,
                        textAreaJob: val,
                      })
                    }
                  />
                </div>
                <div className="cursor-pointer ">
                  <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                  <small className=" text-primary "> ADD TASK</small>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobForm;
