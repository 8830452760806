interface Props {
    value:          string | number;
}

const CellReadonlyValue: React.FC<Props> = (props) => {
    const {value} = props;

    return (
        <>
            {value} 
        </>
    )
}
export default CellReadonlyValue