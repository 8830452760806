import { FreelanceData } from "src/models/ContactModel";
import { addUrlProtocol, isURL } from "src/util";

const Icons: Record<'skype' | 'website' | 'discord' | 'facebook' |'twitter' |'instagram' |'linkedin', string> = {
  instagram: 'bi-instagram',
  facebook: 'bi-facebook',
  discord: 'bi-discord',
  twitter: 'bi-twitter',
  linkedin: 'bi-linkedin',
  skype: 'bi-skype',
  website: 'bi-globe',
}

export function SocialAndComm({
  social,
  communication
}: {
  social?: FreelanceData['social'];
  communication?: FreelanceData['communication'];
}) {
    return (
      <>
        {social !== undefined && Object.keys(social).length > 0 && (
          <div className="border-bottom p-0 py-3">
            <p className="m-0 typo-body text-muted text-uppercase">Social</p>

            <section className="row text-primary">
              {Object.entries(social).map(([key, value]) => (
                <div className="col-6" key={key}>
                  {/* @ts-ignore */}
                  <i className={"bi " + Icons[key]}></i>{' '}
                  {isURL(addUrlProtocol(value)) ? (
                    <a href={addUrlProtocol(value)} target="_blank" rel="noopener noreferrer">{addUrlProtocol(value)}</a>
                  ) : (value)}
                </div>
              ))}
            </section>
          </div>
        )}

        {communication !== undefined && Object.keys(communication).length > 0 && (
          <div className="border-bottom p-0 py-3">
            <p className="m-0 typo-body text-muted text-uppercase">Communication</p>

            <section className="row text-primary">
              {Object.entries(communication!).map(([key, value]) => (
                <div className="col-6" key={key}>
                  {/* @ts-ignore */}
                  <i className={"bi " + Icons[key]}></i>{' '}
                  {isURL(addUrlProtocol(value)) ? (
                    <a href={addUrlProtocol(value)} target="_blank" rel="noopener noreferrer">{addUrlProtocol(value)}</a>
                  ) : (value)}
                </div>
              ))}
            </section>
          </div>
        )}
      </>
    )
}