import { ContactType, IContact } from "src/models/ContactModel";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";
import BookableSkillsTable from "../Freelance/BookableSkillsTable";

const TMDetails: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {
  if (contact.type !== ContactType.TEAM_MEMBER) return null;

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3">
        <BookableSkillsTable skills={contact.entity.bookable_skills as string[]} />
      </div>
    </div>
  );
}

export default TMDetails;