import { useState, useRef, useEffect } from 'react';
import ConfirmModal from '../../Modals/ConfirmModal';
import Checkbox from '../../UI/forms/Checkbox';
import ProgressbarTitle from '../../UI/ProgressbarTitle';
//import Description from './tabs/Description';
import Rates from './tabs/Rates/Rates';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import checkFields from '../../../util/CheckFields'
import { CreateItem, CreateAsset, CreateAccesories, CreateRates, CreateItemService, RefreshForNewItem, EditItem, EditItemService } from '../../../store/stockpile/stockpileSlice';
import { AccesoriesModel, AssetModel, PickupInfo, RatesModel } from '../../../models/ItemModel';
import Description from './tabs/Description'
import StepButton from 'src/components/UI/buttons/stepButton';
import { setCheckItemFormAlert, setShowAddItem, setShowAddItemService } from 'src/store/stockpile/RecursosVisualeSlice';
import { EmptyFormService } from 'src/store/stockpile/createServiceForm';
import { useToast } from 'src/hooks/useToast';
import { useParams } from 'react-router-dom';
import { useRequest } from 'src/hooks/useRequest';
import { setDataAsset } from 'src/store/stockpile/selectAssetsSlice';

interface Props {
  onFinish: () => void;
}

const tabs: string[] = [
  'Description',
  'Rates'
];


const EditStockService: React.FC<Props> = (props) => {
  const dispatch = useDispatch()

  const toast = useToast()

  const DescriptionFull: any = useRef()

  const params = useParams<{ itemId: string; }>();


  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [images, setImagesForm] = useState<Array<any>>([]);
  const [checkItemForm, setCheckItemForm] = useState<any>(false);
  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId });

  const Loading = useSelector((state: RootState) => state.stockpileSlice.loading);
  const checkItemFormAlert = useSelector((state: RootState) => state.RecursosVisualeSlice.checkItemFormGeneralDataAlert);


  const [dateCount, setDateCount] = useState<
    { type: string; date: string }[]
  >([{ type: 'date of purchase', date: '' }]);

  /////Errors 
  const Rtes = useSelector((state: RootState) => state.createServiceForm.RatesErrors);
  const Desc = useSelector((state: RootState) => state.createServiceForm.DescriptionErrors);


  /////FORMS
  const data = useSelector((state: RootState) => state.createServiceForm.Description);
  const rates: any = useSelector((state: RootState) => state.createServiceForm.RatesData);

  useEffect(() => {

    [data].map((item) => {
      if (item.skill_sets) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }

      if (item.name) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }
    })

  }, [checkItemForm, data])

  useEffect(() => {
    dispatch(EmptyFormService([]))
  }, [])

  const progress = {
    ...data, ...rates
  }

  // const percenCompletion = (1 - checkFields(progress).percent)
  const percenCompletion = 0.05

  const tabsContent: JSX.Element[] = [
    <Description alertComplete={checkItemFormAlert} ref={DescriptionFull} />,
    <Rates />,
  ];

  const onCancelModalClose = (value?: boolean) => {
    setIsCancelling(false);

    if (value === undefined) {
      return;
    }

    props.onFinish();

  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    setCurrentTab((prev) => {
      if (prev < tabs.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  const setItem = async () => {
    if (!Rtes) {
      const ItemCreated: any = { ...data }
      const res: any = await dispatch(EditItemService(ItemCreated))
      if (res.payload) {
        dispatch(setDataAsset({ item: ItemCreated }))

        //  dispatch(RefreshForNewItem(res.payload))
        ////Create Rates
        let checkRatesField: Array<RatesModel> = [rates]

        checkRatesField.map(async (item) => {
          if (item.daily || item.holiday_day || item.holiday_hour || item.holiday_overtime_hour ||
            item.hour || item.overtime_hour || item.sale || item.week) {

            ////Create Rates
            const AllDataRates = {
              data: rates,
              item: res.payload?._id
            }
            const resRates = await dispatch(CreateRates(AllDataRates))

          }
        })

        toast.success('Edit service item succesfull')
        props.onFinish()
      }

    } else {
      alert('hay errores en el formulario')
    }
  }

  useEffect(() => {
    DescriptionFull?.current?.FullDataForm(ITEM_DATA)
  }, [DescriptionFull, ITEM_DATA])


  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new item will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD ITEM"
          onClose={onCancelModalClose}
        />
      )}

      <div className="container-fluid">
        <div className="row" style={{ height: '90vh' }}>
          <div className="col-12 card h-100" >
            {/* Top actions */}
            <div className="row justify-content-between align-items-center p-2
            col-lg-12 col-md-auto col-sm-12 col-12 align-self-center
            "
              style={{ position: 'absolute', background: 'white', zIndex: 1 }}>

              <div className='p-0 m-0 border-0 col-auto row'>

                <button
                  onClick={setIsCancelling.bind(null, true)}
                  className="btn btn-outline-danger typo-body col-auto border-0">
                  Cancel
                </button>
                <div className='p-0 m-0 border-0 col-auto ms-1'>
                  {currentTab > 0 ? (
                    <StepButton onClick={prevStep} previous />
                  ) : (<div style={{ width: '110px' }}> </div>)}
                </div>
              </div>

              <h4
                className='text-primary col-auto' >Edit Item Service</h4>

              <div className='p-0 m-0 border-0 col-auto row justify-content-between'>
                {currentTab < tabs.length - 1 && (
                  <div className='p-0 m-0 border-0 col-auto me-1'>
                    <StepButton onClick={nextStep} next />
                  </div>
                )}

                <button onClick={setItem} className={`btn btn-outline-success typo-body col-auto border-0
                ${!Desc ? !checkItemForm ? 'disabled' : '' : 'disabled'}
                `}>
                  Save item service
                </button>

              </div>

            </div>
            {/* Tab View */}
            <div className='custom-scrollbar overflow-y h-100 mt-4' >
              <div className={`container ${Loading && 'disabledElement'} mt-5`}>
                <div className="row mt-4">
                  <div className="col">
                    <ul className="nav nav-tabs mt-3 justify-content-between">
                      <div className="row">
                        {tabs.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="nav-item col-auto p-0"
                              onClick={!Desc ? !checkItemForm ? () => { dispatch(setCheckItemFormAlert(true)) } : setCurrentTab.bind(null, index) : () => { }}
                            >
                              <p
                                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : !Desc ? !checkItemForm ? 'disabled' : '' : 'disabled'
                                  }`}
                                aria-current="page"
                              >
                                {item}
                              </p>
                            </li>
                          );
                        })}
                      </div>
                    </ul>

                    <div className="row justify-content-end mt-2">
                      <div className="col-auto">
                        <ProgressbarTitle value={percenCompletion} />

                        <Checkbox
                          title="Mark as completed"
                          value={false}
                          onChange={(value) => { }}
                        />
                      </div>
                    </div>

                    {tabsContent[currentTab]}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </>
      );
};

      export default EditStockService;
