
interface Props {
    onClick: () => void,
    iconright?:any,
    iconleft?:any,
    title:string;
    custom?:any;
    customStyle?:object;
}

const ButtonIconText: React.FC<Props> = (props) => {
    return (
        <button {...props} onClick={props.onClick} className={'btn text-responsive p-2 pt-1'}
            style={{
                display: 'flex',
                border: '1px solid #6B8AB8',
                height: '29px',
                color: '#6B8AB8',
                width: '100%'
            }}
        >
            {props.custom && <img src={props.custom} alt="" style={{...props.customStyle}} /> }
            {props.iconleft?<i className={props.iconleft}></i>:<></>}
            <p style={{ marginLeft: '.5em' }}>{props.title}</p>
            {props.iconright?<i className={props.iconright}></i>:<></>}

        </button>
    )
}

export default ButtonIconText

