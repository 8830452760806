import { createRef, RefObject } from 'react';
import { capitalizeText, getFileUrl, parseSnakeCase } from '.';
import ContactModel, { ContactType, IContact } from '../models/ContactModel';

type contactSectionType = {
  [key: string]: { items: ContactModel[]; ref: RefObject<HTMLDivElement> };
};

export const getSections = (contacts: ContactModel[]): contactSectionType => {
  const ordered = contacts.sort((a, b) => {
    return a.title.toUpperCase().localeCompare(b.title.toUpperCase());
  });

  const data: contactSectionType = {};
  const abc: string[] = ['A', 'B', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'
  , 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  abc.forEach((letter) => {
    data[letter] = { items: [], ref: createRef() };
  })

  for (let contact of ordered) {
    let letter = contact.title[0].toUpperCase();

    if (data[letter] === undefined) {
      data[letter] = {
        items: [],
        ref: createRef<HTMLDivElement>(),
      };
    }

    data[letter].items.push(contact);
  }

  return data;
};

export interface IContactContactTypeEx {
  getSortKey: (contact: IContact) => string;

  getLogo: (contact: IContact) => string;
  getTitle: (contact: IContact) => string;
  getSubtitle: (contact: IContact) => string;
}

class FreelanceEx implements IContactContactTypeEx {

  getSortKey(contact: IContact) {
    return 'entity.first_name';
  }

  getLogo(contact: IContact) {
    if (contact.type !== ContactType.FREELANCE) return '';
    if (!contact.entity.logo) return '';

    return getFileUrl(contact.entity.logo)
  }

  getTitle(contact: IContact) {
    if (contact.type !== ContactType.FREELANCE) return '';

      return `${contact.entity.first_name} ${contact.entity.last_name || ''}`;
  };

  getSubtitle(contact: IContact) {
    if (contact.type !== ContactType.FREELANCE) return '';

    return `${/* contact.entity.bookable_skills?.[0]?.skillset ||  */'No main skill'}, ${parseSnakeCase(capitalizeText(contact.type))}`;
  }
}

class TeamMemberEx implements IContactContactTypeEx {

  getSortKey(contact: IContact) {
    return 'entity.first_name';
  }

  getLogo(contact: IContact) {
    if (contact.type !== ContactType.TEAM_MEMBER) return '';
    if (!contact.entity.logo) return '';

    return getFileUrl(contact.entity.logo)
  }

  getTitle(contact: IContact) {
    if (contact.type !== ContactType.TEAM_MEMBER) return '';

      return `${contact.entity.first_name} ${contact.entity.last_name || ''}`;
  };

  getSubtitle(contact: IContact) {
    if (contact.type !== ContactType.TEAM_MEMBER) return '';

    return `${/* contact.entity.bookable_skills?.[0]?.skillset ||  */'No main skill'}, ${parseSnakeCase(capitalizeText(contact.type))}`;
  }
}

class ProspectEx implements IContactContactTypeEx {

  getSortKey(contact: IContact) {
    return 'entity.first_name';
  }

  getLogo(contact: IContact) {
    if (contact.type !== ContactType.PROSPECT) return '';
    if (!contact.entity.logo) return '';

    return getFileUrl(contact.entity.logo)
  }

  getTitle(contact: IContact) {
    if (contact.type !== ContactType.PROSPECT) return '';

      return `${contact.entity.first_name} ${contact.entity.last_name || ''}`;
  };

  getSubtitle(contact: IContact) {
    if (contact.type !== ContactType.PROSPECT) return '';

    return `${parseSnakeCase(capitalizeText(contact.type))}`;
  }
}

class ClientEx implements IContactContactTypeEx {

  getSortKey(contact: IContact) {
    return 'entity.first_name';
  }

  getLogo(contact: IContact) {
    if (contact.type !== ContactType.CLIENT) return '';
    if (!contact.entity.logo) return '';

    return getFileUrl(contact.entity.logo)
  }

  getTitle(contact: IContact) {
    if (contact.type !== ContactType.CLIENT) return '';

    return `${contact.entity.first_name} ${contact.entity.last_name || ''}`;
  };

  getSubtitle(contact: IContact) {
    if (contact.type !== ContactType.CLIENT) return '';

    return `${contact.entity.organization || contact.entity.personal_email}, ${parseSnakeCase(capitalizeText(contact.type))}`;
  }
}

class OrganizationEx implements IContactContactTypeEx {

  getSortKey(contact: IContact) {
    return 'entity.name';
  }

  getLogo(contact: IContact) {
    if (contact.type !== ContactType.ORGANIZATION) return '';
    if (!contact.entity.logo) return '';

    return getFileUrl(contact.entity.logo)
  }

  getTitle(contact: IContact) {
    if (contact.type !== ContactType.ORGANIZATION) return '';

      return `${contact.entity.name}`;
  };

  getSubtitle(contact: IContact) {
    if (contact.type !== ContactType.ORGANIZATION) return '';

    return capitalizeText(contact.type);
  }
}


export const ContactTypeEx: Record<ContactType, IContactContactTypeEx> = {
  [ContactType.FREELANCE]: new FreelanceEx(),
  [ContactType.TEAM_MEMBER]: new TeamMemberEx(),
  [ContactType.PROSPECT]: new ProspectEx(),
  [ContactType.CLIENT]:  new ClientEx(),
  [ContactType.ORGANIZATION]: new OrganizationEx(),
}
