import React, { useEffect, useState } from "react";

interface Props {
  setViewModal: (value: boolean) => void;
  ApproveQuote: any;
  loadingQuote: any;
  projectDataId: any;
  closeModal: any;
  handleSave: any;
  filterDataRate: any;
  setIs_new_version: (value: boolean) => void;
  savingRate: boolean;
  setSavingRate: (value: boolean) => void;
  viewCreateRate: boolean;
  setViewCreateRate: (value: boolean) => void;
  modalConfirm: any;
  setModalConfirm: (value: boolean) => void;
  secondMessage: boolean;
  setSecoundMessage: (value: boolean) => void;
  idquote: string;
  closeMessage: boolean;
  setCloseMessage: (value: boolean) => void;
}

const EditNewVersionQuote: React.FC<Props> = (props) => {
  // const [secondMessage, setSecoundMessage] = useState<boolean>(false);
  const [client, setClient] = useState<any>([]);
  const [organization, setOrganization] = useState<any>([]);

  const closeModal = () => {
    if (!props.loadingQuote) {
      props.setViewModal(false);
      props.closeModal();
    }
  };

  // const ConfirmQuote = () => {
  //   props.ApproveQuote();
  //   closeModal();
  //   props.setModalConfirm(false);
  //   props.setIs_new_version(false);
  //   // props.setSecoundMessage(false);
  // };

  const saveQuote = () => {
    props.setModalConfirm(true);
    props.setSecoundMessage(true);
    props.setCloseMessage(true);
  };

  const createQuote = () => {
    props.setIs_new_version(true);
    props.setModalConfirm(true);
    props.ApproveQuote();

    closeModal();
  };

  // const createQuoteEdit = () => {
  //   props.setModalConfirm(true);
  //   props.setIs_new_version(true);
  // };

  const selectData = () => {
    [props.projectDataId].map((e: any) => {
      if (e?.client) {
        setClient(e.client.entity);
      }
      if (e?.organization) {
        setOrganization(e.organization.entity);
      }
    });
  };
  useEffect(() => {
    selectData();
  }, [props.projectDataId]);

  const tab = "\u00A0";

  const newVersionRate = () => {
    // setSecoundMessage(true);
    // props.handleSave();
    // props.setSavingRate(true);
    // closeModal();
    // props.setViewCreateRate(true);
    props.setSavingRate(true);
    props.handleSave();
    props.setCloseMessage(true);
  };

  return (
    <div>
      {!props.loadingQuote ? (
        <>
          {!props.secondMessage && !props.savingRate && !props.closeMessage ? (
            <div>
              <div>
                <h1 className="text-secondary fw-bold fs-3">
                  Create a new rate card version?
                </h1>
              </div>
              <div>
                <p
                  className="text-primary"
                  style={{ fontSize: "13px", padding: "12px", width: " 550px" }}
                >
                  Rates from the rate card{" "}
                  {client?.title +
                    tab +
                    client?.first_name +
                    tab +
                    client?.last_name}{" "}
                  has been modified in this quote. Do you want to create a new
                  version of the rate card whith the modified rates?
                </p>
              </div>
              <div>
                <button
                  className="btn btn-danger text-white me-2 p-2"
                  style={{ width: "100px", height: "40px" }}
                  onClick={saveQuote}
                >
                  Ignore
                </button>
                <button
                  className="btn btn-primary text-white  p-2"
                  style={{ width: "170px", height: "40px" }}
                  onClick={newVersionRate}
                >
                  Create a new version
                </button>
              </div>
            </div>
          ) : props.savingRate ? (
            <div className="d-flex flex-column ">
              <h1 className="text-secondary fw-bold fs-2">Saving</h1>
              <p className="mb-4">
                Wait while we create a new rate card version
              </p>
              <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
            </div>
          ) : props.modalConfirm ? (
            !props.loadingQuote && props.idquote ? (
              <>
                <div>
                  <div>
                    <h1 className="text-secondary fw-bold fs-3">
                      Create a new quote version?
                    </h1>
                  </div>
                  <div>
                    <p
                      className="text-primary m-auto mb-3"
                      style={{
                        fontSize: "13px",
                        padding: "12px",
                        width: " 500px",
                      }}
                    >
                      Changes have been made in the quote. Do you want to create
                      a new version?
                    </p>
                  </div>
                  <div>
                    <button
                      className="btn btn-danger text-white me-2 p-2"
                      style={{ width: "150px", height: "40px" }}
                      onClick={closeModal}
                    >
                      cancel
                    </button>
                    <button
                      className="btn btn-success text-white  p-2"
                      style={{ width: "150px", height: "40px" }}
                      onClick={createQuote}
                    >
                      Create a new version
                    </button>
                  </div>
                </div>
                {/* <div>
                  <div>
                    <h1 className="text-secondary fw-bold fs-2">
                      Do you want to save the new version quote?
                    </h1>
                  </div>
                  <div>
                    <p
                      className="text-primary"
                      style={{
                        fontSize: "14px",
                        padding: "12px",
                        width: " 540px",
                      }}
                    ></p>
                  </div>
                  <div>
                    <button
                      className="btn btn-danger text-white me-2 p-2"
                      style={{ width: "100px", height: "40px" }}
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-success text-white  p-2"
                      style={{ width: "150px", height: "40px" }}
                      onClick={ConfirmQuote}
                    >
                      Save quote
                    </button>
                  </div>
                </div> */}
              </>
            ) : (
              <div className="d-flex flex-column ">
                <h1 className="text-secondary fw-bold fs-2">Saving</h1>
                <p className="mb-4">Wait while we saves your changes</p>
                <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
              </div>
            )
          ) : (
            <div>
              <div>
                <h1 className="text-secondary fw-bold fs-3">
                  Create a new quote version?
                </h1>
              </div>
              <div>
                <p
                  className="text-primary m-auto mb-3"
                  style={{ fontSize: "13px", padding: "12px", width: " 500px" }}
                >
                  Changes have been made in the quote. Do you want to create a
                  new version?
                </p>
              </div>
              <div>
                <button
                  className="btn btn-danger text-white me-2 p-2"
                  style={{ width: "150px", height: "40px" }}
                  onClick={closeModal}
                >
                  cancel
                </button>
                <button
                  className="btn btn-success text-white  p-2"
                  style={{ width: "150px", height: "40px" }}
                  onClick={createQuote}
                >
                  Create a new version
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex flex-column ">
          <h1 className="text-secondary fw-bold fs-2">Saving</h1>
          <p className="mb-4">Wait while we saves your changes</p>
          <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
        </div>
      )}
    </div>
  );
};

export default EditNewVersionQuote;
